import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { DashboardRoutes } from "./DashboardRoutes";
import { PrivateRoute } from "./PrivateRoute";
import { AuthContext } from "./../auth/authContext";
import { PublicRoute } from "./PublicRoute";
import { MainPage } from "./../screens/Public/MainPage/MainPage";
import { Login } from "./../screens/Public/Login/Login";
import { RestorePassword } from "./../screens/Public/RestorePassword/RestorePassword";
import { PublicRoutes } from "./PublicRoutes";
import { SignUp } from "./../screens/Public/SignUp/SignUp";
import { SignUpProffesional } from "./../screens/Public/SignUp/SignUpProfessional";
import { NavBarComponent } from "./../Components/NavBarComponent";
import { EventsPublic } from "../screens/Public/EventsPublic/EventsPublic";
import { CompoundsPublic } from "../screens/Public/CompoundsPublic/CompoundsPublic";
import { SuppliersPublic } from "../screens/Public/SuppliersPublic/SuppliersPublic";
import { FooterComponent } from "../Components/FooterComponent/FooterComponent";
import { TermsAndConditions } from "../screens/Public/TermsAndConditions/TermsAndConditions";
import { NoticeOfPrivacy } from "../screens/Public/NoticeOfPrivacy/NoticeOfPrivacy";
import { EventDetailPublic } from "../screens/Public/EventDetailPublic/EventDetailPublic";
import { CompoundDetailPublic } from "../screens/Public/CompoundDetailPublic/CompoundDetailPublic";
import { SupplierDetailPublic } from "../screens/Public/SupplierDetailPublic/SupplierDetailPublic";
import { EnclosureDetailPublic } from "../screens/Public/EnclosureDetailPublic/EnclosureDetailPublic";
import FloatingWhatsApp from "react-floating-whatsapp";
import { information } from "./../constants/BusinessInfo";
import { CreateNewPasswordScreen } from "../screens/Public/CreateNewPasswordScreen/CreateNewPasswordScreen";
import { toast } from 'react-toastify';

export const AppRouter = () => {
  const { user, dispatch } = React.useContext(AuthContext);




  return (
    <>
      <Router forceRefresh>
        <Switch>
          {/*Inicio Público*/}
          <PublicRoute exact path="/home" isAutenticated={user.logged}>
            <NavBarComponent />
            <MainPage
              title="Inicio"
              description="El mejor sitio para encontrar proveedores e inpiración"
            />
            <FooterComponent />
          </PublicRoute>

          {/*Catálogo de eventos*/}
          <PublicRoute exact path="/events" isAutenticated={user.logged}>
            <NavBarComponent />
            <EventsPublic
              title="Eventos"
              description="Catálogo de Eventos Events U Ctrl"
            />
            <FooterComponent />
          </PublicRoute>

          {/*Detalle de Evento*/}
          <PublicRoute exact path="/event/:id" isAutenticated={user.logged}>
            <NavBarComponent />
            <div className="mt-5 pt-4"></div>
            <EventDetailPublic />
            <FooterComponent />
          </PublicRoute>

          {/*Catálogo de Recintos*/}
          <PublicRoute exact path="/enclosures" isAutenticated={user.logged}>
            <NavBarComponent />
            <CompoundsPublic
              title="Recintos"
              description="Catálogo de Recintos Events U Ctrl"
            />
            <FooterComponent />
          </PublicRoute>

          {/*Detalle de Recintos*/}
          <PublicRoute exact path="/enclosure/:id" isAutenticated={user.logged}>
            <NavBarComponent />
            <div className="mt-5 pt-4"></div>
            <EnclosureDetailPublic />
            <FooterComponent />
          </PublicRoute>

          {/*Catálogo de Proveedores*/}
          <PublicRoute exact path="/suppliers" isAutenticated={user.logged}>
            <NavBarComponent />
            <SuppliersPublic
              title="Proveedores"
              description="Catálogo de Proveedores Events U Ctrl"
            />
            <FooterComponent />
          </PublicRoute>

          {/*Detalle de Proveedores*/}
          <PublicRoute exact path="/supplier/:id" isAutenticated={user.logged}>
            <NavBarComponent />
            <SupplierDetailPublic
              title="Detalle Proveedor"
              description="Detalle proveedor Events"
            />
            <FooterComponent />
          </PublicRoute>

          {/* Login */}
          <PublicRoute exact path="/login" isAutenticated={user.logged}>
            <Login
              title="Iniciar Sesión Profesionista"
              description="Builder Advisor"
              type="Profesionista"
            />
          </PublicRoute>
          {/* Login */}
          <PublicRoute
            exact
            path="/restorepassword"
            isAutenticated={user.logged}
          >
            <RestorePassword
              title="Restablecer Contraseña"
              description="Generación de nueva contraseña"
              type="Profesionista"
            />
          </PublicRoute>

          <PublicRoute exact path="/createnewpassword/:uuid">
          <CreateNewPasswordScreen
            title="Cambiar Contraseña"
            description="Genera una nueva contraseña Builder Advisor"
          />
        </PublicRoute>




          {/* SignUp */}
          <PublicRoute exact path="/signup" isAutenticated={user.logged}>
            <SignUp
              title="Iniciar Sesión Profesionista"
              description="Builder Advisor"
              type="Profesionista"
            />
          </PublicRoute>

          {/* Terminos y Condiciones */}
          <PublicRoute exact path="/terms" isAutenticated={user.logged}>
            <NavBarComponent />
            <TermsAndConditions
              title="Términos y Condiciones"
              description="Builder Advisor"
            />
            <FooterComponent />
          </PublicRoute>

          {/* Aviso de Privacidad */}
          <PublicRoute exact path="/privacy" isAutenticated={user.logged}>
            <NavBarComponent />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <NoticeOfPrivacy
              title="Aviso de Privacidad"
              description="Builder Advisor"
            />
            <FooterComponent />
          </PublicRoute>

          {/*Creando Profesionista*/}
          <PublicRoute
            exact
            path="/createprofessional"
            isAutenticated={user.logged}
          >
            <SignUpProffesional
              typeLogin="professional"
              title="Crear Cuenta Profesionista"
              description="Crear tu cuenta ahora gratis!"
            />
          </PublicRoute>

          {/*Creando Proveedor*/}
          <PublicRoute
            exact
            path="/createsupplier"
            isAutenticated={user.logged}
          >
            <SignUp
              typeLogin="supplier"
              title="Crear Cuenta Profesionista"
              description="Crear tu cuenta ahora gratis!"
            />
          </PublicRoute>

          {/* Login Proveedor */}
          <PublicRoute
            exact
            path="/loginpsupplier"
            isAutenticated={user.logged}
          >
            <Login
              title="Iniciar Sesión Proveedor"
              description="Builder Advisor"
              type="Proveedor"
            />
          </PublicRoute>

          {/* Login Administrador */}
          <PublicRoute
            exact
            path="/loginadministrator"
            isAutenticated={user.logged}
          >
            <Login
              title="Iniciar Sesión Administrador"
              description="Builder Advisor"
              type="Administrador"
            />
          </PublicRoute>

          {/* Ruta inicial, define si entra al panel o no */}
          <PrivateRoute
            path="/"
            isAutenticated={user.logged}
            component={DashboardRoutes}
          />

          <Route path="*">No se ha encontrado el recurso</Route>
        </Switch>
      </Router>

      <FloatingWhatsApp
      className="whatsAppPosicionamiento"
        phoneNumber={information.telefono}
        accountName="Events U-Ctrl"
        allowClickAway
        statusMessage={`Saludos`}
        allowEsc={true}
        darkMode={true}
        chatMessage={`¿En qué podemos ayudarte?`}
        statusMessage={`Respuesta en máximo 1 hora`}
        placeholder="Escribe tu pregunta"
        notificationSound={false}
        notificationDelay={60000} // 1 minute
      />
    </>
  );
};
