import React from "react";
import styles from "./CardSponsorComponent.module.css";

export const CardSponsorComponent = (props) => {
  /*props.public true, */

  return (
    <div className="col">
      {props.public === true ? null : (
        <>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-1 mt-3">
            <button
              className="btn btn-sm btn-warning me-md-2"
              type="button"
              onClick={() => {
                props.setCrudAction({ action: "edit", data: props.data });
              }}
            >
              Editar
            </button>
            <button
              className="btn btn-sm btn-danger"
              type="button"
              onClick={() => {
                props.setCrudAction({ action: "delete", data: props.data });
              }}
            >
              Borrar
            </button>
          </div>
        </>
      )}

      <div
        className={`${styles.aspectratio__container} ${styles.aspect_ratio__container_1x1}`}
      >
        <picture className={`${styles.aspect_ratio__overlay}`}>
          <img
            className={`${styles.aspect_ratio__image}`}
            src={props.data.imagenConvertida}
            alt=""
          />
        </picture>
      </div>
      <div className="w-100 text-center pb-3">
        <small>
          <strong>{props.data.nombrePatrocinador}</strong>
        </small>
      </div>
    </div>
  );
};
