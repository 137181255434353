import React from "react";
import { AuthContext } from "../../auth/authContext";
import logo from "../../Images/logo.png";
import { types } from "../../types/types";
import styles from "./HeaderAdminPanelComponent.module.css";
import { NavLink } from "react-router-dom";
import jwt_decode from "jwt-decode";
import Avatar from "@material-ui/core/Avatar";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
export const HeaderAdminPanelComponent = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [userData, setUserData] = React.useState(jwt_decode(user.accessToken));

  React.useEffect(() => {}, []);

  function logout() {
    dispatch({
      type: types.logout,
    });
  }

  return (
    <>
      <header
        style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 500 }}
        className={
          `d-none d-sm-block d-sm-none d-md-block p-2 mb-3 border-bottom ` +
          styles.headerstyle
        }
      >
        <div className="container-fuid">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between">
            <a
              href="/"
              className="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none me-3 ms-3"
            >
              <img src={logo} style={{ height: "40px" }} />
            </a>

            <ul className="nav col-12 col-lg-auto me-lg-auto mb-2 justify-content-end mb-md-0">
              <li>
                <NavLink
                  exact
                  to="/profile"
                  activeClassName="menuActive"
                  className="nav-link px-2 link-dark"
                >
                  Editar Perfil
                </NavLink>
              </li>
              <li>
                {userData.id == userData.idusuarioPadre ? (
                  <>
                    {" "}
                    <NavLink
                      exact
                      to="/businessdata"
                      activeClassName="menuActive"
                      className="nav-link px-2 link-dark"
                    >
                      Editar Datos de Empresa
                    </NavLink>{" "}
                  </>
                ) : null}
              </li>
            </ul>

            {/*<form className="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">
              <input
                type="search"
                className="form-control"
                placeholder="Buscar..."
                aria-label="Search"
              />
                </form> */}

            <div className="dropdown text-end">
              <a
                href="#"
                className="d-block link-dark text-decoration-none"
                id="dropdownUser1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <Avatar
                  style={{ backgroundColor: "#F2561D" }}
                  className="rounded-circle"
                >
                  {userData.nombre == null ? null : userData.nombre.charAt(0)}
                </Avatar>
              </a>
              <ul
                className="dropdown-menu text-small"
                aria-labelledby="dropdownUser1"
              >
                <li>
                  <a className="dropdown-item" href="/profile">
                    <AccountCircleIcon fontSize="small" className="me-1" /> Mi
                    Perfil
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <div
                    onClick={logout}
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                  >
                    <ExitToAppIcon fontSize="small" className="me-1" /> Cerrar
                    Sesión
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
