import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "../Images/noImageAvailable";
import { ToastContainer, toast } from "react-toastify";

export const checkViewPermission = async (uuidVista, user) => {
  let url = API + "permisos/checkPermission/" + uuidVista;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPermisosUsuario = async (idusuario, user) => {
  let url = API + "permisos/getPUser/" + idusuario;

   
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const setPermisosVistaUsuario = async (guid, idusuario, user) => {
  let url = API + "permisos/setPVU/" + guid + "/" + idusuario;

   
  try {
    const response = await axios.put(url, null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const setPermisosFuncionesUsuario = async (guid, idusuario, user) => {
  let url = API + "permisos/setPFU/" + guid + "/" + idusuario;

   
  try {
    const response = await axios.put(url, null, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return [];
  }
};
