import React from "react";
import { encode, decode } from "base64-arraybuffer";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import { useHistory } from "react-router-dom";
import { API } from "../../constants/ApiConnection";
import { types } from "../../types/types";

export const ImagenSupplierComponent = (props) => {


     
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const eliminarImagen = async (id) => {
    try {
      const response = await axios.delete(
        API + "proveedor/eliminarImagenProveedor/" + id,
        null,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success("Imagen eliminada", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between d-flex align-items-center py-2">
        <div className="fw-bold">Galería</div>
        <div
          className="btn btn-sm btn-danger"
          onClick={() => {
            eliminarImagen(props.data.id);
          }}
        >
          Borrar
        </div>
      </div>

      <img
        alt="Galeria"
        className="img-fluid"
        style={{ width: "100%" }}
        src={props.data.imagenConvertida}
      />
    </>
  );
};
