import React from "react";
import styles from "./ImageDescriptionDetailEventComponent.module.css";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import { encode, decode } from "base64-arraybuffer";

import { ShareSocial } from "react-share-social";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";



export const ImageDescriptionDetailEventComponent = (props) => {

  let finalUrlImage = "";
  if (props.data.imagenPortada) {
    finalUrlImage = `data:image/png;base64,${encode(
      props.data.imagenPortada.data
    )}`;
  } else {
    finalUrlImage = "https://picsum.photos/1000/200?random=1";
  }
  return (
    <>
      <div className="col-12 bg-white">
        <img
          alt="ImagenEvento"
          className={styles.fotografiaPrincipalEventoContenido}
          src={finalUrlImage}
        />

        <div className="p-4">
          <h5 className="tituloEventoContenido">{props.data.tituloEvento}</h5>
          <h6 className="text-end">
            <strong>
              {" "}
              <small className="font-weight-bold">Compartir: &nbsp;</small>
            </strong>

            <TwitterShareButton className="mx-1" url={"https://www.eventsuctrl.com/event/" + props.data.id } >
                    <TwitterIcon size={26} round={true} />
                  </TwitterShareButton>
                  <FacebookShareButton className="mx-1" url={"https://www.eventsuctrl.com/event/" + props.data.id } >
                    <FacebookIcon size={26} round={true} />
                  </FacebookShareButton>
                  <WhatsappShareButton className="mx-1" url={"https://www.eventsuctrl.com/event/" + props.data.id } >
                    <WhatsappIcon size={26} round={true} />
                  </WhatsappShareButton>

          </h6>
          <hr />
          <div className="informacionEventoContenido">
            <p>{props.data.descripcionLargaEvento}</p>
          </div>
        </div>
      </div>
    </>
  );
};
