import React from 'react'
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { CardDirectorioEnclosureComponent } from '../CardDirectorioEnclosureComponent/CardDirectorioEnclosureComponent';
export const DirectorioRecintoPublicComponent = (props) => {
    return (
        <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
            >
              <Masonry gutter={"10px"}>
                {props.data.map((data, index) => (
                  <CardDirectorioEnclosureComponent
                    data={data}
                    key={index}
                    admin={false}
                    index={index}
                  />
                ))}
              </Masonry>
            </ResponsiveMasonry>
    )
}
