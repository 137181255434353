import React from "react";
import { getCarousel } from "../../../API/APICarouselPrincipal";
import { AuthContext } from "../../../auth/authContext";
import { NoDataComponent } from "../../../Components/NoDataComponent/NoDataComponent";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { AddAdminCarouselPrincipalScreen } from "./AddAdminCarouselPrincipalScreen/AddAdminCarouselPrincipalScreen";
import { EditAdminCarouselPrincipalScreen } from "./EditAdminCarouselPrincipalScreen/EditAdminCarouselPrincipalScreen";
import { DeleteAdminCarouselPrincipalScreen } from "./DeleteAdminCarouselPrincipalScreen/DeleteAdminCarouselPrincipalScreen";
import { CardImagenCarouselComponent } from "../../../Components/CardImagenCarouselComponent/CardImagenCarouselComponent";
import { ChangeOrderCarouselPrincipalScreen } from "./ChangeOrderCarouselPrincipalScreen/ChangeOrderCarouselPrincipalScreen";
export const AdminCarouselPrincipalScreen = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getCarousel(null, user).then((resultado) => {
      setFinalData(resultado);
    });
  }, []);

  React.useEffect(() => {
    getCarousel(null, user).then((resultado) => {
      setFinalData(resultado);
    });
  }, [crudAction]);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>
                En esta sección podrás agregar imágenes al carrousel principal
              </strong>
              <br></br>
              <small>*Podrás agregar cuantas imagenes necesites.</small>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar Imagen
              </button>
            </div>
          </div>
        </div>
      </div>

      {finalData.length === 0 ? (
        <>
          <NoDataComponent nombre="imágenes en carousel" />
        </>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row row-cols-md-3 row-cols-sm-2">
              {finalData.map((imagen, index) => (
                <CardImagenCarouselComponent
                  key={index}
                  data={imagen}
                  crudAction={crudAction}
                  setCrudAction={setCrudAction}
                />
              ))}
            </div>
          </div>
        </>
      )}

      {crudAction.action === "add" ? (
        <>
          <AddAdminCarouselPrincipalScreen
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Imagen a Carousel"
          />
        </>
      ) : null}
      {crudAction.action === "edit" ? (
        <>
          <EditAdminCarouselPrincipalScreen
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="plano"
          />
        </>
      ) : null}
      {crudAction.action === "delete" ? (
        <>
          <DeleteAdminCarouselPrincipalScreen
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="imagen a Carousel"
          />
        </>
      ) : null}
      {crudAction.action === "changeOrder" ? (
        <>
          <ChangeOrderCarouselPrincipalScreen
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="imagen a carrousel"
          />
        </>
      ) : null}
    </>
  );
};
