import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "../Images/noImageAvailable";
import { ToastContainer, toast } from "react-toastify";

export const getEventosBusqueda = async (values) => {
  let url = API + "busqueda/buscarEvento/";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    for (const imagen of response.data) {
      if (imagen.imagenPortada === null) {
        imagen.imagenDecoded = noImageAvailable();
      } else {
        imagen.imagenDecoded =
          "data:image/png;base64," + encode(imagen.imagenPortada.data);
      }
    }

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getRecintosBusqueda = async (values) => {
  let url = API + "busqueda/buscarRecinto/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    for (const imagen of response.data) {
      if (imagen.imagenRecinto === null) {
        imagen.imagenDecoded = noImageAvailable();
      } else {
        imagen.imagenDecoded =
          "data:image/png;base64," + encode(imagen.imagenRecinto.data);
      }
    }

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getProveedoresBusqueda = async (values) => {
  let url = API + "busqueda/buscarProveedor/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    for (const imagen of response.data) {
      if (imagen.imagenProveedor === null) {
        imagen.imagenDecoded = noImageAvailable();
      } else {
        imagen.imagenDecoded =
          "data:image/png;base64," + encode(imagen.imagenProveedor.data);
      }
    }

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getCategoriaEventoBusqueda = async () => {
  let url = API + "catalogue/getCategoriaEvento/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getCategoriaRecintoBusqueda = async () => {
  let url = API + "recintos/getCategoriasRecinto/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getCategoriaProveedorBusqueda = async () => {
  let url = API + "proveedor/getCategoriasProveedor/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getSubCategoriaEventoBusqueda = async (idcategoria) => {
  let url = API + "catalogue/getSubCategoriaEvento/" + idcategoria;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getSubCategoriaRecintoBusqueda = async (idcategoria) => {
  let url = API + "recintos/getSubCategoriasRecinto/" + idcategoria;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getSubCategoriaProveedorBusqueda = async (idcategoria) => {
  let url = API + "proveedor/getSubCategoriasProveedor/" + idcategoria;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getEstadosBusqueda = async () => {
  let url = API + "catalogue/getEstado/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getCiudadesBusqueda = async (idestado) => {
  let url = API + "catalogue/getCiudad/" + idestado;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};
