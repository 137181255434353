/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../../constants/ApiConnection";
import { AuthContext } from "../../../auth/authContext";
import { types } from "../../../types/types";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  nombre: yup.string("Ingresa nombre").required("Nombre es necesario"),
  puesto: yup.string("Ingresa puesto").required("Puesto es necesario"),
  telefono: yup
    .string("Ingresa teléfono")
    .trim()
    .matches(
      /(?:\d{1}\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})/g,
      "Número no válido"
    )
    .required("Teléfono es necesario"),
  email: yup
    .string("Ingresa correo")
    .email("Ingresa un correo válido")
    .required("El Correo es necesario"),
});

export const EditDataContactSupplierComponent = (props) => {
  const [isDisabled, setIsDisabled] = React.useState(false);

  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      idproveedor: props.crudAction.data.id,
      nombre: props.crudAction.data.nombre,
      puesto: props.crudAction.data.puesto,
      telefono: props.crudAction.data.telefono,
      email: props.crudAction.data.email,
      imagenPersona: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addData(values);
    },
  });

  const addData = async (values) => {
    setIsDisabled(true);

    var bodyFormData = new FormData();
    bodyFormData.append("idproveedor", values.idproveedor);
    bodyFormData.append("nombre", values.nombre);
    bodyFormData.append("puesto", values.puesto);
    bodyFormData.append("telefono", values.telefono);
    bodyFormData.append("email", values.email);
    bodyFormData.append("imagenPersona", values.imagenPersona);

    try {
      const response = await axios.put(
        API + "proveedor/updateDirectorio/" + props.crudAction.data.id,
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success(
        "Contacto editado correctamente, actualizando información.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
      setIsDisabled(false);
      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Agregar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              {isDisabled === true ? (
                <>
                  <div className="alert alert-primary" role="alert">
                    procesando información
                  </div>
                </>
              ) : null}

              <TextField
                fullWidth
                id="nombre"
                name="nombre"
                disabled={isDisabled}
                label="Nombre de Contacto"
                className="mb-2"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
              />
              <TextField
                fullWidth
                id="puesto"
                name="puesto"
                disabled={isDisabled}
                label="Puesto"
                className="mb-2"
                value={formik.values.puesto}
                onChange={formik.handleChange}
                error={formik.touched.puesto && Boolean(formik.errors.puesto)}
                helperText={formik.touched.puesto && formik.errors.puesto}
              />
              <TextField
                fullWidth
                id="telefono"
                name="telefono"
                disabled={isDisabled}
                label="telefono"
                className="mb-2"
                type="number"
                value={formik.values.telefono}
                onChange={formik.handleChange}
                error={
                  formik.touched.telefono && Boolean(formik.errors.telefono)
                }
                helperText={formik.touched.telefono && formik.errors.telefono}
              />
              <TextField
                fullWidth
                id="email"
                name="email"
                disabled={isDisabled}
                label="email"
                className="mb-2"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />

              {/*
                <div className="my-3">
                <label for="imagenGaleria" className="form-label">
                  Subir imagen de contacto: &nbsp;
                  <span className="badge rounded-pill bg-primary">PNG Y JPG</span>
                </label>
                <input
                  className="form-control"
                  isDisabled={isDisabled}
                  type="file"
                  id="imagenGaleria"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    formik.setFieldValue("imagenPersona", e.target.files[0]);
                  }}
                />
                </div>*/}

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
                disabled={isDisabled}
              >
                Agregar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
