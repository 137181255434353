import React from "react";
import { makeStyles } from "@material-ui/core/styles";

export const HeaderAnnouncementComponent = (props) => {
  const useStyles = makeStyles({
    backgroundSection: {
      background: props.background
        ? 'linear-gradient(rgba(255,255,255,0.65),rgba(255,255,255,0.65)),url("https://source.unsplash.com/1600x900/?conference") no-repeat center center fixed'
        : "#f2561d",
      backgroundSize: "cover",
      color: props.background ? "black" : "white",
    },
  });

  const classes = useStyles();

  return (
    <>
      <section
        className={
          props.icon
            ? `pt-4 pb-4 text-center ${classes.backgroundSection}`
            : `pt-5 pb-5 text-center ${classes.backgroundSection}`
        }
      >
        {props.icon ? props.icon : null}
        {props.icon ? (
          <>
            {" "}
            <h4>{props.title}</h4>{" "}
          </>
        ) : (
          <>
            {" "}
            <h2>{props.title}</h2>{" "}
          </>
        )}

        <p className="m-0">
          <small>{props.subtitle}</small>
        </p>
      </section>
    </>
  );
};
