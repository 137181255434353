import React from "react";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logoImage from "../../../Images/logo.png";
import { CountryStateCity } from "./../../../helpers/CountryStateCity";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../../auth/authContext";
import { API, HEADERS_API_JSON } from "./../../../constants/ApiConnection";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import IconButton from "@material-ui/core/IconButton";
import PhotoCamera from "@material-ui/icons/PhotoCamera";

const validationSchema = yup.object({
  email: yup
    .string("Ingresa tu correo electrónico")
    .email("Ingrresa un correo válido")
    .required("Correo necesario"),
  password: yup
    .string("Ingrgesa contraseña")
    .min(8, "la contraseña deberá de tener un mínimo de 8 caracteres")
    .required("Contraseña requerida"),
  nombreProfesionista: yup
    .string("Ingresa tu(s) nombre(s)")
    .required("El nombre es necesario"),
  apellidoProfesionista: yup
    .string("Ingresa tus apellidos ")
    .required("Apellidos necesarios"),
  descripcion: yup
    .string("Ingresa tu nombre de proveedor")
    .required("La descripción es necesaria"),
  calle: yup.string("Ingresa calle").required("La calle es necesaria"),
  numeroExterior: yup
    .string("Ingresa Número Exterior")
    .required("Número Exterior necesario"),
  colonia: yup
    .string("Ingresa una colonia")
    .required("La colonia es necesaria"),
  codigoPostal: yup
    .number("Ingresa Número")
    .required("El Código Postal es necesario")
    .positive("No hay números negativos en CP, verifica!"),
  facebookUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingresa una URL de facebook correcta"
    )
    .required("Dirección Necesaria"),
  instagramUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingresa una URL de Instagram correcta"
    )
    .required("Dirección Necesaria"),
  webUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingresa una URL correcta"
    )
    .required("Dirección Necesaria"),
  whatsapp: yup
    .string()
    .matches(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Ingresa número de teléfono válido"
    )
    .required("Whatsapp Necesario"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignUpProffesional = (props) => {
  const formik = useFormik({
    initialValues: {
      nombreProfesionista: "",
      apellidoProfesionista: "",
      descripcion: "",
      calle: "",
      numeroExterior: "",
      numeroInterior: "",
      edificio: "",
      colonia: "",
      codigoPostal: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      
      setDisabledTextInput(true);
    //  alert(JSON.stringify(values, null, 2));

      ApiCall(values);
    },
  });

  const ApiCall = async (values) => {
    try {
      //URL
      const APIURL = API + "proveedor/createProveedor";

      const response = await axios.post(APIURL, values, HEADERS_API_JSON(null));
      if (response.status === 200) {
        toast.success("Transfiriendo a Panel");
        setUserData(values);
        setCreatedAccount(false);
      }

      
    } catch (error) {
      toast.error(error.response.data.message);
      setDisabledTextInput(false);
    }
  };

  const classes = useStyles();

  // const [countryList, setCountryList] = React.useState(CountryStateCity);

  const [disabledTextInput, setDisabledTextInput] = React.useState(false);

  const [createdAccount, setCreatedAccount] = React.useState(true);

  const [userData, setUserData] = React.useState({});

  return (
    <>
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>

      {createdAccount ? (
        <>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
              <img src={logoImage} style={{ width: "50%" }} />
              <Typography component="h1" variant="h5">
                Crear Cuenta <strong>Profesionista</strong>
              </Typography>
              <p>
                Necesitamos la siguiente información para crear tu cuenta, en
                unos minutos tendrás acceso a miles de clientes potenciales.
              </p>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={disabledTextInput}
                      autoComplete="nombreProfesionista"
                      size="small"
                      name="nombreProfesionista"
                      fullWidth
                      id="nombreProfesionista"
                      label="Nombre Profesionista"
                      autoFocus
                      value={formik.values.nombreProfesionista}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nombreProfesionista &&
                        Boolean(formik.errors.nombreProfesionista)
                      }
                      helperText={
                        formik.touched.nombreProfesionista &&
                        formik.errors.nombreProfesionista
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={disabledTextInput}
                      autoComplete="apellidoProfesionista"
                      size="small"
                      name="apellidoProfesionista"
                      fullWidth
                      id="apellidoProfesionista"
                      label="Apellidos Profesionista"
                      autoFocus
                      value={formik.values.apellidoProfesionista}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.apellidoProfesionista &&
                        Boolean(formik.errors.apellidoProfesionista)
                      }
                      helperText={
                        formik.touched.apellidoProfesionista &&
                        formik.errors.apellidoProfesionista
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <input
                      accept="image/png, image/jpeg"
                      style={{display: 'none'}}
                      id="contained-button-file"
                      multiple
                      type="file"
                    />
                    <label style={{width: '100%'}} htmlFor="contained-button-file">
                        <br></br>
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                        style={{width: '100%'}}
                      >
                        Subir Imagen
                      </Button>
                    </label>
                   
                  </Grid>
                  
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={disabledTextInput}
                      fullWidth
                      size="small"
                      id="calle"
                      label="Calle"
                      name="calle"
                      autoComplete="calle"
                      value={formik.values.calle}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.calle && Boolean(formik.errors.calle)
                      }
                      helperText={formik.touched.calle && formik.errors.calle}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      disabled={disabledTextInput}
                      fullWidth
                      size="small"
                      id="numeroExterior"
                      label="Ext."
                      name="numeroExterior"
                      autoComplete="numeroExterior"
                      value={formik.values.numeroExterior}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.numeroExterior &&
                        Boolean(formik.errors.numeroExterior)
                      }
                      helperText={
                        formik.touched.numeroExterior &&
                        formik.errors.numeroExterior
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      disabled={disabledTextInput}
                      fullWidth
                      size="small"
                      id="numeroInterior"
                      label="Ext."
                      name="numeroInterior"
                      autoComplete="numeroInterior"
                      value={formik.values.numeroInterior}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <TextField
                      disabled={disabledTextInput}
                      fullWidth
                      size="small"
                      id="edificio"
                      label="Edificio"
                      name="edificio"
                      autoComplete="edificio"
                      value={formik.values.edificio}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={disabledTextInput}
                      fullWidth
                      size="small"
                      id="colonia"
                      label="Colonia"
                      name="colonia"
                      autoComplete="colonia"
                      value={formik.values.colonia}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.colonia && Boolean(formik.errors.colonia)
                      }
                      helperText={
                        formik.touched.colonia && formik.errors.colonia
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={disabledTextInput}
                      fullWidth
                      size="small"
                      id="codigoPostal"
                      label="Código Postal"
                      name="codigoPostal"
                      autoComplete="codigoPostal"
                      value={formik.values.codigoPostal}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.codigoPostal &&
                        Boolean(formik.errors.codigoPostal)
                      }
                      helperText={
                        formik.touched.codigoPostal &&
                        formik.errors.codigoPostal
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      size="small"
                      id="idpais"
                      label="Pais"
                      name="idpais"
                      autoComplete="idpais"
                      value={formik.values.idpais}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      size="small"
                      id="idestado"
                      label="Estado"
                      name="idestado"
                      autoComplete="idestado"
                      value={formik.values.idestado}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      size="small"
                      id="idciudad"
                      label="Ciudad"
                      name="idciudad"
                      autoComplete="idciudad"
                      value={formik.values.idciudad}
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      size="small"
                      id="whatsapp"
                      label="Whatsapp (10 dígitos)"
                      name="whatsapp"
                      autoComplete="whatsapp"
                      value={formik.values.whatsapp}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.whatsapp &&
                        Boolean(formik.errors.whatsapp)
                      }
                      helperText={
                        formik.touched.whatsapp && formik.errors.whatsapp
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      size="small"
                      id="facebookUrl"
                      label="Url Facebook"
                      name="facebookUrl"
                      autoComplete="facebookUrl"
                      value={formik.values.facebookUrl}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.facebookUrl &&
                        Boolean(formik.errors.facebookUrl)
                      }
                      helperText={
                        formik.touched.facebookUrl && formik.errors.facebookUrl
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      size="small"
                      id="instagramUrl"
                      label="Url Instagram"
                      name="instagramUrl"
                      autoComplete="instagramUrl"
                      value={formik.values.instagramUrl}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.instagramUrl &&
                        Boolean(formik.errors.instagramUrl)
                      }
                      helperText={
                        formik.touched.instagramUrl &&
                        formik.errors.instagramUrl
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      size="small"
                      id="webUrl"
                      label="Página Web"
                      name="webUrl"
                      autoComplete="webUrl"
                      value={formik.values.webUrl}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.webUrl && Boolean(formik.errors.webUrl)
                      }
                      helperText={formik.touched.webUrl && formik.errors.webUrl}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      id="email"
                      size="small"
                      label="Correo electrónico"
                      name="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      id="password"
                      type="password"
                      size="small"
                      label="Contraseña"
                      name="password"
                      autoComplete="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Grid>
                </Grid>
                <Button
                  disabled={disabledTextInput}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Crear Cuenta
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="#" variant="body2">
                      Ya tienes cuenta de profesionista? inicia sesión aquí.
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        </>
      ) : (
        <>
          <Container
            component="main"
            maxWidth="md"
            className="my-5"
            style={{ paddingTop: "15vh", paddingBottom: "15vh" }}
          >
            <CssBaseline />
            <div className={classes.paper}>
              <img src={logoImage} style={{ width: "50%" }} />
              <Typography component="h1" variant="h5">
                Gracias! <strong> {userData.nombreProveedor}</strong>
              </Typography>
              <p className="text-center">
                Te damos la bienvenida a una de las plataformas de comunicación
                más importantes de México. <br></br>
                Para entrar a tu panel te invitamos a pulsar el siguiente boton:
              </p>

              <div className="btn btn-lg btn-success">Acceder a panel</div>
            </div>
          </Container>
        </>
      )}
    </>
  );
};
