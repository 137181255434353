import React from "react";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
export const CardFrecuentQuestionEventComponent = (props) => {
  return (
    <div className="col">
      <div className="card mb-2">
        {props.editable === true ? (
          <>
            <div className="card-header bg-white">
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-sm text-white btn-secondary"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "edit", data: props.data })
                  }
                >
                  Editar
                </button>
                <button
                  className="btn btn-sm text-white btn-danger"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "delete", data: props.data })
                  }
                >
                  Eliminar
                </button>
              </div>
            </div>
          </>
        ) : null}

        <div className="card-body">
          <p className="card-text font-weight-bold m-0 fw-bold h5">
            {props.index + 1}.- ¿{props.data.pregunta}?
          </p>
          <p className="card-text m-0 fw-light">{props.data.respuesta}</p>
        </div>
      </div>
    </div>
  );
};
