import React from "react";
import { AuthContext } from "../../../auth/authContext";
import { NoDataComponent } from "../../../Components/NoDataComponent/NoDataComponent";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { AddAdminSupplierAds } from "./AddAdminSupplierAds/AddAdminSupplierAds";
import { toast } from "react-toastify";
import { types } from "../../../types/types";
import { useHistory } from "react-router-dom";
import { AsignarAProveedorAd } from "./AsignarAProveedorAd/AsignarAProveedorAd";
import {
  desactivarPublicidadEnclosure,
  getAdsEnclosure,
} from "../../../API/APIPublicidadEnclosure";
import {
  desactivarPublicidadSupplier,
  getAdsSupplier,
} from "../../../API/APIPublicidadSupplier";

export const SupplierAds = () => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getAdsSupplier(null, user).then((resultado) => {
      setFinalData(resultado.data);
    });
  }, [crudAction]);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>En esta sección podrás agregar publicidad</strong>
              <br></br>
              <small>
                Si tienes seleccionado un evento a determinada publicidad este
                se mostrará únicamente a la seleccionada.
              </small>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar Publicidad
              </button>
            </div>
          </div>
        </div>
      </div>
      {finalData.length === 0 ? (
        <>
          <NoDataComponent nombre="contenidos de publicidad" />
        </>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row row-cols-md-4 row-cols-sm-4">
              {finalData.map((imagen, index) => (
                <div className="col mb-3">
                  <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-2">
                    <button
                      className="btn btn-sm btn-danger"
                      type="button"
                      onClick={() => {
                        desactivarPublicidadSupplier(
                          null,
                          user,
                          imagen.id
                        ).then((resultado) => {
                          if (resultado.status === 200) {
                            toast.success(
                              "Se Ha desactivado la publicidad seleccionada"
                            );
                          } else if (resultado.status === 401) {
                            dispatch({
                              type: types.logout,
                            });
                            history.replace("/");
                          }
                          setCrudAction({ action: null, data: null });
                        });
                      }}
                    >
                      Eliminar
                    </button>
                    <button
                      className="btn btn-sm btn-info"
                      type="button"
                      onClick={() => {
                        setCrudAction({ action: "asignar", data: imagen });
                      }}
                    >
                      Asignar a Proveedor
                    </button>
                  </div>
                  <img
                    src={imagen.imagenConvertida}
                    alt="publicidad"
                    className="w-100 mb-2"
                  />
                  {imagen.idevento != 0 ? (
                    <>
                      <span className="badge bg-primary">
                        Asignada a evento: {imagen.idevento}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="badge bg-info text-white ">
                        Aparecerá de manera sorteada en los eventos
                      </span>
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      {crudAction.action === "add" ? (
        <>
          <AddAdminSupplierAds
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Publicidad"
          />
        </>
      ) : null}

      {crudAction.action === "asignar" ? (
        <>
          <AsignarAProveedorAd
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Asignar"
          />
        </>
      ) : null}
    </>
  );
};
