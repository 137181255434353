import React from "react";
import ImageUploading from "react-images-uploading";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import DeleteIcon from "@material-ui/icons/Delete";
import ImageIcon from "@material-ui/icons/Image";



export const UploadImagesComponent = (props) => {
  const [images, setImages] = React.useState([]);
  const maxNumber = props.numberImages;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
   
    setImages(imageList);
    props.setImageCover(imageList);
  };




  return (
    <div className="App">
      <ImageUploading
        multiple
        value={images}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="data_url"
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            <div
              className="btn btn-sm btn-success"
              style={isDragging ? { color: "red" } : null}
              onClick={onImageUpload}
              {...dragProps}
            >
              <ImageIcon fontSize="inherit" /> Pulsa o carga la imagen aquí
            </div>
            &nbsp;
            <div
              className="btn btn-sm btn-danger"
              onClick={onImageRemoveAll}
            >
              <DeleteIcon fontSize="inherit" /> Eliminar
            </div>
            <br></br>
            <br></br>
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 1 }}
            >
              <Masonry>
                {imageList.map((image, index) => (
                  <div key={index} className="card m-2">
                    <div className="card-body">
                      <img
                        alt={index}
                        src={image.data_url}
                        style={{ width: "100%" }}
                      />
                      <div className="image-item__btn-wrapper">
                        {/*<button className="btn btn-sm btn-info" onClick={() => onImageUpdate(index)}>Actualizar</button>*/}
                        <div
                          className="btn btn-sm btn-danger mt-3"
                          onClick={() => onImageRemove(index)}
                        >
                          <DeleteIcon fontSize="inherit" /> Eliminar
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        )}
      </ImageUploading>
    </div>
  );
};
