import React from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { UserDecode } from "./../../helpers/UserDecode";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import { AuthContext } from "./../../auth/authContext";
import axios from "axios";
import { API } from "./../../constants/ApiConnection";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import SaveIcon from "@material-ui/icons/Save";
import {
  getCategoriasProveedor,
  getDetalleProveedor,
  getSubCategoriasProveedor,
  updateProveedor,
} from "../../API/APIProveedores";
import { HorariosAtencionComponent } from "../HorariosAtencionComponent/HorariosAtencionComponent";

import InputAdornment from "@material-ui/core/InputAdornment";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";



export const EditGeneralDataSupplier = (props) => {

    //Poniendo el objeto de días
    const [horariosAtencion, setHorariosAtencion] = React.useState([
      {
        id: 1,
        nombre: "Lunes",
        horaInicio: "00:00",
        horaFin: "05:00",
      },
    ]);
  
    React.useEffect(() => {
      formik.setFieldValue("horarioAtencion", JSON.stringify(horariosAtencion));
  
      
    }, [horariosAtencion]);


  let idproveedor = props.idproveedor;
  let history = useHistory();
  const idusuarioPadre = UserDecode().idusuarioPadre;
  const [disabled, setDisabled] = React.useState(false);
  const { user, dispatch } = React.useContext(AuthContext);
  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);
  const [categoriasRecinto, setCategoriasRecinto] = React.useState([]);
  const [subCategoriasRecinto, setSubCategoriasRecinto] = React.useState([]);
  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    getDetalleProveedor(null, user, idproveedor).then((resultado) => {
      formik.setFieldValue("idusuarioPadre", resultado[0].idusuarioPadre);
      formik.setFieldValue("nombreProveedor", resultado[0].nombreProveedor);
      formik.setFieldValue("horarioAtencion", resultado[0].horarioAtencion);
      formik.setFieldValue("direccion", resultado[0].direccion);
      formik.setFieldValue("descripcion", resultado[0].descripcion);
      formik.setFieldValue("googleMaps", resultado[0].googleMaps);
      formik.setFieldValue("telefono", resultado[0].telefono);
      formik.setFieldValue("whatsapp", resultado[0].whatsapp);
      formik.setFieldValue("emailContacto", resultado[0].emailContacto);
      formik.setFieldValue("web", resultado[0].web);
      formik.setFieldValue("facebook", resultado[0].facebook);
      formik.setFieldValue("twitter", resultado[0].twitter);
      formik.setFieldValue("instagram", resultado[0].instagram);
      formik.setFieldValue("ext", resultado[0].ext);

      setHorariosAtencion(JSON.parse(resultado[0].horarioAtencion));


      getCategoriasProveedor(null, user).then((resultadoCategoriasRecinto) => {
        setCategoriasRecinto(resultadoCategoriasRecinto.data);

        formik.setFieldValue(
          "idcategoriaProveedor",
          resultado[0].idcategoriaProveedor
        );

        getSubCategoriasProveedor(resultado[0].idcategoriaProveedor, user).then(
          (resultadoSubCategorias) => {
            setSubCategoriasRecinto(resultadoSubCategorias);
            formik.setFieldValue(
              "idsubCategoriaProveedor",
              resultado[0].idsubCategoriaProveedor
            );
          }
        );
      });

      getStates().then((resultadEstados) => {
        formik.setFieldValue("idestado", resultado[0].idestado);
        getMunicipios(resultado[0].idestado).then((resultadoMunicipios) => {
          formik.setFieldValue("idciudad", resultado[0].idciudad);
        });
      });
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      idusuarioPadre: idusuarioPadre,
      idcategoriaProveedor: "",
      idsubCategoriaProveedor: "",
      nombreProveedor: "",
      idciudad: "",
      descripcion: "",
      idestado: "",
      horarioAtencion: "",
      direccion: "",
      googleMaps: "",
      telefono: "",
      whatsapp: "",
      emailContacto: "",
      web: "",
      facebook: "",
      twitter: "",
      instagram: "",
      ext: "",
      imagenProveedor: null,
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => {
      setDisabled(true);
      if (
        values.nombreRecinto === "" ||
        values.idcategoriaProveedor === "" ||
        values.idciudad === "" ||
        values.idestado === "" ||
        values.idcategoriaProveedor === ""
      ) {
        toast.error(
          "Debes introducir como mínimo nombre, categoría, ciudad y estado"
        );
        setDisabled(false);
      } else {
        var bodyFormData = new FormData();
        bodyFormData.append("idusuarioPadre", values.idusuarioPadre);
        bodyFormData.append(
          "idcategoriaProveedor",
          values.idcategoriaProveedor
        );
        bodyFormData.append(
          "idsubCategoriaProveedor",
          values.idsubCategoriaProveedor
        );
        bodyFormData.append("nombreProveedor", values.nombreProveedor);
        bodyFormData.append("idciudad", values.idciudad);
        bodyFormData.append("idestado", values.idestado);
        bodyFormData.append("descripcion", values.descripcion);
        bodyFormData.append("horarioAtencion", values.horarioAtencion);
        bodyFormData.append("direccion", values.direccion);
        bodyFormData.append("googleMaps", values.googleMaps);
        bodyFormData.append("telefono", values.telefono);
        bodyFormData.append("whatsapp", values.whatsapp);
        bodyFormData.append("emailContacto", values.emailContacto);
        bodyFormData.append("web", values.web);
        bodyFormData.append("facebook", values.facebook);
        bodyFormData.append("twitter", values.twitter);
        bodyFormData.append("instagram", values.instagram);
        bodyFormData.append("ext", values.ext);

        updateProveedor(bodyFormData, user, idproveedor, formik).then(
          (resultado) => {
            toast.success("Proveedor Modificado Correctamente");
            history.push("/supplieredit/" + idproveedor);
            setDisabled(false);
          }
        );
      }
    },
  });

  const getStates = async (pais = 1) => {
    await axios
      .get(API + "catalogue/getEstado")
      .then((response) => {
        setEstados(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const getMunicipios = async (e, idestado = 0) => {
    await axios
      .get(API + "catalogue/getCiudad/" + e)
      .then((response) => {
        setMunicipios(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="container-fluid g-0">
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <h5>Información de proveedor</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="nombreProveedor"
                          className="mb-2"
                          name="nombreProveedor"
                          label="Nombre de proveedor"
                          value={formik.values.nombreProveedor}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.nombreProveedor &&
                            Boolean(formik.errors.nombreProveedor)
                          }
                          helperText={
                            formik.touched.nombreProveedor &&
                            formik.errors.nombreProveedor
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="descripcion"
                          multiline
                          rows={5}
                          className="mb-2"
                          name="descripcion"
                          label="Descripción"
                          value={formik.values.descripcion}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.descripcion &&
                            Boolean(formik.errors.descripcion)
                          }
                          helperText={
                            formik.touched.descripcion &&
                            formik.errors.descripcion
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="direccion"
                          className="mb-2"
                          name="direccion"
                          label="Dirección"
                          value={formik.values.direccion}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.direccion &&
                            Boolean(formik.errors.direccion)
                          }
                          helperText={
                            formik.touched.direccion && formik.errors.direccion
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth shrink>
                          <InputLabel htmlFor="estado">
                            Categoría de Proveedor
                          </InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            shrink
                            native
                            className="mb-2"
                            value={formik.values.idcategoriaProveedor}
                            onChange={(e) => {
                              formik.handleChange(e);

                              getSubCategoriasProveedor(
                                e.target.value,
                                user
                              ).then((resultadoSubCategorias) => {
                                setSubCategoriasRecinto(resultadoSubCategorias);
                              });
                            }}
                            inputProps={{
                              name: "idcategoriaProveedor",
                              id: "idcategoriaProveedor",
                            }}
                          >
                            <option shrink aria-label="Seleccionar Proveedor">
                              Seleccionar categoría de proveedor
                            </option>

                            {categoriasRecinto.map((estado, index) => (
                              <option key={index} value={estado.id}>
                                {estado.nombreCategoria}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        <FormControl fullWidth shrink>
                          <InputLabel htmlFor="estado">
                            Subcategoría de Proveedor
                          </InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            shrink
                            native
                            className="mb-2"
                            value={formik.values.idsubCategoriaProveedor}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            inputProps={{
                              name: "idsubCategoriaProveedor",
                              id: "idsubCategoriaProveedor",
                            }}
                          >
                            <option shrink aria-label="Seleccionar Proveedor">
                              Seleccionar subcategoría de proveedor
                            </option>

                            {subCategoriasRecinto.map((estado, index) => (
                              <option key={index} value={estado.id}>
                                {estado.nombreSubcategoria}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="estado">Estado</InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idestado}
                            onChange={(e) => {
                              formik.handleChange(e);
                              getMunicipios(e.target.value);
                            }}
                            inputProps={{ name: "idestado", id: "idestado" }}
                          >
                            <option aria-label="Seleccionar Estado">
                              Seleccionar Estado
                            </option>

                            {estados.map((estado, index) => (
                              <option key={index} value={estado.idestado}>
                                {estado.nombreEstado}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="idciudad">Ciudad</InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idciudad}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            inputProps={{ name: "idciudad", id: "idciudad" }}
                          >
                            <option aria-label="Seleccionar Ciudad">
                              Seleccionar Ciudad
                            </option>

                            {municipios.map((ciudad, index) => (
                              <option key={index} value={ciudad.idciudad}>
                                {ciudad.nombreCiudad}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="googleMaps"
                          className="mb-2"
                          name="googleMaps"
                          label="Ingresa URL de Google"
                          value={formik.values.googleMaps}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.googleMaps &&
                            Boolean(formik.errors.googleMaps)
                          }
                          helperText={
                            formik.touched.googleMaps &&
                            formik.errors.googleMaps
                          }
                        />
                      </div>
                    </div>
                    <div className="row d-none">
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="horarioAtencion"
                          className="mb-2"
                          name="horarioAtencion"
                          label="Horario de atención"
                          value={formik.values.horarioAtencion}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.horarioAtencion &&
                            Boolean(formik.errors.horarioAtencion)
                          }
                          helperText={
                            formik.touched.horarioAtencion &&
                            formik.errors.horarioAtencion
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="telefono"
                          className="mb-2"
                          name="telefono"
                          label="Teléfono 10 dígitos"
                          value={formik.values.telefono}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.telefono &&
                            Boolean(formik.errors.telefono)
                          }
                          helperText={
                            formik.touched.telefono && formik.errors.telefono
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="ext"
                          className="mb-2"
                          name="ext"
                          label="Extensión"
                          value={formik.values.ext}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.ext &&
                            Boolean(formik.errors.ext)
                          }
                          helperText={
                            formik.touched.ext && formik.errors.ext
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="whatsapp"
                          className="mb-2"
                          name="whatsapp"
                          label="Whatsapp 10 dígitos"
                          value={formik.values.whatsapp}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.whatsapp &&
                            Boolean(formik.errors.whatsapp)
                          }
                          helperText={
                            formik.touched.whatsapp && formik.errors.whatsapp
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="emailContacto"
                          className="mb-2"
                          name="emailContacto"
                          label="Correo Contacto"
                          value={formik.values.emailContacto}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.emailContacto &&
                            Boolean(formik.errors.emailContacto)
                          }
                          helperText={
                            formik.touched.emailContacto &&
                            formik.errors.emailContacto
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="facebook"
                          className="mb-2"
                          name="facebook"
                          label="Facebook"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FacebookIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.facebook}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.facebook &&
                            Boolean(formik.errors.facebook)
                          }
                          helperText={
                            formik.touched.facebook && formik.errors.facebook
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="instagram"
                          className="mb-2"
                          name="instagram"
                          label="Instagram"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <InstagramIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.instagram}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.instagram &&
                            Boolean(formik.errors.instagram)
                          }
                          helperText={
                            formik.touched.instagram && formik.errors.instagram
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="twitter"
                          className="mb-2"
                          name="twitter"
                          label="Twitter"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <TwitterIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.twitter}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.twitter &&
                            Boolean(formik.errors.twitter)
                          }
                          helperText={
                            formik.touched.twitter && formik.errors.twitter
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-2 mt-2">
                <div className="card-body">
                  <h5>Horarios de Atención.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-12">
                        <HorariosAtencionComponent
                          horariosAtencion={horariosAtencion}
                          setHorariosAtencion={setHorariosAtencion}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
        <button type="submit" className="btnSubmit btn">
          <SaveIcon fontSize="inherit" /> Guardar
        </button>
      </form>
    </>
  );
};
