import React from "react";
import { Helmet } from "react-helmet-async";
import * as business from "../../constants/BusinessInfo";
import PropTypes from "prop-types";

export const BarTitleComponent = (props) => {
  return (
    <>
      <Helmet>
        <title>{`${business.information.businessName} - ${props.titulo}`}</title>
        <meta
          name="description"
          content={`${business.information.businessName}`}
        />
      </Helmet>
      {props.showBar === false ? null : (
        <div
          className="py-4  mb-2"
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h3 className="m-0 text-black">{props.titulo} </h3>
                <p className="text-muted m-0">{props.descripcion}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
BarTitleComponent.propTypes = {
  titulo: PropTypes.string.isRequired,
  descripcion: PropTypes.string.isRequired,
};
