import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import { EditGeneralDataEnclosure } from "../../../Components/EditGeneralDataEnclosure/EditGeneralDataEnclosure";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { EditGalleryDataEnclosure } from "../../../Components/EditGalleryDataEnclosure/EditGalleryDataEnclosure";
import MapIcon from "@material-ui/icons/Map";
import { EditPlanoDataEnclosureComponent } from "../../../Components/EditPlanoDataEnclosureComponent/EditPlanoDataEnclosureComponent";
import { EditUbicacionesRecientesDataEnclosureComponent } from "../../../Components/EditUbicacionesRecientesDataEnclosureComponent/EditUbicacionesRecientesDataEnclosureComponent";
import { EditImageEnclosureComponent } from "../../../Components/EditImageEnclosureComponent/EditImageEnclosureComponent";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { EditEspaciosDataEnclosureComponent } from "../../../Components/EditEspaciosDataEnclosureComponent/EditEspaciosDataEnclosureComponent";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import { EditServiciosDataEnclosureComponent } from "../../../Components/EditServiciosDataEnclosureComponent/EditServiciosDataEnclosureComponent";
import CategoryIcon from "@material-ui/icons/Category";
import { EditAreasServiciosDataEnclosureComponent } from "../../../Components/EditAreasServiciosDataEnclosureComponent/EditAreasServiciosDataEnclosureComponent";
import { EditGeneralDataSupplier } from "../../../Components/EditGeneralDataSupplier/EditGeneralDataSupplier";
import { EditImageSupplierComponent } from "../../../Components/EditImageSupplierComponent/EditImageSupplierComponent";
import { EditGalleryDataSupplier } from "../../../Components/EditGalleryDataSupplier/EditGalleryDataSupplier";
import ContactsIcon from "@material-ui/icons/Contacts";
import { EditContactSupplierComponent } from "../EditContactSupplierComponent/EditContactSupplierComponent";
import { EditServicesSupplierComponent } from "../../../Components/EditServicesSupplierComponent/EditServicesSupplierComponent";
import { AuthContext } from "../../../auth/authContext";
import { checkViewPermission } from "../../../API/APIPermisos";
import { SinPermisoParaAcceder } from "../../Public/SinPermisoParaAcceder/SinPermisoParaAcceder";
import PanToolIcon from "@material-ui/icons/PanTool";
import { LoremComponent } from "../../../Components/LoremComponent/LoremComponent";
const uuidVista = "59e336be-c46a-42dd-a8ae-e4fc81d436a9";

export const SupplierEditScreen = (props) => {
  const [idproveedor, setIdProveedor] = React.useState(useParams().id);

  const { user, dispatch } = React.useContext(AuthContext);

  const [resultadoPermisos, setResultadoPermisos] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const [statusPermiso, setStatusPermiso] = React.useState(0);

  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [permisoDatosGenerales, setPermisoDatosGenerales] =
    React.useState(false);
  const [permisoImagenProveedor, setPermisoImagenProveedor] =
    React.useState(false);
  const [permisoGaleria, setPermisoGaleria] = React.useState(false);
  const [permisoDirectorio, setPermisoDirectorio] = React.useState(false);
  const [permisoServiciosOfrecidos, setPermisoServiciosOfrecidos] =
    React.useState(false);

  React.useEffect(() => {
    checkViewPermission(uuidVista, user).then((resultado) => {
      setResultadoPermisos(resultado.data);
      setStatusPermiso(resultado.status);

      for (const permiso of resultado.data) {
        if (
          permiso.guidFuncion == "e99bbb4a-5da1-45bd-8212-659f97b7b43c" &&
          permiso.allowed == 1
        ) {
          setPermisoParaEditar(true);
        }
        if (
          permiso.guidFuncion == "4882cb7c-9d21-4e83-8348-862331f40d1c" &&
          permiso.allowed == 1
        ) {
          setPermisoDatosGenerales(true);
        }
        if (
          permiso.guidFuncion == "dcfbd208-c0e6-4725-b251-c0ead15921cf" &&
          permiso.allowed == 1
        ) {
          setPermisoImagenProveedor(true);
        }
        if (
          permiso.guidFuncion == "fcb23188-e30d-4670-bc5c-7ed102ba51b3" &&
          permiso.allowed == 1
        ) {
          setPermisoGaleria(true);
        }
        if (
          permiso.guidFuncion == "2b95e164-acfa-4926-9cbb-f5e1e7ec50cd" &&
          permiso.allowed == 1
        ) {
          setPermisoDirectorio(true);
        }
        if (
          permiso.guidFuncion == "ec90ddb4-90f7-4690-969d-63add27a1ed0" &&
          permiso.allowed == 1
        ) {
          setPermisoServiciosOfrecidos(true);
        }
      }
    });
  }, []);
  return (
    <>
      {permisoParaEditar === false ? (
        <SinPermisoParaAcceder />
      ) : (
        <>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <button
                className="nav-link active"
                id="nav-bienvenida-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-bienvenida"
                type="button"
                role="tab"
                aria-controls="nav-bienvenida"
                aria-selected="true"
              >
                <PanToolIcon fontSize="inherit" className="me-1" /> Bienvenido
              </button>

              {permisoDatosGenerales === true ? (
                <button
                  className="nav-link"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  <InfoIcon fontSize="inherit" className="me-1" /> Datos
                  Generales
                </button>
              ) : null}
              {permisoImagenProveedor === true ? (
                <button
                  className="nav-link "
                  id="nav-imagenproveedor-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-imagenproveedor"
                  type="button"
                  role="tab"
                  aria-controls="nav-imagenproveedor"
                  aria-selected="true"
                >
                  <InfoIcon fontSize="inherit" className="me-1" /> Imagen de
                  proveedor
                </button>
              ) : null}
              {permisoGaleria === true ? (
                <button
                  className="nav-link"
                  id="nav-galeria-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-galeria"
                  type="button"
                  role="tab"
                  aria-controls="nav-galeria"
                  aria-selected="true"
                >
                  <CameraAltIcon fontSize="inherit" className="me-1" /> Galería
                </button>
              ) : null}

              {permisoDirectorio === true ? (
                <button
                  className="nav-link"
                  id="nav-directorio-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-directorio"
                  type="button"
                  role="tab"
                  aria-controls="nav-directorio"
                  aria-selected="true"
                >
                  <ContactsIcon fontSize="inherit" className="me-1" />{" "}
                  Directorio
                </button>
              ) : null}

              {permisoServiciosOfrecidos === true ? (
                <button
                  className="nav-link"
                  id="nav-serviciosofrecidos-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-serviciosofrecidos"
                  type="button"
                  role="tab"
                  aria-controls="nav-serviciosofrecidos"
                  aria-selected="true"
                >
                  <MapIcon fontSize="inherit" className="me-1" /> Servicios
                  Ofrecidos
                </button>
              ) : null}
            </div>
          </nav>
          <div className="tab-content bg-white pt-3 p-2" id="nav-tabContent">
            {permisoDatosGenerales === true ? (
              <div
                className="tab-pane fade"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                {/*<EditGeneralDataEnclosure idrecinto={idRecinto} />*/}

                <EditGeneralDataSupplier idproveedor={idproveedor} />
              </div>
            ) : null}
            {permisoImagenProveedor === true ? (
              <div
                className="tab-pane fade "
                id="nav-imagenproveedor"
                role="tabpanel"
                aria-labelledby="nav-imagenproveedor-tab"
              >
                {/*<EditImageEnclosureComponent idrecinto={idRecinto} />*/}

                <EditImageSupplierComponent idproveedor={idproveedor} />
              </div>
            ) : null}
            {permisoGaleria === true ? (
              <div
                className="tab-pane fade "
                id="nav-galeria"
                role="tabpanel"
                aria-labelledby="nav-galeria-tab"
              >
                {/* <EditGalleryDataEnclosure idrecinto={idRecinto} /> */}

                <EditGalleryDataSupplier idproveedor={idproveedor} />
              </div>
            ) : null}

            {permisoDirectorio === true ? (
              <div
                className="tab-pane fade"
                id="nav-directorio"
                role="tabpanel"
                aria-labelledby="nav-directorio-tab"
              >
                {/*<EditPlanoDataEnclosureComponent idrecinto={idRecinto} />*/}

                <EditContactSupplierComponent idproveedor={idproveedor} />
              </div>
            ) : null}
            {permisoServiciosOfrecidos === true ? (
              <div
                className="tab-pane fade "
                id="nav-serviciosofrecidos"
                role="tabpanel"
                aria-labelledby="nav-serviciosofrecidos-tab"
              >
                {/*<EditUbicacionesRecientesDataEnclosureComponent
  idrecinto={idRecinto}
/>*/}
                <EditServicesSupplierComponent idproveedor={idproveedor} />
              </div>
            ) : null}

            <div
              className="tab-pane fade "
              id="nav-espacios"
              role="tabpanel"
              aria-labelledby="nav-espacios-tab"
            >
              {/*<EditEspaciosDataEnclosureComponent idrecinto={idRecinto} />*/}
            </div>
            <div
              className="tab-pane fade  "
              id="nav-servicios"
              role="tabpanel"
              aria-labelledby="nav-espacios-tab"
            >
              {/*<EditServiciosDataEnclosureComponent idrecinto={idRecinto} /> */}
            </div>
            <div
              className="tab-pane fade  "
              id="nav-areasdeservicio"
              role="tabpanel"
              aria-labelledby="nav-espacios-tab"
            >
              {/*<EditAreasServiciosDataEnclosureComponent idrecinto={idRecinto} /> */}
            </div>

            <div
              className="tab-pane fade show active p-3"
              id="nav-bienvenida"
              role="tabpanel"
              aria-labelledby="nav-bienvenida-tab"
            >
              <h4>Bienvenido a tu edición de proveedor</h4>
              <p>
                <LoremComponent />
              </p>
              <p>
                <LoremComponent />
              </p>
            </div>

          </div>
        </>
      )}
    </>
  );
};
