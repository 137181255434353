import React from "react";
import { ShareSocial } from "react-share-social";

import {
  EmailShareButton,
  FacebookShareButton,
  HatenaShareButton,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LivejournalShareButton,
  MailruShareButton,
  OKShareButton,
  PinterestShareButton,
  PocketShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  ViberShareButton,
  VKShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share";
import { LoremComponent } from "../LoremComponent/LoremComponent";

export const DescriptionAndShareSupplierPublicComponent = (props) => {
  return (
    <div className="col-12 p-4 mb-3" style={{ backgroundColor: "white" }}>
      <h5> {props.data.nombreProveedor}</h5>
      <div className="text-end">
        <TwitterShareButton
          className="mx-1"
          url={"https://www.eventsuctrl.com/enclosure/" + props.data.id}
        >
          <TwitterIcon size={26} round={true} />
        </TwitterShareButton>
        <FacebookShareButton
          className="mx-1"
          url={"https://www.eventsuctrl.com/enclosure/" + props.data.id}
        >
          <FacebookIcon size={26} round={true} />
        </FacebookShareButton>
        <WhatsappShareButton
          className="mx-1"
          url={"https://www.eventsuctrl.com/enclosure/" + props.data.id}
        >
          <WhatsappIcon size={26} round={true} />
        </WhatsappShareButton>
      </div>
      <hr></hr>
      {props.data.descripcion}
      <LoremComponent></LoremComponent>
    </div>
  );
};
