/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";
import { encode, decode } from "base64-arraybuffer";

const validationSchema = yup.object({
  nombreCupo: yup
    .string("Ingresa nombre Cupo")
    .max(50, "nombre cupo menor a 50 caracteres")
    .required("Nombre es necesario"),
  cupo: yup
    .string("Ingresa cupo")
    .max(50, "Cupo menor a 50 caracteres")
    .required("Cupo necesarias"),
});

export const EditCupoDataEnclosureComponent = (props) => {
   
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const [finalData, setFinalData] = React.useState([]);

  const [updateData, setUpdateData] = React.useState(0);

  React.useEffect(() => {
    getData().then((resultado) => {
      setFinalData(resultado);
    });
  }, []);
  React.useEffect(() => {
    getData().then((resultado) => {
      setFinalData(resultado);
    });
  }, [updateData]);

  const formik = useFormik({
    initialValues: {
      idespacio: props.crudAction.data.id,
      nombreCupo: "",
      cupo: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addData(values);
    },
  });

  const getData = async () => {
    try {
      const response = await axios.get(
        API + "recintos/getCupoPorEspacio/" + props.crudAction.data.id,
        
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const addData = async (values) => {
     
    try {
      const response = await axios.post(API + "recintos/crearCupo", values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });

      toast.success("Registro Agregado Correctamente!  ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      setUpdateData(updateData + 1);

      formik.resetForm();
    } catch (error) {
       
    //  alert(error.response.statusText);
      console.warn(error.response.statusText);
      toast.error(error.response.statusText);
      props.setCrudAction({ action: null, data: null });

      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const deleteData = async (values) => {
     
    try {
      const response = await axios.delete(
        API + "recintos/eliminarCupo/" + values,
        null,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success("Registro eliminado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      setUpdateData(updateData + 1);

      formik.resetForm();
    } catch (error) {
       
    //  alert(error.response.statusText);
      console.warn(error.response.statusText);
      toast.error(error.response.statusText);
      props.setCrudAction({ action: null, data: null });

      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Agregar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="nombreCupo"
                name="nombreCupo"
                label="Nombre Cupo"
                className="mb-2"
                value={formik.values.nombreCupo}
                onChange={formik.handleChange}
                error={
                  formik.touched.nombreCupo && Boolean(formik.errors.nombreCupo)
                }
                helperText={
                  formik.touched.nombreCupo && formik.errors.nombreCupo
                }
              />
              <TextField
                fullWidth
                id="cupo"
                name="cupo"
                label="Cupo"
                type="number"
                className="mb-2"
                value={formik.values.cupo}
                onChange={formik.handleChange}
                error={formik.touched.cupo && Boolean(formik.errors.cupo)}
                helperText={formik.touched.cupo && formik.errors.cupo}
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Agregar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
        <div className="row mt-3">
          {finalData.map((cupo, index) => (
            <div
              key={index}
              className="d-flex justify-content-between mb-2 d-flex align-items-center"
            >
              <div>
                <strong>Nombre Cupo:</strong> {cupo.nombreCupo} <br></br>{" "}
                <strong>Cupo: </strong>
                {cupo.cupo}
              </div>
              <div
                className="btn btn-sm btn-danger"
                onClick={() => {
                  deleteData(cupo.id);
                }}
              >
                {" "}
                Eliminar{" "}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
