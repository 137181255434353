import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
export const RedesSocialesRecinto = (props) => {
  return (
    <>
      <h6>
       Redes Sociales
      </h6>
      <hr></hr>
      <div className="text-center h3">
        <a
          className="facebookColor"
          href={props.data.facebook}
          target="_blank"
          rel="noreferrer"
        >
          <FacebookIcon fontSize="inherit" />
        </a>
        <a className="twitterColor" href={props.data.twitter}>
          <TwitterIcon fontSize="inherit" className="mx-3" />
        </a>
        <a className="instagramColor" href={props.data.instagram}>
          <InstagramIcon fontSize="inherit" />
        </a>
      </div>
    </>
  );
};
