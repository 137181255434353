import React from "react";
import {
  getCategoriaEventoBusqueda,
  getCiudadesBusqueda,
  getEventosBusqueda,
  getSubCategoriaEventoBusqueda,
} from "../../API/APIBusquedaEventos";
import { getEstadosBusqueda } from "./../../API/APIBusquedaEventos";
import CloseIcon from "@material-ui/icons/Close";

import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { DateTime } from "luxon";
import {
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
} from "./../../helpers/LanguajeReactDayPicker";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { types } from "./../../types/types";

export const SearchEventsComponent = (props) => {
  const [finalDataCategoriaEvento, setFinalDataCategoriaEvento] =
    React.useState([]);
  const [finalDataSubCategoriaEvento, setFinalDataSubCategoriaEvento] =
    React.useState([]);

  const [finalDataEstadosEvento, setFinalDataEstadosEvento] = React.useState(
    []
  );
  const [finalDataCiudadesEvento, setFinalDataCiudadesEvento] = React.useState(
    []
  );

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    getCategoriaEventoBusqueda().then((resultadoCategoriaEvento) => {
      setFinalDataCategoriaEvento(resultadoCategoriaEvento);
      getEstadosBusqueda().then((resultado) => {
        setFinalDataEstadosEvento(resultado);
        setLoading(false);
      });
    });
    getSubCategoriaEventoBusqueda(1).then((resultado) => {
      //  
    });

    getCiudadesBusqueda(11).then((resultado) => {
      //  
    });
  }, []);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: [] });
    }
  };
  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {},
    validationSchema: null,
    onSubmit: (values) => {
   //   alert(JSON.stringify(values, null, 2));

      getEventosBusqueda(values).then((resultado) => {
         
        props.setCrudAction({ action: "showResults", data: resultado });
      });
    },
  });

  return (
    <div className="crudDivComponent col-md-4 p-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Buscar Eventos</h5>
                <small>
                  Ingresa la información siguiente para encontrar tu evento.
                </small>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: [] })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {loading === true ? (
              <>Cargando información</>
            ) : (
              <> Consulta los filtros necesarios. </>
            )}
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 my-4">
              <TextField
                fullWidth
                id="cadena"
                name="cadena"
                label="Texto a Buscar"
                value={formik.values.cadena}
                onChange={formik.handleChange}
                error={formik.touched.cadena && Boolean(formik.errors.cadena)}
                helperText={formik.touched.cadena && formik.errors.cadena}
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <FormControl fullWidth>
                  <InputLabel htmlFor="idciudad">
                    Categoría Evento *Requerido
                  </InputLabel>
                  <Select
                    autoWidth
                    native
                    className="mb-2"
                    value={formik.values.idcategoria}
                    onChange={(e) => {
                      formik.handleChange(e);
                      getSubCategoriaEventoBusqueda(e.target.value).then(
                        (resultado) => {
                          setFinalDataSubCategoriaEvento(resultado);
                        }
                      );
                    }}
                    inputProps={{
                      name: "idcategoria",
                      id: "idcategoria",
                    }}
                  >
                    <option aria-label="Categoría Evento" value={null}>
                      Categoría Evento
                    </option>

                    {finalDataCategoriaEvento.map((categoria, index) => (
                      <option key={index} value={categoria.id}>
                        {categoria.nombreCategoriaEvento}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl fullWidth>
                  <InputLabel htmlFor="idciudad">
                    Subcategoría Evento *Requerido
                  </InputLabel>
                  <Select
                    autoWidth
                    native
                    className="mb-2"
                    value={formik.values.idsubcategoria}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    inputProps={{
                      name: "idsubcategoria",
                      id: "idsubcategoria",
                    }}
                  >
                    <option aria-label="Categoría Evento" value={null}>
                      Sub Categoría Evento
                    </option>

                    {finalDataSubCategoriaEvento.map((categoria, index) => (
                      <option key={index} value={categoria.id}>
                        {categoria.nombreSubcategoria}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <FormControl fullWidth>
                  <InputLabel htmlFor="idciudad">Estado</InputLabel>
                  <Select
                    autoWidth
                    native
                    className="mb-2"
                    value={formik.values.idestado}
                    onChange={(e) => {
                      formik.handleChange(e);
                      getCiudadesBusqueda(e.target.value).then((resultado) => {
                        setFinalDataCiudadesEvento(resultado);
                      });
                    }}
                    inputProps={{
                      name: "idestado",
                      id: "idestado",
                    }}
                  >
                    <option aria-label="Estado" value={null}>
                      Estado
                    </option>

                    {finalDataEstadosEvento.map((categoria, index) => (
                      <option key={index} value={categoria.idestado}>
                        {categoria.nombreEstado}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl fullWidth>
                  <InputLabel htmlFor="idciudad">Ciudad</InputLabel>
                  <Select
                    autoWidth
                    native
                    className="mb-2"
                    value={formik.values.idciudad}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    inputProps={{
                      name: "idciudad",
                      id: "idciudad",
                    }}
                  >
                    <option aria-label="Ciudad" value={null}>
                      Ciudad
                    </option>

                    {finalDataCiudadesEvento.map((categoria, index) => (
                      <option key={index} value={categoria.idciudad}>
                        {categoria.nombreCiudad}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-6">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="filtroInicio"
                  name="filtroInicio"
                  type="date"
                  inputProps={{max: '3000-01-01'}}
                  label="Fecha inicio"
                  value={formik.values.filtroInicio}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.filtroInicio &&
                    Boolean(formik.errors.filtroInicio)
                  }
                  helperText={
                    formik.touched.filtroInicio && formik.errors.filtroInicio
                  }
                />
              </div>
              <div className="col-sm-6">
                <TextField
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  id="filtroFin"
                  name="filtroFin"
                  type="date"
                  inputProps={{max: '3000-01-01'}}
                  label="Fecha Fin"
                  value={formik.values.filtroFin}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.filtroFin && Boolean(formik.errors.filtroFin)
                  }
                  helperText={
                    formik.touched.filtroFin && formik.errors.filtroFin
                  }
                />
              </div>
            </div>
            <div className="col-12 mt-4">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Buscar Eventos
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
