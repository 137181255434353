import React from "react";
import styles from "./HeaderProveedorDetail.module.css";
import { encode, decode } from "base64-arraybuffer";
export const HeaderProveedorDetail = (props) => {

  let finalUrlImage = "";
  if (props.data.imagenConvertida) {
    finalUrlImage = props.data.imagenConvertida;
  } else {
    finalUrlImage = "https://picsum.photos/1000/200?random=1";
  }
  const estiloBackground = {
    background: `linear-gradient(rgba(0,0,0,0.65),rgba(0,0,0,0.65)),url(${finalUrlImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  return (
    <>
    <br></br>
    <br></br>
    <br></br>
    <br></br>
      <section style={estiloBackground} className={styles.portadaPrincipal}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <span className="badge rounded-pill bg-primary">Proveedor</span>
              <h1 className="mt-2"> {props.data.nombreProveedor}</h1>
              
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
