import React from "react";
import axios from "axios";
import { API } from "./../constants/ApiConnection";
export const CountryStateCity = async () => {
  const api = API + "catalogue/getEstado/";

  try {
    const response = await axios.get(api, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return [];
  }
  return [];
};

export const getCities = async (idestado) => {
  const api = API + "catalogue/getCiudad/" + idestado;
  try {
    const response = await axios.get(api, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return [];
  }
  return [];
};
