export const API = 'http://201.147.245.165:2025/events/services/'

export const HEADERS_API_JSON = (token) =>
{
 return (
     {
         Accept: "application/json",
         "Content-Type": "application/json",
         "Access-Control-Allow-Headers" : "*",
         "x-access-token" : token
     }
 )
}