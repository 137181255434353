import React from "react";
import styles from "./NotFoundComponent.module.css";
export const NotFoundComponent = (props) => {
  return (
    <div className={styles.main}>
      <div className={styles.fof}>
        <h1>Error {props.number}</h1>
        <p>{props.description}</p>
      </div>
    </div>
  );
};
