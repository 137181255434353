/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from "react";
import styles from "./PanelMenuComponent.module.css";
import { NavLink } from "react-router-dom";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import jwt_decode from "jwt-decode";
import EventIcon from "@material-ui/icons/Event";
import PlaceIcon from "@material-ui/icons/Place";
import ApartmentIcon from "@material-ui/icons/Apartment";
import BubbleChartIcon from "@material-ui/icons/BubbleChart";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PaymentIcon from "@material-ui/icons/Payment";

export const PanelMenuComponent = (props) => {
  const [userData, setUserData] = React.useState({});

  React.useEffect(() => {
    const lastPath = JSON.parse(localStorage.getItem("user"));
    const accessTokenDecoded = jwt_decode(lastPath.accessToken);

    setUserData(accessTokenDecoded);

    console.log(accessTokenDecoded);
  }, []);

  return (
    <>
      <div className={`flex-shrink-0 p-3  ` + styles.sidebar}>
        <ul className="list-unstyled ps-0">
          <>
            <li className="border-top my-3"></li>
            <a href="/home">
              <strong className="my-3 text-white text-center">
                Ver Sitio Events U-Ctrl
              </strong>
            </a>
            <br></br>
            <li className="border-top my-3"></li>

            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#eventos-collapse"
                aria-expanded="false"
              >
                <EventIcon className="mx-1" fontSize="small" /> Eventos
              </button>
              <div className="collapse" id="eventos-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <NavLink
                      exact
                      to="/myevents"
                      activeClassName="menuActive"
                      className="nav-link px-2 link-dark"
                    >
                      Ver Mis Eventos
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          </>

          <>
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#recintos-collapse"
                aria-expanded="false"
              >
                <ApartmentIcon className="mx-1" fontSize="small" /> Recintos
              </button>
              <div className="collapse" id="recintos-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <NavLink
                      exact
                      to="/myenclosures"
                      activeClassName="menuActive"
                      className="nav-link px-2 link-dark"
                    >
                      Ver mis recintos
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          </>

          <>
            <li className="mb-1">
              <button
                className="btn btn-toggle align-items-center rounded collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#proveedores-collapse"
                aria-expanded="false"
              >
                <BubbleChartIcon className="mx-1" fontSize="small" />
                Proveedores
              </button>
              <div className="collapse" id="proveedores-collapse">
                <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                  <li>
                    <NavLink
                      exact
                      to="/mysuppliers"
                      activeClassName="menuActive"
                      className="nav-link px-2 link-dark"
                    >
                      Ver mis proveedores
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          </>

          <li className="mb-1">
            <button
              className="btn btn-toggle align-items-center rounded collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#account-collapse"
              aria-expanded="false"
            >
              <AccountBoxIcon className="mx-1" fontSize="small" /> Perfil
            </button>
            <div className="collapse" id="account-collapse">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <NavLink
                    exact
                    to="/profile"
                    activeClassName="menuActive"
                    className=""
                  >
                    Editar Perfil
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>
          <li className="mb-1">
            <button
              className="btn btn-toggle align-items-center rounded collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#administrador-collapse"
              aria-expanded="false"
            >
              <GroupAddIcon className="mx-1" fontSize="small" />
              Usuarios
            </button>
            <div className="collapse" id="administrador-collapse">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <NavLink
                  exact
                  to="/businessusers"
                  activeClassName="menuActive"
                  className=""
                >
                  Crear Usuarios
                </NavLink>
              </ul>
            </div>
          </li>

          <li className="mb-1">
            <button
              className="btn btn-toggle align-items-center rounded collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#payment-collapse"
              aria-expanded="false"
            >
              <PaymentIcon className="mx-1" fontSize="small" /> Mis Pagos
            </button>
            <div className="collapse" id="payment-collapse">
              <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                <li>
                  <NavLink
                    exact
                    to="/profile"
                    activeClassName="menuActive"
                    className=""
                  >
                    Mis Pagos
                  </NavLink>
                </li>
              </ul>
            </div>
          </li>

          {userData.isAdmin ? (
            <>
              {" "}
              <li className="border-top my-3"></li>
              <span className="my-3 text-white">Acciones Especiales</span>
              <br></br>
              <li className="border-top my-3"></li>
            </>
          ) : null}

          {userData.isAdmin ? (
            <>
              <li className="mb-1">
                <button
                  className="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#usuario-administrador-collapse"
                  aria-expanded="false"
                >
                  Usuarios Administradores
                </button>
                <div className="collapse" id="usuario-administrador-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <NavLink
                      exact
                      to="/adminusercatalogue"
                      activeClassName="menuActive"
                      className=""
                    >
                      Catálogo
                    </NavLink>
                  </ul>
                </div>
              </li>
              <li className="mb-1">
                <button
                  className="btn btn-toggle align-items-center rounded collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#catalogosGenerales-collapse"
                  aria-expanded="false"
                >
                  Catálogos Generales
                </button>
                <div className="collapse" id="catalogosGenerales-collapse">
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <NavLink
                      exact
                      to="/adminactivitiesprogramtypes"
                      activeClassName="menuActive"
                      className=""
                    >
                      Tipos de Actividad en programa
                    </NavLink>
                    <NavLink
                      exact
                      to="/admineventCategories"
                      activeClassName="menuActive"
                      className=""
                    >
                      Categorías Eventos
                    </NavLink>
                    <NavLink
                      exact
                      to="/adminenclosurecategories"
                      activeClassName="menuActive"
                      className=""
                    >
                      Categorías Recinto
                    </NavLink>
                    <NavLink
                      exact
                      to="/adminsupplierscategories"
                      activeClassName="menuActive"
                      className=""
                    >
                      Categorías Proveedores
                    </NavLink>
                  </ul>
                </div>
              </li>
            </>
          ) : (
            false
          )}

          {userData.isAdmin === true ? (
            <>
              <li className="mb-1">
                <button
                  className="btn btn-toggle rounded collapsed"
                  data-bs-toggle="collapse"
                  data-bs-target="#herramientasadministrativas-collapse"
                  aria-expanded="false"
                >
                  Herramientas
                </button>
                <div
                  className="collapse"
                  id="herramientasadministrativas-collapse"
                >
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                    <li>
                      <NavLink
                        exact
                        to="/carouselPrincipal"
                        activeClassName="menuActive"
                        className=""
                      >
                        Carousel Principal
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        exact
                        to="/adminFeaturedEvents"
                        activeClassName="menuActive"
                        className=""
                      >
                        Eventos Destacados
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to="/adminUpcomingEvents"
                        activeClassName="menuActive"
                        className=""
                      >
                        Próximos Eventos
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to="/eventads"
                        activeClassName="menuActive"
                        className=""
                      >
                        Publicidad en eventos
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to="/enclosuresads"
                        activeClassName="menuActive"
                        className=""
                      >
                        Publicidad en recintos
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to="/supplierads"
                        activeClassName="menuActive"
                        className=""
                      >
                        Publicidad en Proveedores
                      </NavLink>
                    </li>

                    <li>
                      <NavLink
                        exact
                        to="/adminFeaturedSuppliers"
                        activeClassName="menuActive"
                        className=""
                      >
                        Proveedores Destacados
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to="/adminFeaturedEnclosures"
                        activeClassName="menuActive"
                        className=""
                      >
                        Recintos Destacadoss
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        exact
                        to="/termsAndConditionsEdit"
                        activeClassName="menuActive"
                        className=""
                      >
                        Términos y Condiciones
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </li>
            </>
          ) : (
            false
          )}
        </ul>
      </div>
    </>
  );
};
