import React from "react";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import GetAppIcon from "@material-ui/icons/GetApp";
export const CardPoliticsEventComponent = (props) => {
  
  const donwloadFile = (blob) => {
    const arr = new Uint8Array(blob);
    const file = new Blob([arr], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <div className="col">
      <div className="card mb-2">
        {props.editable === false ? null : (
          <>
            <div className="card-header bg-white d-flex align-items-center d-flex justify-content-between">
              <h6 className="mt-2 text-muted">Documento</h6>
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-sm text-white btn-danger"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "delete", data: props.data })
                  }
                >
                  Eliminar
                </button>
              </div>
            </div>
          </>
        )}

        <div className="card-body d-flex justify-content-between">
          <p className="card-text font-weight-bold m-0 fw-bold h5">
            {props.index + 1}.- {props.data.descripcion}
          </p>
          <p
            className="card-text font-weight-bold m-0 fw-bold h5 text-danger"
            onClick={() => donwloadFile(props.data.archivo.data)}
          >
            <GetAppIcon /> Descargar archivo
          </p>
        </div>
      </div>
    </div>
  );
};
