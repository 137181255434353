import React from "react";
import styles from "./CardButtonPanelComponent.module.css";
export const CardButtonPanelComponent = (props) => {
  return (
    <>
      <a href={props.url} className={`col mb-3 cursor-pointer ${styles.cursorPointer}`} >
        <div
          className={`card ${styles.CardInformationPanelComponent}`}
          style={{
            backgroundColor: props.backgroundColorCard,
            color: props.textColor,
          }}
        >
          <div className="card-body p-4">
            <div className="row">
              <div className="col-12 col-md-12 text-center">
                <p className="p-0 m-0 text-dark fw-bold h4">{props.titulo}</p>
                <small className="fw-light text-success">
                  {props.descripcion}
                </small>
              </div>
            </div>
          </div>
        </div>
      </a>
    </>
  );
};
