import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import { TextEditorComponent } from "../../Components/TextEditorComponent/TextEditorComponent";
import { AuthContext } from "../../auth/authContext";
import { UploadImagesComponent } from "../../Components/UploadImagesComponent/UploadImagesComponent";
import { UserDecode } from "../../helpers/UserDecode";
import Button from "@material-ui/core/Button";
import { API } from "../../constants/ApiConnection";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import SaveIcon from "@material-ui/icons/Save";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { types } from "../../types/types";
import { DateTime } from "luxon";
import {
  getCategoriasEvento,
  getSubCategoriaEventos,
} from "../../API/APiCategoriasEventos";
import { LoaderEventsComponent } from "../LoaderEventsComponent/LoaderEventsComponent";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import { HorariosAtencionComponent } from "../HorariosAtencionComponent/HorariosAtencionComponent";
import ReactAddToCalendar from "react-add-to-calendar";
import { CategorySubcategoryDynamic } from "../CategorySubcategoryDynamic/CategorySubcategoryDynamic";
var dayjs = require("dayjs");

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const EditGeneralDataEventComponent = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  //Poniendo el objeto de días
  const [horariosAtencion, setHorariosAtencion] = React.useState([
    {
      id: 1,
      nombre: "Lunes",
      horaInicio: "00:00",
      horaFin: "05:00",
    },
  ]);

  React.useEffect(() => {
    formik.setFieldValue("diaHoraAtencion", JSON.stringify(horariosAtencion));
  }, [horariosAtencion]);

  //Esta es la imagen de portada (es un array, solo tomar la posicion 0)
  const [imagenPortada, setImageCover] = React.useState([]);
  //Tomando el usuario padre
  const idusuarioPadre = UserDecode().idusuarioPadre;
  //Estado de Estados
  const [estados, setEstados] = React.useState([]);
  //Estado de municipios
  const [municipios, setMunicipios] = React.useState([]);

  //Categorías de evento
  const [categoriaEvento, setCategoriaEvento] = React.useState([]);
  //Categorías de evento
  const [subCategoriaEvento, setSubCategoriaEvento] = React.useState([]);
  //Deshabilitar o habilitar componente
  const [disabled, setDisabled] = React.useState(false);
  //
  const [imgActual, setImgActual] = React.useState("");

  const [loading, setLoading] = React.useState(true);

  const [multiplesCategorias, setMultiplesCategorias] = React.useState([]);

  let [textoInicial, setTextoInicial] = React.useState("");

  const formik = useFormik({
    initialValues: {
      idusuarioPadre: idusuarioPadre,
      tituloEvento: "",
      fechaInicio: "",
      fechaFin: "",
      descripcionCortaEvento: "",
      descripcionLargaEvento: "",
      idcategoria: "",
      idsubcategoria: "",
      idestado: "",
      idciudad: "",
      facebook: "",
      twitter: "",
      instagram: "",
      limitantes: "",
      ubicacionRecinto: "",
      recinto: "",
      imagenPortada: imagenPortada[0],
      web: "",
      emailEvento: "",
      whatsappEvento: "",
      telefonoEvento: "",
      diaHoraAtencion: "",
      ext: "",
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => {
      setDisabled(true);
      formik.setFieldValue("imagenPortada", imagenPortada[0]);
      updateData(values);
      setDisabled(false);
    },
  });

  React.useEffect(() => {
    getStates();
    getDetail().then((resultado) => {
      getCategoriasEvento(null, user).then((resultado) => {
        setCategoriaEvento(resultado);
      });
    });
  }, []);

  React.useEffect(() => {
    if (imagenPortada[0]) {
      formik.setFieldValue("imagenPortada", imagenPortada[0].data_url);
    }
  }, [imagenPortada]);

  const getDetail = async () => {
    setDisabled(true);
    try {
      const response = await axios.get(
        API + "eventos/detalleEvento/" + props.id,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      try {
        const categoriasResult = await axios.get(
          API + "evento/getCategoriaDetalle/" + props.id,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              "Access-Control-Allow-Headers": "*",
              "x-access-token": user.accessToken,
            },
          }
        );

        setMultiplesCategorias(categoriasResult.data);
      } catch {}

      toast.success("Datos de evento cargados correctamente", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      setLoading(false);

      const hola222 = DateTime.fromISO(response.data.fechaInicio)
        .plus({
          days: 1,
        })
        .toISOTime();

      const hola2222 = DateTime.fromISO(response.data.fechaInicio).plus({
        days: 1,
      });

      formik.setFieldValue("tituloEvento", response.data.tituloEvento);
      formik.setFieldValue(
        "fechaInicio",
        dayjs(response.data.fechaInicio).format("YYYY-MM-DDTHH:mm:ss")
      );
      formik.setFieldValue(
        "fechaFin",
        dayjs(response.data.fechaFin).format("YYYY-MM-DDTHH:mm:ss")
      );
      formik.setFieldValue(
        "descripcionCortaEvento",
        response.data.descripcionCortaEvento
      );
      formik.setFieldValue(
        "descripcionLargaEvento",
        response.data.descripcionLargaEvento
      );
      formik.setFieldValue("idcategoria", response.data.idcategoria);

      getSubCategoriaEventos(null, user, response.data.idcategoria).then(
        (resultado) => {
          setSubCategoriaEvento(resultado.data);
          formik.setFieldValue("idsubcategoria", response.data.idsubcategoria);
        }
      );

      getStates().then((resultado) => {
        formik.setFieldValue("idestado", response.data.idestado);

        getMunicipios(response.data.idestado).then((resultado) => {
          formik.setFieldValue("idciudad", response.data.idciudad);
        });
      });

      formik.setFieldValue("facebook", response.data.facebook);
      formik.setFieldValue("twitter", response.data.twitter);
      formik.setFieldValue("instagram", response.data.instagram);
      formik.setFieldValue("limitantes", response.data.limitantes);
      formik.setFieldValue("ubicacionRecinto", response.data.ubicacionRecinto);
      formik.setFieldValue("recinto", response.data.recinto);

      formik.setFieldValue("imagenPortada", response.data.imagenPortada);
      formik.setFieldValue("web", response.data.web);
      formik.setFieldValue("emailEvento", response.data.emailEvento);
      formik.setFieldValue("whatsappEvento", response.data.whatsappEvento);
      formik.setFieldValue("telefonoEvento", response.data.telefonoEvento);
      formik.setFieldValue("diaHoraAtencion", response.data.diaHoraAtencion);
      formik.setFieldValue("ext", response.data.ext);

      setHorariosAtencion(JSON.parse(response.data.diaHoraAtencion));

      setImgActual(response.data.imagenPortada);
      setDisabled(false);

      return response.data;
    } catch (error) {
      setDisabled(false);
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getStates = async (pais = 1) => {
    await axios
      .get(API + "catalogue/getEstado")
      .then((response) => {
        setEstados(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const getMunicipios = async (e, idestado = 0) => {
    await axios
      .get(API + "catalogue/getCiudad/" + e)
      .then((response) => {
        setMunicipios(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };

  const updateData = async (values) => {
    setDisabled(true);
    try {
      const response = await axios.put(
        API + "eventos/updateEvento/" + props.id,
        values,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success("Registro Actualizado Correctamente!  ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setDisabled(false);

      history.go(0);

      return response.data;
    } catch (error) {
      setDisabled(false);
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  React.useEffect(() => {
    console.log(horariosAtencion);
  }, [horariosAtencion]);

  return (
    <>
      {loading === true ? <LoaderEventsComponent /> : null}
      <form onSubmit={formik.handleSubmit}>
        <div className="container-fluid g-0">
          <div className="row mb-3">
            <div className="col-md-12">
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Información principal del evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="tituloEvento"
                          className="mb-2"
                          name="tituloEvento"
                          label="Título de evento"
                          value={formik.values.tituloEvento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.tituloEvento &&
                            Boolean(formik.errors.tituloEvento)
                          }
                          helperText={
                            formik.touched.tituloEvento &&
                            formik.errors.tituloEvento
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          type="datetime-local"
                          inputProps={{ max: "3000-01-01T00:00" }}
                          id="fechaInicio"
                          className="mb-2"
                          name="fechaInicio"
                          label="Fecha de Inicio"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values.fechaInicio}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.fechaInicio &&
                            Boolean(formik.errors.fechaInicio)
                          }
                          helperText={
                            formik.touched.fechaInicio &&
                            formik.errors.fechaInicio
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          type="datetime-local"
                          inputProps={{ max: "3000-01-01T00:00" }}
                          id="fechaFin"
                          className="mb-2"
                          name="fechaFin"
                          label="Fecha de fin"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values.fechaFin}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.fechaFin &&
                            Boolean(formik.errors.fechaFin)
                          }
                          helperText={
                            formik.touched.fechaFin && formik.errors.fechaFin
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="estado">Estado</InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idestado}
                            onChange={(e) => {
                              formik.handleChange(e);
                              getMunicipios(e.target.value);
                            }}
                            inputProps={{ name: "idestado", id: "idestado" }}
                          >
                            <option aria-label="Seleccionar Estado">
                              Seleccionar Estado
                            </option>

                            {estados.map((estado, index) => (
                              <option key={index} value={estado.idestado}>
                                {estado.nombreEstado}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="idciudad">Ciudad</InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idciudad}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            inputProps={{ name: "idciudad", id: "idciudad" }}
                          >
                            <option aria-label="Seleccionar Ciudad">
                              Seleccionar Ciudad
                            </option>

                            {municipios.map((ciudad, index) => (
                              <option key={index} value={ciudad.idciudad}>
                                {ciudad.nombreCiudad}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="recinto"
                          className="mb-2"
                          name="recinto"
                          label="Recinto"
                          value={formik.values.recinto}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.recinto &&
                            Boolean(formik.errors.recinto)
                          }
                          helperText={
                            formik.touched.recinto && formik.errors.recinto
                          }
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="ubicacionRecinto"
                          className="mb-2"
                          name="ubicacionRecinto"
                          label="Ubicación Recinto"
                          value={formik.values.ubicacionRecinto}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.ubicacionRecinto &&
                            Boolean(formik.errors.ubicacionRecinto)
                          }
                          helperText={
                            formik.touched.ubicacionRecinto &&
                            formik.errors.ubicacionRecinto
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Categoría de evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel shrink htmlFor="idciudad">
                            Categoría Evento
                          </InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idcategoria}
                            onChange={(e) => {
                              formik.handleChange(e);
                              getSubCategoriaEventos(
                                null,
                                user,
                                e.target.value
                              ).then((resultado) => {
                                setSubCategoriaEvento(resultado.data);
                              });
                            }}
                            inputProps={{
                              name: "idcategoria",
                              id: "idcategoria",
                            }}
                          >
                            {categoriaEvento.map((categoria, index) => (
                              <option key={index} value={categoria.id}>
                                {categoria.nombreCategoriaEvento}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel shrink htmlFor="idciudad">
                            Subcategoría
                          </InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idsubcategoria}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            inputProps={{
                              name: "idsubcategoria",
                              id: "idsubcategoria",
                            }}
                          >
                            {subCategoriaEvento.map((subcategoria, index) => (
                              <option key={index} value={subcategoria.id}>
                                {subcategoria.nombreSubcategoria}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Categorías adicionales.</h5>
                  <div className="container-fluid g-0">
                    {multiplesCategorias.map((categoria, index) => (
                      <CategorySubcategoryDynamic
                        key={index}
                        categoriaEvento={categoriaEvento}
                        categoriaSeleccionada={categoria}
                        multiplesCategorias={multiplesCategorias}
                        setMultiplesCategorias={setMultiplesCategorias}
                      />
                    ))}
                  </div>
                </div>
              </div>

              <div className="card pb-2 mb-2">
                <div className="card-body">
                  <h5>Descripción corta evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          multiline
                          rows={5}
                          id="descripcionCortaEvento"
                          className="mb-2"
                          name="descripcionCortaEvento"
                          label="Descripción Corta Evento"
                          value={formik.values.descripcionCortaEvento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.descripcionCortaEvento &&
                            Boolean(formik.errors.descripcionCortaEvento)
                          }
                          helperText={
                            formik.touched.descripcionCortaEvento &&
                            formik.errors.descripcionCortaEvento
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card pb-2 mb-2">
                <div className="card-body">
                  <h5>Descripción larga evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          multiline
                          rows={5}
                          id="descripcionLargaEvento"
                          className="mb-2"
                          name="descripcionLargaEvento"
                          label="Descripción Larga Evento"
                          value={formik.values.descripcionLargaEvento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.descripcionLargaEvento &&
                            Boolean(formik.errors.descripcionLargaEvento)
                          }
                          helperText={
                            formik.touched.descripcionLargaEvento &&
                            formik.errors.descripcionLargaEvento
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Limitantes.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="limitantes"
                          className="mb-2"
                          name="limitantes"
                          label="Limitantes del evento"
                          value={formik.values.limitantes}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.limitantes &&
                            Boolean(formik.errors.limitantes)
                          }
                          helperText={
                            formik.touched.limitantes &&
                            formik.errors.limitantes
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Redes Sociales de evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="facebook"
                          className="mb-3"
                          name="facebook"
                          label="URL Facebook"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FacebookIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.facebook}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.facebook &&
                            Boolean(formik.errors.facebook)
                          }
                          helperText={
                            formik.touched.facebook && formik.errors.facebook
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <TextField
                          fullWidth
                          disabled={disabled}
                          id="twitter"
                          className="mb-3"
                          name="twitter"
                          label="URL Twitter"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <TwitterIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.twitter}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.twitter &&
                            Boolean(formik.errors.twitter)
                          }
                          helperText={
                            formik.touched.twitter && formik.errors.twitter
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="instagram"
                          className="mb-2"
                          name="instagram"
                          label="URL Instagram"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <InstagramIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.instagram}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.twitter &&
                            Boolean(formik.errors.instagram)
                          }
                          helperText={
                            formik.touched.instagram && formik.errors.instagram
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Contacto del Evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="web"
                          className="mb-3"
                          name="web"
                          label="Página Web Evento"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Web Evento
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.web}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.web && Boolean(formik.errors.web)
                          }
                          helperText={formik.touched.web && formik.errors.web}
                        />
                      </div>
                      <div className="col-md-12">
                        <TextField
                          fullWidth
                          disabled={disabled}
                          id="emailEvento"
                          className="mb-3"
                          name="emailEvento"
                          label="Correo de Evento"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Correo
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.emailEvento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.emailEvento &&
                            Boolean(formik.errors.emailEvento)
                          }
                          helperText={
                            formik.touched.emailEvento &&
                            formik.errors.emailEvento
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="whatsappEvento"
                          className="mb-2"
                          name="whatsappEvento"
                          label="Whatsapp Evento"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Whatsapp
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.whatsappEvento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.whatsappEvento &&
                            Boolean(formik.errors.whatsappEvento)
                          }
                          helperText={
                            formik.touched.whatsappEvento &&
                            formik.errors.whatsappEvento
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="telefonoEvento"
                          className="mb-2"
                          name="telefonoEvento"
                          label="Teléfono Evento"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Teléfono Evento:
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.telefonoEvento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.telefonoEvento &&
                            Boolean(formik.errors.telefonoEvento)
                          }
                          helperText={
                            formik.touched.telefonoEvento &&
                            formik.errors.telefonoEvento
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="ext"
                          className="mb-2"
                          name="ext"
                          label="Extensión"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                No de Extensión:
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.ext}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.ext && Boolean(formik.errors.ext)
                          }
                          helperText={formik.touched.ext && formik.errors.ext}
                        />
                      </div>
                      <div className="col-md-12 d-none">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="diaHoraAtencion"
                          className="mb-2"
                          name="diaHoraAtencion"
                          label="Día Hora Atención"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                Día hora Atención:
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.diaHoraAtencion}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.diaHoraAtencion &&
                            Boolean(formik.errors.diaHoraAtencion)
                          }
                          helperText={
                            formik.touched.diaHoraAtencion &&
                            formik.errors.diaHoraAtencion
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-2">
                <div className="card-body">
                  <h5>Horarios de Atención.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-12">
                        <HorariosAtencionComponent
                          horariosAtencion={horariosAtencion}
                          setHorariosAtencion={setHorariosAtencion}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 d-none">
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Imagen Actual</h5>
                  <p>
                    <small>Se Muestra la imagen actual.</small>
                  </p>
                  <img
                    alt="imagenEvento"
                    className="img-fluid"
                    src={imgActual}
                  />
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Sube la imagen de tu evento.</h5>
                  <p>
                    <small>Tamaño recomendado: XXXpx x XXXpx</small>
                  </p>
                  <UploadImagesComponent
                    setImageCover={setImageCover}
                    numberImages={1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" className="btnSubmit btn">
          <SaveIcon fontSize="inherit" /> Guardar
        </button>
      </form>
    </>
  );
};
