/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";
import { arrayBufferToBlob } from "blob-util";
import { encode, decode } from "base64-arraybuffer";

const validationSchema = yup.object({
  nombre: yup.string("Ingresa Nombre").required("Nombre necesario"),
  apellido: yup.string("Ingresa Apellidos").required("Nombre Apellidos"),
  facebookPonente: yup.string("Ingresa Url Facebook"),
  youtubePonente: yup.string("Ingresa Url Youtube"),
  cvCorto: yup.string("Ingresa Descripción").required("Descripción Necesaria").max(200, '200 caracteres permitidos como máximo'),
});

export const EditDataPonentesDataEventComponent = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [isDisabled, setIsDisabled] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      idevento: props.idevento,
      nombre: props.crudAction.data.nombre,
      apellido: props.crudAction.data.apellido,
      cvCorto: props.crudAction.data.cvCorto,
      facebookPonente: props.crudAction.data.facebookPonente,
      youtubePonente: props.crudAction.data.youtubePonente,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.filename= "Saludos"
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const addData = async (values) => {
    var bodyFormData = new FormData();

    setIsDisabled(true);

    debugger;

    if (values.cvFile == "" || values.cvFile == undefined) {
    } else {
      bodyFormData.append("imagenPonente", values.imagenPonente);
    }

    if (values.cvFile == "" || values.cvFile == undefined) {
      bodyFormData.append("idevento", values.idevento);
      bodyFormData.append("nombre", values.nombre);
      bodyFormData.append("apellido", values.apellido);
      bodyFormData.append("cvCorto", values.cvCorto);
      bodyFormData.append("facebookPonente", values.facebookPonente);
      bodyFormData.append("youtubePonente", values.youtubePonente);
      addPonente(bodyFormData);
    } else {
      getBase64(values.cvFile).then((data) => {
        values.cvFile = data;

        bodyFormData.append("idevento", values.idevento);
        bodyFormData.append("nombre", values.nombre);
        bodyFormData.append("apellido", values.apellido);
        bodyFormData.append("cvCorto", values.cvCorto);
        bodyFormData.append("cvFile", values.cvFile);
        bodyFormData.append("imagenPonente", values.imagenPonente);
        bodyFormData.append("facebookPonente", values.facebookPonente);
        bodyFormData.append("youtubePonente", values.youtubePonente);

        addPonente(bodyFormData);
      });
    }
  };

  const addPonente = async (bodyFormData) => {
    try {
      const response = await axios.put(
        API + "ponente/updatePonente/" + props.crudAction.data.id,
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      debugger;
      setIsDisabled(false);
      toast.success(
        "Registro Agregado Correctamente, actualizando información",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );

      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      debugger;
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
    setIsDisabled(false);
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Editar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              {isDisabled === true ? (
                <>
                  <div className="alert alert-primary" role="alert">
                    Subiendo imagen, por favor espera...
                  </div>
                </>
              ) : null}
              <TextField
                fullWidth
                id="nombre"
                disabled={isDisabled}
                name="nombre"
                label="Nombre(s)"
                className="mb-2"
                multiline
                rows={1}
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
              />
              <TextField
                fullWidth
                id="apellido"
                name="apellido"
                label="Apellidos"
                disabled={isDisabled}
                className="mb-2"
                multiline
                rows={1}
                value={formik.values.apellido}
                onChange={formik.handleChange}
                error={
                  formik.touched.apellido && Boolean(formik.errors.apellido)
                }
                helperText={formik.touched.apellido && formik.errors.apellido}
              />
              <TextField
                fullWidth
                id="cvCorto"
                name="cvCorto"
                disabled={isDisabled}
                label="Cv Corto"
                className="mb-2"
                multiline
                rows={5}
                value={formik.values.cvCorto}
                onChange={formik.handleChange}
                error={formik.touched.cvCorto && Boolean(formik.errors.cvCorto)}
                helperText={formik.touched.cvCorto && formik.errors.cvCorto}
              />

              <TextField
                fullWidth
                id="facebookPonente"
                name="facebookPonente"
                disabled={isDisabled}
                label="Facebook Ponente"
                className="mb-2"
                multiline
                rows={1}
                value={formik.values.facebookPonente}
                onChange={formik.handleChange}
                error={
                  formik.touched.facebookPonente &&
                  Boolean(formik.errors.facebookPonente)
                }
                helperText={
                  formik.touched.facebookPonente &&
                  formik.errors.facebookPonente
                }
              />

              <TextField
                fullWidth
                id="youtubePonente"
                name="youtubePonente"
                disabled={isDisabled}
                label="Youtube Ponente"
                className="mb-2"
                multiline
                rows={1}
                value={formik.values.youtubePonente}
                onChange={formik.handleChange}
                error={
                  formik.touched.youtubePonente &&
                  Boolean(formik.errors.youtubePonente)
                }
                helperText={
                  formik.touched.youtubePonente && formik.errors.youtubePonente
                }
              />

              <div className="my-3">
                <label for="cvFile" className="form-label">
                  Ingresa archivo PDF de Curriculum.
                </label>
                <span className="badge rounded-pill bg-danger ms-2">
                  {" "}
                  .PDF{" "}
                </span>

                <input
                  className="form-control"
                  type="file"
                  id="cvFile"
                  disabled={isDisabled}
                  accept="application/pdf"
                  onChange={(e) => {
                    formik.setFieldValue("cvFile", e.target.files[0]);
                  }}
                />
              </div>
              <div className="my-3">
                <label for="imagenPonente" className="form-label">
                  Ingresa imagen de ponente.
                </label>

                <span className="badge rounded-pill bg-primary ms-2">
                  {" "}
                  .JPG, .PNG Y JPEG{" "}
                </span>
                <input
                  className="form-control"
                  disabled={isDisabled}
                  type="file"
                  id="imagenPonente"
                  accept="image/x-png,image/jpeg"
                  onChange={(e) => {
                    formik.setFieldValue("imagenPonente", e.target.files[0]);
                  }}
                />
              </div>

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                disabled={isDisabled}
                className="mt-3"
              >
                Editar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
