import React from "react";
import { CardSponsorComponent } from "../CardSponsorComponent/CardSponsorComponent";

export const SponsorsEventDetailComponent = (props) => {
  return (
    <>
      <h6>
        <i className="fas fa-users"></i>&nbsp;&nbsp;Patrocinadores
      </h6>
      <hr></hr>
      <div className="container-fluid">
        <div className="row row-cols-md-2">
          {props.data.map((patrocinador, index) => (
            <CardSponsorComponent
              key={index}
              public={true}
              data={patrocinador}
            />
          ))}
        </div>
      </div>
    </>
  );
};
