import React from "react";

import { DateTime } from "luxon";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import AddToCalendar from "react-add-to-calendar";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale from "dayjs/locale/es-mx";
import { getPublicPatrocinadorActividad } from "../../API/APIPatrocinadoresEventos";
import { AccordionProgramaEventComponent } from "../AccordionProgramaEventComponent/AccordionProgramaEventComponent";
import { AccordionEspacioEnclosureComponent } from "../AccordionEspacioEnclosureComponent/AccordionEspacioEnclosureComponent";

export const EspaciosRecintoPublicComponent = (props) => {
 

  return (
    <>
      <div className="accordion" id="accordionPrograma">
        {props.data.map((espacio, index) => (
         <AccordionEspacioEnclosureComponent key={index} data={espacio} index={index}/>
        ))}
      </div>
    </>
  );
};
