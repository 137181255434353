import React from "react";

import { ToastContainer, toast } from "react-toastify";

import { AppRouter } from "./routers/AppRouter";
import { AuthContext } from "./auth/authContext";
import { authReducer } from "./auth/authReducer";
import 'animate.css';


const init = () => {
  return JSON.parse(localStorage.getItem("user")) || { logged: false };
};

function App() {
  const [user, dispatch] = React.useReducer(authReducer, {}, init);

  React.useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user));
  }, [user]);



  return (
    <>
      <AuthContext.Provider value={{ user, dispatch }}>
        <AppRouter />
      </AuthContext.Provider>
    </>
  );
}

export default App;
