import React from "react";
import axios from "axios";
import { AuthContext } from "../../auth/authContext";
import { UserDecode } from "../../helpers/UserDecode";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { types } from "../../types/types";
import { DateTime } from "luxon";
import { API } from "../../constants/ApiConnection";
import { NoDataComponent } from "../NoDataComponent/NoDataComponent";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { NoImagenDataComponent } from "../NoImagenDataComponent/NoImagenDataComponent";
import { encode, decode } from "base64-arraybuffer";
import { useFormik } from "formik";

import ImageIcon from "@material-ui/icons/Image";
export const EditEventPortadaImageDataEventComponent = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const idusuarioPadre = UserDecode().idusuarioPadre;
  const [finalData, setFinalData] = React.useState([]);
  const [imgBlob, setImageBlog] = React.useState("");
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [isDisabled, setIsDisabled] = React.useState(false);

  const formik = useFormik({
    initialValues: {},
    validationSchema: null,
    onSubmit: (values) => {
      setIsDisabled(true);
      updatePhoto(values);
    },
  });

  const updatePhoto = async (values) => {
    try {
      var bodyFormData = new FormData();
      bodyFormData.append("imagenEvento", values.imagenEvento);

      const response = await axios.put(
        API + "eventos/setImagenEvento/" + props.id,
        bodyFormData,
        {
          headers: {
            "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );
      setIsDisabled(false);
      toast.success("Imagen actualizada correctamente", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      getData().then((result) => {
        setFinalData(result);
      });

      props.setCrudAction({ action: null, data: false });
    } catch (error) {
      console.warn(error.response);
    }
  };

  React.useEffect(() => {
    getData().then((result) => {
      if (typeof result === "undefined") {
        setFinalData([]);
      } else {
        setFinalData(result);
      }
    });
  }, []);

  React.useEffect(() => {
    getData().then((result) => {
      if (typeof result === "undefined") {
        setFinalData([]);
      } else {
        setFinalData(result);
      }
    });
  }, [crudAction]);

  const getData = async () => {
    try {
      const response = await axios.get(
        API + "eventos/detalleEvento/" + props.id,
        
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      if (response.data.imagenEvento != null) {
        let hola =
          "data:image/png;base64," + encode(response.data.imagenEvento.data);

        setImageBlog(hola);
      }

      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  return (
    <>
      {isDisabled === true ? (
        <>
          <div className="alert alert-primary" role="alert">
            Subiendo imagen, por favor espera...
          </div>
        </>
      ) : null}
      {finalData.imagenEvento === null ? (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 border border-secondary p-5 text-center bg-primary">
                <div className="wrapper">
                  <div style={{ cursor: "pointer" }} className="file-upload">
                    <input
                      accept="image/png, image/jpeg"
                      style={{ cursor: "pointer" }}
                      type="file"
                      onChange={(e) => {
                        formik.setFieldValue("imagenEvento", e.target.files[0]);

                        formik.submitForm();
                      }}
                    />
                    <ImageIcon
                      style={{ cursor: "pointer" }}
                      className="text-white"
                      fontSize="inherit"
                    />
                  </div>
                  <h5 className="text-white m-0 ms-2">Subir Imagen Portada</h5>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="container-fluid p-4">
            <div className="row d-flex align-items-center">
              <div className="col-md-8">
                <h3>Esta es la imagen de portada tu evento:</h3>
                <p>
                  Recuerda que como imagen de portada es necesario que tenga el
                  menos texto posible, servirá como una cenefa. <br></br>
                  <br></br>
                </p>
              </div>
              <div className="col-md-4">
                <form onSubmit={formik.handleSubmit} className="mb-3">
                  <span className="btn btn-primary btn-file w-100 text-white">
                    <ImageIcon fontSize="inherit" /> Cambiar Imagen
                    <input
                      accept="image/png, image/jpeg"
                      style={{ cursor: "pointer" }}
                      type="file"
                      onChange={(e) => {
                        formik.setFieldValue("imagenEvento", e.target.files[0]);
                        formik.submitForm();
                      }}
                    />
                  </span>
                </form>

                {
                  <img
                    alt="ImagenPortada"
                    className="img-fluid"
                    style={{ width: "100%" }}
                    src={`${imgBlob}`}
                  />
                }
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
