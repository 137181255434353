import React from "react";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { NoDataComponent } from "./../NoDataComponent/NoDataComponent";
import { AddDataPonentesDataEventComponent } from "./AddDataPonentesDataEventComponent";
import { getPonentesPorEvento } from "../../API/APIPonentesEventos";
import { AuthContext } from "../../auth/authContext";
import { CardPonentComponent } from "../CardPonentComponent/CardPonentComponent";
import { DeleteDataPonentesDataEventComponent } from "./DeleteDataPonentesDataEventComponent";
import { EditDataPonentesDataEventComponent } from "./EditDataPonentesDataEventComponent";
export const EditPonentesDataEventComponent = (props) => {
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    getPonentesPorEvento(null, user, props.id).then((resultado) => {
      setFinalData(resultado);
    });
  }, [crudAction]);
  React.useEffect(() => {
    getPonentesPorEvento(null, user, props.id).then((resultado) => {
      setFinalData(resultado);
    });
  }, []);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>
                En esta sección podrás subir los ponentes de tu evento
              </strong>
              <br></br>
              <small>
                *Te sugerimos ingresar la mayor información del ponente.
              </small>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar Ponente
              </button>
            </div>
          </div>
        </div>
      </div>
      {finalData.length === 0 ? (
        <NoDataComponent nombre="Ponentes para tu evento" />
      ) : (
        <div className="container-fluid">
          <div className="row row-cols-md-4 row-cols-sm-2">
            {finalData.map((ponente, index) => (
              <CardPonentComponent
                key={index}
                data={ponente}
                crudAction={crudAction}
                setCrudAction={setCrudAction}
              />
            ))}
          </div>
        </div>
      )}
      {crudAction.action === "add" ? (
        <>
          <AddDataPonentesDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="ponente"
          />
        </>
      ) : null}
      {crudAction.action === "edit" ? (
        <>
          <EditDataPonentesDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="ponente"
          />
        </>
      ) : null}
      {crudAction.action === "delete" ? (
        <>
          <DeleteDataPonentesDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="ponente"
          />
        </>
      ) : null}
    </>
  );
};
