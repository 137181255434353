import React from "react";

export const LoremComponent = () => {
  return (
    <>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam rutrum
      egestas augue et volutpat. Phasellus at quam venenatis, maximus dui sit
      amet, pellentesque mi. Vestibulum quis arcu a nulla dictum interdum ut non
      dui. Nam nec ornare leo, nec rutrum dolor. Proin a viverra ante. Etiam in
      malesuada enim, sit amet commodo lorem. Etiam ac augue viverra, interdum
      sapien id, pretium ligula. Nulla quis orci turpis. Suspendisse vel risus
      et felis cursus sollicitudin. Phasellus mattis neque sed bibendum mollis.
      Nulla porta sodales lacus, et tincidunt quam eleifend sed. Maecenas tempus
      mauris nulla, ac ultricies lectus faucibus vitae. Aenean enim enim,
      egestas non quam quis, volutpat elementum libero. Integer id ante
      vestibulum, eleifend eros suscipit, dapibus neque. Nulla aliquet vehicula
      mattis. Donec aliquet fermentum augue et rhoncus. Fusce consectetur velit
      eu laoreet eleifend. Maecenas bibendum mattis metus, at venenatis eros.
      Vestibulum sollicitudin blandit tincidunt. Sed vehicula accumsan placerat.
      Fusce malesuada, justo eget imperdiet cursus, tortor metus efficitur erat,
      a consequat arcu erat vel libero. Morbi quis aliquet ligula, sit amet
      suscipit elit. In pulvinar aliquet purus, vel dictum orci ullamcorper ut.
      Duis sollicitudin felis erat. Vestibulum congue tellus a pharetra
      convallis. Donec fermentum erat a nisi tristique suscipit. Sed semper
      rutrum nunc, cursus aliquet ante bibendum quis. Fusce molestie purus eu
      nulla interdum, non ultrices massa porttitor. Maecenas dolor sem, laoreet
      vitae rutrum ac, accumsan eget nunc. Fusce nec elit venenatis, consectetur
      diam vel, eleifend justo. Integer porttitor massa ac nulla iaculis
      sodales. Aliquam erat volutpat. Sed eu urna placerat, efficitur sem
      fermentum, vulputate turpis. Fusce at consectetur erat, id fermentum
      tortor. Cras vitae auctor libero, eget consectetur leo. Duis pellentesque
      elit vitae mi hendrerit, eu accumsan lacus vestibulum. Sed diam urna,
      tincidunt eget nulla sed, pellentesque dictum erat. Pellentesque habitant
      morbi tristique senectus et netus et malesuada fames ac turpis egestas.
      Morbi commodo ex lacus, eu tincidunt lacus interdum in. Vivamus in
      faucibus nunc, vitae vehicula neque. Duis felis mauris, accumsan sit amet
      ligula at, interdum scelerisque lorem. Sed elementum magna quis malesuada
      porta. Mauris placerat tincidunt felis. Vestibulum magna purus, vulputate
      id luctus non, pharetra dictum lorem. Morbi non turpis placerat,
      sollicitudin est sed, dictum enim. Quisque volutpat nisl at ante auctor
      eleifend eget at tellus. Donec quis tincidunt erat. Pellentesque dolor
      velit, euismod a risus quis, pellentesque volutpat ex. Sed in nulla
      sapien. Etiam at est id ante ultricies hendrerit ac quis tellus.
    </>
  );
};
