import { useFormik } from "formik";
import React from "react";
import styles from "./Form.module.css";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { API } from "./../../../../constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../../../auth/authContext";
import { types } from "./../../../../types/types";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";

const validationSchema = yup.object({
  nombreProveedor: yup
    .string("Ingresa Nombre de Proveedor")
    .required("Nombre de Proveedor requerido"),
  descripcion: yup.string("Ingresa Descripción").required("Descripción Requerida"),
  numeroExterior: yup.string().required("Número Exterior Necesario"),
  calle: yup.string().required("Calle requerida"),
  colonia: yup.string().required("Colonia"),
  codigoPostal: yup
    .number("Ingresa Número")
    .required("El Código Postal es necesario")
    .positive("No hay números negativos en CP, verifica!"),
  whatsapp: yup
    .string()
    .matches(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Ingresa número de teléfono válido"
    )
    .required("Whatsapp Necesario"),
  facebookUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingresa una URL de facebook correcta"
    )
    .required("Dirección Necesaria"),
  instagramUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingresa una URL de facebook correcta"
    )
    .required("Dirección Necesaria"),
  webUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingresa una URL de Instagram correcta"
    )
    .required("Dirección Necesaria"),
  email: yup
    .string("Ingresa tu correo electrónico")
    .email("Ingrresa un correo válido")
    .required("Correo necesario"),

});

export const Form = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [urlApi, setUrlApi] = React.useState();

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  const [typeFunction, setTypeFunction] = React.useState(
    props.isFormOpen.formFunction
  );
  const [classForm, setClassForm] = React.useState("");

  React.useEffect(() => {
    CountryStateCity("Estados");
  }, []);


  React.useEffect(() => {
    if (props.isFormOpen.formFunction === "Agregar") {
      setClassForm("text-success");
      setUrlApi("proveedor/createProveedor");
    }
    if (props.isFormOpen.formFunction === "Editar") {
      setClassForm("text-warning");
      setUrlApi("proveedor/updateProveedor/");
      getDetail(props.isFormOpen.id);
    }
    if (props.isFormOpen.formFunction === "Eliminar") {
      setClassForm("text-danger");
      setUrlApi("proveedor/logicalDelete/");
      getDetail(props.isFormOpen.id);
    }
  }, []);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setIsFormOpen({
        id: null,
        visible: false,
        formFuncion: null,
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      nombreProveedor: "",
      descripcion: "",
      idpais: "1",
      idestado: "null",
      idciudad: "null",
      calle: "",
      colonia: "",
      numeroExterior: "",
      numeroInterior: "",
      edificio: "",
      codigoPostal: "",
      whatsapp: "",
      facebookUrl: "",
      instagramUrl: "",
      webUrl: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

      if(values.idestado === "null" || values.idestado === "0" || values.idciudad === "null" || values.idciudad === "0")
      {
        toast.warn('Debes seleccionar el estado y/o municipio', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          });

          return;
      }

      const url = API + urlApi;
      APIData(url, values);
    },
  });

  const APIData = async (url, values) => {
    toast.info("Preparando: " + typeFunction, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

    

    if (typeFunction === "Agregar") {
      try {
        const response = await axios.post(url, values, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });

        toast.success("Registro Agregado Correctamente!  ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        props.setIsFormOpen({
          id: null,
          visible: false,
          formFuncion: null,
        });
      } catch (error) {
        console.warn(error.response);
        toast(error.response.data.message);
        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
    if (typeFunction === "Editar") {
      const urltoEdit = url + props.isFormOpen.id;
      try {
        const response = await axios.put(urltoEdit, values, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });

        toast.success("Registro Editado Correctamente!  ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        props.setIsFormOpen({
          id: null,
          visible: false,
          formFuncion: null,
        });
      } catch (error) {
        console.warn(error.response);
        toast(error.response.data.message);
        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
    if (typeFunction === "Eliminar") {
      const urltoEdit = url + props.isFormOpen.id;

      
      try {
        const response = await axios.put(urltoEdit, values, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });

        toast.success("Registro Eliminado Correctamente!  ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        props.setIsFormOpen({
          id: null,
          visible: false,
          formFuncion: null,
        });
      } catch (error) {
        console.warn(error.response);
        toast(error.response.data.message);
        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
  };

  const getDetail = async (id) => {
    try {
      const response = await axios.get(
        API + "proveedor/getProveedorDetail/" + id,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      

      formik.setFieldValue("nombreProveedor", response.data.nombreProveedor);
      formik.setFieldValue("descripcion", response.data.descripcion);
      formik.setFieldValue("idpais", response.data.idpais);
      formik.setFieldValue("idestado", response.data.idestado);
      formik.setFieldValue("idciudad", response.data.idciudad);
      formik.setFieldValue("calle", response.data.calle);
      formik.setFieldValue("colonia", response.data.colonia);
      formik.setFieldValue("numeroExterior", response.data.numeroExterior);
      formik.setFieldValue("numeroInterior", response.data.numeroInterior);
      formik.setFieldValue("edificio", response.data.edificio);
      formik.setFieldValue("codigoPostal", response.data.codigoPostal);
      formik.setFieldValue("whatsapp", response.data.whatsapp);
      formik.setFieldValue("facebookUrl", response.data.facebookUrl);
      formik.setFieldValue("instagramUrl", response.data.instagramUrl);
      formik.setFieldValue("webUrl", response.data.webUrl);
      formik.setFieldValue("email", response.data.email);

      CountryStateCity("Municipios", response.data.idestado);

    
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/login");
      }
    }
  };

  const CountryStateCity = async (type, idEstado) => {
    if (type === "Estados") {
      const api = API + "generales/getAllEstados";

      try {
        const response = await axios.get(api, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });
        if (response.status === 200) {
          setEstados(response.data);
        }
      } catch (error) {

        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
    if (type === "Municipios") {
      const api = API + "generales/getMunicipiosByEstado/" + idEstado;

      try {
        const response = await axios.get(api, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });
        if (response.status === 200) {
          setMunicipios(response.data);
        }
      } catch (error) {
     
        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
  };

  return (
    <>
      <div
        className={`${styles.FormCrudScreenComponent} col-md-6 col-sm-12 animate_animated animate_bounce`}
      >
        <div className="container-fluid p-5">
          <div className="row mt-3">
            <div className="col mt-1">
              <h5>
                <strong>{props.isFormOpen.formFunction} Proveedor </strong>
              </h5>
            </div>
            <div className="col-1 text-end">
              <button
                type="button"
                onClick={() =>
                  props.setIsFormOpen({
                    id: null,
                    visible: false,
                    formFuncion: null,
                  })
                }
                className="btn btn-sm btn-outline-danger"
              >
                X
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <form onSubmit={formik.handleSubmit}>
                <div className="row gy-2">
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="nombreProveedor"
                      name="nombreProveedor"
                      label="Nombre:"
                      value={formik.values.nombreProveedor}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nombreProveedor &&
                        Boolean(formik.errors.nombreProveedor)
                      }
                      helperText={
                        formik.touched.nombreProveedor &&
                        formik.errors.nombreProveedor
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="descripcion"
                      name="descripcion"
                      label="Descripción:"
                      value={formik.values.descripcion}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.descripcion &&
                        Boolean(formik.errors.descripcion)
                      }
                      helperText={
                        formik.touched.descripcion &&
                        formik.errors.descripcion
                      }
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="calle"
                      name="calle"
                      label="Calle:"
                      value={formik.values.calle}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.calle && Boolean(formik.errors.calle)
                      }
                      helperText={formik.touched.calle && formik.errors.calle}
                    />
                  </div>

                  <div className="col-sm-2">
                    <TextField
                      fullWidth
                      id="numeroExterior"
                      name="numeroExterior"
                      label="Ext."
                      value={formik.values.numeroExterior}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.numeroExterior &&
                        Boolean(formik.errors.numeroExterior)
                      }
                      helperText={
                        formik.touched.numeroExterior &&
                        formik.errors.numeroExterior
                      }
                    />
                  </div>

                  <div className="col-sm-2">
                    <TextField
                      fullWidth
                      id="numeroInterior"
                      name="numeroInterior"
                      label="Int."
                      value={formik.values.numeroInterior}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      fullWidth
                      id="edificio"
                      name="edificio"
                      label="Edificio"
                      value={formik.values.edificio}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="colonia"
                      name="colonia"
                      label="Colonia:"
                      value={formik.values.colonia}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.colonia && Boolean(formik.errors.colonia)
                      }
                      helperText={
                        formik.touched.colonia && formik.errors.colonia
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="codigoPostal"
                      name="codigoPostal"
                      label="Código Postal"
                      value={formik.values.codigoPostal}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.codigoPostal &&
                        Boolean(formik.errors.codigoPostal)
                      }
                      helperText={
                        formik.touched.codigoPostal &&
                        formik.errors.codigoPostal
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="whatsapp"
                      name="whatsapp"
                      label="Whatsapp"
                      value={formik.values.whatsapp}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.whatsapp &&
                        Boolean(formik.errors.whatsapp)
                      }
                      helperText={
                        formik.touched.whatsapp && formik.errors.whatsapp
                      }
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      size="small"
                      id="facebookUrl"
                      label="Url Facebook"
                      name="facebookUrl"
                      autoComplete="facebookUrl"
                      value={formik.values.facebookUrl}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.facebookUrl &&
                        Boolean(formik.errors.facebookUrl)
                      }
                      helperText={
                        formik.touched.facebookUrl && formik.errors.facebookUrl
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      size="small"
                      id="instagramUrl"
                      label="Url Instagram"
                      name="instagramUrl"
                      autoComplete="instagramUrl"
                      value={formik.values.instagramUrl}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.instagramUrl &&
                        Boolean(formik.errors.instagramUrl)
                      }
                      helperText={
                        formik.touched.instagramUrl &&
                        formik.errors.instagramUrl
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      size="small"
                      id="webUrl"
                      label="Url Página Web"
                      name="webUrl"
                      autoComplete="webUrl"
                      value={formik.values.webUrl}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.webUrl && Boolean(formik.errors.webUrl)
                      }
                      helperText={formik.touched.webUrl && formik.errors.webUrl}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      size="small"
                      id="email"
                      label="Correo Electrónico"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <div className="col-sm-6">
                    {
                      typeFunction === "Agregar" ? (<> 
                       <TextField
                      
                      fullWidth
                      type="password"
                      size="small"
                      id="password"
                      label="Contraseña"
                      name="password"
                      autoComplete="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                      </>) : null
                    }
                   
                  </div>

                  <div className="col-sm-6">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="idestado">Estado</InputLabel>
                      <Select
                        autoWidth
                        native
                        value={formik.values.idestado}
                        onChange={(e) => {
                          
                          formik.handleChange(e);
                          toast.dark("No olvides seleccionar el municipio", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          CountryStateCity("Municipios", e.target.value);
                        }}
                        inputProps={{
                          name: "idestado",
                          id: "idestado",
                        }}
                      >
                        <option aria-label="Seleccionar Estado" value={"0"}>
                          {" "}
                          Seleccionar Ciudad{" "}
                        </option>
                        {estados.map((estado) => (
                          <option key={estado.id} value={estado.id}>
                            {estado.nombre}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-sm-6">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="idciudad">Estado</InputLabel>
                      <Select
                        autoWidth
                        native
                        value={formik.values.idciudad}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        inputProps={{
                          name: "idciudad",
                          id: "idciudad",
                        }}
                      >
                        <option aria-label="Seleccionar Ciudad" value={"0"}>
                          Seleccionar Municipio
                        </option>
                        {municipios.map((municipio) => (
                          <option key={municipio.id} value={municipio.id}>
                            {municipio.nombre}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <Button
                  className="my-4"
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  {props.isFormOpen.formFunction} Proveedor
                </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
