/* eslint-disable no-unused-vars */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  NavLink,
} from "react-router-dom";
import { FooterComponent } from "./../Components/FooterComponent/FooterComponent";
import { PanelMenuComponent } from "./../Components/PanelMenuComponent/PanelMenuComponent";
import { LoremComponent } from "./../Components/LoremComponent/LoremComponent";
import logoImage from "../Images/logo.png";
import { AdminUsersScreen } from "./../screens/Private/AdminUsersScreen/AdminUsersScreen";
import { MaterialsScreen } from "./../screens/Private/MaterialsScreen/MaterialsScreen";
import { ProfessionalScreen } from "./../screens/Private/ProfessionalsScreen/ProfessionalScreen";
import { SuppliersScreen } from "./../screens/Private/SuppliersScreen/SuppliersScreen";
import { ProfileSupplierScreen } from "./../screens/Public/ProfileSupplierScreen/ProfileSupplierScreen";
import imagePromotional from "../Images/promotionals/promotional.jpg";
import { ServicesScreen } from "../screens/Private/ServicesScreen/ServicesScreen";
import { HeaderAdminPanelComponent } from "../Components/HeaderAdminPanelComponent/HeaderAdminPanelComponent";
import MenuIcon from "@material-ui/icons/Menu";
import { MainDashboardScreen } from "../screens/Private/MainDashboardScreen/MainDashboardScreen";
import { BusinessUsersScreen } from "../screens/Private/BusinessUsersScreen/BusinessUsersScreen";
import { ProfileScreen } from "../screens/Private/ProfileScreen/ProfileScreen";
import { BusinessDataScreen } from "../screens/Private/BusinessDataScreen/BusinessDataScreen";
import { CreateEventScreen } from "../screens/Private/CreateEventScreen/CreateEventScreen";
import { BarTitleComponent } from "../Components/BarTitleComponent/BarTitleComponent";
import { EventListScreen } from "../screens/Private/EventListScreen/EventListScreen";
import { EventEditScreen } from "../screens/Private/EventEditScreen/EventEditScreen";
import { SpeakersListScreen } from "../screens/Private/SpeakersListScreen/SpeakersListScreen";
import { EventDetailPublic } from "../screens/Public/EventDetailPublic/EventDetailPublic";
import { AdminActivitiesProgramTypes } from "../screens/Private/AdminActivitiesProgramTypes/AdminActivitiesProgramTypes";
import { EventCategoriesSubcategoriesTypesScreen } from "../screens/Private/EventCategoriesSubcategoriesTypesScreen/EventCategoriesSubcategoriesTypesScreen";
import { AdminTypesActivitiesScreen } from "../screens/Private/AdminTypesActivitiesScreen/AdminTypesActivitiesScreen";
import { AdminCarouselPrincipalScreen } from "../screens/Private/AdminCarouselPrincipalScreen/AdminCarouselPrincipalScreen";
import { AdminFeaturedEventsScreen } from "../screens/Private/AdminFeaturedEventsScreen/AdminFeaturedEventsScreen";
import { AdminUpcomingEventsScreen } from "../screens/Private/AdminUpcomingEventsScreen/AdminUpcomingEventsScreen";
import { EnclosuresListScreen } from "../screens/Private/EnclosuresListScreen/EnclosuresListScreen";
import { CreateEnclosureScreen } from "../screens/Private/CreateEnclosureScreen/CreateEnclosureScreen";
import { AdminEnclosureCategories } from "../screens/Private/AdminEnclosureCategories/AdminEnclosureCategories";
import { EnclosureEditScreen } from "../screens/Private/EnclosureEditScreen/EnclosureEditScreen";
import { AdminEventAds } from "../screens/Private/AdminEventAds/AdminEventAds";
import { SuppliersListScreen } from "../screens/Private/SuppliersListScreen/SuppliersListScreen";
import { AdminSupplierCategories } from "../screens/Private/AdminSupplierCategories/AdminSupplierCategories";
import { CreateSupplierScreen } from "../screens/Private/CreateSupplierScreen/CreateSupplierScreen";
import { SupplierEditScreen } from "../screens/Private/SupplierEditScreen/SupplierEditScreen";
import { AdminFeaturedSuppliersScreen } from "../screens/Private/AdminFeaturedSuppliersScreen/AdminFeaturedSuppliersScreen";
import { AdminFeaturedEnclosuresScreen } from "../screens/Private/AdminFeaturedEnclosuresScreen/AdminFeaturedEnclosuresScreen";
import { checkViewPermission } from "../API/APIPermisos";
import { AuthContext } from "../auth/authContext";
import { AdminUsersCatalogueScreen } from "../screens/Private/AdminUsersCatalogueScreen/AdminUsersCatalogueScreen";
import { NotFoundComponent } from "../screens/Public/NotFoundComponent/NotFoundComponent";
import { AdminTermsAndConditionsEdit } from "../screens/Private/AdminTermsAndConditionsEdit/AdminTermsAndConditionsEdit";
import { AdminPrivacyEdit } from "../screens/Private/AdminPrivacyEdit/AdminPrivacyEdit";
import { EnclosureEventAds } from "../screens/Private/EnclosureEventAds/EnclosureEventAds";
import { SupplierAds } from "../screens/Private/SupplierAds/SupplierAds";
import styles from "./DashboardRoutes.module.css";
export const DashboardRoutes = () => {
  const [isVisibleLeftMenu, setIsVisibleLeftMenu] = React.useState(true);
  const [uuidVista, SetUuidVista] = React.useState("");
  const { user, dispatch } = React.useContext(AuthContext);
  const [resultadoPermisos, setResultadoPermisos] = React.useState([]);

  React.useEffect(() => {
    function updateSize() {
      setIsVisibleLeftMenu(true);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
  }, []);

  return (
    <>
      <HeaderAdminPanelComponent />
      <div className={`container-fluid mt-sm-0 mt-md-5 pt-md-2`}>
        <div className="row align-self-stretch">
          <div
            style={{ zIndex: 100000 }}
            className={
              isVisibleLeftMenu ? `col-md-2 g-0 position-fixed` : "d-none"
            }
          >
            <div
              onClick={() => setIsVisibleLeftMenu(!isVisibleLeftMenu)}
              className={`btn btn-sm btn-success my-2  d-sm-none  ${styles.menuAbrirCerrar}`}
            >
              <MenuIcon />
            </div>
            <PanelMenuComponent />
          </div>
          <div
            className={
              isVisibleLeftMenu
                ? `col-md-10 pt-2 offset-md-2`
                : "col-md-12 pt-2 ps-4"
            }
          >
            <div
              onClick={() => setIsVisibleLeftMenu(!isVisibleLeftMenu)}
              className={`btn btn-sm btn-success my-2  d-sm-none ${styles.menuAbrirCerrar}`}
              style={{ zIndex: 500000 }}
            >
              <MenuIcon />
            </div>
            <br></br>
            <Switch>
              <Route exact path="/">
                <MainDashboardScreen SetUuidVista={SetUuidVista} />
              </Route>
              <Route exact path="/adminusers">
                <AdminUsersScreen
                  title="Administradores Builder"
                  description="CRUD Administradores Builder"
                />
              </Route>
              <Route exact path="/businessusers">
                <BusinessUsersScreen
                  title="Agrega Usuarios"
                  description="Agrega usuarios a tu perfil, para que puedan modificar la información de tus servicios contratados."
                />
              </Route>
              <Route exact path="/profile">
                <ProfileScreen
                  title="Editar Información de Perfil"
                  description="Edita tu información de perfil"
                />
              </Route>
              <Route exact path="/myevents">
                <BarTitleComponent
                  titulo="Listado de Eventos"
                  descripcion="Se muestra el listado de eventos creados en la plataforma."
                />
                <EventListScreen />
              </Route>
              <Route exact path="/addevent">
                <BarTitleComponent
                  titulo="Agregar Evento"
                  descripcion="Ingresa la información principal del evento."
                />
                <CreateEventScreen />
              </Route>
              <Route exact path="/eventedit/:id">
                <BarTitleComponent
                  titulo="Editar Evento"
                  descripcion="Ingresa los detalles del evento."
                  showBar={false}
                />
                <EventEditScreen />
              </Route>
              <Route exact path="/event/:id">
                <EventDetailPublic />
              </Route>
              <Route exact path="/speakers">
                <BarTitleComponent
                  titulo="Ponentes"
                  descripcion="Puedes Agregar, editar y cambiar los datos de los ponentes generales de tus eventos."
                />
                <SpeakersListScreen />
              </Route>
              <Route exact path="/businessdata">
                <BusinessDataScreen
                  title="Editar Información de la empresa"
                  description="Edita tu información de tu empresa"
                />
              </Route>
              <Route exact path="/adminactivitiesprogramtypes">
                <BarTitleComponent
                  titulo="Tipos de actividades para programa"
                  descripcion="Puedes Agregar, editar y cambiar las actividades que tiene un programa."
                />
                <AdminTypesActivitiesScreen />
              </Route>
              <Route exact path="/admineventCategories">
                <BarTitleComponent
                  titulo="Categorías de eventos creados en Events U-Ctrl"
                  descripcion="Puedes Agregar, editar y cambiar las categorías en que puede ser englobado un evento"
                />
                <AdminActivitiesProgramTypes />
              </Route>

              {/*Proveedores*/}

              <Route exact path="/adminsupplierscategories">
                <BarTitleComponent
                  titulo="Categorías de proveedores creados en Events U-Ctrl"
                  descripcion="Puedes Agregar, editar y cambiar las categorías en que puede ser englobado un proveedor"
                />
                <AdminSupplierCategories />
              </Route>
              <Route exact path="/mysuppliers">
                <BarTitleComponent
                  titulo="Listado de Proveedores"
                  descripcion="Se muestra el listado de proveedores creados en la plataforma."
                />
                <SuppliersListScreen />
              </Route>
              <Route exact path="/addsupplier">
                <BarTitleComponent
                  titulo="Agregar Proveedor"
                  descripcion="Ingresa la información principal del proveedor."
                />
                <CreateSupplierScreen />
              </Route>
              <Route exact path="/supplieredit/:id">
                <BarTitleComponent
                  titulo="Editar"
                  descripcion="Ingresa la información del proveedor"
                />

                <SupplierEditScreen />
              </Route>

              {/*Recintos*/}
              <Route exact path="/myenclosures">
                <BarTitleComponent
                  titulo="Listado de Recintos"
                  descripcion="Se muestra el listado de recintos creados en la plataforma."
                />
                <EnclosuresListScreen />
              </Route>
              <Route exact path="/addenclosure">
                <BarTitleComponent
                  titulo="Agregar Recinto"
                  descripcion="Ingresa la información principal del recinto."
                />
                <CreateEnclosureScreen />
              </Route>
              <Route exact path="/enclosureedit/:id">
                <BarTitleComponent
                  titulo="Editar"
                  descripcion="Ingresa la información del recinto"
                />
                <EnclosureEditScreen />
              </Route>
              <Route exact path="/adminenclosurecategories">
                <BarTitleComponent
                  titulo="Categorías de recintos creados en Events U-Ctrl"
                  descripcion="Puedes Agregar, editar y cambiar las categorías en que puede ser englobado un evento"
                />
                <AdminEnclosureCategories />
              </Route>

              {/*carouselPrincipal Administrador*/}
              <Route exact path="/carouselPrincipal">
                <BarTitleComponent
                  titulo="Carrousel Principal"
                  descripcion="Puedes Agregar, editar y cambiar el Carrousel principal"
                />
                <AdminCarouselPrincipalScreen />
              </Route>
              <Route exact path="/termsAndConditionsEdit">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-md-6">
                      {" "}
                      <AdminTermsAndConditionsEdit />
                    </div>
                    <div className="col-md-6">
                      {" "}
                      <AdminPrivacyEdit />
                    </div>
                  </div>
                </div>
              </Route>
              {/*Administrador Eventos Destacados*/}
              <Route exact path="/adminFeaturedEvents">
                <BarTitleComponent
                  titulo="Eventos Destacados"
                  descripcion="Puedes Agregar, editar y cambiar los eventos destacados de página principal, tambien cambiarán en la vista de todos los eventos."
                />
                <AdminFeaturedEventsScreen />
              </Route>

              {/*Administrador Proveedores Destacados*/}
              <Route exact path="/adminFeaturedSuppliers">
                <BarTitleComponent
                  titulo="Proveedores Destacados"
                  descripcion="Puedes Agregar, editar y cambiar los proveedores destacados de página principal, tambien cambiarán en la vista de todos los eventos."
                />
                <AdminFeaturedSuppliersScreen />
              </Route>

              {/*Administrador Recintos Destacados*/}
              <Route exact path="/adminFeaturedEnclosures">
                <BarTitleComponent
                  titulo="Recintos Destacados"
                  descripcion="Puedes Agregar, editar y cambiar los recintos destacados de página principal, tambien cambiarán en la vista de todos los eventos."
                />
                <AdminFeaturedEnclosuresScreen />
              </Route>

              {/*Administrador Próximos Eventos*/}
              <Route exact path="/adminUpcomingEvents">
                <BarTitleComponent
                  titulo="Proximos Eventos"
                  descripcion="Puedes Agregar, editar y cambiar los próximos eventos de página principal, tambien cambiarán en la vista de todos los eventos."
                />
                <AdminUpcomingEventsScreen />
              </Route>
              {/*Administrador Publicidad Eventos*/}
              <Route exact path="/eventads">
                <BarTitleComponent
                  titulo="Publicidad en eventos"
                  descripcion="Puedes Agregar, editar y cambiar la publicidad en el detalles de los eventos, tambien cambiarán en la vista de todos los eventos."
                />

                <AdminEventAds />
              </Route>

              {/*Administrador Publicidad Recintos*/}
              <Route exact path="/enclosuresads">
                <BarTitleComponent
                  titulo="Publicidad en recintos"
                  descripcion="Puedes Agregar, editar y cambiar la publicidad en el detalle de los recintos, tambien cambiarán en la vista de todos los recintos."
                />

                <EnclosureEventAds />
              </Route>

              {/*Administrador Publicidad Recintos*/}
              <Route exact path="/supplierads">
                <BarTitleComponent
                  titulo="Publicidad en proveedores"
                  descripcion="Puedes Agregar, editar y cambiar la publicidad en el detalle de los proveedores, tambien cambiarán en la vista de todos los proveedores."
                />

                <SupplierAds />
              </Route>

              {/*Administración Usuarios*/}
              <Route exact path="/adminusercatalogue">
                <AdminUsersCatalogueScreen
                  title="Agrega Usuarios Administradores"
                  description="Agrega usuarios a tu perfil, para que puedan modificar la información de tus servicios contratados."
                />
              </Route>

              <Route path="/403">
                <NotFoundComponent
                  number={"403"}
                  description={"No tienes permiso para acceder a este recurso"}
                />
              </Route>
              <Route path="*">
                <NotFoundComponent
                  number={"404"}
                  description={"No se ha encontrado la página"}
                />
              </Route>
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};
