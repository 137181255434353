import React from "react";
import { AuthContext } from "../../auth/authContext";
import CloseIcon from "@material-ui/icons/Close";
import {
  getEventosAsignados,
  getEventosParaAsignar,
} from "../../API/APIAsignacionEventos";
import { CardAssignEventComponent } from "../CardAssignEventComponent/CardAssignEventComponent";

export const UserAssignEventsComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [userPermissions, setUserPermissions] = React.useState([]);

  const [finalData, setFinalData] = React.useState([]);
  const [eventosAsignados, setEventosAsignados] = React.useState([]);
  const [counter, setCounter] =React.useState(0);

  React.useEffect(() => {
    getEventosParaAsignar(null, user).then((resultadoEventosParaAsignar) => {
      for (const evento of resultadoEventosParaAsignar) {
        evento.isAssigned = false;
      }
      getEventosAsignados(props.crudType.data.id, user).then((resultado) => {
        let iterarEnEventos = resultadoEventosParaAsignar;
        for (const todosEventos of iterarEnEventos) {
          for (const eventoAsignado of resultado) {
            if (todosEventos.tituloEvento === eventoAsignado.tituloEvento) {
              todosEventos.isAssigned = true;
            }
          }
        }
        setFinalData(resultadoEventosParaAsignar);
      });
    });
  }, []);


  React.useEffect(() => {
    getEventosParaAsignar(null, user).then((resultadoEventosParaAsignar) => {
      for (const evento of resultadoEventosParaAsignar) {
        evento.isAssigned = false;
      }
      getEventosAsignados(props.crudType.data.id, user).then((resultado) => {
        let iterarEnEventos = resultadoEventosParaAsignar;
        for (const todosEventos of iterarEnEventos) {
          for (const eventoAsignado of resultado) {
            if (todosEventos.tituloEvento === eventoAsignado.tituloEvento) {
              todosEventos.isAssigned = true;
            }
          }
        }
        setFinalData(resultadoEventosParaAsignar);
      });
    });
  }, [counter]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudType({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div className="crudDivComponent col-md-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h5>Asignación de Eventos a Usuario</h5>
                  <small>
                    <strong> {props.crudType.data.email}</strong>{" "}
                  </small>
                  <p className="mt-2">
                    Puedes dar acceso a los eventos que el usuario necesite
                  </p>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudType({ action: null, data: null })
                    }
                  >
                    {" "}
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {finalData.map((evento, index) => (
                <CardAssignEventComponent
                  key={index}
                  data={evento}
                  eventosAsignados={eventosAsignados}
                  dataUsuario={props.crudType.data}
                  setCounter={setCounter}
                  counter={counter}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
