import React from "react";
import styles from "./CardImagenCarouselComponent.module.css";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
export const CardImagenCarouselComponent = (props) => {
  

  return (
    <div className="col mb-3">
      <div className="card">
        <div className="p-2">
          <div className="d-flex justify-content-between">
            <span className="badge rounded-pill bg-success">
              {props.data.orden}
            </span>
            <span className="badge rounded-pill bg-success" onClick={() => {
              props.setCrudAction({ action: "changeOrder", data: props.data })
            }}>
              Cambiar Orden
            </span>
          </div>
        </div>
        <div
          className={`card-body ${styles.imagenFondo}`}
          style={{
            backgroundSize: "cover",
            background:
              'linear-gradient(rgba(0,0,0,0.65),rgba(0,0,0,0.65)),url("' +
              props.data.imagenConvertida +
              '")',
          }}
        >
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <div
              className="btn btn-sm btn-danger"
              onClick={() =>
                props.setCrudAction({ action: "delete", data: props.data })
              }
            >
              <DeleteIcon fontSize="inherit" /> Eliminar
            </div>
          </div>

          <div className="my-5 py-5 text-center">
            <h5 className="text-white">{props.data.titulo}</h5>
            <h6 className="text-white">
              <small>{props.data.descripcion}</small>
            </h6>
            <a
              href={props.data.urlAccion}
              target="_blank"
              rel="noreferrer"
              className="btn btn-sm btn-info"
            >
              {props.data.textoBoton}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
