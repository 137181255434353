import React from "react";
import styles from "./SuppliersPublic.module.css";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";
import { HeaderAnnouncementComponent } from "../../../Components/HeaderAnnouncementComponent/HeaderAnnouncementComponent";
import { CardSupplierComponent } from "../../../Components/CardSupplierComponent/CardSupplierComponent";
import { getPublicFeaturedSuppliers } from "../../../API/APIProveedores";
import Skeleton from "@material-ui/lab/Skeleton";
import { CardFeaturedSuppliersComponent } from "../../../Components/CardFeaturedSuppliersComponent/CardFeaturedSuppliersComponent";
import { AllSuppliersPublicComponent } from "./../../../Components/AllSuppliersPublicComponent/AllSuppliersPublicComponent";
import { AllCompoundsPublicComponent } from "../../../Components/AllCompoundsPublicComponent/AllCompoundsPublicComponent";
import SearchIcon from "@material-ui/icons/Search";
import { SearchSuppliersResultComponent } from "../../../Components/SearchSuppliersResultComponent/SearchSuppliersResultComponent";
import { SearchSuppliersComponent } from "../../../Components/SearchSuppliersComponent/SearchSuppliersComponent";
export const SuppliersPublic = (props) => {
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [finalDataFeatured, setFinalDataFeatured] = React.useState([]);
  const [allFinalData, setAllFinalData] = React.useState([]);
  const [loadingFeatured, setLoadingFeatured] = React.useState(true);

  let effectReady = false;

  React.useEffect(() => {
    getPublicFeaturedSuppliers(null).then((resultado) => {
      setFinalDataFeatured(resultado);
      setLoadingFeatured(false);
    });

    return () => {
      effectReady = true;
    };
  }, []);

  return (
    <>
      <div
        className={styles.searchButton}
        onClick={() => {
          setCrudAction({ action: "search", data: [] });
        }}
      >
        <SearchIcon /> Buscar {props.title}
      </div>

      <Helmet>
        <title>Events U-Ctrl - Proveedores</title>
        <meta
          name="description"
          content="Proveedores plataforma events U-Ctrl"
        />
      </Helmet>
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <div className="" style={{ marginTop: "88px" }}></div>
      <HeaderAnnouncementComponent
        title="Proveedores"
        subtitle="Proveedores registrados en Events U-Ctrl"
        background={false}
      />

      {crudAction.action === "showResults" ? (
        <>
          <section className={`pt-1 pb-5 text-center`}>
            <div className="container mt-5 pt-2 pb-3">
              <div className="row">
                <div className="col-md-12 mb-5 mt-3">
                  <h2>Proveedores Encontrados</h2>
                </div>
              </div>
            </div>
            <SearchSuppliersResultComponent data={crudAction.data} />
          </section>
        </>
      ) : null}

      <section className={`pt-1 pb-5 text-center`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Proveedores Destacados</h2>
            </div>
          </div>
          {loadingFeatured === true ? (
            <>
              {" "}
              <div className="row row-cols-md-3">
                <div className="col">
                  <Skeleton variant="rect" height={118} />
                </div>
                <div className="col">
                  <Skeleton variant="rect" height={118} />
                </div>
                <div className="col">
                  <Skeleton variant="rect" height={118} />
                </div>
              </div>{" "}
            </>
          ) : (
            <>
              <div className="row row-cols-md-3">
                {" "}
                {finalDataFeatured.map((recintoDestacado) => (
                  <CardFeaturedSuppliersComponent
                    data={recintoDestacado}
                    admin={false}
                    destacado={true}
                  />
                ))}{" "}
              </div>
            </>
          )}
        </div>
      </section>

      <section className={`pt-1 pb-5 text-center`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Todos los Proveedores</h2>
            </div>
          </div>
        </div>

        <AllSuppliersPublicComponent />
      </section>

      {crudAction.action === null ||
      crudAction.action == "showResults" ? null : (
        <SearchSuppliersComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
        />
      )}

    </>
  );
};
