import React from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import {
  getAreasServicioRecintoPublico,
  getDetalleRecintoPublico,
  getDirectorioRecintoPublico,
  getEspacioRecintoPublico,
  getGaleriaRecintoPublico,
  getPlanoRecintoPublico,
  getServiciosRecintoPublico,
  getUbicacionesCercanasRecintoPublico,
} from "../../../API/APIRecintos";
import { LoaderEventsComponent } from "../../../Components/LoaderEventsComponent/LoaderEventsComponent";
import { HeaderEnclosureDetail } from "../../../Components/HeaderEnclosureDetail/HeaderEnclosureDetail";
import { ImageDescriptionDetailEnclosureComponent } from "../../../Components/ImageDescriptionDetailEnclosureComponent/ImageDescriptionDetailEnclosureComponent";
import { ImageDescriptionDetailEventComponent } from "../../../Components/ImageDescriptionDetailEventComponent/ImageDescriptionDetailEventComponent";
import { InformationEnclosureDetail } from "../../../Components/InformationEnclosureDetail/InformationEnclosureDetail";
import { UbicacionesCercanasRecinto } from "../../../Components/UbicacionesCercanasRecinto/UbicacionesCercanasRecinto";
import { RedesSocialesRecinto } from "../../../Components/RedesSocialesRecinto/RedesSocialesRecinto";
import NearMeIcon from "@material-ui/icons/NearMe";
import { PublicPlanoRecintoComponent } from "../../../Components/PublicPlanoRecintoComponent/PublicPlanoRecintoComponent";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { GaleriaRecintoPublicComponent } from "../../../Components/GaleriaRecintoPublicComponent/GaleriaRecintoPublicComponent";
import { ServiciosOfrecidosRecintoPublico } from "../../../Components/ServiciosOfrecidosRecintoPublico/ServiciosOfrecidosRecintoPublico";
import { AreasDeServicioRecintoComponent } from "../../../Components/AreasDeServicioRecintoComponent/AreasDeServicioRecintoComponent";
import { EspaciosRecintoPublicComponent } from "../../../Components/EspaciosRecintoPublicComponent/EspaciosRecintoPublicComponent";
import { DirectorioRecintoPublicComponent } from "../../../Components/DirectorioRecintoPublicComponent/DirectorioRecintoPublicComponent";
import { PublicAdsEclosureComponent } from "../../../Components/PublicAdsEnclosureComponent/PublicAdsEnclosureComponent";

export const EnclosureDetailPublic = (props) => {
  const [principalData, setPrincipalData] = React.useState({});
  const [ubicacionesCeranasRecinto, setUbicacionesCercanasRecinto] =
    React.useState([]);
  const [planoRecinto, setPlanoRecinto] = React.useState([]);
  const [galeriaRecinto, setGaleriaRecinto] = React.useState([]);
  const [directorioRecinto, setDirectorioRecinto] = React.useState([]);
  const [serviciosRecinto, setServiciosRecinto] = React.useState([]);
  const [areasServicioRecinto, setAreasServicioRecinto] = React.useState([]);
  const [espaciosRecinto, setEspaciosRecinto] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);

  const [planoShow, setPlanoShow] = React.useState(false);

  const [ligaVacia, setLigaVacia] = React.useState();



  let history = useHistory();
  const idrecinto = useParams().id;

  React.useEffect(() => {
    getDetalleRecintoPublico(idrecinto).then((resultado) => {

    
      
      setPrincipalData(resultado);
    });

    getUbicacionesCercanasRecintoPublico(idrecinto).then((resultado) => {
      setUbicacionesCercanasRecinto(resultado);
    });

    getPlanoRecintoPublico(idrecinto).then((resultado) => {
      setPlanoRecinto(resultado);
    });

    getGaleriaRecintoPublico(idrecinto).then((resultado) => {
      setGaleriaRecinto(resultado);
    });
    getServiciosRecintoPublico(idrecinto).then((resultado) => {
      setServiciosRecinto(resultado);
    });

    getAreasServicioRecintoPublico(idrecinto).then((resultado) => {
      setAreasServicioRecinto(resultado);
    });

    getDirectorioRecintoPublico(idrecinto).then((resultado) => {
      setDirectorioRecinto(resultado);
    });

    getEspacioRecintoPublico(idrecinto).then((resultado) => {
      setEspaciosRecinto(resultado);
    });

    setLoaded(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${business.information.businessName} - ${principalData.nombreRecinto}`}</title>
        <meta name="description" content={principalData.nombreRecinto} />
      </Helmet>
      {loaded === false ? <LoaderEventsComponent /> : null}
      <HeaderEnclosureDetail type="evento" data={principalData} />
      <section className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-8 blog-main">
            <div className="container-fluid g-0">
              <div className="row g-0">
                <ImageDescriptionDetailEnclosureComponent
                  data={principalData}
                />
              </div>
            </div>
            <div className="container-fluid g-0">
              <div className="row mt-4 g-0">
                <div className="col-12 bg-white p-4">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    {espaciosRecinto.length === 0 ? null : (
                      <>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-espacios-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-espacios"
                            type="button"
                            role="tab"
                            aria-controls="pills-espacios"
                            aria-selected="true"
                          >
                            Espacios
                          </button>
                        </li>
                      </>
                    )}

                    {serviciosRecinto.length === 0 ? null : (
                      <>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-servicios-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-servicios"
                            type="button"
                            role="tab"
                            aria-controls="pills-servicios"
                            aria-selected="true"
                          >
                            Servicios
                          </button>
                        </li>
                      </>
                    )}

                    {directorioRecinto.length === 0 ? null : (
                      <>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-directorioRecinto-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-directorioRecinto"
                            type="button"
                            role="tab"
                            aria-controls="pills-directorioRecinto"
                            aria-selected="true"
                          >
                            Directorio
                          </button>
                        </li>
                      </>
                    )}
                    {galeriaRecinto.length === 0 ? null : (
                      <>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-galeriarecinto-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-galeriarecinto"
                            type="button"
                            role="tab"
                            aria-controls="pills-galeriarecinto"
                            aria-selected="true"
                          >
                            <CameraAltIcon />
                          </button>
                        </li>
                      </>
                    )}
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-espacios"
                      role="tabpanel"
                      aria-labelledby="pills-espacios-tab"
                    >
                      <div className="container-fluid">
                        <div className="row row-cols-md-1 g-0">
                          <EspaciosRecintoPublicComponent
                            data={espaciosRecinto}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-directorio"
                      role="tabpanel"
                      aria-labelledby="pills-directorio-tab"
                    >
                      <div className="container-fluid">
                        <div className="row row-cols-md-1 g-0">Directorio</div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-galeriarecinto"
                      role="tabpanel"
                      aria-labelledby="pills-galeriarecinto-tab"
                    >
                      <div className="container-fluid">
                        <div className="row row-cols-md-1 g-0">
                          <GaleriaRecintoPublicComponent
                            data={galeriaRecinto}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-directorioRecinto"
                      role="tabpanel"
                      aria-labelledby="pills-directorioRecinto-tab"
                    >
                      <div className="container-fluid">
                        <div className="row row-cols-md-1 g-0">
                          <DirectorioRecintoPublicComponent
                            data={directorioRecinto}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-servicios"
                      role="tabpanel"
                      aria-labelledby="pills-servicios-tab"
                    >
                      <div className="container-fluid">
                        <div className="row row-cols-md-2">
                          <div className="col">
                            <ServiciosOfrecidosRecintoPublico
                              data={serviciosRecinto}
                            />
                          </div>
                          <div className="col">
                            <AreasDeServicioRecintoComponent
                              data={areasServicioRecinto}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 blog-main">
            <div className="container-fluid">
              <div className="row no-gutters">
                <div
                  className="col-12 p-4"
                  style={{ backgroundColor: "white" }}
                >
                  <InformationEnclosureDetail
                    data={principalData}
                    idrecinto={idrecinto}
                  />
                </div>
              </div>

              {ubicacionesCeranasRecinto.length === 0 ? null : (
                <>
                  <div className="row no-gutters mt-4">
                    <div
                      className="col-12 p-4"
                      style={{ backgroundColor: "white" }}
                    >
                      {/*<SponsorsEventDetailComponent data={patrocinadoresResult} />*/}
                      <UbicacionesCercanasRecinto
                        data={ubicacionesCeranasRecinto}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className="row no-gutters mt-4">
                <div
                  className="col-12 p-4"
                  style={{ backgroundColor: "white" }}
                >
                  <RedesSocialesRecinto data={principalData} />
                </div>
              </div>
              <div className="row no-gutters mt-4">
                <div
                  className="col-12 p-4"
                  style={{ backgroundColor: "white" }}
                >
                  <h6>Plano Recinto</h6>
                  <hr></hr>
                  <div
                    className="colorDegradadoEvents px-2 py-5 text-center cursor-pointer"
                    onClick={() => {
                      setPlanoShow(!planoShow);
                    }}
                  >
                    <NearMeIcon /> <br></br>{" "}
                    <strong> Pulsa para ver el plano del recinto. </strong>
                  </div>
                </div>
              </div>
              <div className="row no-gutters mt-4">
                <div
                  className="col-12 p-4"
                  style={{ backgroundColor: "white" }}
                >
                  <PublicAdsEclosureComponent idrecinto={idrecinto} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {planoShow === true ? (
        <>
          {planoRecinto.length === 0 ? null : (
            <>
              <PublicPlanoRecintoComponent
                data={planoRecinto}
                setPlanoShow={setPlanoShow}
                planoShow={planoShow}
              />
            </>
          )}
        </>
      ) : null}

      
    </>
  );
};
