import React from "react";
import { useState, useEffect } from "react";
import { LoaderComponent } from "./../../../Components/LoaderComponent/LoaderComponent";
import MaterialTable from "material-table";
import { tableLanguaje } from "./../../../helpers/SpanishLanguageTable";
import { tableIcons } from "./../../../helpers/TableIcons";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../../auth/authContext";
import axios from "axios";
import { API, HEADERS_API_JSON } from "./../../../constants/ApiConnection";
import { toast } from "react-toastify";
import { types } from "./../../../types/types";
import { CardAdminUsersComponent } from "./../../../Components/CardAdminUsersComponent/CardAdminUsersComponent";
import { Form } from "./Form/Form";

export const AdminUsersScreen = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [isloadingData, setIsLoadingData] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState({
    id: null,
    visible: false,
    formFuncion: "Agregar",
  });

  const [resultData, setResultData] = useState([]);

  useEffect(() => {
    getInfo();
  }, [isFormOpen]);

  async function getInfo() {
    const url = API + "admin/getAllAdminUser";

    try {
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });

      if (response.status === 200) {
       
      }
      setIsLoadingData(false);
      setResultData(response.data);
    } catch (error) {
      

    

      toast.error("Error en llamada: " + JSON.stringify(error), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (error.response.status === 401) {
        toast.error("Tu sesión ha caducado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  }

  return (
    <>
      {isloadingData ? (
        <>
          <LoaderComponent />
        </>
      ) : (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 d-flex justify-content-between my-4">
                <div>
                  <h4>{props.title} </h4>
                  <p> {props.description}</p>
                </div>
                <button
                  className="btn btn-sm btn-success"
                  onClick={() =>
                    setIsFormOpen({
                      id: null,
                      visible: true,
                      formFunction: "Agregar",
                    })
                  }
                >
                  {" "}
                  Agregar {props.title}
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row row-cols-md-2">
              {resultData.map((result) => (
                <CardAdminUsersComponent
                  key={result.guid}
                  data={result}
                  setIsFormOpen={setIsFormOpen}
                />
              ))}
            </div>
          </div>
        </>
      )}
      {isFormOpen.visible ? (
        <>
          <Form setIsFormOpen={setIsFormOpen} isFormOpen={isFormOpen} />
        </>
      ) : null}
    </>
  );
};
