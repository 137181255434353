import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import RoomIcon from "@material-ui/icons/Room";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import PublicIcon from "@material-ui/icons/Public";
import PeopleIcon from "@material-ui/icons/People";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";

export const SocialNetWorksSupplierPublicComponent = (props) => {
 
  return (
    <>
      <div className="col-12 p-4 mb-3" style={{ backgroundColor: "white" }}>
        <h6>
          <PeopleIcon fontSize="" className="me-2" /> Redes Sociales:
        </h6>
        <hr></hr>
        <ul classsName="list-group list-group-flush">
          <li classsName="list-group-item h2 text-center">
            <a rel="noreferrer" href={props.data.facebook}>
              <FacebookIcon fontSize="inherit" style={{ color: "#4267B2" }} />
            </a>
            <a rel="noreferrer" href={props.data.instagram}>
              <InstagramIcon
                fontSize="inherit"
                className="mx-2"
                style={{ color: "#833AB4" }}
              />
            </a>
            <a rel="noreferrer" href={props.data.twitter}>
              <TwitterIcon fontSize="inherit" style={{ color: "#1DA1F2" }} />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};
