import { API } from "./../constants/ApiConnection";
import axios from "axios";
export const getCategoriasRecinto = async (values = null, user) => {
  let url = API + "recintos/getCategoriasRecinto";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getSubCategoriasRecinto = async (values = null, user) => {
  let url = API + "recintos/getSubCategoriasRecinto/" + values;

 
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createCategoriaRecinto = async (values, user) => {
  let url = API + "recintos/nuevaCategoriaRecinto";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const editCategoriaRecinto = async (values, idcategoria, user) => {
  let url = API + "recintos/updateCategoriaRecinto/" + idcategoria;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    }); 
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
export const editCategoriaProveedor = async (values, idcategoria, user) => {
  let url = API + "proveedor/updateCategoriaProveedor/" + idcategoria;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    }); 
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const createSubCategoria = async (values, user) => {
  let url = API + "recintos/nuevaSubCategoriaRecinto";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const eliminarCategoriaRecinto = async (values, user, idcategoria) => {
  let url = API + "recintos/eliminarCategoriaRecinto/" + idcategoria;
  try {
    const response = await axios.delete(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const eliminarSubCategoriaRecinto = async (values, user, idcategoria) => {
  let url = API + "recintos/eliminarSubCategoriaRecinto/" + idcategoria;
  try {
    const response = await axios.delete(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
