import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import jwt_decode from "jwt-decode";
import { toast } from "react-toastify";
import axios from "axios";
import { API } from "../../../../constants/ApiConnection";
import { AuthContext } from "../../../../auth/authContext";
import { types } from "../../../../types/types";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const CreateUserScreen = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [userData, setUserData] = React.useState({});
  let history = useHistory();

  React.useEffect(() => {
    const lastPath = JSON.parse(localStorage.getItem("user"));
    setUserData(jwt_decode(lastPath.accessToken));
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      idusuarioPadre: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.idusuarioPadre = userData.idusuarioPadre;
      APIData(values);
    },
  });

  const APIData = async (values) => {
    try {
      const urls = API + "user/newSubuser";
      const response = await axios.post(API + "user/newSubuser", values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });
      toast.success("Usuario agregado correctamente, no olvides asignar permisos ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCounterUpdate(props.counterUpdate + 1)

    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          fullWidth
          id="email"
          name="email"
          label="Email"
          className="mb-2"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          fullWidth
          id="password"
          name="password"
          label="Password"
          type="password"
          className="mb-2"
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Button color="primary" variant="contained" fullWidth type="submit">
          Agregar Usuario
        </Button>
      </form>
    </>
  );
};
