import React from "react";
import { getPublicFeaturedEvents } from "../../API/APIEventos";
import { CardFeaturedProductComponent } from "../CardFeaturedProductComponent/CardFeaturedProductComponent";
import Skeleton from "@material-ui/lab/Skeleton";
import { LoadingThreeCardsComponent } from "../LoadingThreeCardsComponent/LoadingThreeCardsComponent";

export const SearchEventsResultComponent = (props) => {
  const [finalData, setFinalData] = React.useState(props.data);

  return (
    <>
      {finalData.length === 0 ? <> No encontramos resultados </> : null}
      <div className="container">
        <div className="row">
          {finalData.map((evento, index) => (
            <CardFeaturedProductComponent
              key={index}
              admin={false}
              data={evento}
            />
          ))}
        </div>
      </div>
    </>
  );
};
