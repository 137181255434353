import React from "react";

export const CardEspaciosEnclosureComponent = (props) => {
  function downloadPDF(pdf) {
    const linkSource = `${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "fileEvents.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
              className="btn btn-sm btn-warning"
              type="button"
              onClick={() =>
                props.setCrudAction({ action: "edit", data: props.data })
              }
            >
              Editar
            </button>
            <button
              className="btn btn-sm btn-danger"
              type="button"
              onClick={() =>
                props.setCrudAction({ action: "delete", data: props.data })
              }
            >
              Eliminar
            </button>
            <button
              className="btn btn-sm btn-info"
              type="button"
              onClick={() =>
                props.setCrudAction({ action: "crudCupo", data: props.data })
              }
            >
              Cupo
            </button>
            <button
              className="btn btn-sm btn-dark"
              type="button"
              onClick={() =>
                props.setCrudAction({
                  action: "galeriaEspacio",
                  data: props.data,
                })
              }
            >
              Galería de Espacio
            </button>
          </div>
          <br></br>
          <strong>Nombre Espacio: </strong>
          {props.data.nombreEspacio}
          <br></br>
          <strong>Dimensiones: </strong>
          {props.data.dimensiones}
          <br></br>
          <strong>Altura: </strong>
          {props.data.altura}
          <br></br>
          <strong>Capacidad de carga: </strong> {props.data.capacidadCarga}
          <br></br>
          <strong>Capacidad de carga techo: </strong>
          {props.data.capacidadCargaTecho}
          <br></br>
          <br></br>
          <strong>Corte arquitectonico: </strong>
          <div
            className="btn btn-sm btn-success"
            onClick={() => {
              downloadPDF(props.data.corteArquitectonico);
            }}
          >
            {" "}
            Descargar{" "}
          </div>
          <br></br>
          <br></br>
          <strong>Planta arquitectonica: </strong>
          <div
            className="btn btn-sm btn-success"
            onClick={() => {
              downloadPDF(props.data.plantaArquitectonica);
            }}
          >
            {" "}
            Descargar{" "}
          </div>
        </div>
      </div>
    </>
  );
};
