import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "../Images/noImageAvailable";
import { ToastContainer, toast } from "react-toastify";

export const getTerminosYCondiciones = async () => {
  let url = API + "sitio/getTerminosAviso/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const postTerminosYCondiciones = async (value) => {
  let url = API + "sitio/setTerminosAviso/";
  try {
    const response = await axios.post(
      url,
      { terminos: value },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
        },
      }
    );

  
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const postAvisoPrivacidad = async (value) => {
  let url = API + "sitio/setTerminosAviso/";
  try {
    const response = await axios.post(
      url,
      { aviso: value },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
        },
      }
    );

 
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
