import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "../Images/noImageAvailable";
import { ToastContainer, toast } from "react-toastify";

export const createNuevoRecinto = async (values, user, formik) => {
  let url = API + "recintos/createRecinto";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const updateRecinto = async (values, user, idrecinto, formik) => {
  let url = API + "recintos/updateRecinto/" + idrecinto;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDetalleRecinto = async (values, user, idrecinto) => {
  let url = API + "recintos/detalleRecinto/" + idrecinto;


  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenRecinto === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenRecinto.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getPublicRecinto = async (values, user, idrecinto) => {
  let url = API + "public/updateRecinto/" + idrecinto;
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getAllRecintos = async (values, user) => {
  let url = API + "recintos/getAllRecinto/";
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenRecinto === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenRecinto.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getAllPublicRecintos = async (values) => {
  let url = API + "recintos/getAllRecinto";
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenRecinto === null) {
          imagen.imagenDecoded = noImageAvailable();
        } else {
          imagen.imagenDecoded =
            "data:image/png;base64," + encode(imagen.imagenRecinto.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getAllRecintosByCuenta = async (values, user, idpadre) => {
  let url = API + "recintos/getAllRecintoByCuenta/" + idpadre;
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenRecinto === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenRecinto.data);
        }
      }
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const eliminarRecinto = async (values, user, idrecinto) => {
  let url = API + "public/eliminarRecinto/" + idrecinto;
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

//APIS para Recintos Públicos
export const getDetalleRecintoPublico = async (idrecinto) => {
  let url = API + "recintos/detalleRecinto/" + idrecinto;

  

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });


    
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenRecinto === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenRecinto.data);
        }
      }
      return response.data[0];
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getUbicacionesCercanasRecintoPublico = async (idrecinto) => {
  let url = API + "recintos/getUbicacionPorRecinto/" + idrecinto;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPlanoRecintoPublico = async (idrecinto) => {
  let url = API + "recintos/getPlanoRecinto/" + idrecinto;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPlano === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPlano.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getGaleriaRecintoPublico = async (idrecinto) => {
  let url = API + "recintos/getImagenesPorRecinto/" + idrecinto;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenRecinto === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenRecinto.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getServiciosRecintoPublico = async (idrecinto) => {
  let url = API + "recintos/getServicioPorRecinto/" + idrecinto;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAreasServicioRecintoPublico = async (idrecinto) => {
  let url = API + "recintos/getAreaServicioPorRecinto/" + idrecinto;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDirectorioRecintoPublico = async (idrecinto) => {
  let url = API + "recinto/getDirectorioPorRecinto/" + idrecinto;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getEspacioRecintoPublico = async (idrecinto) => {
  let url = API + "recinto/getEspaciosPorRecinto/" + idrecinto;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });

    if (response.status === 200) {
      return response.data.espacio;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const recintosSeleccionarDestacados = async (values, user) => {
  let url = API + "sitio/recintosSeleccionarDestacados";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenRecinto === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenRecinto.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPublicFeaturedRecintos = async (values) => {
  let url = API + "sitio/getRecintosDestacados";
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenRecinto === null) {
          imagen.imagenDecoded = noImageAvailable();
        } else {
          imagen.imagenDecoded =
            "data:image/png;base64," + encode(imagen.imagenRecinto.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const setUnsetRecintoDestacado = async (values, user, idevento) => {
  let url = API + "sitio/setUnsetRecintoDestacado/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      toast.success("Ejecución exitosa");
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const setOrdenDestacadosRecintos = async (values, user, idproveedor) => {
  let url = API + "sitio/setOrdenRecintoDestacados/" + idproveedor;

  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
