import React from "react";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
export const CardDirectorioProveedorComponent = (props) => {
  return (
    <div className="col">
      <div className="card">
        <div className="card-body text-center">
          <strong>{props.data.nombre}</strong>
          <br></br>
          <small>{props.data.puesto}</small>
          <br></br>
          <a href={"tel:" + props.data.telefono}>
            <small>
              {" "}
              <PhoneIcon fontSize="inherit" className="me-2" />
              {props.data.telefono}
            </small>
          </a>
          <br></br>
          <a href={"mailto:" + props.data.email}>
            <small>
              <MailIcon fontSize="inherit" className="me-2" />
              {props.data.email}
            </small>
          </a>
        </div>
        {props.admin === true ? (
          <>
            <div className="card-footer bg-white">
              <div className="d-grid gap-2 d-md-flex justify-content-center">
                <button
                  className="btn btn-sm text-white btn-secondary"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "edit", data: props.data })
                  }
                >
                  Editar
                </button>
                <button
                  className="btn btn-sm text-white btn-danger"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "delete", data: props.data })
                  }
                >
                  Eliminar
                </button>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
