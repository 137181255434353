import React from "react";
import RoomIcon from "@material-ui/icons/Room";
import { encode, decode } from "base64-arraybuffer";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import imagePlano from '../../Images/navegarPorLosPlanos.png'
export const PlanoPublicComponent = (props) => {
  var planoResult = props.data;
  const [finalUrl, setFinalUrl] = React.useState(imagePlano);
  const setSrcData = (id) => {
  
    var planocaca =
      "data:image/png;base64," + encode(planoResult[id].imagenPlano.data);
    setFinalUrl(planocaca);
  };

  return (
    <div className="col">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3">
            <ul className="list-group list-group-flush">
              {props.data.map((plano, index) => (
                <li
                  key={index}
                  className="list-group-item"
                  onClick={() => setSrcData(index)}
                  style={{ cursor: "pointer" }}
                >
                  <RoomIcon fontSize="inherit" /> {plano.nombre}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-9">
            <InnerImageZoom src={finalUrl} zoomSrc={finalUrl} />
          </div>
        </div>
      </div>
    </div>
  );
};
