/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { getPatrocinadorPorEvento } from "../../API/APIPatrocinadoresEventos";
import { AuthContext } from "../../auth/authContext";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { NoImagenDataComponent } from "../NoImagenDataComponent/NoImagenDataComponent";

import { NoDataComponent } from "../NoDataComponent/NoDataComponent";
import { CardSponsorComponent } from "../CardSponsorComponent/CardSponsorComponent";
import { AddDataOrganizadoresDataEventComponent } from "./AddDataOrganizadoresDataEventComponent";
import { EditDataOrganizadoresDataEventComponent } from "./EditDataOrganizadoresDataEventComponent";
import { DeleteDataOrganizadoresDataEventComponent } from "./DeleteDataOrganizadoresDataEventComponent";
import { getOrganizadoresEvento } from "../../API/APIOrganizadoresEvento";
import { CardOrganizadorEventoComponent } from "../CardOrganizadorEventoComponent/CardOrganizadorEventoComponent";

export const EditOrganizadoresDataEventComponent = (props) => {
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    getOrganizadoresEvento(null, user, props.id).then((resultado) => {

       

      setFinalData(resultado);
    });
  }, [crudAction]);
  React.useEffect(() => {
    getOrganizadoresEvento(null, user, props.id).then((resultado) => {
       
      
       
      setFinalData(resultado);
    });
  }, []);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>
                En esta sección podrás subir los Organizadores / Empresas que formaràn parte de tu evento
              </strong>
              <br></br>
              <small>
                *Te sugerimos ingresar la mayor información del organizador.
              </small>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar Organizador
              </button>
            </div>
          </div>
        </div>
      </div>
      {finalData.length === 0 ? (
        <NoDataComponent nombre="Organizadores" />
      ) : (
        <div className="container-fluid">
          <div className="row row-cols-md-6 row-cols-sm-2">
            {finalData.map((patrocinador, index) => (
              <CardOrganizadorEventoComponent
                data={patrocinador}
                key={index}
                crudAction={crudAction}
                setCrudAction={setCrudAction}
               
              />
            ))}
          </div>
        </div>
      )}
      {crudAction.action === "edit" ? (
        <>
          <EditDataOrganizadoresDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="organizador"
          />
        </>
      ) : null}
      {crudAction.action === "delete" ? (
        <>
          <DeleteDataOrganizadoresDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="organizador"
          />
        </>
      ) : null}
      {crudAction.action === "add" ? (
        <>
          <AddDataOrganizadoresDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="organizador"
          />
        </>
      ) : null}

    
    </>
  );
};
