import React from "react";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logoImage from "../../../Images/logo.png";

import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../../auth/authContext";
import { API, HEADERS_API_JSON } from "./../../../constants/ApiConnection";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import { types } from "./../../../types/types";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/1600x900/?conference)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoImage: {
    width: "30%",
  },
}));

const validationSchema = yup.object({
  email: yup
    .string("Ingresa correo registrado")
    .email("Ingresa un correo electrónico válido")
    .required("El correo es necesario"),
});

export const RestorePassword = (props) => {
  const [isDisabled, setIsDisabled] = React.useState(false);

  const classes = useStyles();
  const [typeLogin, isTypeLogin] = React.useState(props.type);
  let history = useHistory();
  const { dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      ApiCall(values);
    },
  });

  const ApiCall = async (values) => {
    try {
      //URL
      const APIURL = API + "user/passRecovery";



       
      const response = await axios.post(APIURL, values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
        },
      });


       
      if (response.status === 200) {
        toast.success("Revisa tu correo electrónico.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        formik.resetForm();
        setIsDisabled(false);

        dispatch({
          type: types.logout,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.error("Ha habido un error, consulte consola");
      console.error(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>

      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img
              src={logoImage}
              className={classes.logoImage}
              alt="Tipo de usuario"
            />
            <br></br>

            <Typography component="h1" className="fw-bold" variant="h5">
              Restablecer Contraseña
            </Typography>
            <Typography
              className="text-center fw-bold my-3"
              component="p"
              variant="p"
            >
              Ingresa tu correo registrado, nos pondremos en contacto por correo
              electrónico
            </Typography>
            <form className={classes.form} onSubmit={formik.handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                disabled={isDisabled}
                id="email"
                label="Correo Electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />

              <Button
                type="submit"
                fullWidth
                disabled={isDisabled}
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Restablecer Contraseña
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/login" variant="body2">
                    <strong>¿Ya tienes cuenta?</strong> inicia sesión aquí
                  </Link>
                </Grid>
                <Grid item>
                  {typeLogin != "Administrador" ? (
                    <>
                      <Link href="/signup" variant="body2">
                        {"Crear Cuenta"}
                      </Link>
                    </>
                  ) : null}
                </Grid>
              </Grid>
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
