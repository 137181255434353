/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";
import { encode, decode } from "base64-arraybuffer";

const validationSchema = yup.object({
  nombreEspacio: yup
    .string("Ingresa nombre de espacio")
    .max(50, "nombre menor a 50 caracteres")
    .required("Nombre es necesario"),
  dimensiones: yup
    .string("Ingresa dimensiones")
    .max(50, "Dimensiones menor a 50 caracteres")
    .required("Dimensiones necesarias"),
  altura: yup
    .string("Ingresa altura")
    .max(50, "altura menor a 50 caracteres")
    .required("Altura es necesaria"),
  capacidadCarga: yup
    .string("Ingresa capacidad de carga")
    .max(50, "capacidad de carga menor a 50 caracteres")
    .required("Capacidad de carga es necesaria"),
  capacidadCargaTecho: yup
    .string("Ingresa capacidad de carga techo")
    .max(50, "capacidad de carga techo menor a 50 caracteres")
    .required("capacidad de carga techo es necesaria"),
});

export const AddDataEspaciosDataEnclosureComponent = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      idrecinto: props.idevento,
      nombreEspacio: "",
      dimensiones: "",
      altura: "",
      capacidadCarga: "",
      capacidadCargaTecho: "",
      corteArquitectonico: null,
      plantaArquitectonica: null,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));

      getBase64(values.corteArquitectonico).then((resultado) => {
        values.corteArquitectonico = resultado;
        getBase64(values.plantaArquitectonica).then((resultadoDos) => {
          values.plantaArquitectonica = resultado;
          addData(values);
        });
      });
    },
  });

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const addData = async (values) => {
     
    try {
      const response = await axios.post(
        API + "recintos/createEspacio",
        values,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success("Registro Agregado Correctamente!  ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCrudAction({ action: null, data: null });
    } catch (error) {
       
      alert(error.response.statusText);
      console.warn(error.response.statusText);
      toast.error(error.response.statusText);
      props.setCrudAction({ action: null, data: null });
      
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Agregar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="nombreEspacio"
                name="nombreEspacio"
                label="Nombre del espacio"
                className="mb-2"
                value={formik.values.nombreEspacio}
                onChange={formik.handleChange}
                error={
                  formik.touched.nombreEspacio &&
                  Boolean(formik.errors.nombreEspacio)
                }
                helperText={
                  formik.touched.nombreEspacio && formik.errors.nombreEspacio
                }
              />
              <TextField
                fullWidth
                id="dimensiones"
                name="dimensiones"
                label="Dimensiones"
                className="mb-2"
                value={formik.values.dimensiones}
                onChange={formik.handleChange}
                error={
                  formik.touched.dimensiones &&
                  Boolean(formik.errors.dimensiones)
                }
                helperText={
                  formik.touched.dimensiones && formik.errors.dimensiones
                }
              />
              <TextField
                fullWidth
                id="altura"
                name="altura"
                label="Altura"
                className="mb-2"
                value={formik.values.altura}
                onChange={formik.handleChange}
                error={formik.touched.altura && Boolean(formik.errors.altura)}
                helperText={formik.touched.altura && formik.errors.altura}
              />

              <TextField
                fullWidth
                id="capacidadCarga"
                name="capacidadCarga"
                label="Capacidad de carga"
                className="mb-2"
                value={formik.values.capacidadCarga}
                onChange={formik.handleChange}
                error={
                  formik.touched.capacidadCarga &&
                  Boolean(formik.errors.capacidadCarga)
                }
                helperText={
                  formik.touched.capacidadCarga && formik.errors.capacidadCarga
                }
              />
              <TextField
                fullWidth
                id="capacidadCargaTecho"
                name="capacidadCargaTecho"
                label="Capacidad de carga techo"
                className="mb-2"
                value={formik.values.capacidadCargaTecho}
                onChange={formik.handleChange}
                error={
                  formik.touched.capacidadCargaTecho &&
                  Boolean(formik.errors.capacidadCargaTecho)
                }
                helperText={
                  formik.touched.capacidadCargaTecho &&
                  formik.errors.capacidadCargaTecho
                }
              />

              <div className="my-3">
                <label for="corteArquitectonico" className="form-label">
                  Corte Arquitectónico: &nbsp;
                  <span className="badge rounded-pill bg-primary">pdf</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="corteArquitectonico"
                  accept="application/pdf"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "corteArquitectonico",
                      e.target.files[0]
                    );
                  }}
                />
              </div>
              <div className="my-3">
                <label for="plantaArquitectonica" className="form-label">
                  Planta Arquitectónica: &nbsp;
                  <span className="badge rounded-pill bg-primary">pdf</span>
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="plantaArquitectonica"
                  accept="application/pdf"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "plantaArquitectonica",
                      e.target.files[0]
                    );
                  }}
                />
              </div>

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Agregar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
