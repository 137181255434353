import React from "react";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../auth/authContext";
import { UserDecode } from "../../../helpers/UserDecode";
import { API } from "../../../constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import { types } from "../../../types/types";
import { AddDataSpeakersComponent } from "../../../Components/AddDataSpeakersComponent/AddDataSpeakersComponent";

export const SpeakersListScreen = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  let history = useHistory();
  const [finalData, setFinalData] = React.useState([]);
  const idusuarioPadre = UserDecode().idusuarioPadre;


  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    addPrincipalData().then((result) => setFinalData(result));
  }, [crudAction]);

  React.useEffect(() => {
    addPrincipalData().then((result) => setFinalData(result));
  }, []);

    //Agregamos datos principales
    const addPrincipalData = async () => {
        try {
          const response = await axios.get(
            API + "ponente/getPonentes/",
            
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Headers": "*",
                "x-access-token": user.accessToken,
              },
            }
          );
    
          return response.data;
        } catch (error) {
          console.warn(error.response);
          toast(error.response.data.message);
          if (error.response.status === 401) {
            dispatch({
              type: types.logout,
            });
            history.replace("/");
          }
        }
      };
  return (
    <>
      <div className="container-fluid mt-4">
        <div className="row">
          <div className="col-12">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddCircleIcon fontSize="inherit" /> Agregar Ponente
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-4">
        <div className="row row-cols-md-1">
          {finalData.map((evento, index) => (
           <> </>
          ))}
        </div>
      </div>




      {crudAction.action === "add" ? (
        <>
          {" "}
          <AddDataSpeakersComponent
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Ponente"
          />{" "}
        </>
      ) : null}


    </>
  );
};
