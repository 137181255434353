import React from "react";

export const CardAdminTypesComponent = (props) => {
  return (
    <>
      <div className="col mb-2">
        <div className="card-header bg-white d-flex align-items-center d-flex justify-content-between">
          <h6 className="mt-2 text-muted">{props.data.nombre}</h6>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
              className="btn btn-sm text-white btn-warning"
              type="button"
              onClick={() =>
                props.setCrudType({ action: "edit", data: props.data })
              }
            >
              Editar
            </button>
            <button
              className="btn btn-sm text-white btn-danger"
              type="button"
              onClick={() =>
                props.setCrudType({ action: "delete", data: props.data })
              }
            >
              Eliminar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
