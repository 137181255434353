/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import { AuthContext } from "../../auth/authContext";
import { UserDecode } from "../../helpers/UserDecode";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { types } from "../../types/types";
import { DateTime } from "luxon";
import { API } from "../../constants/ApiConnection";
import { NoDataComponent } from "../NoDataComponent/NoDataComponent";
import AddBoxIcon from "@material-ui/icons/AddBox";

import { CardFrecuentQuestionEventComponent } from "../CardFrecuentQuestionEventComponent/CardFrecuentQuestionEventComponent";

import { CardPoliticsEventComponent } from "../CardPoliticsEventComponent/CardPoliticsEventComponent";
import { AddDataPlanoDataEventComponent } from "./AddDataPlanoDataEventComponent";
import { EditDataPlanoDataEventComponent } from "./EditDataPlanoDataEventComponent";
import { DeleteDataPlanoDataEventComponent } from "./DeleteDataPlanoDataEventComponent";
import { CardPlanoEventComponent } from "../CardPlanoEventComponent/CardPlanoEventComponent";



export const EditPlanoDataEventComponent = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const idusuarioPadre = UserDecode().idusuarioPadre;
  const [finalData, setFinalData] = React.useState([]);
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getData().then((result) => {
      setFinalData(result);
    });
  }, []);

  React.useEffect(() => {
    getData().then((result) => {
      setFinalData(result);
    });
  }, [crudAction]);

  const getData = async () => {
    try {
      const response = await axios.get(
        API + "plano/planoPorEvento/" + props.id,
        
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>
                En esta sección podrás subir los planos de tu evento
              </strong>
              <br></br>
              <small>
                *Podrás agregar cuantas imagenes de plano necesites, ejemplo:
                piso 1...
              </small>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar Plano
              </button>
            </div>
          </div>
        </div>
      </div>
      {finalData && finalData.length ? (
        <>
          <div className="container-fluid">
            <div className="row row-cols-md-1 row-cols-sm-2">
              {finalData.map((data, index) => (
                <CardPlanoEventComponent
                  data={data}
                  key={index}
                  crudAction={crudAction}
                  setCrudAction={setCrudAction}
                  admin={true}
                  index={index}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <NoDataComponent nombre="planos" />{" "}
        </>
      )}

      {crudAction.action === "add" ? (
        <>
          {" "}
          <AddDataPlanoDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="plano"
          />{" "}
        </>
      ) : null}
      {crudAction.action === "edit" ? (
        <>
          {" "}
          <EditDataPlanoDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="plano"
          />{" "}
        </>
      ) : null}
      {crudAction.action === "delete" ? (
        <>
          {" "}
          <DeleteDataPlanoDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="plano"
          />{" "}
        </>
      ) : null}
    </>
  );
};
