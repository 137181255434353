import React from "react";
import ReactDOM from "react-dom";
import { AuthContext } from "./../../../../auth/authContext";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import styles from "./Form.module.css";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import { API } from "./../../../../constants/ApiConnection";
import { types } from "./../../../../types/types";
import axios from "axios";
import { toast } from "react-toastify";
import { CountryStateCity } from "./../../../../helpers/CountryStateCity";
import Input from "@material-ui/core/Input";
import { placeholder } from "@babel/types";

const validationSchema = yup.object({
  nombreProfesionista: yup
    .string("Ingresa Nombre de profesionista")
    .required("Nombre de profesionista requerido"),
  apellidoProfesionista: yup
    .string("Ingresa Apellido")
    .required("Apellido Requerido"),
  numeroExterior: yup.string().required("Número Exterior Necesario"),
  calle: yup.string().required("Calle requerida"),
  colonia: yup.string().required("Colonia"),
  codigoPostal: yup
    .number("Ingresa Número")
    .required("El Código Postal es necesario")
    .positive("No hay números negativos en CP, verifica!"),
  whatsapp: yup
    .string()
    .matches(
      /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Ingresa número de teléfono válido"
    )
    .required("Whatsapp Necesario"),
  facebookUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingresa una URL de facebook correcta"
    )
    .required("Dirección Necesaria"),
  instagramUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingresa una URL de facebook correcta"
    )
    .required("Dirección Necesaria"),
  webUrl: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      "Ingresa una URL de Instagram correcta"
    )
    .required("Dirección Necesaria"),
  email: yup
    .string("Ingresa tu correo electrónico")
    .email("Ingrresa un correo válido")
    .required("Correo necesario"),
  password: yup
    .string("Ingrgesa contraseña")
    .min(8, "la contraseña deberá de tener un mínimo de 8 caracteres")
    .required("Contraseña requerida"),
});

export const Form = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);
  const [imagenProfesionista, setImagenProfesionista] = React.useState({
    src: null,
    alt: "Sube una imagen",
  });

  React.useEffect(() => {
    CountryStateCity("Estados");
  }, []);

  const CountryStateCity = async (type, idEstado) => {
    if (type === "Estados") {
      const api = API + "generales/getAllEstados";

      try {
        const response = await axios.get(api, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });
        if (response.status === 200) {
          setEstados(response.data);
        }
      } catch (error) {
       
        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
    if (type === "Municipios") {
      const api = API + "generales/getMunicipiosByEstado/" + idEstado;

      try {
        const response = await axios.get(api, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });
        if (response.status === 200) {
          setMunicipios(response.data);
        }
      } catch (error) {
      
        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      nombreProfesionista: "",
      apellidoProfesionista: "",
      logo: "null",
      idpais: "1",
      idestado: "null",
      idciudad: "null",
      calle: "",
      colonia: "",
      numeroExterior: "",
      numeroInterior: "",
      edificio: "",
      codigoPostal: "",
      whatsapp: "",
      facebookUrl: "",
      instagramUrl: "",
      webUrl: "",
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //Validando que tenga ciudad y estado
      if (values.idestado === "null" || values.idciudad === "null") {
        toast.error("Recuerda elegir estado y municipio", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }
      if (values.logo === "null") {
        toast.error("Debes de subir una imagen", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        return;
      }

      saveData(values);
    },
  });

  const saveData = async (values) => {
    const formData = new FormData();

    formData.append("nombreProfesionista", values.nombreProfesionista);
    formData.append("apellidoProfesionista", values.apellidoProfesionista);
    formData.append("logo", values.logo);
    formData.append("idpais", values.idpais);
    formData.append("idestado", values.idestado);
    formData.append("idciudad", values.idciudad);
    formData.append("calle", values.calle);
    formData.append("colonia", values.colonia);
    formData.append("numeroExterior", values.numeroExterior);
    formData.append("numeroInterior", values.numeroInterior);
    formData.append("edificio", values.edificio);
    formData.append("codigoPostal", values.codigoPostal);
    formData.append("whatsapp", values.whatsapp);
    formData.append("facebookUrl", values.facebookUrl);
    formData.append("instagramUrl", values.instagramUrl);
    formData.append("webUrl", values.webUrl);
    formData.append("email", values.email);
    formData.append("password", values.password);

    try {
      const response = await axios.post(
        API + "profesionista/createProfesionista/",
        formData,
        {
          headers: {
            "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );
      if (response.status === 200) {
        props.setIsAddOpen(false);
        toast("Guardado Correctamente");
      }
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.data === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/login");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setIsFormOpen({
        id: null,
        visible: false,
        formFuncion: null,
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div
        className={`${styles.FormCrudScreenComponent} col-md-6 col-sm-12 animate_animated animate_bounce`}
      >
        <div className="container-fluid p-5">
          <div className="row mt-3">
            <div className="col mt-1">
              <h5>
                <strong>{props.isFormOpen.formFunction} Profesionista </strong>
              </h5>
            </div>
            <div className="col-1 text-end">
              <button
                type="button"
                onClick={() =>
                  props.setIsFormOpen({
                    id: null,
                    visible: false,
                    formFuncion: null,
                  })
                }
                className="btn btn-sm btn-outline-danger"
              >
                X
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <form onSubmit={formik.handleSubmit}>
                <div className="row gy-2">
                  <div className="col-sm-12 d-flex justify-content-between my-5">
                    <div>
                      <input
                        accept="image/png, image/jpeg"
                        className={null}
                        id="logo"
                        type="file"
                        style={{ display: "none" }}
                        onChange={(event) => {
                          formik.setFieldValue("logo", event.target.files[0]);
                          setImagenProfesionista({
                            src: URL.createObjectURL(event.target.files[0]),
                            alt: event.target.files[0].name,
                          });
                        }}
                      />
                      <label htmlFor="logo">
                        <Button variant="contained" component="span">
                          Subir Imagen
                        </Button>
                      </label>
                    </div>
                    <div>
                      <img
                        src={imagenProfesionista.src}
                        alt="Imagen Profesionista"
                        className={styles.imagenPreview}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="nombreProfesionista"
                      name="nombreProfesionista"
                      label="Nombre:"
                      value={formik.values.nombreProfesionista}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nombreProfesionista &&
                        Boolean(formik.errors.nombreProfesionista)
                      }
                      helperText={
                        formik.touched.nombreProfesionista &&
                        formik.errors.nombreProfesionista
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="apellidoProfesionista"
                      name="apellidoProfesionista"
                      label="Apellido:"
                      value={formik.values.apellidoProfesionista}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.apellidoProfesionista &&
                        Boolean(formik.errors.apellidoProfesionista)
                      }
                      helperText={
                        formik.touched.apellidoProfesionista &&
                        formik.errors.apellidoProfesionista
                      }
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="calle"
                      name="calle"
                      label="Calle:"
                      value={formik.values.calle}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.calle && Boolean(formik.errors.calle)
                      }
                      helperText={formik.touched.calle && formik.errors.calle}
                    />
                  </div>

                  <div className="col-sm-2">
                    <TextField
                      fullWidth
                      id="numeroExterior"
                      name="numeroExterior"
                      label="Ext."
                      value={formik.values.numeroExterior}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.numeroExterior &&
                        Boolean(formik.errors.numeroExterior)
                      }
                      helperText={
                        formik.touched.numeroExterior &&
                        formik.errors.numeroExterior
                      }
                    />
                  </div>

                  <div className="col-sm-2">
                    <TextField
                      fullWidth
                      id="numeroInterior"
                      name="numeroInterior"
                      label="Int."
                      value={formik.values.numeroInterior}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-sm-2">
                    <TextField
                      fullWidth
                      id="edificio"
                      name="edificio"
                      label="Edificio"
                      value={formik.values.edificio}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="colonia"
                      name="colonia"
                      label="Colonia:"
                      value={formik.values.colonia}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.colonia && Boolean(formik.errors.colonia)
                      }
                      helperText={
                        formik.touched.colonia && formik.errors.colonia
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="codigoPostal"
                      name="codigoPostal"
                      label="Código Postal"
                      value={formik.values.codigoPostal}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.codigoPostal &&
                        Boolean(formik.errors.codigoPostal)
                      }
                      helperText={
                        formik.touched.codigoPostal &&
                        formik.errors.codigoPostal
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      id="whatsapp"
                      name="whatsapp"
                      label="Whatsapp"
                      value={formik.values.whatsapp}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.whatsapp &&
                        Boolean(formik.errors.whatsapp)
                      }
                      helperText={
                        formik.touched.whatsapp && formik.errors.whatsapp
                      }
                    />
                  </div>

                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      size="small"
                      id="facebookUrl"
                      label="Url Facebook"
                      name="facebookUrl"
                      autoComplete="facebookUrl"
                      value={formik.values.facebookUrl}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.facebookUrl &&
                        Boolean(formik.errors.facebookUrl)
                      }
                      helperText={
                        formik.touched.facebookUrl && formik.errors.facebookUrl
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      size="small"
                      id="instagramUrl"
                      label="Url Instagram"
                      name="instagramUrl"
                      autoComplete="instagramUrl"
                      value={formik.values.instagramUrl}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.instagramUrl &&
                        Boolean(formik.errors.instagramUrl)
                      }
                      helperText={
                        formik.touched.instagramUrl &&
                        formik.errors.instagramUrl
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      size="small"
                      id="webUrl"
                      label="Url Página Web"
                      name="webUrl"
                      autoComplete="webUrl"
                      value={formik.values.webUrl}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.webUrl && Boolean(formik.errors.webUrl)
                      }
                      helperText={formik.touched.webUrl && formik.errors.webUrl}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      size="small"
                      id="email"
                      label="Correo Electrónico"
                      name="email"
                      type="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </div>
                  <div className="col-sm-6">
                    <TextField
                      fullWidth
                      type="password"
                      size="small"
                      id="password"
                      label="Contraseña"
                      name="password"
                      autoComplete="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </div>

                  <div className="col-sm-6">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="idestado">Estado</InputLabel>
                      <Select
                        autoWidth
                        native
                        value={formik.values.idestado}
                        onChange={(e) => {
                          formik.handleChange(e);
                          toast.dark("No olvides seleccionar el municipio", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                          });
                          CountryStateCity("Municipios", e.target.value);
                        }}
                        inputProps={{
                          name: "idestado",
                          id: "idestado",
                        }}
                      >
                        <option aria-label="Seleccionar Estado" value={"0"}>
                          {" "}
                          Seleccionar Ciudad{" "}
                        </option>
                        {estados.map((estado) => (
                          <option key={estado.id} value={estado.id}>
                            {estado.nombre}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  <div className="col-sm-6">
                    <FormControl fullWidth>
                      <InputLabel htmlFor="idciudad">Estado</InputLabel>
                      <Select
                        autoWidth
                        native
                        value={formik.values.idciudad}
                        onChange={(e) => {
                          formik.handleChange(e);
                        }}
                        inputProps={{
                          name: "idciudad",
                          id: "idciudad",
                        }}
                      >
                        <option aria-label="Seleccionar Ciudad" value={"0"}>
                          Seleccionar Municipio
                        </option>
                        {municipios.map((municipio) => (
                          <option key={municipio.id} value={municipio.id}>
                            {municipio.nombre}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </div>

                  <Button
                    className="my-4"
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                  >
                    Agregar Profesionista
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
