import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  createCategoriaRecinto,
  eliminarCategoriaRecinto,
} from "../../../API/APICategoriasRecinto";
import { AuthContext } from "../../../auth/authContext";
import { types } from "../../../types/types";
import { useHistory } from "react-router-dom";
import { eliminarCategoriaProveedor } from "../../../API/APIProveedores";

const validationSchema = yup.object({
  nombreCategoria: yup
    .string("Ingresa nombre")
    .required("El nombre es necesario"),
});

export const DeleteAdminSupplierCategories = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  let history = useHistory();
  const formik = useFormik({
    initialValues: {
      nombreCategoria: props.crudAction.data.nombreCategoria,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      eliminarCategoriaProveedor(values, user, props.crudAction.data.id).then(
        (resultado) => {
          if (resultado.status === 200) {
            toast.success("Categoría Eliminada correctamente");

            props.setCrudAction({ action: null, data: null });
          } else if (resultado.status === 401) {
            toast.error(
              "No tienes permiso para realizar esta información, cerrando sesión"
            );
            dispatch({
              type: types.logout,
            });
            history.replace("/");
          } else {
            toast.error("Hemos tenido un problema, consulta consola");
          }
        }
      );
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div
        className={`FormCrudScreenComponent col-md-3 col-sm-12 animate_animated animate_bounce`}
      >
        <div className="container-fluid p-5">
          <div className="row mt-3">
            <div className="col mt-1">
              <h5 className={null}>
                <strong>Borrar Categoria</strong>
              </h5>
            </div>
            <div className="col-1 text-end">
              <button
                type="button"
                onClick={() =>
                  props.setCrudType({
                    action: null,
                    data: null,
                  })
                }
                className="btn btn-sm btn-outline-danger"
              >
                X
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form onSubmit={formik.handleSubmit} className="mt-5">
                <TextField
                  className="mb-3"
                  fullWidth
                  id="nombreCategoria"
                  name="nombreCategoria"
                  disabled={true}
                  label="Nombre Categoría"
                  value={formik.values.nombreCategoria}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nombreCategoria &&
                    Boolean(formik.errors.nombreCategoria)
                  }
                  helperText={
                    formik.touched.nombreCategoria &&
                    formik.errors.nombreCategoria
                  }
                />

                <Button
                  className="mt-2"
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Eliminar
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
