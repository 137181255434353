import React from "react";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import logoImage from "../../../Images/logo.png";
import {
  CountryStateCity,
  getCities,
} from "./../../../helpers/CountryStateCity";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../../auth/authContext";
import { API, HEADERS_API_JSON } from "./../../../constants/ApiConnection";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { types } from "../../../types/types";

const validationSchema = yup.object({
  email: yup
    .string("Ingresa tu correo electrónico")
    .email("Ingrresa un correo válido")
    .required("Correo necesario"),
  password: yup
    .string("Ingrgesa contraseña")
    .min(8, "la contraseña deberá de tener un mínimo de 8 caracteres")
    .required("Contraseña requerida"),
  nombre: yup
    .string("Ingresa tu nombre completo")
    .required("El nombre es necesario"),
  telCelular: yup
    .string()
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Teléfono inválido")
    .required("Teléfono Necesario"),
  fechaNacimiento: yup.string().required("Fecha Requerida"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export const SignUp = (props) => {
  const classes = useStyles();
  const [disabledTextInput, setDisabledTextInput] = React.useState(false);
  const [createdAccount, setCreatedAccount] = React.useState(true);
  const [userData, setUserData] = React.useState({});
  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);
  const [loginType, setLoginType] = React.useState();

  React.useEffect(() => {
    CountryStateCity().then((resultado) => {
      CountryStateCity(1).then((resultados) => {
        setEstados(resultados);
      });

      getCities(1).then((resultado) => {
        setMunicipios(resultado);
      });
    });
  }, []);

  let history = useHistory();
  const { dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      sexo: "M",
      telCelular: "",
      email: "",
      password: "",
      idpais: "1",
      idestado: "1",
      idciudad: "1",
      fechaNacimiento: "",
      rolEvento: false,
      rolRecinto: false,
      rolProveedor: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setDisabledTextInput(true);
      // alert(JSON.stringify(values, null, 2));

      ApiCall(values);
    },
  });

  const ApiCall = async (values) => {
    try {
      //URL
      const APIURL = API + "gral/nuevoUsuarioGA";

      const response = await axios.post(APIURL, values, HEADERS_API_JSON(null));
      if (response.status === 200) {
        toast.success("Inicia Sesión");
        setUserData(values);
        setCreatedAccount(false);

        dispatch({
          type: types.logout,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message);
      setDisabledTextInput(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>

      {createdAccount ? (
        <>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <div className={classes.paper}>
              <img src={logoImage} style={{ width: "100px" }} />

              <Typography className="mt-3 fw-bold" component="h1" variant="h5">
                Crear Cuenta de empresa
              </Typography>
              <p className="text-center">
                Necesitamos la siguiente información para crear tu cuenta, en
                unos minutos tendrás acceso a miles de clientes potenciales.{" "}
                <br></br> Podrás agregar usuarios a tu empresa.
              </p>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={10}>
                    <TextField
                      disabled={disabledTextInput}
                      autoComplete="nombre"
                      size="small"
                      name="nombre"
                      fullWidth
                      id="nombre"
                      label="Nombre Completo"
                      autoFocus
                      value={formik.values.nombre}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.nombre && Boolean(formik.errors.nombre)
                      }
                      helperText={formik.touched.nombre && formik.errors.nombre}
                    />
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="sexo">Sexo</InputLabel>
                      <Select
                        native
                        value={formik.values.sexo}
                        onChange={formik.handleChange}
                        inputProps={{
                          name: "sexo",
                          id: "sexo",
                        }}
                      >
                        <option value={"M"}>Masculino</option>
                        <option value={"F"}>Femenino</option>
                        <option value={"F"}>N/A</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel
                        InputLabelProps={{ shrink: true }}
                        htmlFor="idpais"
                      >
                        País
                      </InputLabel>
                      <Select
                        native
                        value={formik.values.idpais}
                        onChange={formik.handleChange}
                        inputProps={{
                          name: "idpais",
                          id: "idpais",
                        }}
                      >
                        <option value={"1"}>MÉXICO</option>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel
                        InputLabelProps={{ shrink: true }}
                        htmlFor="idestado"
                        shrink
                      >
                        Estado
                      </InputLabel>
                      <Select
                        native
                        value={formik.values.idestado}
                        onChange={(e) => {
                          formik.handleChange(e);
                          var idEstado = e.target.value;
                          getCities(idEstado).then((resultado) => {
                            setMunicipios(resultado);
                          });
                        }}
                        inputProps={{
                          name: "idestado",
                          id: "idestado",
                        }}
                      >
                        {estados.map((estado, index) => (
                          <option key={index} value={estado.idestado}>
                            {estado.nombreEstado}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel
                        InputLabelProps={{ shrink: true }}
                        htmlFor="idciudad"
                        shrink
                      >
                        Ciudad
                      </InputLabel>
                      <Select
                        native
                        value={formik.values.idciudad}
                        onChange={formik.handleChange}
                        inputProps={{
                          name: "idciudad",
                          id: "idciudad",
                        }}
                      >
                        {municipios.map((municipio, index) => (
                          <option key={index} value={municipio.idciudad}>
                            {municipio.nombreCiudad}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={disabledTextInput}
                      fullWidth
                      size="small"
                      id="telCelular"
                      label="Teléfono Celular 10 dígitos"
                      name="telCelular"
                      autoComplete="telCelular"
                      value={formik.values.telCelular}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.telCelular &&
                        Boolean(formik.errors.telCelular)
                      }
                      helperText={
                        formik.touched.telCelular && formik.errors.telCelular
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      disabled={disabledTextInput}
                      fullWidth
                      size="small"
                      id="fechaNacimiento"
                      label="Fecha de Nacimiento"
                      name="fechaNacimiento"
                      type="date"
                      inputProps={{max: '3000-01-01'}}
                      InputLabelProps={{ shrink: true }}
                      autoComplete="fechaNacimiento"
                      value={formik.values.fechaNacimiento}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.fechaNacimiento &&
                        Boolean(formik.errors.fechaNacimiento)
                      }
                      helperText={
                        formik.touched.fechaNacimiento &&
                        formik.errors.fechaNacimiento
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      id="email"
                      size="small"
                      label="Correo electrónico"
                      name="email"
                      autoComplete="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      disabled={disabledTextInput}
                      id="password"
                      type="password"
                      size="small"
                      label="Contraseña"
                      name="password"
                      autoComplete="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <h5 className="my-3 text-info">
                      ¿Qué módulos deseas habilitar?
                    </h5>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.rolEvento}
                          onChange={formik.handleChange}
                          name="rolEvento"
                          color="primary"
                        />
                      }
                      label="Eventos"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.rolRecinto}
                          onChange={formik.handleChange}
                          name="rolRecinto"
                          color="primary"
                        />
                      }
                      label="Recinto"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.rolProveedor}
                          onChange={formik.handleChange}
                          name="rolProveedor"
                          color="primary"
                        />
                      }
                      label="Proveedor"
                    />
                  </Grid>
                </Grid>
                <Button
                  disabled={disabledTextInput}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                >
                  Crear Cuenta
                </Button>
                <Grid container justifyContent="flex-end">
                  <Grid item>
                    <Link href="/login" variant="body2">
                      Ya tienes cuenta de Administrador?{" "}
                      <strong>inicia sesión aquí.</strong>
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Container>
        </>
      ) : (
        <>
          <Container
            component="main"
            maxWidth="md"
            className="my-5"
            style={{ paddingTop: "15vh", paddingBottom: "15vh" }}
          >
            <CssBaseline />
            <div className={classes.paper}>
              <img src={logoImage} style={{ width: "200px" }} />
              <Typography component="h1" variant="h5">
                Gracias! <strong> {userData.nombre}</strong>
              </Typography>
              <p className="text-center">
                Te damos la bienvenida a una de las plataformas de eventos más
                importantes de México. <br></br>
                Para entrar a tu panel te invitamos a pulsar el siguiente boton:
              </p>

              <a href="/login" className="btn btn-lg btn-success">
                Acceder a panel
              </a>
            </div>
          </Container>
        </>
      )}
    </>
  );
};
