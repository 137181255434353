/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import { TextEditorComponent } from "../../../Components/TextEditorComponent/TextEditorComponent";
import { AuthContext } from "../../../auth/authContext";
import { UploadImagesComponent } from "../../../Components/UploadImagesComponent/UploadImagesComponent";
import { UserDecode } from "../../../helpers/UserDecode";
import Button from "@material-ui/core/Button";
import axios from "axios";
import { API } from "../../../constants/ApiConnection";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment";
import SaveIcon from "@material-ui/icons/Save";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { types } from "../../../types/types";
import { getCategoriasEvento, getSubCategoriaEventos } from "../../../API/APiCategoriasEventos";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";


export const CreateEventScreen = () => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  //Esta es la imagen de portada (es un array, solo tomar la posicion 0)
  const [imagenPortada, setImageCover] = React.useState([]);
  const idusuarioPadre = UserDecode().idusuarioPadre;
  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);

    //Categorías de evento
    const [categoriaEvento, setCategoriaEvento] = React.useState([]);
    //Categorías de evento
    const [subCategoriaEvento, setSubCategoriaEvento] = React.useState([]);


  React.useEffect(() => {
    getStates();
    getCategoriasEvento(null, user).then((resultado) => {
      setCategoriaEvento(resultado);
    });

  }, []);

  const getStates = async (pais = 1) => {
    await axios
      .get(API + "catalogue/getEstado")
      .then((response) => {
        setEstados(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  const getMunicipios = async (e, idestado = 0) => {
    
    await axios
      .get(API + "catalogue/getCiudad/" + e.target.value)
      .then((response) => {
        setMunicipios(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  React.useEffect(() => {
    if (imagenPortada[0]) {
      formik.setFieldValue("imagenPortada", imagenPortada[0].data_url);
    }
  }, [imagenPortada]);

  //Agregamos datos principales
  const addPrincipalData = async (values, imagenPortada, imagenesGaleria) => {
    setDisabled(true);
    try {
      const response = await axios.post(API + "eventos/createEvento", values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });

  

      toast.success("Registro Agregado Correctamente!  ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
      setDisabled(false);
      history.push("/eventedit/" + response.data.id);
      return response.data;
    } catch (error) {
      setDisabled(false);
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      idusuarioPadre: idusuarioPadre,
      tituloEvento: "",
      fechaInicio: "",
      fechaFin: "",
      descripcionCortaEvento: "",
      descripcionLargaEvento: "",
      idcategoria: "",
      idsubcategoria: "",
      idestado: "",
      idciudad: "",
      idcategoria: "",
      idsubcategoria: "",
      facebook: "",
      twitter: "",
      instagram: "",
      limitantes: "",
      ubicacionRecinto: "",
      diaHoraAtencion: "[]",
      recinto: "",
      imagenPortada: imagenPortada[0],
    },
    //validationSchema: validationSchema,
    onSubmit: (values) => {
      setDisabled(true);


      formik.setFieldValue("imagenPortada", imagenPortada[0]);
    //  alert(JSON.stringify(values, null, 2));
      addPrincipalData(values);
      setDisabled(false);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="container-fluid g-0">
          <div className="row mb-3">
            <div className="col-md-8">
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Información principal del evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="tituloEvento"
                          className="mb-2"
                          name="tituloEvento"
                          label="Título de evento *Requerido"
                          value={formik.values.tituloEvento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.tituloEvento &&
                            Boolean(formik.errors.tituloEvento)
                          }
                          helperText={
                            formik.touched.tituloEvento &&
                            formik.errors.tituloEvento
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          type="datetime-local"
                          inputProps={{ max: "3000-01-01T00:00" }}
                          id="fechaInicio"
                          className="mb-2"
                          name="fechaInicio"
                          label="Fecha de Inicio *Requerido"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values.fechaInicio}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.fechaInicio &&
                            Boolean(formik.errors.fechaInicio)
                          }
                          helperText={
                            formik.touched.fechaInicio &&
                            formik.errors.fechaInicio
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          type="datetime-local"
                          inputProps={{ max: "3000-01-01T00:00" }}
                          id="fechaFin"
                          className="mb-2"
                          name="fechaFin"
                          label="Fecha de fin *Requerido"
                          InputLabelProps={{ shrink: true }}
                          value={formik.values.fechaFin}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.fechaFin &&
                            Boolean(formik.errors.fechaFin)
                          }
                          helperText={
                            formik.touched.fechaFin && formik.errors.fechaFin
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="estado">Estado *Requerido</InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idpais}
                            onChange={(e) => {
                              formik.handleChange(e);
                              getMunicipios(e);
                            }}
                            inputProps={{ name: "idestado", id: "idestado" }}
                          >
                            <option aria-label="Seleccionar Estado">
                              Seleccionar Estado
                            </option>

                            {estados.map((estado, index) => (
                              <option key={index} value={estado.idestado}>
                                {estado.nombreEstado}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="idciudad">Ciudad *Requerido</InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idciudad}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            inputProps={{ name: "idciudad", id: "idciudad" }}
                          >
                            <option aria-label="Seleccionar Ciudad">
                              Seleccionar Ciudad
                            </option>

                            {municipios.map((ciudad, index) => (
                              <option key={index} value={ciudad.idciudad}>
                                {ciudad.nombreCiudad}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="recinto"
                          className="mb-2"
                          name="recinto"
                          label="Recinto *Requerido"
                          value={formik.values.recinto}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.recinto &&
                            Boolean(formik.errors.recinto)
                          }
                          helperText={
                            formik.touched.recinto &&
                            formik.errors.recinto
                          }
                        />
                      </div>
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="ubicacionRecinto"
                          className="mb-2"
                          name="ubicacionRecinto"
                          label="Ubicación en Recinto *Requerido"
                          value={formik.values.ubicacionRecinto}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.ubicacionRecinto &&
                            Boolean(formik.errors.ubicacionRecinto)
                          }
                          helperText={
                            formik.touched.ubicacionRecinto &&
                            formik.errors.ubicacionRecinto
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Categoría de evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="idciudad">
                            Categoría Evento *Requerido
                          </InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idcategoria}
                            onChange={(e) => {
                              formik.handleChange(e);
                              getSubCategoriaEventos(
                                null,
                                user,
                                e.target.value
                              ).then((resultado) => {
                                setSubCategoriaEvento(resultado.data);
                              });
                            }}
                            inputProps={{
                              name: "idcategoria",
                              id: "idcategoria",
                            }}
                          >
                            <option aria-label="Categoría Evento">
                              Categoría Evento
                            </option>

                            {categoriaEvento.map((categoria, index) => (
                              <option key={index} value={categoria.id}>
                                {categoria.nombreCategoriaEvento}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-6">
                        <FormControl fullWidth>
                          <InputLabel htmlFor="idciudad">
                            Subcategoría *Requerido
                          </InputLabel>
                          <Select
                            disabled={disabled}
                            autoWidth
                            native
                            className="mb-2"
                            value={formik.values.idsubcategoria}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            inputProps={{
                              name: "idsubcategoria",
                              id: "idsubcategoria",
                            }}
                          >
                            <option aria-label="Subcategoría Evento">
                              Subcategoría Evento
                            </option>

                            {subCategoriaEvento.map((subcategoria, index) => (
                              <option key={index} value={subcategoria.id}>
                                {subcategoria.nombreSubcategoria}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card pb-2 mb-2">
                <div className="card-body">
                  <h5>Descripción corta evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          maxRows={4}
                          rows={5}
                          multiline
                          id="descripcionCortaEvento"
                          className="mb-2"
                          name="descripcionCortaEvento"
                          label="Descripción Corta Evento"
                          value={formik.values.descripcionCortaEvento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.descripcionCortaEvento &&
                            Boolean(formik.errors.descripcionCortaEvento)
                          }
                          helperText={
                            formik.touched.descripcionCortaEvento &&
                            formik.errors.descripcionCortaEvento
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card pb-2 mb-2">
                <div className="card-body">
                  <h5>Descripción larga evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          maxRows={9}
                          rows={9}
                          multiline
                          id="descripcionLargaEvento"
                          className="mb-2"
                          name="descripcionLargaEvento"
                          label="Descripción Larga Evento"
                          value={formik.values.descripcionLargaEvento}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.descripcionLargaEvento &&
                            Boolean(formik.errors.descripcionLargaEvento)
                          }
                          helperText={
                            formik.touched.descripcionLargaEvento &&
                            formik.errors.descripcionLargaEvento
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-2">
                <div className="card-body">
                  <h5>Limitantes.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="limitantes"
                          className="mb-2"
                          name="limitantes"
                          label="Limitantes del evento"
                          value={formik.values.limitantes}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.limitantes &&
                            Boolean(formik.errors.limitantes)
                          }
                          helperText={
                            formik.touched.limitantes &&
                            formik.errors.limitantes
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-2">
                <div className="card-body">
                  <h5>Redes Sociales de evento.</h5>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="facebook"
                          className="mb-3"
                          name="facebook"
                          label="URL Facebook"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <FacebookIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.facebook}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.facebook &&
                            Boolean(formik.errors.facebook)
                          }
                          helperText={
                            formik.touched.facebook && formik.errors.facebook
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <TextField
                          fullWidth
                          disabled={disabled}
                          id="twitter"
                          className="mb-3"
                          name="twitter"
                          label="URL Twitter"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <TwitterIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.twitter}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.twitter &&
                            Boolean(formik.errors.twitter)
                          }
                          helperText={
                            formik.touched.twitter && formik.errors.twitter
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <TextField
                          disabled={disabled}
                          fullWidth
                          id="instagram"
                          className="mb-2"
                          name="instagram"
                          label="URL Instagram"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <InstagramIcon />
                              </InputAdornment>
                            ),
                          }}
                          value={formik.values.instagram}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.twitter &&
                            Boolean(formik.errors.instagram)
                          }
                          helperText={
                            formik.touched.instagram && formik.errors.instagram
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card bg-primary text-white mb-2">
                <div className="card-body text-center">
                  <h5>Consejo Events U-Ctrl</h5>
                  <p className="m-0">
                    Recuerda introducir la mayor información mencionada, si aún
                    no cuentas con ella no te preocupes, podrás cambiarla más
                    adelante.
                  </p>
                </div>
              </div>

              <div className="card mb-2 d-none">
                <div className="card-body">
                  <h5>Sube la imagen de tu evento.</h5>
                  <p>
                    <small>Tamaño recomendado: XXXpx x XXXpx</small>
                  </p>
                  <UploadImagesComponent
                    setImageCover={setImageCover}
                    numberImages={1}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className="btnSubmit btn">
          <SaveIcon fontSize="inherit" /> Guardar
        </button>
      </form>
    </>
  );
};
