import React from "react";
import {
  getCategoriaEventoBusqueda,
  getCategoriaProveedorBusqueda,
  getCategoriaRecintoBusqueda,
  getCiudadesBusqueda,
  getEventosBusqueda,
  getProveedoresBusqueda,
  getRecintosBusqueda,
  getSubCategoriaEventoBusqueda,
  getSubCategoriaProveedorBusqueda,
  getSubCategoriaRecintoBusqueda,
} from "../../API/APIBusquedaEventos";
import { getEstadosBusqueda } from "./../../API/APIBusquedaEventos";
import CloseIcon from "@material-ui/icons/Close";

import DayPickerInput from "react-day-picker/DayPickerInput";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { DateTime } from "luxon";
import {
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
} from "./../../helpers/LanguajeReactDayPicker";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { types } from "./../../types/types";

export const SearchSuppliersComponent = (props) => {
  const [finalDataCategoriaEvento, setFinalDataCategoriaEvento] =
    React.useState([]);
  const [finalDataSubCategoriaEvento, setFinalDataSubCategoriaEvento] =
    React.useState([]);

  const [finalDataEstadosEvento, setFinalDataEstadosEvento] = React.useState(
    []
  );
  const [finalDataCiudadesEvento, setFinalDataCiudadesEvento] = React.useState(
    []
  );

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    getCategoriaProveedorBusqueda().then((resultadoCategoriaEvento) => {
      setFinalDataCategoriaEvento(resultadoCategoriaEvento);
      getEstadosBusqueda().then((resultado) => {
        setFinalDataEstadosEvento(resultado);
        setLoading(false);
      });
    });
  }, []);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: [] });
    }
  };
  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {},
    validationSchema: null,
    onSubmit: (values) => {
        getProveedoresBusqueda(values).then((resultado) => {
        props.setCrudAction({ action: "showResults", data: resultado });
      });
    },
  });

  return (
    <div className="crudDivComponent col-md-4 p-4">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Buscar Proveedores</h5>
                <small>
                  Ingresa la información siguiente para encontrar el proveedor.
                </small>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: [] })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            {loading === true ? (
              <>Cargando información</>
            ) : (
              <> Consulta los filtros necesarios. </>
            )}
          </div>

          <form onSubmit={formik.handleSubmit}>
            <div className="col-12 my-4">
              <TextField
                fullWidth
                id="cadena"
                name="cadena"
                label="Texto a Buscar"
                value={formik.values.cadena}
                onChange={formik.handleChange}
                error={formik.touched.cadena && Boolean(formik.errors.cadena)}
                helperText={formik.touched.cadena && formik.errors.cadena}
              />
            </div>
            <div className="row">
              <div className="col-md-6">
                <FormControl fullWidth>
                  <InputLabel htmlFor="idcategoriaProveedor">
                    Categoría Proveedor
                  </InputLabel>
                  <Select
                    autoWidth
                    native
                    className="mb-2"
                    value={formik.values.idcategoriaProveedor}
                    onChange={(e) => {
                      formik.handleChange(e);
                      getSubCategoriaProveedorBusqueda(e.target.value).then(
                        (resultado) => {
                          setFinalDataSubCategoriaEvento(resultado);
                        }
                      );
                    }}
                    inputProps={{
                      name: "idcategoriaProveedor",
                      id: "idcategoriaProveedor",
                    }}
                  >
                    <option aria-label="Categoría Proveedor" value={null}>
                      Categoría Proveedor
                    </option>

                    {finalDataCategoriaEvento.map((categoria, index) => (
                      <option key={index} value={categoria.id}>
                        {categoria.nombreCategoria}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl fullWidth>
                  <InputLabel htmlFor="idsubCategoriaProveedor">
                    Subcategoría Proveedor
                  </InputLabel>
                  <Select
                    autoWidth
                    native
                    className="mb-2"
                    value={formik.values.idsubCategoriaProveedor}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    inputProps={{
                      name: "idsubCategoriaProveedor",
                      id: "idsubCategoriaProveedor",
                    }}
                  >
                    <option aria-label="Categoría Proveedor" value={null}>
                      Sub Categoría Proveedor
                    </option>

                    {finalDataSubCategoriaEvento.map((categoria, index) => (
                      <option key={index} value={categoria.id}>
                        {categoria.nombreSubcategoria}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <FormControl fullWidth>
                  <InputLabel htmlFor="idciudad">Estado</InputLabel>
                  <Select
                    autoWidth
                    native
                    className="mb-2"
                    value={formik.values.idestado}
                    onChange={(e) => {
                      formik.handleChange(e);
                      getCiudadesBusqueda(e.target.value).then((resultado) => {
                        setFinalDataCiudadesEvento(resultado);
                      });
                    }}
                    inputProps={{
                      name: "idestado",
                      id: "idestado",
                    }}
                  >
                    <option aria-label="Estado" value={null}>
                      Estado
                    </option>

                    {finalDataEstadosEvento.map((categoria, index) => (
                      <option key={index} value={categoria.idestado}>
                        {categoria.nombreEstado}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-6">
                <FormControl fullWidth>
                  <InputLabel htmlFor="idciudad">Ciudad</InputLabel>
                  <Select
                    autoWidth
                    native
                    className="mb-2"
                    value={formik.values.idciudad}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    inputProps={{
                      name: "idciudad",
                      id: "idciudad",
                    }}
                  >
                    <option aria-label="Ciudad" value={null}>
                      Ciudad
                    </option>

                    {finalDataCiudadesEvento.map((categoria, index) => (
                      <option key={index} value={categoria.idciudad}>
                        {categoria.nombreCiudad}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>

            <div className="col-12 mt-4">
              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
              >
                Buscar Eventos
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
