import React from 'react'
import { getPublicFeaturedRecintos } from '../../API/APIRecintos';
import Skeleton from "@material-ui/lab/Skeleton";
import { CardFeaturedEnclosureComponent } from '../CardFeaturedEnclosureComponent/CardFeaturedEnclosureComponent';

export const FeaturedEnclosuresComponent = () => {
    const [loadingFeatured, setLoadingFeatured] = React.useState(true);
    const [finalDataFeatured, setFinalDataFeatured] = React.useState([]);

    let effectReady = false;

  React.useEffect(() => {
    getPublicFeaturedRecintos(null).then((resultado) => {
      setFinalDataFeatured(resultado);
      setLoadingFeatured(false);
    });

    return () => {
      effectReady = true;
    };
  }, []);


    return (
        <div className="container mt-5 pt-2 pb-3">
              {loadingFeatured === true ? (
            <>
              {" "}
              <div className="row row-cols-md-3">
                <div className="col">
                  <Skeleton variant="rect" height={118} />
                </div>
                <div className="col">
                  <Skeleton variant="rect" height={118} />
                </div>
                <div className="col">
                  <Skeleton variant="rect" height={118} />
                </div>
              </div>{" "}
            </>
          ) : (
            <>
              <div className="row row-cols-md-3">
                {" "}
                {finalDataFeatured.map((recintoDestacado) => (
                  <CardFeaturedEnclosureComponent
                    data={recintoDestacado}
                    admin={false}
                    destacado={true}
                  />
                ))}{" "}
              </div>
            </>
          )}
        </div>
    )
}
