/* eslint-disable no-useless-escape */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";

import { useHistory } from "react-router-dom";
import { AuthContext } from "../../auth/authContext";
import { createCarousel } from "../../API/APICarouselPrincipal";
import { types } from "../../types/types";
import {
  createPatrocinador,
  deletePatrocinador,
  editPatrocinador,
} from "../../API/APIPatrocinadoresEventos";

const validationSchema = yup.object({
  nombrePatrocinador: yup
    .string("Ingresa Patrocinador")
    .max(50, "Patrocinador menor a 50 caracteres")
    .required("Patrocinador es necesario"),
  url: yup
    .string("Ingresa Url")
    .matches(
      /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
      "Enter correct url!"
    )
    .required("Ingresa URL"),
});

export const DeleteDataSponsorsDataEventComponent = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      idevento: props.idevento,
      nombrePatrocinador: props.crudAction.data.nombrePatrocinador,
      url: props.crudAction.data.url,
    },
  
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {
    var bodyFormData = new FormData();
    bodyFormData.append("idevento", values.idevento);
    bodyFormData.append("nombrePatrocinador", values.nombrePatrocinador);
    
    if(values.imagenPatrocinador)
    {
        bodyFormData.append("imagenPatrocinador", values.imagenPatrocinador);
    }
    bodyFormData.append("url", values.url);

    deletePatrocinador(null, user, formik, props.crudAction.data.id).then(
      (respuesta) => {
         
        if (respuesta.status === 200) {
          toast.success("Patrocinador Eliminado Correctamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          props.setCrudAction({ action: null, data: null });
        } else {
          console.error(respuesta);
          if (respuesta.status === 401) {
            dispatch({
              type: types.logout,
            });
            history.replace("/");
          }
        }
      }
    );
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Eliminar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="nombrePatrocinador"
                name="nombrePatrocinador"
                label="Nombre Patrocinador"
                disabled={true}
                className="mb-2"
                value={formik.values.nombrePatrocinador}
                onChange={formik.handleChange}
                error={
                  formik.touched.nombrePatrocinador &&
                  Boolean(formik.errors.nombrePatrocinador)
                }
                helperText={
                  formik.touched.nombrePatrocinador &&
                  formik.errors.nombrePatrocinador
                }
              />

              <TextField
                fullWidth
                id="url"
                name="url"
                disabled={true}
                label="Url llamado a la acción"
                className="mb-2"
                value={formik.values.url}
                onChange={formik.handleChange}
                error={formik.touched.url && Boolean(formik.errors.url)}
                helperText={formik.touched.url && formik.errors.url}
              />
              <div className="my-3">
                <label for="imagen" className="form-label me-2">
                  Subir Imagen de Patrocinador
                </label>

                <span className="badge rounded-pill bg-primary">
                  {" "}
                  .JPG, .PNG Y JPEG{" "}
                </span>

                <input
                  className="form-control"
                  type="file"
                  id="imagen"
                  disabled={true}
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    formik.setFieldValue(
                      "imagenPatrocinador",
                      e.target.files[0]
                    );
                  }}
                />
              </div>

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Eliminar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
