import React from "react";

export const CardPricingEventComponent = (props) => {
  return (
    <div className="col">
      <div className="card mb-2 pricingDiv">
        <div className="card-body">
          <h1 className="card-text font-weight-bold mt-2 text-center">
            ${props.data.precio}
          </h1>
          <p className="card-text mt-2 text-center fw-light">
            {props.data.nombre}
          </p>
          <hr></hr>
          <p className="card-text text-center">
            <small>{props.data.descripcion}</small>
          </p>
        </div>
        {props.editable === false ? null : (
          <>
            <div className="card-footer bg-white">
              <div className="d-grid gap-2 d-md-flex justify-content-center">
                <button
                  className="btn btn-sm text-white btn-secondary"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "edit", data: props.data })
                  }
                >
                  Editar
                </button>
                <button
                  className="btn btn-sm text-white btn-danger"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "delete", data: props.data })
                  }
                >
                  Eliminar
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
