import React from "react";
import { LoremComponent } from "./../LoremComponent/LoremComponent";
import MapIcon from "@material-ui/icons/Map";
import styles from "./PublicPlanoRecintoComponent.module.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";

export const PublicPlanoRecintoComponent = (props) => {
  const [planoData, setPlanoData] = React.useState(props.data);
  const [idSelected, setIdSelected] = React.useState(props.data[0]);
  return (
    <>
      <div className="modalEvents">
        <div className="modal-contentEvents">
          <div className="modal-headerEvents">
            <div classsName="d-flex justify-content-between d-flex align-items-center">
              <h5 className="m-0 p-0">Plano</h5>
              <span
                onClick={() => {
                  props.setPlanoShow(!props.planoShow);
                }}
                className="close"
              >
                &times;
              </span>
            </div>
            <hr></hr>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <ul classsName="list-group list-group-flush">
                  {planoData.map((plano, index) => (
                    <li
                      key={index}
                      className="list-group-item cursor-pointer"
                      onClick={() => {
                        setIdSelected(plano);
                      }}
                    >
                      <MapIcon fontSize="inherit" />{" "}
                      <strong>{plano.nombre}</strong> <br></br>{" "}
                      <small>{plano.descripcion}</small>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="col-md-6">
                <InnerImageZoom
                  className="w-100"
                  src={idSelected.imagenConvertida}
                  zoomSrc={idSelected.imagenConvertida}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
