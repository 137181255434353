import { API } from "../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "../Images/noImageAvailable";

export const createAdSupplier = async (values, user, formik) => {
  let url = API + "sitio/createPublicidadProveedor";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getAdsSupplier = async (values, user) => {
  let url = API + "sitio/getPublicidadProveedor/";
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPublicidad === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPublicidad.data);
        }
      }
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPublicAdsSupplier = async (values) => {
  let url = API + "sitio/getPublicidadProveedor/";
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPublicidad === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPublicidad.data);
        }
      }
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const desactivarPublicidadSupplier = async (values, user, idpublicidad) => {
  let url = API + "sitio/desactivarPublicidadProveedor/" + idpublicidad;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const asignarPublicidadASupplier = async (values, user, idpublicidad) => {
  let url = API + "sitio/asignarAProveedor/" + idpublicidad;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
