import React from "react";

export const AreasDeServicioRecintoComponent = (props) => {
  return (
    <>
      <h6>Areas de Servicio:</h6>
      <hr></hr>
      <table classsName="table table-borderless">
        <thead>
          <tr>
            <th scope="col">Nombre</th>
            <th scope="col">Capacidad</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((servicio) => (
            <tr>
              <td>
                {servicio.nombreAreaServicio} <br></br>{" "}
                <small>{servicio.descripcion}</small>
              </td>
              <td>{servicio.capacidad}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
