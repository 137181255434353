import React from "react";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailIcon from "@material-ui/icons/Mail";
import PublicIcon from '@material-ui/icons/Public';
export const InformationEnclosureDetail = (props) => {



  return (
    <>
      <h6>
        <i className="fas fa-info-circle"></i>&nbsp;&nbsp;Información
      </h6>
      <hr></hr>
      <div className="list-group list-group-flush fillInformationEvent">
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Categoría: </strong>
          </small>
          <strong>
            <br />
            {props.data.nombreCategoria}
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Subcategoría: </strong>
          </small>
          <strong>
            <br />
            {props.data.nombreSubCategoria}
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Estado: </strong>
          </small>
          <strong>
            <br />
            {props.data.nombreEstado}
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Ciudad: </strong>
          </small>
          <strong>
            <br />
            {props.data.nombreCiudad}
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Horario de Atención: </strong>
          </small>
          <strong>
            <br />
            {props.data.horarioAtencion}
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Dirección: </strong>
          </small>
          <strong>
            <br />
            {props.data.direccion} <br></br>{" "}
            <a href={props.data.googleMaps}>
              {" "}
              <RoomIcon fontSize="inherit" />
              Abrir en Google Maps
            </a>
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong>
              {" "}
              <PhoneIcon fontSize="inherit" /> Teléfono:{" "}
            </strong>
          </small>
          <strong>
            <br />
            <a href={"tel:" + props.data.telefono}> {props.data.telefono}</a>
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong>
              {" "}
              <WhatsAppIcon fontSize="inherit" /> Whatsapp:{" "}
            </strong>
          </small>
          <strong>
            <br />
            <a
              href={
                "whatsapp://send?text=Contacto&phone=+52" + props.data.whatsapp
              }
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              {props.data.whatsapp}
            </a>
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong>
              {" "}
              <MailIcon fontSize="inherit" /> Correo Electrónico:{" "}
            </strong>
          </small>
          <strong>
            <br />
            <a
              href={"mailto:" + props.data.emailContacto}
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              {props.data.emailContacto}
            </a>
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong>
              {" "}
              <PublicIcon fontSize="inherit" /> Página Web:{" "}
            </strong>
          </small>
          <strong>
            <br />
            <a
              href={"" + props.data.web}
              target="_blank"
              className="text-wrap"
              rel="noreferrer"
            >
              {" "}
              {props.data.web}
            </a>
          </strong>
        </div>
      </div>
    </>
  );
};
