import React from "react";
import InfoIcon from "@material-ui/icons/Info";
import RoomIcon from "@material-ui/icons/Room";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import PhoneIcon from "@material-ui/icons/Phone";
import PublicIcon from "@material-ui/icons/Public";

export const InformationSupplierPublicComponent = (props) => {
  return (
    <>
      <div className="col-12 p-4 mb-3" style={{ backgroundColor: "white" }}>
        <img
          alt="imagenProveedor"
          src={props.data.imagenConvertida}
          className="w-100"
        />
        <h6 className="mt-4">
          <InfoIcon fontSize="" className="me-2" /> Información:
        </h6>
        <hr></hr>
        <ul classsName="list-group list-group-flush">
          <li classsName="list-group-item">
            <strong>
              <small>Categoría:</small>
            </strong>{" "}
            <br></br>
            <span>{props.data.nombreCategoria}</span>
          </li>
          <li classsName="list-group-item">
            <strong>
              <small>Sub Categoría:</small>
            </strong>{" "}
            <br></br>
            <span>{props.data.nombreSubcategoria}</span>
          </li>
          <li classsName="list-group-item">
            <strong>
              <small>Estado:</small>
            </strong>{" "}
            <br></br>
            <span>{props.data.nombreEstado}</span>
          </li>
          <li classsName="list-group-item">
            <strong>
              <small>Ciudad:</small>
            </strong>{" "}
            <br></br>
            <span>{props.data.nombreCiudad}</span>
          </li>
          <li classsName="list-group-item">
            <strong>
              <small>
                <RoomIcon className="pe-2" />
                Dirección:
              </small>
            </strong>{" "}
            <br></br>
            <span>{props.data.direccion}</span>
            <br></br>
            <br></br>
            <strong className="cursor-pointer">
              <a target="_blank" rel="noreferrer" href={props.data.googleMaps}>
                Abrir Ubicación
              </a>
            </strong>
          </li>
          <li classsName="list-group-item">
            <strong>
              <small>
                {" "}
                <PhoneIcon fontSize="inherit" className="me-1" />
                Teléfono:
              </small>
            </strong>{" "}
            <br></br>
            <strong className="cursor-pointer">
              <a className="cursor-pointer" href={`tel:` + props.data.telefono}>
                {props.data.telefono}
              </a>
            </strong>
          </li>
          <li classsName="list-group-item">
            <strong>
              <small>
                {" "}
                <WhatsAppIcon fontSize="inherit" className="me-1" />
                Whatsapp:
              </small>
            </strong>{" "}
            <br></br>
            <strong className="cursor-pointer">
              <a
                className="cursor-pointer"
                href={
                  `whatsapp://send?text=Contacto&phone=+52:` +
                  props.data.whatsapp
                }
              >
                {props.data.whatsapp}
              </a>
            </strong>
          </li>
          <li classsName="list-group-item">
            <strong>
              <small>
                <MailOutlineIcon fontSize="inherit" className="me-1" />
                Correo Electrónico:
              </small>
            </strong>{" "}
            <br></br>
            <strong className="cursor-pointer">
              <a
                className="cursor-pointer"
                href={`mailto:` + props.data.emailContacto}
              >
                {props.data.emailContacto}
              </a>
            </strong>
          </li>
          <li classsName="list-group-item">
            <strong>
              <small>
                <PublicIcon fontSize="inherit" className="me-1" />
                Página Web:
              </small>
            </strong>{" "}
            <br></br>
            <strong className="cursor-pointer">
              <a className="cursor-pointer" href={`` + props.data.web}>
                {props.data.web}
              </a>
            </strong>
          </li>
          <li classsName="list-group-item">
            <strong>
              <small>Horario de atención:</small>
            </strong>{" "}
            <br></br>
            {props.data.horarioAtencion}
          </li>
        </ul>
      </div>
    </>
  );
};
