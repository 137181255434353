/* eslint-disable no-unused-vars */
import React from "react";
import { AuthContext } from "../auth/authContext";
import jwt_decode from "jwt-decode";

export const UserDecode = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const decoded = jwt_decode(user.accessToken);
  return decoded;
};
