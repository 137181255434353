import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  nombre: yup.string("Ingresa Nombre").required("Nombre Necesario"),
  apellido: yup.string("Ingresa Apellido").required("Apellido Necesario"),
  cvCorto: yup.string("Ingresa CV Corto").required("CV Corto Necesario"),
  twitterPonente: yup
    .string("Ingresa Url Twitter")
    .required("Twitter Necesario"),
  instagramPonente: yup
    .string("Ingresa Url Instagram")
    .required("Instagram Necesario"),
  facebookPonente: yup
    .string("Ingresa Url Facebook")
    .required("Facebook Necesario"),
  youtubePonente: yup
    .string("Ingresa Url Youtube")
    .required("Youtube Necesario"),
});

export const AddDataSpeakersComponent = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const [isDisabled, setIsDisabled] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      nombre: "",
      apellido: "",
      cvCorto: "",
      twitterPonente: "",
      instagramPonente: "",
      facebookPonente: "",
      youtubePonente: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
       
     // alert(JSON.stringify(values, null, 2));
      if (values.imagenPonente === {}) {
        toast.error("no hay imagen de ponente, debes de subir una.");
      } else {
            addData(values);
      }
    },
  });


  const addData = async (values) => {

     
    setIsDisabled(true);
    var bodyFormData = new FormData();
      bodyFormData.append("nombre", values.nombre);
      bodyFormData.append("apellido", values.apellido);
      bodyFormData.append("cvCorto", values.cvCorto.data);
      bodyFormData.append("twitterPonente", values.twitterPonente);
      bodyFormData.append("instagramPonente", values.instagramPonente);
      bodyFormData.append("facebookPonente", values.facebookPonente);
      bodyFormData.append("youtubePonente", values.youtubePonente);
      bodyFormData.append("cvFile", values.cvFile);
      bodyFormData.append("imagenPonente", values.imagenPonente);   

       

    try {
      const response = await axios.post(
        API + "ponente/createPonente/",
        bodyFormData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );
      setIsDisabled(false);
      toast.success("Ponente Agregado Correctamente!  ", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div className="crudDivComponent col-md-3">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Agregar {props.nombre}</h5>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    {" "}
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form onSubmit={formik.handleSubmit}>
              {isDisabled === true ? (
                <>
                  <div className="alert alert-primary" role="alert">
                    Procesando información, por favor espera...
                  </div>
                </>
              ) : null}
                <TextField
                  fullWidth
                  id="nombre"
                  name="nombre"
                  disabled={isDisabled}
                  label="Nombre de ponente"
                  className="mb-2"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                  helperText={formik.touched.nombre && formik.errors.nombre}
                />
                <TextField
                  fullWidth
                  id="apellido"
                  name="apellido"
                  disabled={isDisabled}
                  label="Apellido de ponente"
                  className="mb-2"
                  value={formik.values.apellido}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.apellido && Boolean(formik.errors.apellido)
                  }
                  helperText={formik.touched.apellido && formik.errors.apellido}
                />
                <TextField
                  fullWidth
                  id="cvCorto"
                  name="cvCorto"
                  label="Cv Corto"
                  disabled={isDisabled}
                  className="mb-2"
                  multiline
                  rows={3}
                  value={formik.values.cvCorto}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.cvCorto && Boolean(formik.errors.cvCorto)
                  }
                  helperText={formik.touched.cvCorto && formik.errors.cvCorto}
                />

                <TextField
                  fullWidth
                  id="twitterPonente"
                  name="twitterPonente"
                  disabled={isDisabled}
                  label="Twitter Ponente"
                  className="mb-2"
                  value={formik.values.twitterPonente}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.twitterPonente &&
                    Boolean(formik.errors.twitterPonente)
                  }
                  helperText={
                    formik.touched.twitterPonente &&
                    formik.errors.twitterPonente
                  }
                />
                <TextField
                  fullWidth
                  id="instagramPonente"
                  name="instagramPonente"
                  disabled={isDisabled}
                  label="Instagram Ponente"
                  className="mb-2"
                  value={formik.values.instagramPonente}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.instagramPonente &&
                    Boolean(formik.errors.instagramPonente)
                  }
                  helperText={
                    formik.touched.instagramPonente &&
                    formik.errors.instagramPonente
                  }
                />
                <TextField
                  fullWidth
                  id="facebookPonente"
                  name="facebookPonente"
                  disabled={isDisabled}
                  label="Facebook Ponente"
                  className="mb-2"
                  value={formik.values.facebookPonente}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.facebookPonente &&
                    Boolean(formik.errors.facebookPonente)
                  }
                  helperText={
                    formik.touched.facebookPonente &&
                    formik.errors.facebookPonente
                  }
                />
                <TextField
                  fullWidth
                  id="youtubePonente"
                  name="youtubePonente"
                  disabled={isDisabled}
                  label="Youtube Ponente"
                  className="mb-2"
                  value={formik.values.youtubePonente}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.youtubePonente &&
                    Boolean(formik.errors.youtubePonente)
                  }
                  helperText={
                    formik.touched.youtubePonente &&
                    formik.errors.youtubePonente
                  }
                />

                <div className="my-3">
                  <label for="cvFile" className="form-label">
                    Ingresa archivo PDF de C.V.
                  </label>
                  <input
                    className="form-control"
                    disabled={isDisabled}
                    type="file"
                    id="cvFile"
                    accept="application/pdf"
                    onChange={(e) => {
                      formik.setFieldValue("cvFile", e.target.files[0]);
                    }}
                  />
                </div>
                <div className="my-3">
                  <label for="imagenPonente" className="form-label">
                    Agrega imagen del ponente
                  </label>
                  <input
                    className="form-control"
                    disabled={isDisabled}
                    type="file"
                    id="imagenPonente"
                    accept="image/x-png,image/gif,image/jpeg"
                    onChange={(e) => {
                      formik.setFieldValue("imagenPonente", e.target.files[0]);
                    }}
                  />
                </div>

                <Button
                  color="primary"
                  variant="contained"
                  disabled={isDisabled}
                  fullWidth
                  type="submit"
                  className="mt-3"
                >
                  Agregar {props.nombre}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
