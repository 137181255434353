import React from "react";
import {
  eventosSeleccionarDestacados,
  eventosSeleccionarProximos,
  getAllEvents,
  getEventosSeleccionarProximos,
  getPublicProximos,
  setUnsetEspecialDestacado,
  setUnsetEspecialProximo,
} from "../../../API/APIEventos";
import { AuthContext } from "../../../auth/authContext";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import { CardFeaturedProductComponent } from "../../../Components/CardFeaturedProductComponent/CardFeaturedProductComponent";

export const AdminUpcomingEventsScreen = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [value, setValue] = React.useState({});
  const [inputValue, setInputValue] = React.useState("");
  const [finalData, setFinalData] = React.useState([]);
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });
  const [finalFeaturedData, setFinalFeaturedData] = React.useState([]);
  React.useEffect(() => {
    getEventosSeleccionarProximos(null, user).then((resultado) => {
      setFinalData(resultado);
    });

    getPublicProximos(null, user).then((resultado) => {
      setFinalFeaturedData(resultado);
    });
  }, []);
  
  React.useEffect(() => {
    getEventosSeleccionarProximos(null, user).then((resultado) => {
      setFinalData(resultado);
    });

    getPublicProximos(null, user).then((resultado) => {
      setFinalFeaturedData(resultado);
    });
  }, [crudAction]);

  return (
    <>
      <div className="container">
        <div className="row my-3">
          <div className="col-md-10">
            <Autocomplete
              id="combo-box-demo"
              value={value}
              options={finalData}
              getOptionLabel={(option) => option.tituloEvento}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccionar Eventos"
                  variant="filled"
                />
              )}
            />
          </div>
          <div className="col-md-2">
            <div
              onClick={() => {
                setUnsetEspecialProximo(
                  { idevento: value.id },
                  user,
                  value.id
                ).then((resultado) => {
                  setCrudAction({ action: null, data: resultado });
                });
              }}
              className="btn btn-lg btn-success w-100 mt-1"
            >
              Agregar
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row-cols-md-3">
          {finalFeaturedData.map((evento) => (
            <CardFeaturedProductComponent
              data={evento}
              crudAction={crudAction}
              setCrudAction={setCrudAction}
              proximos={true}
              admin={true}
            />
          ))}
        </div>
      </div>
    </>
  );
};
