import React from "react";
import styles from "./HeaderEventDetail.module.css";
import { encode, decode } from "base64-arraybuffer";
export const HeaderEventDetail = (props) => {


   

  let finalUrlImage = "";
  if (props.data.imagenEvento) {
    finalUrlImage = `data:image/png;base64,${encode(
      props.data.imagenEvento.data
    )}`;
  } else {
    finalUrlImage = "https://picsum.photos/1000/200?random=1";
  }
  const estiloBackground = {
    background: `linear-gradient(rgba(0,0,0,0.65),rgba(0,0,0,0.65)),url(${finalUrlImage})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
  return (
    <>
      <section style={estiloBackground} className={styles.portadaPrincipal}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {props.type === "evento" ? (
                <span className="badge rounded-pill bg-primary">Evento</span>
              ) : props.type === "proveedor" ? (
                <span className="badge rounded-pill bg-primary">Proveedor</span>
              ) : props.type === "recinto" ? (
                <span className="badge rounded-pill bg-primary">Recinto</span>
              ) : (
                "Sin Definir"
              )}
              <h1 className="mt-2"> {props.data.tituloEvento}</h1>
              <p>{props.data.descripcionCortaEvento}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
