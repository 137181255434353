import React from "react";
import { AuthContext } from "../../../auth/authContext";
import styles from "./ProfileScreen.module.css";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { API } from "../../../constants/ApiConnection";
import { types } from "../../../types/types";
import { DateTime } from "luxon";
import { CountryStateCity, getCities } from "../../../helpers/CountryStateCity";
import DoneIcon from "@material-ui/icons/Done";
const validationSchema = yup.object({
  email: yup
    .string("Ingresa tu correo electrónico")
    .email("Ingrresa un correo válido")
    .required("Correo necesario"),
  nombre: yup
    .string("Ingresa tu nombre completo")
    .required("El nombre es necesario"),
  telCelular: yup
    .string()
    .matches(/^(\+\d{1,3}[- ]?)?\d{10}$/, "Teléfono inválido")
    .required("Teléfono Necesario"),
  fechaNacimiento: yup.string().required("Fecha Requerida"),
});

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

let resultadoEstado = 0;

export const ProfileScreen = (props) => {
  const [userData, setUserData] = React.useState({});
  const [resultData, setResultData] = React.useState([]);

  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  const [userDataPerfil, setUserDataPerfil] = React.useState({});

  const updateProfileData = async (values) => {
    try {
      const { id } = await jwt_decode(user.accessToken);

      //URL
      const APIURL = API + "user/updateGralData/" + id;
      const response = await axios.put(APIURL, values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });
      if (response.status === 200) {
        toast.success("Datos cambiados correctamente, se cerrará la sesión");

        dispatch({
          type: types.logout,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getData = async () => {
    const { id } = await jwt_decode(user.accessToken);

    var usuarioDecoded = await jwt_decode(user.accessToken);

    setUserDataPerfil(usuarioDecoded);

    const url = API + "user/getUserGralData/" + id;

    try {
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });

      if (response.status === 200) {
        toast.success("Datos de perfil  cargados.  ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }

      const fecha = DateTime.fromISO(response.data.fechaNacimiento).toFormat(
        "yyyy-MM-dd"
      );

      formik.setFieldValue("email", response.data.email);
      formik.setFieldValue("sexo", response.data.sexo);
      formik.setFieldValue("nombre", response.data.nombre);
      formik.setFieldValue("telCelular", response.data.telCelular);
      formik.setFieldValue("idpais", response.data.idpais);
      formik.setFieldValue("idestado", response.data.idestado);
      formik.setFieldValue("idciudad", response.data.idciudad);
      formik.setFieldValue("fechaNacimiento", fecha);
      formik.setFieldValue("rolEvento", response.data.rolEvento);
      formik.setFieldValue("rolRecinto", response.data.rolRecinto);
      formik.setFieldValue("rolProveedor", response.data.rolProveedor);

      resultadoEstado = response.data.idestado;

      setResultData(response.data);
    } catch (error) {
      toast.error("Error en llamada: " + JSON.stringify(error), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (error.response.status === 401) {
        toast.error("Tu sesión ha caducado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };
  React.useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("user"));

    setUserData(jwt_decode(userInfo.accessToken));

    getData().then(() => {
      CountryStateCity().then((resultado) => {
        setEstados(resultado);
        formik.setFieldValue("idestado", resultData.idestado);
        getCities(resultadoEstado).then((resultadoa) => {
          setMunicipios(resultadoa);
          formik.setFieldValue("idciudad", resultData.idciudad);
        });
      });
    });
  }, []);

  const classes = useStyles();
  const formik = useFormik({
    initialValues: {
      nombre: "",
      sexo: "M",
      telCelular: "",
      email: "",
      password: "",
      idpais: "1",
      idestado: "1",
      idciudad: "1",
      fechaNacimiento: "",
      rolEvento: false,
      rolRecinto: false,
      rolProveedor: false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      updateProfileData(values);
    },
  });

  const [disabledTextInput, setDisabledTextInput] = React.useState(false);
  const { user, dispatch } = React.useContext(AuthContext);

  let history = useHistory();
  return (
    <div className="container-fluid mt-3">
      <div className="row">
        <div className="col-12">
          <h4 className="text-success">{props.title}</h4>
          <p>{props.description}</p>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-end">
          <h6>Tipo de Usuario</h6>
          {userDataPerfil.master == true ? (
            <span class="badge rounded-pill bg-success me-2">
              <DoneIcon /> Usuario Maestro Events
            </span>
          ) : null}
          {userDataPerfil.isAdmin == true ? (
            <span class="badge rounded-pill bg-success">
              <DoneIcon /> Administrador de empresa
            </span>
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <form className={classes.form} onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={10}>
                <TextField
                  disabled={disabledTextInput}
                  autoComplete="nombre"
                  size="small"
                  name="nombre"
                  fullWidth
                  id="nombre"
                  label="Nombre Completo"
                  autoFocus
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                  helperText={formik.touched.nombre && formik.errors.nombre}
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel shrink htmlFor="sexo">
                    Sexo
                  </InputLabel>
                  <Select
                    native
                    shrink
                    value={formik.values.sexo}
                    onChange={formik.handleChange}
                    inputProps={{
                      name: "sexo",
                      id: "sexo",
                    }}
                  >
                    <option value={"M"}>Masculino</option>
                    <option value={"F"}>Femenino</option>
                    <option value={"F"}>N/A</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel
                    InputLabelProps={{ shrink: true }}
                    htmlFor="idpais"
                    shrink
                  >
                    idpais
                  </InputLabel>
                  <Select
                    native
                    value={formik.values.idpais}
                    onChange={formik.handleChange}
                    inputProps={{
                      name: "idpais",
                      id: "idpais",
                    }}
                  >
                    <option value={"1"}>México</option>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel
                    shrink
                    InputLabelProps={{ shrink: true }}
                    htmlFor="idestado"
                  >
                    Estado
                  </InputLabel>
                  <Select
                    native
                    value={formik.values.idestado}
                    onChange={(e) => {
                      formik.handleChange(e);
                      var idEstado = e.target.value;
                      getCities(idEstado).then((resultado) => {
                        setMunicipios(resultado);
                      });
                    }}
                    inputProps={{
                      name: "idestado",
                      id: "idestado",
                    }}
                  >
                    {estados.map((estado, index) => (
                      <option key={index} value={estado.idestado}>
                        {estado.nombreEstado}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl className={classes.formControl} fullWidth>
                  <InputLabel
                    InputLabelProps={{ shrink: true }}
                    shrink
                    htmlFor="idciudad"
                  >
                    Municipio
                  </InputLabel>
                  <Select
                    native
                    value={formik.values.idciudad}
                    onChange={formik.handleChange}
                    inputProps={{
                      name: "idciudad",
                      id: "idciudad",
                    }}
                  >
                    {municipios.map((municipio, index) => (
                      <option key={index} value={municipio.idciudad}>
                        {municipio.nombreCiudad}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={disabledTextInput}
                  fullWidth
                  size="small"
                  id="telCelular"
                  label="Teléfono Celular 10 dígitos"
                  name="telCelular"
                  autoComplete="telCelular"
                  value={formik.values.telCelular}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.telCelular &&
                    Boolean(formik.errors.telCelular)
                  }
                  helperText={
                    formik.touched.telCelular && formik.errors.telCelular
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  disabled={disabledTextInput}
                  fullWidth
                  size="small"
                  id="fechaNacimiento"
                  label="Fecha de Nacimiento"
                  name="fechaNacimiento"
                  type="date"
                  inputProps={{ max: "3000-01-01" }}
                  InputLabelProps={{ shrink: true }}
                  autoComplete="fechaNacimiento"
                  value={formik.values.fechaNacimiento}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.fechaNacimiento &&
                    Boolean(formik.errors.fechaNacimiento)
                  }
                  helperText={
                    formik.touched.fechaNacimiento &&
                    formik.errors.fechaNacimiento
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextField
                  fullWidth
                  disabled={true}
                  id="email"
                  size="small"
                  label="Correo electrónico"
                  name="email"
                  autoComplete="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid className="d-none" item xs={12} sm={6}>
                <TextField
                  fullWidth
                  disabled={disabledTextInput}
                  id="password"
                  type="password"
                  size="small"
                  label="Contraseña"
                  name="password"
                  autoComplete="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />
              </Grid>
              {userData.isAdmin ? (
                <>
                  <Grid className="d-none" item xs={12} sm={12}>
                    <h5 className="my-3 text-info">
                      ¿Qué módulos deseas habilitar?
                    </h5>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.rolEvento}
                          onChange={formik.handleChange}
                          name="rolEvento"
                          color="primary"
                        />
                      }
                      label="Eventos"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.rolRecinto}
                          onChange={formik.handleChange}
                          name="rolRecinto"
                          color="primary"
                        />
                      }
                      label="rolRecinto"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.rolProveedor}
                          onChange={formik.handleChange}
                          name="rolProveedor"
                          color="primary"
                        />
                      }
                      label="rolProveedor"
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
            <Button
              disabled={disabledTextInput}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Actualizar Datos de Perfil
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
