import React from "react";
import { AuthContext } from "../../auth/authContext";
import StreetviewIcon from "@material-ui/icons/Streetview";
export const CardUbicacionRecienteComponent = (props) => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
          <button
            className="btn btn-sm btn-warning me-md-2"
            type="button"
            onClick={() =>
              props.setCrudAction({ action: "edit", data: props.data })
            }
          >
            Editar
          </button>
          <button
            className="btn btn-sm btn-danger"
            type="button"
            onClick={() =>
              props.setCrudAction({ action: "delete", data: props.data })
            }
          >
            Eliminar
          </button>
        </div>
        <h6>
          <StreetviewIcon className="me-2 text-info" />{" "}
          {props.data.nombreUbicacion}
        </h6>
        <small> Se encuentra a: {props.data.distancia} Km.</small>
      </div>
    </div>
  );
};
