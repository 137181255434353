import React from "react";
import axios from "axios";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import { UserDecode } from "../../../helpers/UserDecode";
import { toast } from "react-toastify";
import { API } from "../../../constants/ApiConnection";
import { AuthContext } from "../../../auth/authContext";
import { types } from "../../../types/types";
import { getAllRecintosByCuenta } from "../../../API/APIRecintos";
import { CardAdminEnclosureComponent } from "../../../Components/CardAdminEnclosureComponent/CardAdminEnclosureComponent";
import { DeleteEnclosureComponent } from "../../../Components/DeleteEnclosureComponent/DeleteEnclosureComponent";
import { checkViewPermission } from "../../../API/APIPermisos";
import { LoaderEventsComponent } from "../../../Components/LoaderEventsComponent/LoaderEventsComponent";
import { SinPermisoParaAcceder } from "../../Public/SinPermisoParaAcceder/SinPermisoParaAcceder";
import Skeleton from "@material-ui/lab/Skeleton";
const uuidVista = "d2860cda-3d81-48c3-b3b9-371543da5ec3";

export const EnclosuresListScreen = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  let history = useHistory();
  const [finalData, setFinalData] = React.useState([]);
  const idusuarioPadre = UserDecode().idusuarioPadre;
  const [CrudType, setCrudType] = React.useState({
    type: null,
    data: null,
  });

  const [resultadoPermisos, setResultadoPermisos] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const [statusPermiso, setStatusPermiso] = React.useState(0);
  React.useEffect(() => {
    checkViewPermission(uuidVista, user).then((resultado) => {
       
      setResultadoPermisos(resultado.data);
      setStatusPermiso(resultado.status);
      if (resultado.status === 200) {
        getAllRecintosByCuenta(null, user, idusuarioPadre).then((resultado) => {
          if (resultado.status === 200) {
            setFinalData(resultado.data);
            setCargando(false);
          } else if (resultado.status === 401) {
            dispatch({
              type: types.logout,
            });
            history.replace("/");
          }
        });
      }
    });
  }, []);

  React.useEffect(() => {
    getAllRecintosByCuenta(null, user, idusuarioPadre).then((resultado) => {
      if (resultado.status === 200) {
        setFinalData(resultado.data);
      } else if (resultado.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    });
  }, [CrudType]);

  return (
    <>
      {statusPermiso === 200 ? (
        <>
          {" "}
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-12">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  {resultadoPermisos.map((permiso, index) => (
                    <React.Fragment key={index}>
                      {permiso.guidFuncion ==
                        "b16d2826-4081-44f7-b39e-0ae048b19fc5" &&
                      permiso.allowed == 1 ? (
                        <>
                          <button
                            className="btn btn-primary text-white"
                            type="button"
                            onClick={() => history.push("/addenclosure")}
                          >
                            <AddCircleIcon fontSize="inherit" /> Agregar Recinto
                          </button>
                        </>
                      ) : null}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid mt-4">
            <div className="row row-cols-md-1">
              {cargando === true ? (
                <>
                  <div className="col">
                    <Skeleton
                      className="mb-3"
                      variant="rect"
                      width={"100%"}
                      height={200}
                    />
                    <Skeleton
                      className="mb-3"
                      variant="rect"
                      width={"100%"}
                      height={200}
                    />
                    <Skeleton
                      className="mb-3"
                      variant="rect"
                      width={"100%"}
                      height={200}
                    />
                    <Skeleton
                      className="mb-3"
                      variant="rect"
                      width={"100%"}
                      height={200}
                    />
                  </div>
                </>
              ) : null}
            </div>
          </div>
          <div className="container-fluid mt-4">
            <div className="row row-cols-md-1">
              {finalData.map((enclosure, index) => (
                <CardAdminEnclosureComponent
                  data={enclosure}
                  key={index}
                  CrudType={CrudType}
                  setCrudType={setCrudType}
                  resultadoPermisos={resultadoPermisos}
                />
              ))}
            </div>
          </div>{" "}
        </>
      ) : statusPermiso === 0 ? (
        <LoaderEventsComponent />
      ) : (
        <SinPermisoParaAcceder />
      )}

      {CrudType.type === "eliminar" ? (
        <DeleteEnclosureComponent
          data={CrudType}
          setCrudType={setCrudType}
          nombre="recinto"
        />
      ) : null}
    </>
  );
};
