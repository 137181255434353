import React from "react";
import { SliderCarouselComponent } from "./../../../Components/SliderCarouselComponent/SliderCarouselComponent";
import { CardIndexComponent } from "./../../../Components/CardIndexComponent/CardIndexComponent";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";
import styles from "./MainPage.module.css";
import { CardFeaturedProductComponent } from "../../../Components/CardFeaturedProductComponent/CardFeaturedProductComponent";
import { HeaderAnnouncementComponent } from "../../../Components/HeaderAnnouncementComponent/HeaderAnnouncementComponent";
import { CardEventComponent } from "../../../Components/CardEventComponent/CardEventComponent";
import { CardCompoundComponent } from "../../../Components/CardCompoundComponent/CardCompoundComponent";
import { CardSupplierComponent } from "../../../Components/CardSupplierComponent/CardSupplierComponent";
import { CardAllyComponent } from "../../../Components/CardAllyComponent/CardAllyComponent";
import { LoaderEventsComponent } from "../../../Components/LoaderEventsComponent/LoaderEventsComponent";
import { noImageAvailable } from "./../../../Images/noImageAvailable";
import { FeaturedEventsComponent } from "../../../Components/FeaturedEventsComponent/FeaturedEventsComponent";
import { UpcomingEventsComponent } from "../../../Components/UpcomingEventsComponent/UpcomingEventsComponent";
import { FeaturedEnclosuresComponent } from "../../../Components/FeaturedEnclosuresComponent/FeaturedEnclosuresComponent";
import { FeaturedSuppliersComponent } from "./../../../Components/FeaturedSuppliersComponent/FeaturedSuppliersComponent";
export const MainPage = (props) => {
  const [loader, setLoader] = React.useState(true);

  React.useEffect(() => {
    setTimeout(function () {
      setLoader(false);
    }, 2000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Events U-Ctrl- Bienvenidos</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>

      <div className="container-fluid g-0 mt-5">
        <div className="row g-0">
          <div className="col-12">
            <SliderCarouselComponent />
          </div>
        </div>
      </div>
      {/*Cargando Eventos Destacados*/}
      <section className={`pt-1 pb-5 text-center ${styles.eventosDestacados}`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Eventos Destacados</h2>
            </div>
          </div>
        </div>
        <FeaturedEventsComponent />
      </section>
      {/*Cargando Próximos Eventos*/}
      <HeaderAnnouncementComponent
        title="Próximos Eventos"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam mollitia saepe atque magnam facilis, delectus vel quos quaerat, blanditiis placeat culpa, a expedita veniam. Saepe adipisci deserunt tempore molestias quas!"
      />
      <section
        className={`pt-1 pb-5 text-center mt-5 pt-2 pb-3 ${styles.eventosDestacados}`}
      >
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Próximos Eventos</h2>
            </div>
          </div>
        </div>
        <UpcomingEventsComponent />
      </section>
      {/*Cargando Recintos*/}
      <HeaderAnnouncementComponent
        title="Recintos"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam mollitia saepe atque magnam facilis, delectus vel quos quaerat, blanditiis placeat culpa, a expedita veniam. Saepe adipisci deserunt tempore molestias quas!"
      />
      <section className={`pt-1 pb-5 text-center ${styles.eventosDestacados}`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Recintos Destacados</h2>
            </div>
          </div>
        </div>
        <FeaturedEnclosuresComponent />
      </section>
      {/*Cargando Proveedores*/}
      <HeaderAnnouncementComponent
        title="Proveedores"
        subtitle="Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam mollitia saepe atque magnam facilis, delectus vel quos quaerat, blanditiis placeat culpa, a expedita veniam. Saepe adipisci deserunt tempore molestias quas!"
      />
      <section className={`pt-1 pb-5 text-center ${styles.eventosDestacados}`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Proveedores Destacados</h2>
            </div>
          </div>
        </div>
        <FeaturedSuppliersComponent />
      </section>
      {/*Cargando Aliados Estratégicos*/}

      {loader === true ? <LoaderEventsComponent isOpen={loader} /> : null}
    </>
  );
};
