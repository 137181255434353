import React from "react";
import {
  eventosSeleccionarDestacados,
  getAllEvents,
  getPublicFeaturedEvents,
  setUnsetEspecialDestacado,
} from "../../../API/APIEventos";
import { AuthContext } from "../../../auth/authContext";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ToastContainer, toast } from "react-toastify";
import { CardFeaturedProductComponent } from "../../../Components/CardFeaturedProductComponent/CardFeaturedProductComponent";
import { ChangeOrderEventosDestacados } from "../ChangeOrderEventosDestacados/ChangeOrderEventosDestacados";
import {
  getPublicFeaturedSuppliers,
  proveedoresSeleccionarDestacados,
  setUnsetProveedorDestacado,
} from "../../../API/APIProveedores";
import { CardFeaturedSuppliersComponent } from "../../../Components/CardFeaturedSuppliersComponent/CardFeaturedSuppliersComponent";
import { ChangeOrderFeaturedSuppliers } from "../../../Components/ChangeOrderFeaturedSuppliers/ChangeOrderFeaturedSuppliers";
import {
  getPublicFeaturedRecintos,
  recintosSeleccionarDestacados,
  setUnsetRecintoDestacado,
} from "../../../API/APIRecintos";
import { CardFeaturedEnclosureComponent } from "../../../Components/CardFeaturedEnclosureComponent/CardFeaturedEnclosureComponent";
import { ChangeOrderFeaturedEnclosure } from "../../../Components/ChangeOrderFeaturedEnclosure/ChangeOrderFeaturedEnclosure";

export const AdminFeaturedEnclosuresScreen = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [value, setValue] = React.useState({});
  const [inputValue, setInputValue] = React.useState("");
  const [finalData, setFinalData] = React.useState([]);
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });
  const [finalFeaturedData, setFinalFeaturedData] = React.useState([]);
  React.useEffect(() => {
    recintosSeleccionarDestacados(null, user).then((resultado) => {
      setFinalData(resultado);
    });

    getPublicFeaturedRecintos().then((resultado) => {
      setFinalFeaturedData(resultado);
    });
  }, []);
  React.useEffect(() => {
    recintosSeleccionarDestacados(null, user).then((resultado) => {
      setFinalData(resultado);
    });

    getPublicFeaturedRecintos().then((resultado) => {
      setFinalFeaturedData(resultado);
    });
  }, [crudAction]);

  return (
    <>
      <div className="container">
        <div className="row my-3">
          <div className="col-md-10">
            <Autocomplete
              id="combo-box-demo"
              value={value}
              options={finalData}
              getOptionLabel={(option) => option.nombreRecinto}
              onChange={(event, newValue) => {
                 
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                 
                setInputValue(newInputValue);
              }}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Seleccionar Recinto"
                  variant="filled"
                />
              )}
            />
          </div>
          <div className="col-md-2">
            <div
              onClick={() => {
                setUnsetRecintoDestacado(
                  { idrecinto: value.id },
                  user,
                  value.id
                ).then((resultado) => {
                  setCrudAction({ action: null, data: resultado });
                });
              }}
              className="btn btn-lg btn-success w-100 mt-1"
            >
              Agregar
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row row-cols-md-3">
          {finalFeaturedData.map((evento) => (
            <CardFeaturedEnclosureComponent
              data={evento}
              crudAction={crudAction}
              setCrudAction={setCrudAction}
              admin={true}
              destacado={true}
            />
          ))}
        </div>
      </div>

      {crudAction.action === "changeOrder" ? (
        <>
          <ChangeOrderFeaturedEnclosure
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Recinto"
          />
        </>
      ) : null}
    </>
  );
};
