import React from "react";
import { getAdminUsers } from "../../../API/APIAdminUsers";
import { AuthContext } from "../../../auth/authContext";
import { AdminUsersListComponent } from "../../../Components/AdminUsersListComponent/AdminUsersListComponent";
import { CreateAdminUserComponent } from "../../../Components/CreateAdminUserComponent/CreateAdminUserComponent";
import { getCategoriasEvento } from "./../../../API/APiCategoriasEventos";

export const AdminUsersCatalogueScreen = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [crudType, setCrudType] = React.useState({
    action: null,
    data: [],
  });

  const [actualizarRegistros, setActualizarRegistros] = React.useState(0);

  React.useEffect(() => {
    let listo = false;

    getAdminUsers(user).then((resultado) => {
      setCrudType({
        action: "dataLoaded",
        data: resultado,
      });
    });
    return () => {
      listo = true;
    };
  }, []);
  React.useEffect(() => {
    let listo = false;

    getAdminUsers(user).then((resultado) => {
      setCrudType({
        action: "dataLoaded",
        data: resultado,
      });
    });
    return () => {
      listo = true;
    };
  }, [actualizarRegistros]);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12">
            <h4 className="text-success">{props.title}</h4>
            <p>{props.description}</p>
          </div>
        </div>
        <CreateAdminUserComponent
          crudType={crudType}
          setCrudType={setCrudType}
          setActualizarRegistros={setActualizarRegistros}
          actualizarRegistros={actualizarRegistros}
        />
      </div>
      <div className="container-fluid mt-3">
        <AdminUsersListComponent
          crudType={crudType}
          setCrudType={setCrudType}
          setActualizarRegistros={setActualizarRegistros}
          actualizarRegistros={actualizarRegistros}
        />
      </div>
    </>
  );
};
