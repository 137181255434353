import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "./../Images/noImageAvailable";

export const getEventosParaAsignar = async (values, user) => {
  let url = API + "eventos/getAEventos";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getEventosAsignados = async (values, user) => {
  let url = API + "eventos/getEventosAsignados/" + values;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const asignarNoAsignarEventoaUsuario = async (values, user) => {
  let url = API + "eventos/asignarEventoUsuario/";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
       
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
