import React from "react";
import { AuthContext } from "../../../auth/authContext";
import { getCategoriasEvento } from "./../../../API/APiCategoriasEventos";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { NoDataComponent } from "../../../Components/NoDataComponent/NoDataComponent";
import { CardAdminTypesComponent } from "../../../Components/CardAdminTypesComponent/CardAdminTypesComponent";
import { getTiposActividad } from "../../../API/APITiposActividad";
import { AddAdminTypesActivitiesScreen } from "./AddAdminTypesActivitiesScreen/AddAdminTypesActivitiesScreen";
import { EditAdminTypesActivitiesScreen } from "./EditAdminTypesActivitiesScreen/EditAdminTypesActivitiesScreen";
import { DeleteAdminTypesActivitiesScreen } from "./DeleteAdminTypesActivitiesScreen/DeleteAdminTypesActivitiesScreen";

export const AdminTypesActivitiesScreen = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [crudType, setCrudType] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getTiposActividad(null, user).then((resultado) => {
      setFinalData(resultado);
    });
  }, []);
  React.useEffect(() => {
    getTiposActividad(null, user).then((resultado) => {
      setFinalData(resultado);
    });
  }, [crudType]);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>
                En esta sección podrás subir los tipos de actividades para el
                programa de un evento
              </strong>
              <br></br>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudType({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar
              </button>
            </div>
          </div>
        </div>
      </div>
      {finalData && finalData.length ? (
        <>
          <div className="container-fluid">
            <div className="row row-cols-md-4 row-cols-sm-2">
              {finalData.map((data, index) => (
                <CardAdminTypesComponent
                  data={data}
                  key={index}
                  crudType={crudType}
                  setCrudType={setCrudType}
                  admin={true}
                  index={index}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <NoDataComponent nombre="tipos de actividades de evento" />{" "}
        </>
      )}

      {crudType.action === "add" ? (
        <>
          <AddAdminTypesActivitiesScreen
            crudType={crudType}
            setCrudType={setCrudType}
            nombre="Categoria"
          />
        </>
      ) : null}
      {crudType.action === "edit" ? (
        <>
          <EditAdminTypesActivitiesScreen
            crudType={crudType}
            setCrudType={setCrudType}
            nombre="Categoria"
          />
        </>
      ) : null}

      {crudType.action === "delete" ? (
        <>
          <DeleteAdminTypesActivitiesScreen
            idevento={props.id}
            crudType={crudType}
            setCrudType={setCrudType}
            nombre="Categoria"
          />
        </>
      ) : null}
    </>
  );
};
