/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import { AuthContext } from "../../auth/authContext";
import { UserDecode } from "../../helpers/UserDecode";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { types } from "../../types/types";
import { DateTime } from "luxon";
import { API } from "../../constants/ApiConnection";
import { NoDataComponent } from "../NoDataComponent/NoDataComponent";
import AddBoxIcon from "@material-ui/icons/AddBox";

import { CardPricingEventComponent } from "../CardPricingEventComponent/CardPricingEventComponent";
import { AddProgramDataEventComponent } from "./AddProgramDataEventComponent";
import { EditDataProgramDataEventComponent } from "./EditDataProgramDataEventComponent";
import { DeleteDataProgramDataEventComponent } from "./DeleteDataProgramDataEventComponent";
import { CardProgramEventComponent } from "../CardProgramEventComponent/CardProgramEventComponent";
import { AddPonentesToProgramComponent } from "../AddPonentesToProgramComponent/AddPonentesToProgramComponent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { AddPatrocinadoresToProgramComponent } from "../AddPatrocinadoresToProgramComponent/AddPatrocinadoresToProgramComponent";


export const EditProgramDataEventComponent = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const idusuarioPadre = UserDecode().idusuarioPadre;
  const [finalData, setFinalData] = React.useState([]);
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [finalDataActivities, setFinalDataActivities] = React.useState([]);

  React.useEffect(() => {
    getData().then((result) => {
      setFinalData(result);
    });
    getDataActivities().then((data) => {
      setFinalDataActivities(data);
    });
  }, []);

  React.useEffect(() => {
    getData().then((result) => {
      setFinalData(result);
    });
    getDataActivities().then((data) => {
      setFinalDataActivities(data);
    });
  }, [crudAction]);

  const getDataActivities = async () => {
    try {
      const response = await axios.get(
        API + "tipoActividad/getTipoActividad/",
        
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getData = async () => {
    try {
      const response = await axios.get(
        API + "programa/programaPorEvento/" + props.id,
        
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>
                En esta sección podrás subir las actividades de tu evento
              </strong>
              <br></br>
             
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar Actividad
              </button>
            </div>
          </div>
        </div>
      </div>
      {finalData && finalData.length ? (
        <>
          <div className="container-fluid">
            <div className="row row-cols-md-1 row-cols-sm-1">
              {finalData.map((data, index) => (
                <CardProgramEventComponent
                  data={data}
                  key={index}
                  crudAction={crudAction}
                  setCrudAction={setCrudAction}
                  admin={true}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <NoDataComponent nombre="Actividades en tu programa" />{" "}
        </>
      )}

      {crudAction.action === "add" ? (
        <>
          <AddProgramDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Actividad"
          />
        </>
      ) : null}
      {crudAction.action === "edit" ? (
        <>
          <EditDataProgramDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Actividad"
            finalDataActivities={finalDataActivities}
          />
        </>
      ) : null}
      {crudAction.action === "delete" ? (
        <>
          {" "}
          <DeleteDataProgramDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Actividad"
            finalDataActivities={finalDataActivities}
          />{" "}
        </>
      ) : null}
      {crudAction.action === "addPonentes" ? (
        <>
          <AddPonentesToProgramComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Ponentes de actividad"
            finalDataActivities={finalDataActivities}
          />
        </>
      ) : null}
      {crudAction.action === "addPatrocinadores" ? (
        <>
          <AddPatrocinadoresToProgramComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Patrocinadores de actividad"
            finalDataActivities={finalDataActivities}
          />
        </>
      ) : null}

     
    </>
  );
};
