/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import GetAppIcon from "@material-ui/icons/GetApp";
import { encode, decode } from "base64-arraybuffer";
import { getSubCategoriaEventos } from "../../API/APiCategoriasEventos";
import { AuthContext } from "../../auth/authContext";
import { AddSubCategorieScreen } from "../../screens/Private/EventCategoriesSubcategoriesTypesScreen/AddSubCategorieScreen/AddSubCategorieScreen";
import { EditSubCategorieScreen } from "../../screens/Private/EventCategoriesSubcategoriesTypesScreen/EditSubcategorieScreen/EditSubcategorieScreen";
import { DeleteSubCategorieScreen } from "../../screens/Private/EventCategoriesSubcategoriesTypesScreen/DeleteSubCategorieScreen/DeleteSubCategorieScreen";
export const CardAdminActivityTypes = (props) => {
 
  const { user, dispatch } = React.useContext(AuthContext);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [finalDataSubCategorie, setFinalDataSubCategorie] = React.useState([]);

  React.useEffect(() => {
    getSubCategoriaEventos(null, user, props.data.id).then((resultado) => {
      setFinalDataSubCategorie(resultado.data);
    });
  }, []);

  React.useEffect(() => {
    getSubCategoriaEventos(null, user, props.data.id).then((resultado) => {
      setFinalDataSubCategorie(resultado.data);
    });
  }, [crudAction]);

  return (
    <>
      <div className="">
        <div className="card mb-2">
          <div className="card-header bg-white d-flex align-items-center d-flex justify-content-between">
            <h6 className="mt-2 text-muted">Categoría Evento</h6>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-sm text-white btn-warning"
                type="button"
                onClick={() =>
                  props.setCrudAction({ action: "edit", data: props.data })
                }
              >
                Editar
              </button>
              <button
                className="btn btn-sm text-white btn-danger d-none"
                type="button"
                onClick={() =>
                  props.setCrudAction({ action: "delete", data: props.data })
                }
              >
                Eliminar
              </button>
            </div>
          </div>
          <div className="card-body">
            <strong>
              {props.index + 1}.- {props.data.nombreCategoriaEvento}{" "}
            </strong>

            <hr />

            <div className="d-flex justify-content-between align-items-center">
              <h6>Subcategorías: </h6>
              <button
                className="btn btn-sm btn-success"
                type="button"
                onClick={() =>
                  setCrudAction({ action: "add", data: props.data })
                }
              >
                Agregar
              </button>
            </div>
            <br />
            {finalDataSubCategorie.map((subcategoria, index) => (
              <div
                key={index}
                className="d-flex justify-content-between align-items-center my-2"
              >
                {subcategoria.nombreSubcategoria}
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button
                    className="btn btn-sm btn-warning me-md-2"
                    type="button"
                    onClick={() => {
                      setCrudAction({ action: "edit", data: subcategoria })
                    }}
                  >
                    Editar
                  </button>
                  <button
                    className="btn btn-sm btn-danger"
                    type="button"
                    onClick={() => {
                      setCrudAction({ action: "delete", data: subcategoria })
                    }}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {crudAction.action === "add" ? (
        <>
          <AddSubCategorieScreen
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Subcategoría"
            nombreCategoria={props.data.nombre}
          />
        </>
      ) : null}
      {crudAction.action === "edit" ? (
        <>
          <EditSubCategorieScreen
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Subcategoría"
          />
        </>
      ) : null}
       {crudAction.action === "delete" ? (
        <>
          {" "}
          <DeleteSubCategorieScreen
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Tipo actividad"
          />{" "}
        </>
      ) : null}

    </>
  );
};
