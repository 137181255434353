import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";
import {
  asignarPonenteAActividad,
  GetPonentesPorActividad,
  getPonentesPorEvento,
} from "../../API/APIPonentesEventos";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";
import { CardPonentComponent } from "../CardPonentComponent/CardPonentComponent";
export const AddPonentesToProgramComponent = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const [value, setValue] = React.useState({});
  const [inputValue, setInputValue] = React.useState("");
  const [finalData, setFinalData] = React.useState([]);
  const [ponentesPorActividad, setPonentesEnActividad] = React.useState([]);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getPonentesPorEvento(null, user, props.idevento).then((resultado) => {
      setFinalData(resultado);
    });

    GetPonentesPorActividad(null, user, props.crudAction.data.id).then(
      (resultado) => {
         
        setPonentesEnActividad(resultado.data);
      }
    );
  }, []);

  React.useEffect(() => {
    getPonentesPorEvento(null, user, props.idevento).then((resultado) => {
      setFinalData(resultado);
    });

    GetPonentesPorActividad(null, user, props.crudAction.data.id).then(
      (resultado) => {
         
        setPonentesEnActividad(resultado.data);
      }
    );
  }, [crudAction]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };
  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div className="crudDivComponent col-md-6">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h5>Agregar {props.nombre}</h5>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudAction({ action: null, data: null })
                    }
                  >
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-12">
              <p>Busca el ponente para agregarlo</p>
            </div>
            <div className="col-md-10 mb-2">
              <Autocomplete
                id="combo-box-demo"
                fullWidth
                value={value}
                options={finalData}
                getOptionLabel={(option) => option.nombre + " " +option.apellido}
                style={{ zIndex: 900000 }}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => {
                  setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Combo box"
                    variant="standard"
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="col-md-2">
              <div
                className="btn btn-success"
                onClick={() => {
                  asignarPonenteAActividad(
                    {
                      idactividadPrograma: props.crudAction.data.id,
                      idponente: value.id,
                    },
                    user
                  ).then((resultado) => {
                    if (resultado.status === 200) {
                      toast.success("Ponente Actualizado");
                      setCrudAction({action: 'actalizando' , data: null})
                    }
                    if (resultado.status === 401) {
                      dispatch({
                        type: types.logout,
                      });
                    }
                  });
                }}
              >
                Alternar Ponente
              </div>
            </div>

            <div className="row row-cols-sm-4">
              {ponentesPorActividad.map((ponente, index) => (
                <CardPonentComponent
                  key={index}
                  data={ponente}
                  crudAction={crudAction}
                  setCrudAction={setCrudAction}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
