import React from "react";
import {
  getAllEventsPublic,
  getPublicFeaturedEvents,
  getPublicProximos,
} from "../../API/APIEventos";
import { CardFeaturedEnclosureComponent } from "../CardFeaturedEnclosureComponent/CardFeaturedEnclosureComponent";
import { CardFeaturedProductComponent } from "../CardFeaturedProductComponent/CardFeaturedProductComponent";
import { LoadingThreeCardsComponent } from "../LoadingThreeCardsComponent/LoadingThreeCardsComponent";
import { getAllPublicRecintos, getAllRecintos } from "./../../API/APIRecintos";

export const SearchCompundsResultComponent = (props) => {
  const [finalData, setFinalData] = React.useState(props.data);

  return (
    <>
      {finalData.length === 0 ? <> No encontramos resultados </> : null}
      <div className="container">
        <div className="row">
          {finalData.map((recinto, index) => (
            <CardFeaturedEnclosureComponent
              key={index}
              data={recinto}
              admin={false}
              destacado={false}
            />
          ))}
        </div>
      </div>
    </>
  );
};
