import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import InfoIcon from "@material-ui/icons/Info";
import { EditGeneralDataEnclosure } from "../../../Components/EditGeneralDataEnclosure/EditGeneralDataEnclosure";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import { EditGalleryDataEnclosure } from "../../../Components/EditGalleryDataEnclosure/EditGalleryDataEnclosure";
import MapIcon from "@material-ui/icons/Map";
import { EditPlanoDataEnclosureComponent } from "../../../Components/EditPlanoDataEnclosureComponent/EditPlanoDataEnclosureComponent";
import { EditUbicacionesRecientesDataEnclosureComponent } from "../../../Components/EditUbicacionesRecientesDataEnclosureComponent/EditUbicacionesRecientesDataEnclosureComponent";
import { EditImageEnclosureComponent } from "../../../Components/EditImageEnclosureComponent/EditImageEnclosureComponent";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import { EditEspaciosDataEnclosureComponent } from "../../../Components/EditEspaciosDataEnclosureComponent/EditEspaciosDataEnclosureComponent";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import { EditServiciosDataEnclosureComponent } from "../../../Components/EditServiciosDataEnclosureComponent/EditServiciosDataEnclosureComponent";
import CategoryIcon from "@material-ui/icons/Category";
import { EditAreasServiciosDataEnclosureComponent } from "../../../Components/EditAreasServiciosDataEnclosureComponent/EditAreasServiciosDataEnclosureComponent";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import { EditDirectorioDataEnclosureComponent } from "./../../../Components/EditDirectorioDataEnclosureComponent/EditDirectorioDataEnclosureComponent";
import { checkViewPermission } from "../../../API/APIPermisos";
import { AuthContext } from "../../../auth/authContext";
import PanToolIcon from "@material-ui/icons/PanTool";
import { SinPermisoParaAcceder } from "../../Public/SinPermisoParaAcceder/SinPermisoParaAcceder";
import { LoremComponent } from "./../../../Components/LoremComponent/LoremComponent";
const uuidVista = "d2860cda-3d81-48c3-b3b9-371543da5ec3";

export const EnclosureEditScreen = () => {
  const [idRecinto, setIdRecinto] = React.useState(useParams().id);
  const { user, dispatch } = React.useContext(AuthContext);

  const [resultadoPermisos, setResultadoPermisos] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const [statusPermiso, setStatusPermiso] = React.useState(0);

  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [permisoDatosGenerales, setPermisoDatosGenerales] =
    React.useState(false);
  const [permisoImagenRecinto, setPermisoImagenRecinto] = React.useState(false);
  const [permisoGaleria, setPermisoGaleria] = React.useState(false);
  const [permisoPlano, setPermisoPlano] = React.useState(false);
  const [permisoUbicacionesCercanas, setPermisoUbicacionesCercanas] =
    React.useState(false);
  const [permisoEspacios, setPermisoEspacios] = React.useState(false);
  const [permisoDirectorio, setPermisoDirectorio] = React.useState(false);
  const [permisoServiciosOfrecidos, setPermisoServiciosOfrecidos] =
    React.useState(false);
  const [permisoAreasDeServicio, setPermisoAreasDeServicio] =
    React.useState(false);

  React.useEffect(() => {
    checkViewPermission(uuidVista, user).then((resultado) => {
      setResultadoPermisos(resultado.data);
      setStatusPermiso(resultado.status);
      for (const permiso of resultado.data) {
        if (
          permiso.guidFuncion == "5ede007b-c17e-48e8-8134-7f1fc3de36fe" &&
          permiso.allowed == 1
        ) {
          setPermisoParaEditar(true);
        }
        if (
          permiso.guidFuncion == "4a97e572-bf9b-4380-bcbd-81b38c37a9a5" &&
          permiso.allowed == 1
        ) {
          setPermisoDatosGenerales(true);
        }
        if (
          permiso.guidFuncion == "58d3a1fa-1785-464e-b2d5-61443c1cecad" &&
          permiso.allowed == 1
        ) {
          setPermisoImagenRecinto(true);
        }
        if (
          permiso.guidFuncion == "dfaa98cb-09a3-4b52-a953-e5b8da9ff861" &&
          permiso.allowed == 1
        ) {
          setPermisoGaleria(true);
        }
        if (
          permiso.guidFuncion == "becbb8be-e321-4962-94ef-9e1976c5c813" &&
          permiso.allowed == 1
        ) {
          setPermisoPlano(true);
        }
        if (
          permiso.guidFuncion == "08e950f9-878b-42ef-8e91-bfd0a7d58097" &&
          permiso.allowed == 1
        ) {
          setPermisoUbicacionesCercanas(true);
        }
        if (
          permiso.guidFuncion == "27b9528f-b080-47f6-b638-3aa4297dcd93" &&
          permiso.allowed == 1
        ) {
          setPermisoEspacios(true);
        }
        if (
          permiso.guidFuncion == "ab38ccae-7fc6-441b-866f-1b674759e5e0" &&
          permiso.allowed == 1
        ) {
          setPermisoDirectorio(true);
        }
        if (
          permiso.guidFuncion == "8ef78934-29c0-4ee1-b4a6-a7f8f5d356e0" &&
          permiso.allowed == 1
        ) {
          setPermisoServiciosOfrecidos(true);
        }
        if (
          permiso.guidFuncion == "b4fa0212-969d-4c53-ad76-e1b9f56d9bb6" &&
          permiso.allowed == 1
        ) {
          setPermisoAreasDeServicio(true);
        }
      }
    });
  }, []);

  return (
    <>
      {permisoParaEditar === false ? (
        <SinPermisoParaAcceder />
      ) : (
        <>
          <nav>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <button
                className="nav-link active"
                id="nav-bienvenida-tab"
                data-bs-toggle="tab"
                data-bs-target="#nav-bienvenida"
                type="button"
                role="tab"
                aria-controls="nav-bienvenida"
                aria-selected="true"
              >
                <PanToolIcon fontSize="inherit" className="me-1" /> Bienvenido
              </button>
              {permisoDatosGenerales === true ? (
                <button
                  className="nav-link"
                  id="nav-home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-home"
                  type="button"
                  role="tab"
                  aria-controls="nav-home"
                  aria-selected="true"
                >
                  <InfoIcon fontSize="inherit" className="me-1" /> Datos
                  Generales
                </button>
              ) : null}
              {permisoImagenRecinto === true ? (
                <button
                  className="nav-link "
                  id="nav-imagenrecinto-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-imagenrecinto"
                  type="button"
                  role="tab"
                  aria-controls="nav-imagenrecinto"
                  aria-selected="true"
                >
                  <InfoIcon fontSize="inherit" className="me-1" /> Imagen de
                  recinto
                </button>
              ) : null}
              {permisoGaleria === true ? (
                <button
                  className="nav-link"
                  id="nav-galeria-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-galeria"
                  type="button"
                  role="tab"
                  aria-controls="nav-galeria"
                  aria-selected="true"
                >
                  <CameraAltIcon fontSize="inherit" className="me-1" /> Galería
                </button>
              ) : null}
              {permisoPlano === true ? (
                <button
                  className="nav-link"
                  id="nav-plano-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-plano"
                  type="button"
                  role="tab"
                  aria-controls="nav-plano"
                  aria-selected="true"
                >
                  <MapIcon fontSize="inherit" className="me-1" /> Plano
                </button>
              ) : null}
              {permisoUbicacionesCercanas === true ? (
                <button
                  className="nav-link"
                  id="nav-ubicacionesrecientes-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-ubicacionesrecientes"
                  type="button"
                  role="tab"
                  aria-controls="nav-ubicacionesrecientes"
                  aria-selected="true"
                >
                  <MapIcon fontSize="inherit" className="me-1" /> Ubicaciones
                  Cercanas
                </button>
              ) : null}
              {permisoEspacios === true ? (
                <button
                  className="nav-link"
                  id="nav-espacios-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-espacios"
                  type="button"
                  role="tab"
                  aria-controls="nav-espacios"
                  aria-selected="true"
                >
                  <ViewModuleIcon fontSize="inherit" className="me-1" />{" "}
                  Espacios
                </button>
              ) : null}
              {permisoDirectorio === true ? (
                <button
                  className="nav-link"
                  id="nav-directorio-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-directorio"
                  type="button"
                  role="tab"
                  aria-controls="nav-directorio"
                  aria-selected="true"
                >
                  <ContactPhoneIcon fontSize="inherit" className="me-1" />{" "}
                  Directorio
                </button>
              ) : null}
              {permisoServiciosOfrecidos === true ? (
                <button
                  className="nav-link"
                  id="nav-servicios-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-servicios"
                  type="button"
                  role="tab"
                  aria-controls="nav-servicios"
                  aria-selected="true"
                >
                  <RoomServiceIcon fontSize="inherit" className="me-1" />{" "}
                  Servicios Ofrecidos
                </button>
              ) : null}

              {permisoAreasDeServicio === true ? (
                <button
                  className="nav-link"
                  id="nav-areasdeservicio-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-areasdeservicio"
                  type="button"
                  role="tab"
                  aria-controls="nav-areasdeservicio"
                  aria-selected="true"
                >
                  <CategoryIcon fontSize="inherit" className="me-1" /> Áreas de
                  Servicio
                </button>
              ) : null}
            </div>
          </nav>
          <div className="tab-content bg-white pt-3 p-2" id="nav-tabContent">
            {permisoDatosGenerales === true ? (
              <div
                className="tab-pane fade"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <EditGeneralDataEnclosure idrecinto={idRecinto} />
              </div>
            ) : null}
            {permisoImagenRecinto === true ? (
              <div
                className="tab-pane fade "
                id="nav-imagenrecinto"
                role="tabpanel"
                aria-labelledby="nav-imagenrecinto-tab"
              >
                <EditImageEnclosureComponent idrecinto={idRecinto} />
              </div>
            ) : null}

            {permisoGaleria === true ? (
              <div
                className="tab-pane fade  "
                id="nav-galeria"
                role="tabpanel"
                aria-labelledby="nav-galeria-tab"
              >
                <EditGalleryDataEnclosure idrecinto={idRecinto} />
              </div>
            ) : null}

            {permisoPlano === true ? (
              <div
                className="tab-pane fade  "
                id="nav-plano"
                role="tabpanel"
                aria-labelledby="nav-plano-tab"
              >
                <EditPlanoDataEnclosureComponent idrecinto={idRecinto} />
              </div>
            ) : null}
            {permisoUbicacionesCercanas === true ? (
              <div
                className="tab-pane fade  "
                id="nav-ubicacionesrecientes"
                role="tabpanel"
                aria-labelledby="nav-ubicacionesrecientes-tab"
              >
                <EditUbicacionesRecientesDataEnclosureComponent
                  idrecinto={idRecinto}
                />
              </div>
            ) : null}
            {permisoEspacios === true ? (
              <div
                className="tab-pane fade  "
                id="nav-espacios"
                role="tabpanel"
                aria-labelledby="nav-espacios-tab"
              >
                <EditEspaciosDataEnclosureComponent idrecinto={idRecinto} />
              </div>
            ) : null}

            {permisoServiciosOfrecidos === true ? (
              <div
                className="tab-pane fade  "
                id="nav-servicios"
                role="tabpanel"
                aria-labelledby="nav-espacios-tab"
              >
                <EditServiciosDataEnclosureComponent idrecinto={idRecinto} />
              </div>
            ) : null}
            {permisoAreasDeServicio === true ? (
              <div
                className="tab-pane fade  "
                id="nav-areasdeservicio"
                role="tabpanel"
                aria-labelledby="nav-espacios-tab"
              >
                <EditAreasServiciosDataEnclosureComponent
                  idrecinto={idRecinto}
                />
              </div>
            ) : null}

            {permisoDirectorio === true ? (
              <div
                className="tab-pane fade "
                id="nav-directorio"
                role="tabpanel"
                aria-labelledby="nav-directorio-tab"
              >
                <EditDirectorioDataEnclosureComponent idrecinto={idRecinto} />
              </div>
            ) : null}

            <div
              className="tab-pane fade show active p-3"
              id="nav-bienvenida"
              role="tabpanel"
              aria-labelledby="nav-bienvenida-tab"
            >
              <h4>Formato de Bienvenida a la edición del recinto</h4>
              <p>
                <LoremComponent />
              </p>
              <p>
                <LoremComponent />
              </p>
          
            </div>
          </div>
        </>
      )}
    </>
  );
};
