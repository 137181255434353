import React from "react";
import { AuthContext } from "../../../auth/authContext";
import { CardAdminEnclosureType } from "../../../Components/CardAdminEnclosureType/CardAdminEnclosureType";
import { NoDataComponent } from "../../../Components/NoDataComponent/NoDataComponent";
import { UserDecode } from "../../../helpers/UserDecode";
import { getCategoriasRecinto } from "./../../../API/APICategoriasRecinto";
import AddBoxIcon from "@material-ui/icons/AddBox";

import { useFormik } from "formik";
import { toast } from "react-toastify";

import { AddAdminSupplierCategories } from "./AddAdminSupplierCategories";
import { EditAdminSupplierCategories } from "./EditAdminSupplierCategories";
import { DeleteAdminSupplierCategories } from "./DeleteAdminSupplierCategories";
import { getCategoriasProveedor } from "../../../API/APIProveedores";
import { CardAdminSupplierType } from "../../../Components/CardAdminSupplierType/CardAdminSupplierType";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AddAdminSupplierSubcategorie } from "./AddAdminSupplierSubcategorie";

export const AdminSupplierCategories = () => {
  const [finalData, setFinalData] = React.useState([]);
  const { user, dispatch } = React.useContext(AuthContext);

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getCategoriasProveedor(null, user).then((resultado) => {
      setFinalData(resultado.data);
    });
  }, []);
  React.useEffect(() => {
    getCategoriasProveedor(null, user).then((resultado) => {
      setFinalData(resultado.data);
    });
  }, [crudAction]);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>
                En esta sección podrás subir las categorías para los Proveedores
              </strong>
              <br></br>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar categorías
              </button>
            </div>
          </div>
        </div>
      </div>

      {finalData && finalData.length ? (
        <>
          <div className="container-fluid">
            <ResponsiveMasonry
              columnsCountBreakPoints={{ 350: 1, 750: 1, 900: 1 }}
            >
              <Masonry gutter="10px">
                {finalData.map((data, index) => (
                  <>
                    <CardAdminSupplierType
                      data={data}
                      key={index}
                      crudAction={crudAction}
                      setCrudAction={setCrudAction}
                      admin={true}
                      index={index}
                      nombre={"Proveedor"}
                    />
                  </>
                ))}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        </>
      ) : (
        <>
          <NoDataComponent nombre="Categorías de proveedores" />{" "}
        </>
      )}

      {crudAction.action === "add" ? (
        <>
          <AddAdminSupplierCategories
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Categoria"
          />
        </>
      ) : null}
      {crudAction.action === "edit" ? (
        <>
          <EditAdminSupplierCategories
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Categoria"
          />
        </>
      ) : null}
      {crudAction.action === "delete" ? (
        <>
          <DeleteAdminSupplierCategories
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Categoria"
          />
        </>
      ) : null}

      {crudAction.action === "addSubCategorie" ? (
        <>
          <AddAdminSupplierSubcategorie
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Sub Categoria"
          />
        </>
      ) : null}
    </>
  );
};
