import React from "react";
import { DateTime } from "luxon";
import DateRangeIcon from "@material-ui/icons/DateRange";
import HomeIcon from "@material-ui/icons/Home";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale from "dayjs/locale/es-mx";
import { getPublicOrganizadoresEvento } from "../../API/APIOrganizadoresEvento";
import { CardOrganizadorEventoComponent } from "../CardOrganizadorEventoComponent/CardOrganizadorEventoComponent";

export const InformationEventDetail = (props) => {
  dayjs.extend(localizedFormat);
  const [organizadoresFinal, setOrganizadoresFinal] = React.useState([]);

  React.useEffect(() => {
    getPublicOrganizadoresEvento(null, props.idEvento).then((resultado) => {
      setOrganizadoresFinal(resultado);
    });
  }, []);

  return (
    <>
      <h6>
        <i className="fas fa-info-circle"></i>&nbsp;&nbsp;Información
      </h6>

      <hr></hr>

      <div className="list-group list-group-flush fillInformationEvent">
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Categoría: </strong>
          </small>

          <br />
          {props.data.nombreCategoriaEvento}
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Subcategoría: </strong>
          </small>

          <br />
          {props.data.nombreSubcategoria}
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong>
              <DateRangeIcon
                style={{ color: "#34B7F1" }}
                fontSize="inherit"
                className="me-1"
              />
              Inicio:{" "}
            </strong>
          </small>{" "}
          <br />
          {dayjs(props.data.fechaInicio).locale("es-mx").format("lll")}
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong>
              <DateRangeIcon
                style={{ color: "green" }}
                fontSize="inherit"
                className="me-1"
              />
              Término:{" "}
            </strong>
          </small>{" "}
          <br />
          {dayjs(props.data.fechaFin).locale("es-mx").format("lll")}
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong>
              <HomeIcon
                style={{ color: "gray" }}
                fontSize="inherit"
                className="me-1"
              />{" "}
              Recinto:{" "}
            </strong>
          </small>{" "}
          <br /> {props.data.recinto}
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong>
              <HomeIcon
                style={{ color: "gray" }}
                fontSize="inherit"
                className="me-1"
              />{" "}
              Ubicación de recinto:{" "}
            </strong>
          </small>{" "}
          <br /> {props.data.ubicacionRecinto}
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Ciudad: </strong>
          </small>{" "}
          <br /> {props.data.nombreCiudad}
        </div>
        <div className="list-group-item list-group-item-action">
          <small>
            <strong> Estado: </strong>
          </small>
          <br /> {props.data.nombreEstado}
        </div>
        <div className="list-group-item list-group-item-action">
          <small className="text-warning">
            <strong> Limitantes: </strong>
          </small>
          <br /> {props.data.limitantes}
        </div>

        {/*

        <div className="list-group-item list-group-item-action text-success">
          <small>
            <strong>
              <i className="fas fa-ticket-alt"></i>&nbsp; &nbsp; Boletos
              Disponibles:
            </strong>
          </small>
          <br /> 8
        </div>
        <div className="list-group-item list-group-item-action text-info">
          <small>
            <strong> Precios desde: </strong>
          </small>
          <br /> $100.00
        </div>*/}

        {organizadoresFinal.length > 0 ? (
          <>
            <div className="list-group-item list-group-item-action ">
              <small>
                <strong> Organizado por: </strong>
              </small>
              <div className="container-fluid mt-3">
                <div className="row row-cols-md-2">
                  {organizadoresFinal.map((organizador, index) => (
                    <CardOrganizadorEventoComponent
                      data={organizador}
                      key={index}
                      public={true}
                    />
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : null}

        {/*
          <div className="list-group-item list-group-item-action ">
            <small>
              <strong> Operado por: </strong>
            </small>
            <strong>
              {" "}
              <img
                className="mt-2 mb-1 d-block"
                src="https://picsum.photos/500/500?random=500"
                height="50"
              />
              Organizador del Curso
            </strong>
          </div>*/}
      </div>
    </>
  );
};
