import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { setNewPasswordAdmin } from "../../API/APIAdminUsers";
import { AuthContext } from "../../auth/authContext";
import { toast } from "react-toastify";

const validationSchema = yup.object({
  password: yup
    .string("Ingresa tu contraseña")
    .min(8, "La contraseña deberá tener mínimo 8 caracteres")
    .required("Contraseña requerido"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las Contraseñas deben coincidir"),
});

export const ChangePasswordComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [disabled, setDisabled] = React.useState(false);

  const [isopen, setIsOpen] = React.useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
      id: props.id,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      var detalles = props;
      setDisabled(true);
      debugger;

      setNewPasswordAdmin(values, user).then((resultado) => {
        if (resultado.status == 200) {
          toast.success("Contraseña Creada correctamente");
          setIsOpen(false);
          formik.resetForm();
          props.crudType.setActualizarRegistros(Math.random() * 30);
        }

        setDisabled(false);
      });
    },
  });

  return (
    <>
      <div
        className="btn btn-sm btn-info"
        onClick={() => {
          setIsOpen(!isopen);
        }}
      >
        {isopen ? <> Cerrar </> : <>Restablecer Contraseña</>}
      </div>
      {isopen === true ? (
        <>
          <form onSubmit={formik.handleSubmit} className="mt-3">
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Nueva Contraseña"
              type="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
            <TextField
              fullWidth
              id="passwordConfirmation"
              name="passwordConfirmation"
              label="Confirma Contraseña"
              type="password"
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              error={
                formik.touched.passwordConfirmation &&
                Boolean(formik.errors.passwordConfirmation)
              }
              helperText={
                formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation
              }
            />
            <br></br>
            <br></br>
            <Button color="primary" variant="contained" fullWidth type="submit">
              Cambiar Contraseña
            </Button>
          </form>
        </>
      ) : null}
    </>
  );
};
