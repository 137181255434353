import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import { toast } from "react-toastify";
import { React } from "react";
import { useState } from "react";

export const HorariosAtencionComponent = (props) => {
  const [horaInicioPosibilidades, sethoraInicioPosibilidades] = useState([
    {
      hora: "00:00",
      estado: 1,
    },
    {
      hora: "01:00",
      estado: 1,
    },
    {
      hora: "02:00",
      estado: 1,
    },
    {
      hora: "03:00",
      estado: 1,
    },
    {
      hora: "04:00",
      estado: 1,
    },
    {
      hora: "05:00",
      estado: 1,
    },
    {
      hora: "06:00",
      estado: 1,
    },
    {
      hora: "07:00",
      estado: 1,
    },
    {
      hora: "08:00",
      estado: 1,
    },
    {
      hora: "09:00",
      estado: 1,
    },
    {
      hora: "10:00",
      estado: 1,
    },
    {
      hora: "11:00",
      estado: 1,
    },
    {
      hora: "12:00",
      estado: 1,
    },
    {
      hora: "13:00",
      estado: 1,
    },
    {
      hora: "14:00",
      estado: 1,
    },
    {
      hora: "15:00",
      estado: 1,
    },
    {
      hora: "16:00",
      estado: 1,
    },
    {
      hora: "17:00",
      estado: 1,
    },
    {
      hora: "18:00",
      estado: 1,
    },
    {
      hora: "19:00",
      estado: 1,
    },
    {
      hora: "20:00",
      estado: 1,
    },
    {
      hora: "21:00",
      estado: 1,
    },
    {
      hora: "22:00",
      estado: 1,
    },
    {
      hora: "23:00",
      estado: 1,
    },
  ]);

  const [horaFinPosibilidades, sethoraFinPosibilidades] = useState([
    {
      hora: "00:00",
      estado: 1,
    },
    {
      hora: "01:00",
      estado: 1,
    },
    {
      hora: "02:00",
      estado: 1,
    },
    {
      hora: "03:00",
      estado: 1,
    },
    {
      hora: "04:00",
      estado: 1,
    },
    {
      hora: "05:00",
      estado: 1,
    },
    {
      hora: "06:00",
      estado: 1,
    },
    {
      hora: "07:00",
      estado: 1,
    },
    {
      hora: "08:00",
      estado: 1,
    },
    {
      hora: "09:00",
      estado: 1,
    },
    {
      hora: "10:00",
      estado: 1,
    },
    {
      hora: "11:00",
      estado: 1,
    },
    {
      hora: "12:00",
      estado: 1,
    },
    {
      hora: "13:00",
      estado: 1,
    },
    {
      hora: "14:00",
      estado: 1,
    },
    {
      hora: "15:00",
      estado: 1,
    },
    {
      hora: "16:00",
      estado: 1,
    },
    {
      hora: "17:00",
      estado: 1,
    },
    {
      hora: "18:00",
      estado: 1,
    },
    {
      hora: "19:00",
      estado: 1,
    },
    {
      hora: "20:00",
      estado: 1,
    },
    {
      hora: "21:00",
      estado: 1,
    },
    {
      hora: "22:00",
      estado: 1,
    },
    {
      hora: "23:00",
      estado: 1,
    },
  ]);

  return (
    <>
      <div className="row d-flex align-items-center my-2">
        <div
          onClick={() => {
            props.setHorariosAtencion((horariosAtencion) => [
              ...props.horariosAtencion,
              {
                id: 1,
                nombre: "Lunes",
                horaInicio: "00:00",
                horaFin: "00:00",
              },
            ]);
            toast.success("agregando un día más");
          }}
          className="btn btn-sm btn-success"
        >
          Agregar un día
        </div>
      </div>

      {props.horariosAtencion.map((dia, key) => (
        <>
          <div className="row d-flex align-items-center">
            <div className="col-sm-3">
              <FormControl className={`mb-3`} fullWidth>
                <InputLabel htmlFor="age-native-simple">Día</InputLabel>
                <Select
                  native
                  value={dia.nombre}
                  onChange={(e) => {
                    let arregloHorarios = [...props.horariosAtencion];
                    arregloHorarios[key].nombre = e.target.value;
                    props.setHorariosAtencion(arregloHorarios);
                  }}
                  inputProps={{
                    name: "age",
                    id: "age-native-simple",
                  }}
                >
                  <option value={"Lunes"}>Lunes</option>
                  <option value={"Martes"}>Martes</option>
                  <option value={"Miércoles"}>Miércoles</option>
                  <option value={"Jueves"}>Jueves</option>
                  <option value={"Viernes"}>Viernes</option>
                  <option value={"Sábado"}>Sábado</option>
                  <option value={"Domingo"}>Domingo</option>
                </Select>
              </FormControl>
            </div>
            <div className="col-sm-3">
              <FormControl className={`mb-3`} fullWidth>
                <InputLabel htmlFor="age-native-simple">Hora Inicio</InputLabel>
                <Select
                  native
                  value={dia.horaInicio}
                  onChange={(e) => {
                    let arregloHorarios = [...props.horariosAtencion];
                    arregloHorarios[key].horaInicio = e.target.value;
                    props.setHorariosAtencion(arregloHorarios);
                  }}
                  inputProps={{
                    name: "age",
                    id: "age-native-simple",
                  }}
                >
                  {horaInicioPosibilidades.map((hora, key) => (
                    <>
                      <option value={hora.hora}>{hora.hora}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-sm-3">
              <FormControl className={`mb-3`} fullWidth>
                <InputLabel htmlFor="age-native-simple">Hora Fin</InputLabel>
                <Select
                  native
                  value={dia.horaFin}
                  onChange={(e) => {
                    let arregloHorarios = [...props.horariosAtencion];
                    arregloHorarios[key].horaFin = e.target.value;
                    props.setHorariosAtencion(arregloHorarios);
                  }}
                  inputProps={{
                    name: "age",
                    id: "age-native-simple",
                  }}
                >
                  {horaFinPosibilidades.map((hora, key) => (
                    <>
                      <option value={hora.hora}>{hora.hora}</option>
                    </>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-md-3">
              <div
                className="btn-group btn-sm w-100"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  classsName="btn btn-sm btn-success"
                  onClick={() => {
                    props.setHorariosAtencion((horariosAtencion) => [
                      ...props.horariosAtencion,
                      {
                        id: 1,
                        nombre: "Lunes",
                        horaInicio: "00:00",
                        horaFin: "00:00",
                      },
                    ]);
                    toast.success("agregando un día más");
                  }}
                >
                  Agregar otro día
                </button>

                <button
                  type="button"
                  classsName="btn btn-sm btn-danger"
                  onClick={() => {
                    var arregloActual = dia;
                    let arregloHorarios = [...props.horariosAtencion];
                    var llave = key;
                    arregloHorarios.splice(llave, 1);
                    props.setHorariosAtencion(arregloHorarios);
                  }}
                >
                  Eliminar
                </button>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};
