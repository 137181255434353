import { useFormik } from "formik";
import React from "react";
import styles from "./Form.module.css";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { API } from "./../../../../constants/ApiConnection";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../../../auth/authContext";
import { types } from "./../../../../types/types";
const validationSchema = yup.object({
  email: yup
    .string("Ingresa Correo")
    .email("Ingresa un correo válido")
    .required("Correo Necesario"),
  password: yup
    .string("Ingresa Contraseña")
    .min(8, "8 caracteres como mínimo para la contraseña")
    .required("La contraseña es necesaria"),
  nombreUsuario: yup
    .string("Ingresa nombre de empresa")
    .required("El nombre de empresa es necesario"),
});

export const Form = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [urlApi, setUrlApi] = React.useState();

  const [typeFunction, setTypeFunction] = React.useState(
    props.isFormOpen.formFunction
  );
  const [classForm, setClassForm] = React.useState("");

  React.useEffect(() => {
    if (props.isFormOpen.formFunction === "Agregar") {
      setClassForm("text-success");
      setUrlApi("admin/createUser");
    }
    if (props.isFormOpen.formFunction === "Editar") {
      setClassForm("text-warning");
      setUrlApi("admin/updateUser/");
      getDetail(props.isFormOpen.id);
    }
    if (props.isFormOpen.formFunction === "Eliminar") {
      setClassForm("text-danger");
      setUrlApi("admin/logicalDelete/");
    }
  }, []);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setIsFormOpen({
        id: null,
        visible: false,
        formFuncion: null,
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      nombreUsuario: "foobar@example.com",
      email: "foobar@dominio.com",
      password: "12345678",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const url = API + urlApi;

      APIData(url, values);

//      alert(JSON.stringify(values, null, 2));
    },
  });

  const APIData = async (url, values) => {
    toast.info("Preparando: " + typeFunction, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });

    

    if (typeFunction === "Agregar") {
      try {
        const response = await axios.post(url, values, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });

        toast.success("Registro Agregado Correctamente!  ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        props.setIsFormOpen({
          id: null,
          visible: false,
          formFuncion: null,
        });
      } catch (error) {
        console.warn(error.response);
        toast(error.response.data.message);
        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
    if (typeFunction === "Editar") {
      const urltoEdit = url + props.isFormOpen.id;
      try {
        const response = await axios.put(urltoEdit, values, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });

        toast.success("Registro Editado Correctamente!  ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        props.setIsFormOpen({
          id: null,
          visible: false,
          formFuncion: null,
        });
      } catch (error) {
        console.warn(error.response);
        toast(error.response.data.message);
        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
    if (typeFunction === "Eliminar") {
      const urltoEdit = url + props.isFormOpen.id;

      
      try {
        const response = await axios.put(urltoEdit, values, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        });

        toast.success("Registro Eliminado Correctamente!  ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });

        props.setIsFormOpen({
          id: null,
          visible: false,
          formFuncion: null,
        });
      } catch (error) {
        console.warn(error.response);
        toast(error.response.data.message);
        if (error.response.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    }
  };

  const getDetail = async (id) => {
    try {
      const response = await axios.get(API + "admin/getAdminUserDetail/" + id, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });

      formik.setFieldValue("nombreUsuario", response.data.nombreUsuario);
      formik.setFieldValue("email", response.data.email);

     
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/login");
      }
    }
  };

  return (
    <div
      className={`${styles.FormCrudScreenComponent} col-md-6 col-sm-12 animate_animated animate_bounce`}
    >
      <div className="FormCrudScreenComponent">
        <div className="container-fluid p-5">
          <div className="row mt-3">
            <div className="col mt-1">
              <h5 className={classForm}>
                <strong>{props.isFormOpen.formFunction}</strong>
              </h5>
            </div>
            <div className="col-1 text-end">
              <button
                type="button"
                onClick={() =>
                  props.setIsFormOpen({
                    id: null,
                    visible: false,
                    formFuncion: null,
                  })
                }
                className="btn btn-sm btn-outline-danger"
              >
                X
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form onSubmit={formik.handleSubmit} className="mt-5">
                <TextField
                  className="mb-3"
                  fullWidth
                  disabled={props.isFormOpen.formFunction === "Eliminar" ? true: false}
                  id="nombreUsuario"
                  name="nombreUsuario"
                  label="Nombre de Usuario"
                  value={formik.values.nombreUsuario}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nombreUsuario &&
                    Boolean(formik.errors.nombreUsuario)
                  }
                  helperText={
                    formik.touched.nombreUsuario && formik.errors.nombreUsuario
                  }
                />
                {props.isFormOpen.formFunction === "Editar" ||
                props.isFormOpen.formFunction === "Eliminar" ? null : (
                  <>
                    <TextField
                      type="email"
                      className="mb-3"
                      fullWidth
                      id="email"
                      name="email"
                      label="Correo electrónico: correo@dominio.com"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                    />
                    <TextField
                      type="password"
                      className="mb-3"
                      fullWidth
                      id="password"
                      name="password"
                      label="Contraseña: 8 caracteres mínimo"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </>
                )}

                <Button
                  className="mt-2"
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  {props.isFormOpen.formFunction} Usuario
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
