import React from "react";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
export const CardContactDirectoryEventComponent = (props) => {
  return (
    <div className="col">
      <div className="card mb-2">
      
        <div className="card-body">
          <h3 className="text-center">
              <AccountBoxIcon fontSize="inherit" />
          </h3>
          <p className="card-text font-weight-bold m-0 text-center fw-bold">
            {props.data.nombre}
          </p>
          <p className="card-text m-0 text-center fw-light">
            {props.data.puesto}
          </p>
          <p className="card-text m-0 text-center fw-semibold text-success">
            <a className="text-success" href={`tel:${props.data.telefono}`}>
             <PhoneIcon fontSize="inherit" /> {props.data.telefono}   {props.data.ext == null ? null : 'Ext. ' + props.data.ext }
            </a>
          </p>
          <p className="card-text m-0 text-center fw-light">
            <a className="text-info" href={`mailto:${props.data.email}`}>
             <MailIcon fontSize="inherit" /> {props.data.email}
            </a>
          </p>
        </div>
 
 {
   props.editable === false ? null : (<>
   
   <div className="card-footer bg-white">
          <div className="d-grid gap-2 d-md-flex justify-content-center">
            <button
              className="btn btn-sm text-white btn-secondary"
              type="button"
              onClick={() =>
                props.setCrudAction({ action: "edit", data: props.data })
              }
            >
              Editar
            </button>
            <button
              className="btn btn-sm text-white btn-danger"
              type="button"
              onClick={() =>
                props.setCrudAction({ action: "delete", data: props.data })
              }
            >
              Eliminar
            </button>
          </div>
        </div>

    </>)
 }
      
      </div>
    </div>
  );
};
