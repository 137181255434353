import React from "react";
import {
  getPermisosUsuario,
  setPermisosFuncionesUsuario,
  setPermisosVistaUsuario,
} from "../../API/APIPermisos";
import { AuthContext } from "../../auth/authContext";
import Checkbox from "@material-ui/core/Checkbox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CloseIcon from "@material-ui/icons/Close";

export const UserPermissionsComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [userPermissions, setUserPermissions] = React.useState([]);

  const [update, setUpdated] = React.useState(1);

  React.useEffect(() => {
    getPermisosUsuario(props.crudType.data.idusuario, user).then(
      (resultado) => {
        setUserPermissions(resultado.data);
      }
    );
  }, []);

  React.useEffect(() => {
    getPermisosUsuario(props.crudType.data.idusuario, user).then(
      (resultado) => {
        setUserPermissions(resultado.data);
      }
    );
  }, [update]);

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudType({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div className="crudDivComponent col-md-5">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-4">
              <div className="d-flex justify-content-between align-items-start">
                <div>
                  <h5>Modificación de Permisos a Usuario</h5>
                  <small>
                    <strong> {props.crudType.data.email}</strong>{" "}
                  </small>
                  <p className="mt-2">
                    Puedes habilitar o deshabilitar los accesos que tendra el
                    usuario.
                  </p>
                </div>
                <div>
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() =>
                      props.setCrudType({ action: null, data: null })
                    }
                  >
                    {" "}
                    <CloseIcon fontSize="inherit" />{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {userPermissions.map((resultado, index) => (
                <React.Fragment key={index}>
                  <h6 className="m-0 p-0">Módulo</h6>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={resultado.allowed === 1 ? true : false}
                        onChange={() => {
                          setPermisosVistaUsuario(
                            resultado.guid,
                            props.crudType.data.idusuario,
                            user
                          ).then((permisoConcedido) => {
                            setUpdated(update + 1);
                          });
                        }}
                        name={resultado.nombreVista}
                      />
                    }
                    label={resultado.nombreVista}
                  />
                  <br></br>

                  {resultado.allowed === 1 ? (
                    <>
                      <h6 className="ms-2">Funciones</h6>
                      {resultado.funciones.map((resultadoPermisos, index) => (
                        <React.Fragment key={index}>
                          <FormControlLabel
                            className="ms-3"
                            style={{ margin: 0 }}
                            control={
                              <Checkbox
                                size="small"
                                checked={
                                  resultadoPermisos.allowed === 1 ? true : false
                                }
                                onChange={() => {
                                  setPermisosFuncionesUsuario(
                                    resultadoPermisos.guid,
                                    props.crudType.data.idusuario,
                                    user
                                  ).then((permisoConcedido) => {
                                    setUpdated(update + 1);
                                  });
                                }}
                                name={resultadoPermisos.nombreFuncion}
                              />
                            }
                            label={resultadoPermisos.nombreFuncion}
                          />
                        </React.Fragment>
                      ))}
                    </>
                  ) : null}

                  <hr></hr>
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
