import React from "react";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logoImage from "../../../Images/logo.png";

import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../../auth/authContext";
import { API, HEADERS_API_JSON } from "./../../../constants/ApiConnection";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import { types } from "./../../../types/types";

import HomeIcon from "@material-ui/icons/Home";

var Recaptcha = require("react-recaptcha");

let recaptchaInstance;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/1600x900/?conference)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoImage: {
    width: "30%",
  },
}));

const validationSchema = yup.object({
  email: yup
    .string("Ingresa correo registrado")
    .email("Ingresa un correo electrónico válido")
    .required("El correo es necesario"),
  password: yup
    .string("Ingresa tu contraseña")
    .required("La contraseña requerida."),
});

var callback = function () {
  console.log("Done!!!!");
};

// manually trigger reCAPTCHA execution
const executeCaptcha = function () {
  recaptchaInstance.execute();
};

// executed once the captcha has been verified
// can be used to post forms, redirect, etc.
const verifyCallback = function (response) {
  console.log(response);
  document.getElementById("someForm").submit();
};

export const Login = (props) => {
  const classes = useStyles();
  const [typeLogin, isTypeLogin] = React.useState(props.type);
  let history = useHistory();
  const { dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      ApiCall(values);
    },
  });

  const ApiCall = async (values) => {
    try {
      //URL
      const APIURL = API + "auth/loginUser";

      const response = await axios.post(APIURL, values, HEADERS_API_JSON(null));
      if (response.status === 200) {
        toast.success("Bienvenido a Events U Ctrl");
        const lastPath = "/";
        dispatch({
          type: types.login,
          payload: response.data,
        });

        history.replace(lastPath);
      }
    } catch (error) {
      toast.error(error.response.data.message);
      console.error(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>

      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className="d-flex justify-content-end align-items-center mt-3">
            <a classsName="badge bg-primary text-white px-2" href="/">
              <HomeIcon /> Página Principal
            </a>
          </div>

          <div className={classes.paper}>
            <img
              src={logoImage}
              className={classes.logoImage}
              alt="Tipo de usuario"
            />
            <br />
            <Typography className="fw-bold" component="h1" variant="h5">
              Iniciar sesión
            </Typography>
            <Typography component="h4" variant="h6">
              {null}
            </Typography>
            <form className={classes.form} onSubmit={formik.handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Correo Electrónico"
                name="email"
                autoComplete="email"
                autoFocus
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type="password"
                id="password"
                autoComplete="current-password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Iniciar Sesión
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href="/restorepassword" variant="body2">
                    Recuperar Contraseña
                  </Link>
                </Grid>
                <Grid item>
                  {typeLogin != "Administrador" ? (
                    <>
                      <Link href="#" variant="body2">
                        {"Crear Cuenta"}
                      </Link>
                    </>
                  ) : null}
                </Grid>
              </Grid>
              <br />

              <Recaptcha
                ref={(e) => (recaptchaInstance = e)}
                sitekey="6LdkxWwdAAAAALALUD9s--zUZr-WNzo9NuJ83dqp"
                size="invisible"
                verifyCallback={verifyCallback}
              />
            </form>
          </div>
        </Grid>
      </Grid>
    </>
  );
};
