import React from "react";
import { getContactosProveedorPublico, getDetalleServiciosProveedorPublico } from "../../API/APIProveedores";
import { AccordionServiciosProveedorComponent } from "../AccordionServiciosProveedorComponent/AccordionServiciosProveedorComponent";
import { CardDirectorioProveedorComponent } from "../CardDirectorioProveedorComponent/CardDirectorioProveedorComponent";
import { ServiciosProveedorPublicComponent } from "../ServiciosProveedorPublicComponent/ServiciosProveedorPublicComponent";

export const TabsSupplierPublicComponent = (props) => {
  const [finalServicios, setFinalServicios] = React.useState([]);
  const [finalDirectorio, setFinalDirectorio] = React.useState([]);
  const [finalPreguntasFrecuentes, setFinalPreguntasFrecuentes] =
    React.useState([]);

  const idproveedor = props.idproveedor;

  React.useEffect(() => {
    let variable = false;
    getDetalleServiciosProveedorPublico(null, idproveedor).then((resultado) => {

       


      setFinalServicios(resultado.responseServicio);
    });

    getContactosProveedorPublico(null, idproveedor).then((resultado) => {

       
      setFinalDirectorio(resultado)
    })
    return () => {
      variable = true;
    };
  }, []);
  return (
    <div className="col-12 p-4 mb-3" style={{ backgroundColor: "white" }}>
      <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link active"
            id="pills-servicios-tab"
            data-bs-toggle="pill"
            data-bs-target="#pills-servicios"
            type="button"
            role="tab"
            aria-controls="pills-servicios"
            aria-selected="true"
          >
            Servicios
          </button>
        </li>
        {finalDirectorio.length == 0 ? null : (
          <>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="pills-directorio-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-directorio"
                type="button"
                role="tab"
                aria-controls="pills-directorio"
                aria-selected="true"
              >
                Directorio
              </button>
            </li>
          </>
        )}

        {finalPreguntasFrecuentes.length == 0 ? null : (
          <>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link "
                id="pills-preguntasFrecuentes-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-preguntasFrecuentes"
                type="button"
                role="tab"
                aria-controls="pills-preguntasFrecuentes"
                aria-selected="true"
              >
                Preguntas Frecuentes
              </button>
            </li>
          </>
        )}
      </ul>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-servicios"
          role="tabpanel"
          aria-labelledby="pills-servicios-tab"
        >
          <div className="container-fluid">
            <div className="row row-cols-md-1 g-0">
              <ServiciosProveedorPublicComponent data={finalServicios} />
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-directorio"
          role="tabpanel"
          aria-labelledby="pills-directorio-tab"
        >
          <div className="container-fluid">
            <div className="row row-cols-md-2">
              {
                finalDirectorio.map((contacto, index) => (
                  <CardDirectorioProveedorComponent key={index} data={contacto} />
                ))
              }
            </div>
          </div>
        </div>
        <div
          className="tab-pane fade"
          id="pills-preguntasFrecuentes"
          role="tabpanel"
          aria-labelledby="pills-preguntasFrecuentes-tab"
        >
          <div className="container-fluid">
            <div className="row row-cols-md-1 g-0"> PReguntas Frecuentes</div>
          </div>
        </div>
      </div>
    </div>
  );
};
