import React from "react";
import styles from "./LoaderEventsComponent.module.css";
import logo from "../../Images/logo.png";
import { Animated } from "react-animated-css";

export const LoaderEventsComponent = (props) => {
  const [isVisible, setIsVisible] = React.useState(props.isOpen);
 
  return (
    <>
      <Animated
        animationIn="fadeIn"
        animationOut="fadeOut"
        isVisible={isVisible}
        className={styles.loaderComponent}
      >
        <div className={styles.textLoading}>
          <img
            className={`animate__animated animate__bounce ${styles.heartbeat}`}
            src={logo}
            alt="Cargando"
          />
          <br />
          <br />
          <span className="frasecargando">Cargando </span>
          <span className="puntoscargando">
            ...<span></span>
          </span>
        </div>
      </Animated>
    </>
  );
};
