import React from "react";
import { AccordionServiciosProveedorComponent } from "../AccordionServiciosProveedorComponent/AccordionServiciosProveedorComponent";

export const ServiciosProveedorPublicComponent = (props) => {


   
  return (
    <>
      <div className="accordion" id="accordionPrograma">
        {props.data.map((servicio, index) => (
          <AccordionServiciosProveedorComponent
            key={index}
            data={servicio}
            index={index}
          />
        ))}
      </div>
    </>
  );
};
