import React from "react";
import { DateTime } from "luxon";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
export const CardProgramEventComponent = (props) => {

  return (
    <div className="col">
      <div className="card mb-2 pricingDiv">
        <div className="card-body">
          <span className="badge rounded-pill bg-primary">
            {props.data.nombreCategoria}
          </span>

          <p className="card-text font-weight-bold mt-2 fw-bold">
            <TodayIcon fontSize="inherit" /> Inicia:{" "}
            {DateTime.fromISO(props.data.fechaInicio).toLocaleString(
              DateTime.DATETIME_SHORT
            )}
            <br />
            <EventIcon fontSize="inherit" /> Termina:{" "}
            {DateTime.fromISO(props.data.fechaFin).toLocaleString(
              DateTime.DATETIME_SHORT
            )}
          </p>
          <h5 className="card-text font-weight-bold mt-2 text-primary">
            {props.data.nombreActividad}
          </h5>
          <p className="card-text font-weight-bold mt-2">
            {props.data.descripcion}
          </p>
          <p className="card-text font-weight-bold mt-2">
            <small className="fw-bold">
              <strong>Cupo:</strong> {props.data.cupo}
              <br />
              <strong>Recinto:</strong> {props.data.recinto}
              <br />
              <strong>Salón:</strong> {props.data.salon}
              <br />
              <strong>Costo:</strong> ${props.data.costo}
            </small>
          </p>
        </div>
        {props.editable === false ? null : (
          <>
            <div className="card-footer bg-white">
              <div className="d-grid gap-2 d-md-flex justify-content-end">
                <button
                  className="btn btn-sm text-white btn-dark"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({
                      action: "addPonentes",
                      data: props.data,
                    })
                  }
                >
                  <GroupWorkIcon fontSize="inherit" /> Agregar Ponentes 
                </button>
                <button
                  className="btn btn-sm text-white btn-dark"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({
                      action: "addPatrocinadores",
                      data: props.data,
                    })
                  }
                >
                  <GroupWorkIcon fontSize="inherit" /> Agregar Patrocinadores
                </button>
                <button
                  className="btn btn-sm text-white btn-secondary"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "edit", data: props.data })
                  }
                >
                  <EditIcon fontSize="inherit" /> Editar
                </button>
                <button
                  className="btn btn-sm text-white btn-danger"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "delete", data: props.data })
                  }
                >
                  <DeleteIcon fontSize="inherit" /> Eliminar
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
