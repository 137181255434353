import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from "./SliderCarouselComponent.module.css";
import imageExample from "../../Images/imagenCarouselPrincipal1.jpg";
import { getPublicCarousel } from "../../API/APICarouselPrincipal";

import Skeleton from "@material-ui/lab/Skeleton";

export const SliderCarouselComponent = () => {
  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    getPublicCarousel().then((resultado) => {
      setFinalData(resultado);
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    draggable: true,
    arrows: false,
  };

  return (
    <>
      {finalData.length === 0 ? (
        <>
          {" "}
          <Skeleton variant="rect" width={'100%'} height={'90vh'} />{" "}
        </>
      ) : null}
      <Slider {...settings} className={styles.sliderContainer}>
        {finalData.map((resultado, index) => (
          <div key={index}>
            <div
              style={{
                background: `linear-gradient(rgba(0,0,0,0.65),rgba(0,0,0,0.65)),url('${resultado.imagenDecoded}')`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
              className={styles.slide}
            >
              <h4>{resultado.titulo}</h4>
              <h5 className="my-3">{resultado.descripcion}</h5>
              <a href={resultado.urlAccion} className="btn btn-info">
                {resultado.textoBoton}
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};
