import React from "react";
import styles from "./CardInformationPanelComponent.module.css";
export const CardInformationPanelComponent = (props) => {
  return (
    <>
      <div className="col mb-3">
        <div className={`card ${styles.CardInformationPanelComponent}`}>
          <div className="card-body p-4">
            <div className="row">
              <div className="col-5 col-md-4 h2">{props.icon}</div>
              <div className="col-7 col-md-8 text-end">
                <h2 className="p-0 m-0"> {props.numero} </h2>
              </div>
            </div>
          </div>
          <div className="card-footer text-end">
            <p className="p-0 m-0 text-dark fw-bold">{props.titulo}</p>
            <small className="fw-light text-success">{props.descripcion}</small>
          </div>
        </div>
      </div>
    </>
  );
};
