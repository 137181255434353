import React from "react";
import styles from "./CompoundsPublic.module.css";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";
import { HeaderAnnouncementComponent } from "../../../Components/HeaderAnnouncementComponent/HeaderAnnouncementComponent";
import { CardCompoundComponent } from "../../../Components/CardCompoundComponent/CardCompoundComponent";
import { getPublicFeaturedRecintos } from "../../../API/APIRecintos";
import Skeleton from "@material-ui/lab/Skeleton";
import { CardFeaturedEnclosureComponent } from "../../../Components/CardFeaturedEnclosureComponent/CardFeaturedEnclosureComponent";
import { AllCompoundsPublicComponent } from "../../../Components/AllCompoundsPublicComponent/AllCompoundsPublicComponent";
import SearchIcon from "@material-ui/icons/Search";
import { SearchCompundsResultComponent } from "../../../Components/SearchCompundsResultComponent/SearchCompundsResultComponent";
import { SearchCompoundsComponent } from "../../../Components/SearchCompoundsComponent/SearchCompoundsComponent";
export const CompoundsPublic = (props) => {
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  const [finalDataFeatured, setFinalDataFeatured] = React.useState([]);
  const [allFinalData, setAllFinalData] = React.useState([]);
  const [loadingFeatured, setLoadingFeatured] = React.useState(true);

  let effectReady = false;

  React.useEffect(() => {
    getPublicFeaturedRecintos(null).then((resultado) => {
      setFinalDataFeatured(resultado);
      setLoadingFeatured(false);
    });

    return () => {
      effectReady = true;
    };
  }, []);

  return (
    <>
      <div
        className={styles.searchButton}
        onClick={() => {
          setCrudAction({ action: "search", data: [] });
        }}
      >
        <SearchIcon /> Buscar {props.title}
      </div>

      <Helmet>
        <title>Events U-Ctrl - Recintos</title>
        <meta
          name="description"
          content="Recintos creados en plataforma events U-Ctrl"
        />
      </Helmet>
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <div className="" style={{ marginTop: "88px" }}></div>
      <HeaderAnnouncementComponent
        title="Recintos"
        subtitle="Recintos registrados en Events"
        background={false}
      />

      {crudAction.action === "showResults" ? (
        <>
          <section className={`pt-1 pb-5 text-center`}>
            <div className="container mt-5 pt-2 pb-3">
              <div className="row">
                <div className="col-md-12 mb-5 mt-3">
                  <h2>Recintos Encontrados</h2>
                </div>
              </div>
            </div>
            <SearchCompundsResultComponent data={crudAction.data} />
          </section>
        </>
      ) : null}

      <section className={`pt-1 pb-5 text-center`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Recintos Destacados</h2>
            </div>
          </div>
          {loadingFeatured === true ? (
            <>
              {" "}
              <div className="row row-cols-md-3">
                <div className="col">
                  <Skeleton variant="rect" height={118} />
                </div>
                <div className="col">
                  <Skeleton variant="rect" height={118} />
                </div>
                <div className="col">
                  <Skeleton variant="rect" height={118} />
                </div>
              </div>{" "}
            </>
          ) : (
            <>
              <div className="row row-cols-md-3">
                {" "}
                {finalDataFeatured.map((recintoDestacado, index) => (
                  <CardFeaturedEnclosureComponent
                    key={index}
                    data={recintoDestacado}
                    admin={false}
                    destacado={true}
                  />
                ))}{" "}
              </div>
            </>
          )}
        </div>
      </section>

      <section className={`pt-1 pb-5 text-center`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Todos los Recintos</h2>
            </div>
          </div>
        </div>
        <AllCompoundsPublicComponent />
      </section>

      {crudAction.action === null ||
      crudAction.action == "showResults" ? null : (
        <SearchCompoundsComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
        />
      )}
    </>
  );
};
