import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "../Images/noImageAvailable";
import { ToastContainer, toast } from "react-toastify";

export const validatePassRecovery = async (uuid) => {
   
  let url = API + "user/validatePassRecovery/" + uuid;

  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if(response.status == 200)
    {
        return true;
    }
    else
    {
        return false;
    }
    return response.data;
  } catch (error) {
    console.error(error);
    return false;
  }
};
