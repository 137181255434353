import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";

import axios from "axios";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { toast } from "react-toastify";
import { types } from "../../types/types";

import WebIcon from '@material-ui/icons/Web';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PhoneIcon from '@material-ui/icons/Phone';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import MailIcon from '@material-ui/icons/Mail';

export const ContactEventDetailComponent = (props) => {




  return (
    <>
      <h6>Contacto</h6>
      <hr></hr>
      <div className="list-group list-group-flush">
        <div className="list-group-item list-group-item-action">
          <small><WebIcon fontSize="inherit" className="me-1" />Página Web:</small>
          <br></br>
          <strong><a href={props.data.web} target="_blank" rel="noreferrer">{props.data.webUrl}</a></strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small><MailIcon style={{color: '#34B7F1'}}  fontSize="inherit" className="me-1" />Correo Electrónico:</small>
          <br></br>
          <strong><a href={"mailto:" + props.data.emailEvento}>{props.data.emailEvento}</a></strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small><WhatsAppIcon style={{color: '#128C7E'}} fontSize="inherit" className="me-1" />Whatsapp:</small>
          <br></br>
          <strong>
            <a
              href={`whatsapp://send?phone=+521${props.data.whatsappEvento}`}
            >
              {props.data.whatsappEvento}
            </a>
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small><PhoneIcon style={{color: 'blue'}}  fontSize="inherit" className="me-1" />Teléfono Contacto:</small>
          <br></br>
          <strong>
            <a
              href={`tel:${props.data.telefonoEvento}`}
            >
              {props.data.telefonoEvento}
            </a>
          </strong>
        </div>
        <div className="list-group-item list-group-item-action">
          <small><ScheduleIcon style={{color: 'orange'}}  fontSize="inherit" className="me-1" />Días y Horas de atención:</small>
          <br></br>
          <strong>
            {props.data.diaHoraAtencion}
          </strong>
        </div>
      </div>
    </>
  );
};
