/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";



import { useHistory } from "react-router-dom";
import { API } from "../../constants/ApiConnection";
import { types } from "../../types/types";

import { AuthContext } from "../../auth/authContext";
import { setOrdenDestacados } from "../../API/APIEventos";
import { setOrdenDestacadosProveedores } from "../../API/APIProveedores";
import { setOrdenDestacadosRecintos } from "../../API/APIRecintos";

const validationSchema = yup.object({
  orden: yup
    .string("Ingresa Título")
    .max(50, "Título menor a 50 caracteres")
    .required("Nombre es necesario"),
});

export const ChangeOrderFeaturedEnclosure = (props) => {

    
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      orden: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {

    setOrdenDestacadosRecintos(values, user, props.crudAction.data.id).then((respuesta) => {
      if (respuesta.status === 200) {
        toast.success("Ordenamiento Correcto", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        props.setCrudAction({ action: null, data: null });
      } else {
        console.error(respuesta);
        if (respuesta.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    });
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Cambiar orden {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="orden"
                name="orden"
                type="number"
                label="Orden"
                className="mb-2"
                value={formik.values.orden}
                onChange={formik.handleChange}
                error={formik.touched.orden && Boolean(formik.errors.orden)}
                helperText={formik.touched.orden && formik.errors.orden}
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Cambiar orden de {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
