import React from "react";
import { CardAdminEnclosureComponent } from "./../../../Components/CardAdminEnclosureComponent/CardAdminEnclosureComponent";
import ReactQuill from "react-quill"; // ES6
import "react-quill/dist/quill.snow.css";
import {
  getTerminosYCondiciones,
  postTerminosYCondiciones,
} from "../../../API/APITerminosYCondicionesAvisoPrivacidad";
import { toast } from "react-toastify";


export const AdminTermsAndConditionsEdit = () => {
  const [textoActual, setTextoActual] = React.useState("");

  const [disabled, setDisabled] = React.useState(false);

  const handleChange = (value) => {
    setTextoActual(value);
  };

  React.useEffect(() => {
    let listo = false;
    setDisabled(true);
    getTerminosYCondiciones().then((resultado) => {
       
      setTextoActual(resultado[0].terminos);
      setDisabled(false);
    });
    return () => {
      listo = true;
    };
  }, []);

  return (
    <>
      <h4>Términos y Condiciones</h4>
      <hr />
      <ReactQuill
        value={textoActual}
        onChange={handleChange}
        theme="snow"
        disabled={disabled}
        style={{ height: "60vh", marginBottom: "50px" }}
      />

      <button
        className="btn btn-sm btn-success"
        disabled={disabled}
        onClick={() => {
          setDisabled(true);
          postTerminosYCondiciones(textoActual).then((resultado) => {
            toast.success("Términos y condiciones actualizados correctamente");
            setDisabled(false);
          });
        }}
      >
        Guardar Términos y Condiciones
      </button>
    </>
  );
};
