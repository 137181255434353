import React from "react";

import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext } from "../../auth/authContext";
import { API } from "../../constants/ApiConnection";
import { UserDecode } from "../../helpers/UserDecode";
import { types } from "../../types/types";
import axios from "axios";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { NoDataComponent } from "../NoDataComponent/NoDataComponent";
import { CardProgramEventComponent } from "../CardProgramEventComponent/CardProgramEventComponent";
import { AddDataEditServicesSupplierComponent } from "./AddDataEditServicesSupplierComponent";
import { CardSupplierServicesComponent } from "../CardSupplierServicesComponent/CardSupplierServicesComponent";
import { EditDataEditServicesSupplierComponent } from "./EditDataEditServicesSupplierComponent";
import { DeleteDataEditServicesSupplierComponent } from "./DeleteDataEditServicesSupplierComponent";
import { GalleryDataEditServicesSupplierComponent } from "./GalleryDataEditServicesSupplierComponent";

export const EditServicesSupplierComponent = (props) => {
  let history = useHistory();
  const idproveedor = props.idproveedor;

  const { user, dispatch } = React.useContext(AuthContext);
  const idusuarioPadre = UserDecode().idusuarioPadre;
  const [finalData, setFinalData] = React.useState([]);
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getData().then((result) => {
      setFinalData(result);
    });
  }, []);

  React.useEffect(() => {
    getData().then((result) => {
      setFinalData(result);
    });
  }, [crudAction]);

  const getData = async () => {
    try {
      const response = await axios.get(
        API + "proveedor/getServicioPorProveedor/" + props.idproveedor,
        
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>
                En esta sección podrás definir los servicios que realizas
              </strong>
              <br></br>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar Servicio a Proveedor
              </button>
            </div>
          </div>
        </div>
      </div>

    
      {finalData.responseServicio ? (
        <>
          <div className="container-fluid">
            <div className="row row-cols-md-1 row-cols-sm-1">
              {finalData.responseServicio.map((data, index) => (
                <CardSupplierServicesComponent
                  data={data}
                  key={index}
                  crudAction={crudAction}
                  setCrudAction={setCrudAction}
                  admin={true}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <>
          <NoDataComponent nombre="Servicios ofrecidos" />{" "}
        </>
      )}

      {crudAction.action === "add" ? (
        <>
          <AddDataEditServicesSupplierComponent
            idproveedor={props.idproveedor}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Servicios"
          />
        </>
      ) : null}
      {crudAction.action === "edit" ? (
        <>
          <EditDataEditServicesSupplierComponent
            idproveedor={props.idproveedor}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Servicios"
          />
        </>
      ) : null}
      {crudAction.action === "delete" ? (
        <>
          <DeleteDataEditServicesSupplierComponent
            idproveedor={props.idproveedor}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Servicios"
          />
        </>
      ) : null}
      {crudAction.action === "galeriaServicios" ? (
        <>
          <GalleryDataEditServicesSupplierComponent
            idproveedor={props.idproveedor}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Galería"
          />
        </>
      ) : null}
    </>
  );
};
