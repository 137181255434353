import React from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale from "dayjs/locale/es-mx";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import { getPublicPatrocinadorActividad } from "../../API/APIPatrocinadoresEventos";
import { CardSponsorComponent } from "../CardSponsorComponent/CardSponsorComponent";
import { publicGetPonentesPorActividad } from "../../API/APIPonentesEventos";
import { CardPonentComponent } from "../CardPonentComponent/CardPonentComponent";

export const AccordionProgramaEventComponent = (props) => {
  dayjs.extend(localizedFormat);

  const [patrocinadoresFinal, setPatrocindoresFinal] = React.useState([]);
  const [ponentesFinal, setPonentesFinal] = React.useState([]);

  React.useEffect(() => {
    getPublicPatrocinadorActividad(null, null, props.programa.id).then(
      (resultado) => {
         
        setPatrocindoresFinal(resultado.data);
      }
    );
    publicGetPonentesPorActividad(null, props.programa.id).then((resultado) => {
       
      setPonentesFinal(resultado.data);
    });
  }, []);

  return (
    <>
      <div key={props.index} className="accordion-item">
        <div className="accordion-header" id={`heading${props.index}`}>
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${props.index}`}
            aria-expanded="true"
            aria-controls={`#collapse${props.index}`}
          >
            <div className="d-flex justify-content-between w-100">
              <span className="badge rounded-pill bg-primary me-3">
                {props.programa.nombreCategoria}
              </span>
              <strong> {props.programa.nombreActividad} </strong>
              <strong className="me-2">
                <AccessTimeIcon fontSize="inherit" />{" "}
                {dayjs(props.programa.fechaInicio)
                  .locale("es-mx")
                  .format("lll")}
              </strong>
            </div>
          </button>
        </div>
        <div
          id={`collapse${props.index}`}
          className="accordion-collapse collapse show w-100"
          aria-labelledby={`heading${props.index}`}
          data-bs-parent="#accordionPrograma"
        >
          <div className="w-100 py-3">
            <div className="d-grid gap-2 d-md-flex justify-content-md-end px-3">
              <div className="btn btn-sm btn-success buttonGradient">
                <EventAvailableIcon fontSize="inherit" /> Añadir a Calendario
              </div>
            </div>
            <div className="accordion-body">
              <strong>Descripción:</strong>
              <div className="w-100">{props.programa.descripcion}</div>
              <hr></hr>

              <table className="table table-sm table-borderless">
                <tbody>
                  <tr>
                    <td>
                      <strong> Inicio: </strong>
                    </td>
                    <td>
                      {dayjs(props.programa.fechaInicio)
                        .locale("es-mx")
                        .format("lll")}{" "}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong> Fin: </strong>
                    </td>
                    <td>
                      {dayjs(props.programa.fechaFin)
                        .locale("es-mx")
                        .format("lll")}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong> Cupo: </strong>
                    </td>
                    <td> {props.programa.cupo} </td>
                  </tr>
                  <tr>
                    <td>
                      <strong> Recinto: </strong>
                    </td>
                    <td> {props.programa.recinto} </td>
                  </tr>
                  <tr>
                    <td>
                      <strong> Salón: </strong>
                    </td>
                    <td> {props.programa.salon} </td>
                  </tr>
                  <tr>
                    <td>
                      <strong> Costo: </strong>
                    </td>
                    <td>
                      {" "}
                      {props.programa.costo == "0"
                        ? "Sin Costo"
                        : props.programa.costo}{" "}
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr></hr>

              {ponentesFinal.length > 0 ? (
                <>
                  <strong>Ponentes:</strong>
                  <div className="container-fluid mt-4">
                    <div className="row row-cols-sm-3">
                      {ponentesFinal.map((ponente, index) => (
                        <CardPonentComponent key={index} data={ponente} editable={false}/>
                      ))}
                    </div>
                  </div>
                </>
              ) : null}

              {patrocinadoresFinal.length > 0 ? (
                <>
                  <strong>Patrocinadores:</strong>
                  <div className="container-fluid mt-4">
                    <div className="row row-cols-sm-5">
                      {patrocinadoresFinal.map((patrocinador, index) => (
                        <CardSponsorComponent
                          data={patrocinador}
                          key={index}
                          public={true}
                        />
                      ))}
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
