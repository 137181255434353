import React from "react";
import styles from "./NavBarComponent.module.css";
import logoImage from "../Images/logo.png";
import Fab from "@material-ui/core/Fab";
import MenuIcon from "@material-ui/icons/Menu";
import Avatar from "@material-ui/core/Avatar";
import BuildIcon from "@material-ui/icons/Build";
import ApartmentIcon from "@material-ui/icons/Apartment";
import CollectionsIcon from "@material-ui/icons/Collections";
import { Link, NavLink, useHistory } from "react-router-dom";
import { AuthContext } from "../auth/authContext";
import { toast } from "react-toastify";
export const NavBarComponent = (props) => {
  const history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const [userLogged, setUserLogged] = React.useState(user.logged);

  React.useEffect(() => {
    if (userLogged == true) {
      toast.success(
        "Tienes tu sesión iniciada, utiliza el botón de ir al panel principal para regresar al modo edición."
      );
    }
    return true;
  }, []);

  return (
    <>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <NavLink
            exact
            to="/"
            className="navbar-brand"
            href="/home"
            target="_self"
            activeClassName="menuActive"
          >
            <img src={logoImage} style={{ height: "60px" }} />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav me-auto mb-2 mb-lg-0"
              style={{ margin: "0 auto" }}
            >
              <li className="nav-item active text-center">
                <NavLink
                  exact
                  to="/home"
                  className="nav-link"
                  activeClassName="menuActive"
                >
                  Inicio
                </NavLink>
              </li>
              <li className="nav-item  text-center">
                <NavLink
                  exact
                  to="/events"
                  className="nav-link"
                  activeClassName="menuActive"
                >
                  Eventos
                </NavLink>
              </li>
              <li className="nav-item  text-center">
                <NavLink
                  exact
                  to="/enclosures"
                  className="nav-link"
                  activeClassName="menuActive"
                >
                  Recintos
                </NavLink>
              </li>
              <li className="nav-item  text-center">
                <NavLink
                  exact
                  to="/suppliers"
                  className="nav-link"
                  activeClassName="menuActive"
                >
                  Proveedores
                </NavLink>
              </li>
              <li className="nav-item  text-center">
                <NavLink
                  exact
                  to="/eventsuctrl"
                  className="nav-link"
                  activeClassName="menuActive"
                >
                  ¿Quiénes Somos?
                </NavLink>
              </li>
            </ul>
            <div className="d-flex justify-content-center">
              <ul
                className={`navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-center text-center mx-sm-auto mx-xs-auto ${styles.centrarBotones}`}
              >
                {userLogged == true ? (
                  <>
                    <li className="nav-item  text-center">
                      <NavLink
                        exact
                        to="/"
                        className="btn btn-sm btn-info mb-1"
                        activeClassName="menuActive"
                      >
                        Ir a Panel Principal
                      </NavLink>
                      &nbsp;
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item  text-center">
                      <NavLink
                        exact
                        to="/login"
                        className="btn btn-sm btn-info mb-1"
                        activeClassName="menuActive"
                      >
                        Iniciar Sesión
                      </NavLink>
                      &nbsp;
                    </li>
                    <li className="nav-item  text-center">
                      <NavLink
                        exact
                        to="/signup"
                        className="btn btn-sm btn-outline-info mb-1"
                        activeClassName="menuActive"
                      >
                        Crear Cuenta
                      </NavLink>
                      &nbsp;
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
