/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  nombreServicio: yup
    .string("Ingresa nombre de servicio")
    .max(50, "Nombre menor a 50 caracteres")
    .required("Nombre es necesario"),
  descripcion: yup
    .string("Ingresa descripcion")
    .max(50, "Descripción menor a 50 caracteres")
    .required("Descripción es necesario"),
  capacidad: yup
    .string("Ingresa capacidad")
    .max(50, "Capacidad menor a 50 caracteres")
    .required("Capacidad es necesaria"),
  limitantes: yup
    .string("Ingresa limitantes")
    .max(50, "Limitantes menor a 50 caracteres")
    .required("Limitantes son necesarias"),
  alcances: yup
    .string("Ingresa alcances")
    .max(50, "Alcances menor a 50 caracteres")
    .required("Alcances son necesarios"),
  precio: yup.string("Ingresa Precio").required("Precio es necesario"),
});

export const DeleteDataEditServicesSupplierComponent = (props) => {


  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      idproveedor: props.idproveedor,
      nombreServicio: props.crudAction.data.nombreServicio,
      descripcion: props.crudAction.data.descripcion,
      capacidad: props.crudAction.data.capacidad,
      limitantes: props.crudAction.data.limitantes,
      alcances: props.crudAction.data.alcances,
      precio: props.crudAction.data.precio,
    },
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {
    try {
      const response = await axios.delete(
        API + "proveedor/eliminarServicio/" + props.crudAction.data.id,
        values,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );


      toast.success("Registro eliminado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Eliminar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="nombreServicio"
                name="nombreServicio"
                label="Nombre"
                disabled={true}
                className="mb-2"
                value={formik.values.nombreServicio}
                onChange={formik.handleChange}
                error={
                  formik.touched.nombreServicio &&
                  Boolean(formik.errors.nombreServicio)
                }
                helperText={
                  formik.touched.nombreServicio && formik.errors.nombreServicio
                }
              />

              <TextField
                fullWidth
                id="descripcion"
                name="descripcion"
                label="Descripcion"
                disabled={true}
                className="mb-2"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={
                  formik.touched.descripcion &&
                  Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
              />
              <TextField
                fullWidth
                id="capacidad"
                name="capacidad"
                disabled={true}
                type="number"
                label="Capacidad"
                className="mb-2"
                value={formik.values.capacidad}
                onChange={formik.handleChange}
                error={
                  formik.touched.capacidad && Boolean(formik.errors.capacidad)
                }
                helperText={formik.touched.capacidad && formik.errors.capacidad}
              />
              <TextField
                fullWidth
                id="limitantes"
                name="limitantes"
                disabled={true}
                label="Limitantes"
                className="mb-2"
                value={formik.values.limitantes}
                onChange={formik.handleChange}
                error={
                  formik.touched.limitantes && Boolean(formik.errors.limitantes)
                }
                helperText={
                  formik.touched.limitantes && formik.errors.limitantes
                }
              />
              <TextField
                fullWidth
                id="alcances"
                name="alcances"
                disabled={true}
                label="Alcances"
                className="mb-2"
                value={formik.values.alcances}
                onChange={formik.handleChange}
                error={
                  formik.touched.alcances && Boolean(formik.errors.alcances)
                }
                helperText={formik.touched.alcances && formik.errors.alcances}
              />
              <TextField
                fullWidth
                id="precio"
                name="precio"
                disabled={true}
                label="Precio"
                type="number"
                className="mb-2"
                value={formik.values.precio}
                onChange={formik.handleChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
                error={formik.touched.precio && Boolean(formik.errors.precio)}
                helperText={formik.touched.precio && formik.errors.precio}
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Eliminar
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
