import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import { AuthContext } from "../../auth/authContext";
import { getSubCategoriaEventos } from "../../API/APiCategoriasEventos";

export const CategorySubcategoryDynamic = (props) => {

  const [subCategorias, setsubCategorias] = React.useState([]);
  const { user, dispatch } = React.useContext(AuthContext);

  const [categoriaSeleccionada, setCategoriaSeleccionada] = React.useState(
    props.categoriaSeleccionada.idcategoria
  );
  const [subCategoriaSeleccionada, setSubCategoriaSeleccionada] =
    React.useState([]);

  React.useEffect(() => {
    getSubCategoriaEventos(
      null,
      user,
      props.categoriaSeleccionada.idcategoria
    ).then((resultado) => {
      setsubCategorias(resultado.data);
      console.log(resultado);
    });
    return () => {
      return true;
    };
  }, []);

  return (
    <div className="row mb-3">
      <div className="col-md-5">
        <FormControl className={null} fullWidth>
          <InputLabel htmlFor="age-native-simple" shrink>
            Categoría Evento
          </InputLabel>
          <Select
            native
            value={categoriaSeleccionada}
            onChange={(e) => {
              setCategoriaSeleccionada(e.target.value);
              getSubCategoriaEventos(null, user, e.target.value).then(
                (resultado) => {
                  setsubCategorias(resultado.data);
                }
              );
            }}
            inputProps={{
              name: "age",
              id: "age-native-simple",
            }}
          >
            {props.categoriaEvento.map((categoria, index) => (
              <option key={index} value={categoria.id}>
                {categoria.nombreCategoriaEvento}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-md-5">
        <FormControl className={null} fullWidth>
          <InputLabel htmlFor="age-native-simple" shrink>
            Subcategoría Evento
          </InputLabel>
          <Select
            native
            multiple
            value={subCategoriaSeleccionada}
            onChange={(e) => {
              debugger;

              setSubCategoriaSeleccionada(e.target.value);
            }}
            inputProps={{
              name: "age",
              id: "age-native-simple",
            }}
          >
            {subCategorias.map((categoria, index) => (
              <option key={index} value={categoria.id}>
                {categoria.nombreSubcategoria}
              </option>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="col-md-2">
        <div
          className="btn-group w-100"
          role="group"
          aria-label="Basic example"
        >
          <button type="button" className="btn btn-sm btn-danger">
            Eliminar
          </button>
          <button type="button" className="btn btn-sm btn-success">
            Agregar Nuevo
          </button>
        </div>
      </div>
    </div>
  );
};
