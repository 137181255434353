import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { encode, decode } from "base64-arraybuffer";
export const GaleriaEventPublicComponent = (props) => {
  return (
    <>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2 }}>
        <Masonry gutter={"10px"}>
          {props.data.map((imagen, index) => (
            <div key={index}>
              <img
                alt="Galeria"
                className="img-fluid"
                style={{ width: "100%" }}
                src={`data:image/png;base64,${encode(
                  imagen.imagenGaleria.data
                )}`}
              />
              <small>{imagen.descripcion}</small>
            </div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </>
  );
};
