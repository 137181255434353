import React from "react";

export const UbicacionesCercanasRecinto = (props) => {
  return (
    <>
      <h6>
        <i className="fas fa-info-circle"></i>&nbsp;&nbsp;Ubicaciones Cercanas
      </h6>
      <hr></hr>
      <div className="list-group list-group-flush fillInformationEvent">
        {props.data.map((ubicacionRecinto, index) => (
          <div key={index} className="list-group-item list-group-item-action">
            De: &nbsp;
            <strong>{ubicacionRecinto.nombreUbicacion}</strong>
            <strong>
              <br />
              {ubicacionRecinto.distancia} Km.
            </strong>
          </div>
        ))}
      </div>
    </>
  );
};
