import React from "react";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";
import Interweave from "interweave";
import { getTerminosYCondiciones } from "../../../API/APITerminosYCondicionesAvisoPrivacidad";

export const TermsAndConditions = (props) => {
  const [finalData, setFinalData] = React.useState("");

  React.useEffect(() => {
    let ready = false;

    getTerminosYCondiciones().then((resultado) => {
       
      setFinalData(resultado[0].terminos);
    });
    return () => {
      ready = true;
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>
<br />
<br />
<br />
      <div className="container my-5" style={{ marginTop: "5000px" }}>
        <div className="row">
          <div className="col-12 text-center">
            <h3 className="text-primary">Términos y Condiciones</h3>
            <hr />
          </div>
        </div>
        <div className="row">
          <div className="col-12 h-100" style={{ minHeight: "80vh" }}>
            <Interweave tagName="div" content={finalData} />
          </div>
        </div>
      </div>
    </>
  );
};
