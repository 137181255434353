import React from "react";
import { Helmet } from "react-helmet-async";
import { HeaderEventDetail } from "../../../Components/HeaderEventDetail/HeaderEventDetail";
import { ImageDescriptionDetailEventComponent } from "../../../Components/ImageDescriptionDetailEventComponent/ImageDescriptionDetailEventComponent";
import { InformationSupplierDetail } from "../../../Components/InformationSupplierDetail/InformationSupplierDetail";
import * as business from "../../../constants/BusinessInfo";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { getDetalleProveedorPublico } from "../../../API/APIProveedores";
import { LoaderEventsComponent } from "../../../Components/LoaderEventsComponent/LoaderEventsComponent";
import { HeaderProveedorDetail } from "../../../Components/HeaderProveedorDetail/HeaderProveedorDetail";
import { InformationSupplierPublicComponent } from "../../../Components/InformationSupplierPublicComponent/InformationSupplierPublicComponent";
import { SocialNetWorksSupplierPublicComponent } from "../../../Components/SocialNetWorksSupplierPublicComponent/SocialNetWorksSupplierPublicComponent";
import { DescriptionAndShareSupplierPublicComponent } from "../../../Components/DescriptionAndShareSupplierPublicComponent/DescriptionAndShareSupplierPublicComponent";
import { TabsSupplierPublicComponent } from "../../../Components/TabsSupplierPublicComponent/TabsSupplierPublicComponent";
import { PublicAdsSupplierComponent } from "../../../Components/PublicAdsSupplierComponent/PublicAdsSupplierComponent";

export const SupplierDetailPublic = (props) => {
  const [principalData, setPrincipalData] = React.useState({});
  const [loaded, setLoaded] = React.useState(false);

  let history = useHistory();
  const idproveedor = useParams().id;

  React.useEffect(() => {
    let cleanup = false;
    getDetalleProveedorPublico(null, idproveedor).then((resultado) => {
      setPrincipalData(resultado[0]);
      setLoaded(true);
    });

    return () => {
      cleanup = true;
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>{`${business.information.businessName} - ${principalData.nombreProveedor}`}</title>
        <meta name="description" content={principalData.nombreProveedor} />
      </Helmet>
      {loaded === false ? <LoaderEventsComponent /> : null}
      <HeaderProveedorDetail data={principalData} />
      <section className="container mt-5 mb-5">
        <div className="row">
          <div className="col-md-5 blog-main">
            <div className="container-fluid">
              <div className="row g-0">
                <InformationSupplierPublicComponent data={principalData} />
                <SocialNetWorksSupplierPublicComponent data={principalData} />
                <div className="row no-gutters mt-4">
                  <div
                    className="col-12 p-4"
                    style={{ backgroundColor: "white" }}
                  >
                    <PublicAdsSupplierComponent idproveedor={idproveedor} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-7 blog-main">
            <DescriptionAndShareSupplierPublicComponent data={principalData} />
            <TabsSupplierPublicComponent
              data={principalData}
              idproveedor={idproveedor}
            />
          </div>
        </div>
      </section>
    </>
  );
};
