/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import { getPatrocinadorPorEvento } from "../../API/APIPatrocinadoresEventos";
import { AuthContext } from "../../auth/authContext";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { NoImagenDataComponent } from "../NoImagenDataComponent/NoImagenDataComponent";
import { AddDataSponsorsDataEventComponent } from "./AddDataSponsorsDataEventComponent";
import { EditDataSponsorsDataEventComponent } from "./EditDataSponsorsDataEventComponent";
import { DeleteDataSponsorsDataEventComponent } from "./DeleteDataSponsorsDataEventComponent";
import { NoDataComponent } from "../NoDataComponent/NoDataComponent";
import { CardSponsorComponent } from "../CardSponsorComponent/CardSponsorComponent";

export const EditSponsorsDataEventComponent = (props) => {
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    getPatrocinadorPorEvento(null, user, props.id).then((resultado) => {
     
      setFinalData(resultado);
    });
  }, [crudAction]);
  React.useEffect(() => {
    getPatrocinadorPorEvento(null, user, props.id).then((resultado) => {
    
      setFinalData(resultado);
    });
  }, []);

  return (
    <>
      <div className="container-fluid mt-3">
        <div className="row">
          <div className="col-12 mb-3 d-flex justify-content-between d-flex align-items-center">
            <div className="d-none d-sm-block">
              <strong>
                En esta sección podrás subir los patrocinadores de tu evento
              </strong>
              <br></br>
              <small>
                *Te sugerimos ingresar la mayor información del patrocinador.
              </small>
            </div>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-primary text-white"
                type="button"
                onClick={() => setCrudAction({ action: "add", data: null })}
              >
                <AddBoxIcon className="me-2" /> Agregar Patrocinador
              </button>
            </div>
          </div>
        </div>
      </div>
      {finalData.length === 0 ? (
        <NoDataComponent nombre="Patrocinadores" />
      ) : (
        <div className="container-fluid">
          <div className="row row-cols-md-6 row-cols-sm-2">
            {finalData.map((patrocinador, index) => (
              <CardSponsorComponent data={patrocinador} key={index} crudAction={crudAction} setCrudAction={setCrudAction}/>
            ))}
          </div>
        </div>
      )}
      {crudAction.action === "edit" ? (
        <>
          <EditDataSponsorsDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="patrocinador"
          />
        </>
      ) : null}
      {crudAction.action === "delete" ? (
        <>
          <DeleteDataSponsorsDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="patrocinador"
          />
        </>
      ) : null}
      {crudAction.action === "add" ? (
        <>
          <AddDataSponsorsDataEventComponent
            idevento={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="patrocinador"
          />
        </>
      ) : null}
    </>
  );
};
