import React from "react";
import styles from "./RestorePasswordSupplier.module.css";
import ReactDOM from "react-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import generator from "generate-password";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../../../auth/authContext";
import { types } from "./../../../../types/types";
import { API } from './../../../../constants/ApiConnection';
const validationSchema = yup.object({
  password: yup
    .string("Ingresa tu contraseña")
    .min(8, "La contraseña deberá tener 8 caracteres como mínimo")
    .required("Contraseña necesaria"),
});

export const RestorePasswordSupplier = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  
  React.useEffect(() => {
    createPassword();
  }, [])

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setIsRestorePassword({
        id: null,
        email: null,
        visible: false,
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
        changePassword(values)
    },
  });

  const changePassword = async (values) => {
    try {
      const response = await axios.put(API + "proveedor/updatePassword/" + props.isRestorePassword.id, values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });

      toast.success("Contraseña modificada correctamente  ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setIsRestorePassword({
        id: null,
        visible: false,
        mail: null,
      });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const createPassword = () => {
    var password = generator.generate({
      length: 10,
      numbers: true,
    });

    toast.success("Generando contraseña aleatoria", {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    formik.setFieldValue("password", password);
  };

  return (
    <>
      <div
        className={`${styles.FormCrudScreenComponent} col-md-4 col-sm-12 animate_animated animate_bounce`}
      >
        <div className="container-fluid p-5">
          <div className="row mt-3">
            <div className="col mt-1">
              <h5>
                <strong>Restablecer contraseña Proveedor </strong>
              </h5>
            </div>
            <div className="col-1 text-end">
              <button
                type="button"
                onClick={() => null}
                className="btn btn-sm btn-outline-danger"
              >
                X
              </button>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12">
              Restablecer contraseña para:
              <br></br>
              <strong>{props.isRestorePassword.email}</strong>
              <form onSubmit={formik.handleSubmit} className="py-4">
                <TextField
                  fullWidth
                  id="password"
                  name="password"
                  label="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                />

                <Button
                  color="primary"
                  variant="contained"
                  className="my-3"
                  onClick={createPassword}
                >
                  Generar Contraseña
                </Button>

                <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  className="my-1"
                  type="submit"
                >
                  Cambiar Contraseña
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
