
import { API } from './../constants/ApiConnection';
import axios from 'axios';
export const getCategoriasEvento = async (values = null, user) => {
    let url = API + "catalogue/getCategoriaEvento";
    try{
        const response = await axios.get(url, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Headers": "*",
                "x-access-token": user.accessToken,
            },
        });
        if(response.status === 200)
        {
            return response.data;
        }
        else if (response.status === 401){
            return response;
        }
    }
    catch(error)
    {
        console.error(error)
        return []
    }
}

export const createCategoriaEvento = async (values, user) => {
    let url = API + "catalogue/createCategoriaEvento";
    try{
        const response = await axios.post(url, values, {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Headers": "*",
                "x-access-token": user.accessToken,
            },
        });
        if(response.status === 200)
        {
            return response;
        }
        else if (response.status === 401){
            return response;
        }
    }
    catch(error)
    {
        console.error(error)
        return error;
    }
}

export const getSubCategoriaEventos = async (values, user, idevento) => {
    let url = API + "catalogue/getSubCategoriaEvento/" + idevento;
    try{
        const response = await axios.get(url,  {
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Headers": "*",
                "x-access-token": user.accessToken,
            },
        });
        if(response.status === 200)
        {
            return response;
        }
        else if (response.status === 401){
            return response;
        }
    }
    catch(error)
    {
        console.error(error)
        return error;
    }
}
