/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { DateTime } from "luxon";
const validationSchema = yup.object({
  idcategoria: yup.string("Ingresa Categoría").required("Categoría necesaria"),
  nombreActividad: yup
    .string("Ingresa Nombre de Actividad")
    .required("Nombre de Actividad Necesario"),
  descripcion: yup
    .string("Ingresa Descripción")
    .required("Descripción Necesaria"),
  fechaInicio: yup
    .string("Ingresa Fecha Inicio")
    .required("Fecha Inicio Necesaria"),
  fechaFin: yup.string("Ingresa Fecha Fin").required("Fecha Fin Necesaria"),
  cupo: yup.string("Ingresa Cupo").required("Cupo Necesario"),
  recinto: yup.string("Ingresa Recinto").required("Recinto Necesario"),
  salon: yup.string("Ingresa Salón").required("Salón Necesario"),
  costo: yup.string("Ingresa Costo").required("Costo Necesario"),
});

export const EditDataProgramDataEventComponent = (props) => {

  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      idevento: props.idevento,
      idcategoria: props.crudAction.data.idcategoria,
      nombreActividad: props.crudAction.data.nombreActividad,
      descripcion: props.crudAction.data.descripcion,
      fechaInicio:  DateTime.fromISO(props.crudAction.data.fechaInicio).toFormat("yyyy-MM-dd'T'HH':'mm"),
      fechaFin: DateTime.fromISO(props.crudAction.data.fechaFin).toFormat("yyyy-MM-dd'T'HH':'mm"),
      cupo: props.crudAction.data.cupo,
      recinto: props.crudAction.data.recinto,
      salon: props.crudAction.data.salon,
      costo: props.crudAction.data.costo,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
       
     // alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {
    try {
      const response = await axios.put(API + "programa/updatePrograma/" + props.crudAction.data.id , values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });

      toast.success("Registro Editado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-6">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Editar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <FormControl className="mb-2" fullWidth>
                <InputLabel shrink htmlFor="idcategoria">
                  Categoría de Actividad
                </InputLabel>
                <Select
                  native
                  value={formik.values.idcategoria}
                  onChange={formik.handleChange}
                  inputProps={{
                    name: "idcategoria",
                    id: "idcategoria",
                  }}
                >
                   {
                    props.finalDataActivities.map((actividades, index) => (
                      <option key={index} value={actividades.id}>{actividades.nombre}</option>
                    ))
                  }
                </Select>
              </FormControl>

              <TextField
                fullWidth
                id="nombreActividad"
                name="nombreActividad"
                label="Nombre Actividad"
                className="mb-2"
                value={formik.values.nombreActividad}
                onChange={formik.handleChange}
                error={
                  formik.touched.nombreActividad &&
                  Boolean(formik.errors.nombreActividad)
                }
                helperText={
                  formik.touched.nombreActividad &&
                  formik.errors.nombreActividad
                }
              />

              <TextField
                fullWidth
                id="descripcion"
                name="descripcion"
                label="Descripcion"
                className="mb-2"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={
                  formik.touched.descripcion &&
                  Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
              />

              <TextField
                fullWidth
                id="fechaInicio"
                name="fechaInicio"
                label="Fecha Inicio"
                type="datetime-local"
                inputProps={{ max: "3000-01-01T00:00" }}
                className="mb-2"
                value={formik.values.fechaInicio}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={
                  formik.touched.fechaInicio &&
                  Boolean(formik.errors.fechaInicio)
                }
                helperText={
                  formik.touched.fechaInicio && formik.errors.fechaInicio
                }
              />
              <TextField
                fullWidth
                id="fechaFin"
                name="fechaFin"
                label="Fecha Fin"
                type="datetime-local"
                inputProps={{ max: "3000-01-01T00:00" }}
                className="mb-2"
                value={formik.values.fechaFin}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={
                  formik.touched.fechaFin && Boolean(formik.errors.fechaFin)
                }
                helperText={formik.touched.fechaFin && formik.errors.fechaFin}
              />

              <TextField
                fullWidth
                id="cupo"
                name="cupo"
                label="Cupo"
                type="number"
                min="0"
                max="500000000"
                className="mb-2"
                value={formik.values.cupo}
                onChange={formik.handleChange}
                InputLabelProps={{ shrink: true }}
                error={formik.touched.cupo && Boolean(formik.errors.cupo)}
                helperText={formik.touched.cupo && formik.errors.cupo}
              />

              <TextField
                fullWidth
                id="recinto"
                name="recinto"
                label="Recinto"
                className="mb-2"
                value={formik.values.recinto}
                onChange={formik.handleChange}
                error={
                  formik.touched.recinto &&
                  Boolean(formik.errors.recinto)
                }
                helperText={
                  formik.touched.recinto &&
                  formik.errors.recinto
                }
              />

              <TextField
                fullWidth
                id="salon"
                name="salon"
                label="Salón"
                className="mb-2"
                value={formik.values.salon}
                onChange={formik.handleChange}
                error={
                  formik.touched.salon &&
                  Boolean(formik.errors.salon)
                }
                helperText={
                  formik.touched.salon &&
                  formik.errors.salon
                }
              />
              <TextField
                fullWidth
                id="costo"
                name="costo"
                label="Costo"
                className="mb-2"
                value={formik.values.costo}
                onChange={formik.handleChange}
                error={
                  formik.touched.costo &&
                  Boolean(formik.errors.costo)
                }
                helperText={
                  formik.touched.costo &&
                  formik.errors.costo
                }
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Editar
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
