import React from "react";
import styles from "./CardFeaturedEnclosureComponent.module.css";
import EventIcon from "@material-ui/icons/Event";
import { DateTime } from "luxon";
import { noImageAvailable } from "./../../Images/noImageAvailable";
import {
  setUnsetEspecialDestacado,
  setUnsetEspecialProximo,
  setUnsetEventoProximo,
} from "../../API/APIEventos";
import { AuthContext } from "../../auth/authContext";
import stylesDos from "../CardSponsorComponent/CardSponsorComponent.module.css";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale from "dayjs/locale/es-mx";
import { setUnsetProveedorDestacado } from "../../API/APIProveedores";
import { setUnsetRecintoDestacado } from "../../API/APIRecintos";
import CategoryIcon from "@material-ui/icons/Category";
import ChangeHistoryIcon from "@material-ui/icons/ChangeHistory";
export const CardFeaturedEnclosureComponent = (props) => {
  console.log(props.data);
  dayjs.extend(localizedFormat);

  const { user, dispatch } = React.useContext(AuthContext);
  var fotoFinal = noImageAvailable();

  return (
    <div className="col-sm-4 mb-3">
      <div className="card">
        <div
          className={`${stylesDos.aspectratio__container} ${stylesDos.aspect_ratio__container_1x1}`}
        >
          {props.destacado != false ? (
            <>
              {" "}
              <div
                style={{
                  position: "absolute",
                  top: "20px",
                  right: "20px",
                  zIndex: "5",
                }}
              >
                <span className="badge rounded-pill bg-warning">
                  Recinto Destacado
                </span>
              </div>{" "}
            </>
          ) : null}

          <picture className={`${stylesDos.aspect_ratio__overlay}`}>
            <img
              className={`${stylesDos.aspect_ratio__image}`}
              src={props.data.imagenDecoded}
              alt=""
            />
          </picture>
          <div className="w-100 text-end">
            <span className="badge bg-warning">Recinto Destacado</span>
          </div>
        </div>
        <div className="card-body text-start">
          {props.admin === true && props.destacado === true ? (
            <>
              <div className="w-100 text-end">
                <div
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    setUnsetRecintoDestacado(
                      { idrecinto: props.data.id },
                      user,
                      props.data.id
                    ).then((resultado) => {
                      props.setCrudAction({
                        action: "" + Math.ceil(Math.random() * 100),
                        data: props.data,
                      });
                    });
                  }}
                >
                  Borrar
                </div>
                <div
                  className="btn btn-sm btn-warning ms-2"
                  onClick={() => {
                    props.setCrudAction({
                      action: "changeOrder",
                      data: props.data,
                    });
                  }}
                >
                  Cambiar Orden
                </div>
              </div>
            </>
          ) : null}
          {props.admin === true && props.proximos === true ? (
            <>
              <div className="w-100 text-end">
                <div
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    setUnsetRecintoDestacado(
                      { idproveedor: props.data.id },
                      user,
                      props.data.id
                    ).then((resultado) => {
                      props.setCrudAction({
                        action: "" + Math.ceil(Math.random() * 100),
                        data: props.data,
                      });
                    });
                  }}
                >
                  Borrar
                </div>
              </div>
            </>
          ) : null}
          <h5 className="card-title mb-1">{props.data.nombreRecinto}</h5>
          <div className="py-3">
            <p className="small mb-1">
              <CategoryIcon
                className="me-2"
                fontSize="small"
                style={{ color: "orange" }}
              />
              {props.data.nombreCategoria}
            </p>
            <p className="small">
              <ChangeHistoryIcon
                className="me-2"
                fontSize="small"
                style={{ color: "black" }}
              />
              {props.data.descripcion == null ? (
                "Sin Descripción"
              ) : (
                <> {props.data.descripcion.substring(0, 50)} ... </>
              )}
            </p>
          </div>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <a
              href={`enclosure/${props.data.id}`}
              className="btn btn-sm btn-dark me-md-2 text-white"
            >
              Ver Recinto
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
