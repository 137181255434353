import React from "react";
import { Helmet } from "react-helmet-async";
import { ContactEventDetailComponent } from "../../../Components/ContactEventDetailComponent/ContactEventDetailComponent";
import { HeaderEventDetail } from "../../../Components/HeaderEventDetail/HeaderEventDetail";
import { ImageDescriptionDetailEventComponent } from "../../../Components/ImageDescriptionDetailEventComponent/ImageDescriptionDetailEventComponent";
import { InformationEventDetail } from "../../../Components/InformationEventDetail/InformationEventDetail";
import { SponsorsEventDetailComponent } from "../../../Components/SponsorsEventDetailComponent/SponsorsEventDetailComponent";
import * as business from "../../../constants/BusinessInfo";
import "../../../css/eventStyle.scss";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { API } from "../../../constants/ApiConnection";
import { AuthContext } from "../../../auth/authContext";
import { types } from "../../../types/types";
import { CardFrecuentQuestionEventComponent } from "../../../Components/CardFrecuentQuestionEventComponent/CardFrecuentQuestionEventComponent";
import { CardContactDirectoryEventComponent } from "./../../../Components/CardContactDirectoryEventComponent/CardContactDirectoryEventComponent";
import { CardPoliticsEventComponent } from "../../../Components/CardPoliticsEventComponent/CardPoliticsEventComponent";
import { CardPricingEventComponent } from "../../../Components/CardPricingEventComponent/CardPricingEventComponent";
import { PlanoPublicComponent } from "../../../Components/PlanoPublicComponent/PlanoPublicComponent";
import { GaleriaEventPublicComponent } from "../../../Components/GaleriaEventPublicComponent/GaleriaEventPublicComponent";
import { ProgramaEventPublicComponent } from "../../../Components/ProgramaEventPublicComponent/ProgramaEventPublicComponent";
import { getPonentesPorEvento } from "../../../API/APIPonentesEventos";
import { CardPonentComponent } from "../../../Components/CardPonentComponent/CardPonentComponent";
import { getPatrocinadorPorEventoPublic } from "../../../API/APIPatrocinadoresEventos";
import { PublicAdsEventComponent } from "../../../Components/PublicAdsEventComponent/PublicAdsEventComponent";
import { LoaderEventsComponent } from "./../../../Components/LoaderEventsComponent/LoaderEventsComponent";
import { Error404Component } from "../../../Components/Error404Component/Error404Component";
import { NotFoundComponent } from "./../NotFoundComponent/NotFoundComponent";

export const EventDetailPublic = (props) => {
  let history = useHistory();
  const idEvento = useParams().id;
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState({});
  const [contactoEvento, setContactoEvento] = React.useState({});
  const [preguntasFrecuentesResult, setPreguntasFrecuentes] = React.useState(
    []
  );
  const [ponentesResult, setPonentesResult] = React.useState([]);
  const [directorioResult, setDirectorioResult] = React.useState([]);
  const [politicasResult, setPoliticasResult] = React.useState([]);
  const [preciosResult, setPreciosResult] = React.useState([]);
  const [planoResult, setPlanoResult] = React.useState([]);
  const [galeriaResult, setGaleriaResult] = React.useState([]);
  const [programaResult, setProgramaResult] = React.useState([]);
  const [patrocinadoresResult, setPatrocinadoresResult] = React.useState([]);

  const [ligaVacia, setLigaVacia] = React.useState();

  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    getDetail(user, idEvento).then((evento) => {
      debugger;

      if (evento.length == 0) {
        setLigaVacia(true);
      }

      if (typeof evento === "undefined") {
        history.push("/error");
      } else {
        setFinalData(evento);
        getPreguntasFrecuentes(idEvento).then((preguntas) => {
          setPreguntasFrecuentes(preguntas);
        });

        getDirectorio(idEvento).then((result) => {
          setDirectorioResult(result);
        });

        getPoliticas(idEvento).then((result) => {
          setPoliticasResult(result);
        });

        getPrecios(idEvento).then((result) => {
          setPreciosResult(result);
        });

        getPlano(idEvento).then((result) => {
          setPlanoResult(result);
        });

        getGaleria(idEvento).then((result) => {
          setGaleriaResult(result);
        });
        getPrograma(idEvento).then((result) => {
          setProgramaResult(result);
        });

        getPonentesPorEvento(null, user, idEvento).then((result) => {
          setPonentesResult(result);
        });

        getPatrocinadorPorEventoPublic(null, null, idEvento).then((result) => {
          setPatrocinadoresResult(result);
        });

        setLoaded(true);
      }
    });
  }, []);
  const getDetail = async (user, idEvento) => {
    try {
      const response = await axios.get(
        API + "public/detalleEvento/" + idEvento,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );

      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getContactEvento = async (idusuarioPadre) => {
    try {
      const response = await axios.get(
        API + "user/getCompData/" + idusuarioPadre,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getPreguntasFrecuentes = async (idevento) => {
    try {
      const response = await axios.get(
        API + "pregunta/preguntasPorEvento/" + idevento,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getPrecios = async (idevento) => {
    try {
      const response = await axios.get(
        API + "precio/getPreciosPorEvento/" + idevento,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getPlano = async (idevento) => {
    try {
      const response = await axios.get(
        API + "plano/planoPorEvento/" + idevento,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getPoliticas = async (idevento) => {
    try {
      const response = await axios.get(
        API + "politica/politicasPorEvento/" + idevento,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getDirectorio = async (idevento) => {
    try {
      const response = await axios.get(
        API + "directorio/directorioPorEvento/" + idevento,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getGaleria = async (idevento) => {
    try {
      const response = await axios.get(
        API + "galeria/galeriaPorEvento/" + idevento,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const getPrograma = async (idevento) => {
    try {
      const response = await axios.get(
        API + "programa/programaPorEvento/" + idevento,

        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  return (
    <>
      {ligaVacia == true ? (
        <>
          <NotFoundComponent
            number={"404"}
            description={"No se ha encontrado la página"}
          />
        </>
      ) : (
        <>
          <Helmet>
            <title>{`${business.information.businessName} - ${finalData.tituloEvento}`}</title>
            <meta
              name="description"
              content={
                props.description ? props.description : "Sin Descripción"
              }
            />
          </Helmet>
          {
            //<div className="" style={{ marginTop: "88px" }}></div>
          }

          {loaded === false ? <LoaderEventsComponent /> : null}

          <HeaderEventDetail type="evento" data={finalData} />

          <section className="container mt-5 mb-5">
            <div className="row">
              <div className="col-md-8 blog-main">
                <div className="container-fluid g-0">
                  <div className="row g-0">
                    <ImageDescriptionDetailEventComponent data={finalData} />
                  </div>
                </div>
                <div className="container-fluid g-0">
                  <div className="row mt-4 g-0">
                    <div className="col-12 bg-white p-4">
                      <ul
                        className="nav nav-pills mb-3"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link active"
                            id="pills-programa-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-programa"
                            type="button"
                            role="tab"
                            aria-controls="pills-programa"
                            aria-selected="true"
                          >
                            Programa
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-ponentes-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-ponentes"
                            type="button"
                            role="tab"
                            aria-controls="pills-ponentes"
                            aria-selected="false"
                          >
                            Ponentes
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-precios-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-precios"
                            type="button"
                            role="tab"
                            aria-controls="pills-precios"
                            aria-selected="false"
                          >
                            Precios
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-preguntas-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-preguntas"
                            type="button"
                            role="tab"
                            aria-controls="pills-preguntas"
                            aria-selected="false"
                          >
                            Preguntas Frecuentes
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-directorio-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-directorio"
                            type="button"
                            role="tab"
                            aria-controls="pills-contact"
                            aria-selected="false"
                          >
                            Directorio
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-politicas-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-politicas"
                            type="button"
                            role="tab"
                            aria-controls="pills-politicas"
                            aria-selected="false"
                          >
                            Políticas
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-plano-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-plano"
                            type="button"
                            role="tab"
                            aria-controls="pills-plano"
                            aria-selected="false"
                          >
                            Plano
                          </button>
                        </li>

                        <li className="nav-item" role="presentation">
                          <button
                            className="nav-link"
                            id="pills-galeria-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-galeria"
                            type="button"
                            role="tab"
                            aria-controls="pills-galeria"
                            aria-selected="false"
                          >
                            <CameraAltIcon />
                          </button>
                        </li>
                      </ul>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-programa"
                          role="tabpanel"
                          aria-labelledby="pills-programa-tab"
                        >
                          <div className="container-fluid">
                            <div className="row row-cols-md-1 g-0">
                              <ProgramaEventPublicComponent
                                data={programaResult}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className="tab-pane fade"
                          id="pills-contact"
                          role="tabpanel"
                          aria-labelledby="pills-contact-tab"
                        >
                          <div className="container-fluid">
                            <div className="row row-cols-md-3"></div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-preguntas"
                          role="tabpanel"
                          aria-labelledby="pills-preguntas-tab"
                        >
                          <div className="container-fluid">
                            <div className="row row-cols-md-1">
                              {preguntasFrecuentesResult.map(
                                (pregunta, index) => (
                                  <CardFrecuentQuestionEventComponent
                                    data={pregunta}
                                    key={index}
                                    editable={false}
                                  />
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-ponentes"
                          role="tabpanel"
                          aria-labelledby="pills-ponentes-tab"
                        >
                          <div className="container-fluid">
                            <div className="row row-cols-md-3">
                              {ponentesResult.map((ponente, index) => (
                                <CardPonentComponent
                                  data={ponente}
                                  key={index}
                                  editable={false}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-directorio"
                          role="tabpanel"
                          aria-labelledby="pills-directorio-tab"
                        >
                          <div className="container-fluid">
                            <div className="row row-cols-md-3">
                              {directorioResult.map((pregunta, index) => (
                                <CardContactDirectoryEventComponent
                                  data={pregunta}
                                  index={index}
                                  editable={false}
                                  key={index}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-politicas"
                          role="tabpanel"
                          aria-labelledby="pills-politicas-tab"
                        >
                          <div className="container-fluid">
                            <div className="row row-cols-md-1">
                              {politicasResult.map((politica, index) => (
                                <CardPoliticsEventComponent
                                  data={politica}
                                  key={index}
                                  editable={false}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-precios"
                          role="tabpanel"
                          aria-labelledby="pills-precios-tab"
                        >
                          <div className="container-fluid">
                            <div className="row row-cols-md-3">
                              {preciosResult.map((precio, index) => (
                                <CardPricingEventComponent
                                  data={precio}
                                  key={index}
                                  editable={false}
                                />
                              ))}
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-plano"
                          role="tabpanel"
                          aria-labelledby="pills-plano-tab"
                        >
                          <div className="container-fluid">
                            <div className="row row-cols-md-1 g-0">
                              <PlanoPublicComponent data={planoResult} />
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-galeria"
                          role="tabpanel"
                          aria-labelledby="pills-galeria-tab"
                        >
                          <div className="container-fluid">
                            <div className="row row-cols-md-1 g-0">
                              <GaleriaEventPublicComponent
                                data={galeriaResult}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4 blog-main">
                <div className="container-fluid">
                  <div className="row no-gutters">
                    <div
                      className="col-12 p-4"
                      style={{ backgroundColor: "white" }}
                    >
                      <InformationEventDetail
                        data={finalData}
                        idEvento={idEvento}
                      />
                    </div>
                  </div>
                  <div className="row no-gutters mt-4">
                    <div
                      className="col-12 p-4"
                      style={{ backgroundColor: "white" }}
                    >
                      <SponsorsEventDetailComponent
                        data={patrocinadoresResult}
                      />
                    </div>
                  </div>

                  <div className="row no-gutters mt-4">
                    <div
                      className="col-12 p-4"
                      style={{ backgroundColor: "white" }}
                    >
                      <ContactEventDetailComponent data={finalData} />
                    </div>
                  </div>
                  <div className="row no-gutters mt-4">
                    <div
                      className="col-12 p-4"
                      style={{ backgroundColor: "white" }}
                    >
                      <SponsorsEventDetailComponent
                        data={patrocinadoresResult}
                      />
                    </div>
                  </div>
                  <div className="row no-gutters mt-4">
                    <div
                      className="col-12 p-4"
                      style={{ backgroundColor: "white" }}
                    >
                      <PublicAdsEventComponent idEvento={idEvento} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};
