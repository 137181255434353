import React from "react";
import { DateTime } from "luxon";
import TodayIcon from "@material-ui/icons/Today";
import EventIcon from "@material-ui/icons/Event";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import styles from './CardSupplierServicesComponent.module.css'
import "animate.css";

export const CardSupplierServicesComponent = (props) => {
  return (
    <div className="col">
      <div className="card mb-2 pricingDiv">
        <div className="card-body">
          <span className="badge rounded-pill bg-primary">
            Servicio de Proveedor
          </span>
          <h5 className="card-text font-weight-bold mt-2 text-primary">
            {props.data.nombreActividad}
          </h5>
          <p className="card-text font-weight-bold mt-2 p-0 m-0">
            {props.data.nombreServicio}
          </p>
          <p className="card-text font-weight-bold mt-2 p-0 m-0">
            <small> {props.data.descripcion}</small>
          </p>
          <p className="card-text font-weight-bold mt-2">
            <small className="fw-bold">
              <strong>Descripción:</strong> {props.data.descripcion}
              <br />
              <strong>Capacidad:</strong> {props.data.capacidad}
              <br />
              <strong>Limitantes:</strong> {props.data.limitantes}
              <br />
              <strong>Alcances:</strong> {props.data.alcances}
              <br />
              <strong>Precio:</strong> $ {props.data.precio}
            </small>
          </p>
        </div>
        {props.editable === false ? null : (
          <>
            <div className="card-footer bg-white">
              <div className="d-grid gap-2 d-md-flex justify-content-end">
                <button
                  className={`btn btn-sm text-white btn-dark ${styles.botonGaleria}`}
                  type="button"
                  onClick={() =>
                    props.setCrudAction({
                      action: "galeriaServicios",
                      data: props.data,
                    })
                  }
                >
                  <GroupWorkIcon fontSize="inherit" /> Galería
                </button>

                <button
                  className="btn btn-sm text-white btn-secondary"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "edit", data: props.data })
                  }
                >
                  <EditIcon fontSize="inherit" /> Editar
                </button>
                <button
                  className="btn btn-sm text-white btn-danger"
                  type="button"
                  onClick={() =>
                    props.setCrudAction({ action: "delete", data: props.data })
                  }
                >
                  <DeleteIcon fontSize="inherit" /> Eliminar
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
