/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../../constants/ApiConnection";
import { AuthContext } from "../../../auth/authContext";
import { types } from "../../../types/types";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  nombreCategoriaEvento: yup
    .string("Ingresa Nombre")
    .max(100, "100 caracteres máximo")
    .required("Nombre es necesario"),
});

export const AddAdminActivitiesProgramTypes = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      nombreCategoriaEvento: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {
    try {
      const response = await axios.post(
        API + "catalogue/createCategoriaEvento",
        values,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success("Registro Agregado Correctamente!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Agregar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="nombreCategoriaEvento"
                name="nombreCategoriaEvento"
                label="Nombre Categoría de Evento"
                className="mb-2"
                value={formik.values.nombreCategoriaEvento}
                onChange={formik.handleChange}
                error={
                  formik.touched.nombreCategoriaEvento &&
                  Boolean(formik.errors.nombreCategoriaEvento)
                }
                helperText={
                  formik.touched.nombreCategoriaEvento &&
                  formik.errors.nombreCategoriaEvento
                }
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Agregar
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
