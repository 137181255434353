import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  createCategoriaRecinto,
  createSubCategoria,
} from "../../../API/APICategoriasRecinto";
import { AuthContext } from "../../../auth/authContext";
import { types } from "../../../types/types";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  idcategoria: yup.string("Ingresa nombre").required("El nombre es necesario"),
  nombreSubcategoria: yup
    .string("Ingresa nombre")
    .required("El nombre es necesario"),
});

export const AddAdminEnclosureSubcategorie = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  let history = useHistory();
  const formik = useFormik({
    initialValues: {
      idcategoria: props.crudAction.data.id,
      nombreSubcategoria: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
       
      createSubCategoria(values, user).then((resultado) => {
        if (resultado.status === 200) {
          toast.success("Subcategoría agregada correctamente");

          props.setCrudAction({ action: null, data: null, Number: 700 });
        } else if (resultado.status === 401) {
          toast.error(
            "No tienes permiso para realizar esta información, cerrando sesión"
          );
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        } else {
          toast.error("Hemos tenido un problema, consulta consola");
        }
      });
    },
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div
        className={`FormCrudScreenComponent col-md-3 col-sm-12 animate_animated animate_bounce`}
      >
        <div className="container-fluid p-5">
          <div className="row mt-3">
            <div className="col mt-1">
              <h5 className={null}>
                <strong>Agregar Subcategoría</strong>
              </h5>
              <small>
                Se Agregara la categoria padre:
                {props.crudAction.data.nombreCategoria}
              </small>
            </div>
            <div className="col-1 text-end">
              <button
                type="button"
                onClick={() =>
                  props.setCrudType({
                    action: null,
                    data: null,
                  })
                }
                className="btn btn-sm btn-outline-danger"
              >
                X
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form onSubmit={formik.handleSubmit} className="mt-5">
                <TextField
                  className="mb-3"
                  fullWidth
                  id="nombreSubcategoria"
                  name="nombreSubcategoria"
                  label="Nombre SubCategoría"
                  value={formik.values.nombreSubcategoria}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nombreSubcategoria &&
                    Boolean(formik.errors.nombreSubcategoria)
                  }
                  helperText={
                    formik.touched.nombreSubcategoria &&
                    formik.errors.nombreSubcategoria
                  }
                />

                <Button
                  className="mt-2"
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Agregar
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
