import React from "react";
import LinkIcon from "@material-ui/icons/Link";
import LinkOffIcon from "@material-ui/icons/LinkOff";
import { AuthContext } from "../../auth/authContext";
import { asignarNoAsignarEventoaUsuario } from "../../API/APIAsignacionEventos";
import { toast } from "react-toastify";
export const CardAssignEventComponent = (props) => {
   
  const { user, dispatch } = React.useContext(AuthContext);

  const [loading, setLoading] = React.useState(false);

  return (
    <div className="card mb-2">
      <div className="card-header text-end">
        {props.data.isAssigned === false ? (
          <>
            <button
              disabled={loading}
              className="btn btn-sm btn-info fw-light"
              onClick={() => {
                setLoading(true);
                asignarNoAsignarEventoaUsuario(
                  { idevento: props.data.id, idusuario: props.dataUsuario.id },
                  user
                ).then((resultado) => {
                  setLoading(false);
                  toast.success("Moviento generado con éxito");
                  props.setCounter(props.counter + 1)
                });
              }}
            >
              <LinkOffIcon className="me-2" /> Asignar
            </button>
          </>
        ) : (
          <>
            <button
              disabled={loading}
              className="btn btn-sm btn-success fw-light"
              onClick={() => {
                setLoading(true);
                asignarNoAsignarEventoaUsuario(
                  { idevento: props.data.id, idusuario: props.dataUsuario.id },
                  user
                ).then((resultado) => {
                  setLoading(false);
                  toast.success("Moviento generado con éxito");
                  props.setCounter(props.counter + 1)
                });
              }}
            >
              <LinkIcon className="me-2" /> Asignado
            </button>
          </>
        )}
      </div>
      <div className="card-body">
        <p className="m-0 fw-bold"> {props.data.tituloEvento}</p>
        <small>{props.data.descripcionCortaEvento}</small>
        <br></br>
      </div>
    </div>
  );
};
