/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";

import { AuthContext } from "../../../../auth/authContext";

import { useHistory } from "react-router-dom";
import { API } from "./../../../../constants/ApiConnection";
import { types } from "../../../../types/types";
import { alternarImagenCarousel, createCarousel } from "../../../../API/APICarouselPrincipal";

const validationSchema = yup.object({
  titulo: yup
    .string("Ingresa Título")
    .max(50, "Título menor a 50 caracteres")
    .required("Nombre es necesario"),
  descripcion: yup
    .string("Ingresa la descipción")
    .max(50, "Descripción menor a 50 caracteres")
    .required("Descripción Requerida"),
  textoBoton: yup
    .string("Ingresa Texto Botón")
    .required("Pregunta es necesaria"),
  urlAccion: yup
    .string("Ingresa Url")
    .matches(
      /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i,
      "Enter correct url!"
    )
    .required("Ingresa URL"),
});

export const DeleteAdminCarouselPrincipalScreen = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
        titulo: props.crudAction.data.titulo,
        descripcion: props.crudAction.data.descripcion,
        textoBoton: props.crudAction.data.textoBoton,
        imagen: props.crudAction.data.imagen,
        urlAccion: props.crudAction.data.urlAccion,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {
    
    alternarImagenCarousel(null, user, props.crudAction.data.id).then((respuesta) => {

     
        
      if (respuesta.status === 200) {
        toast.success("Imagen Eliminada", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        props.setCrudAction({ action: null, data: null }); 
      } else {
        console.error(respuesta);
        if (respuesta.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    });
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Agregar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="titulo"
                name="titulo"
                label="Título Principal"
                className="mb-2"
                disabled={true}
                value={formik.values.titulo}
                onChange={formik.handleChange}
                error={formik.touched.titulo && Boolean(formik.errors.titulo)}
                helperText={formik.touched.titulo && formik.errors.titulo}
              />
              <TextField
                fullWidth
                id="descripcion"
                name="descripcion"
                label="Describe Carousel"
                className="mb-2"
                disabled={true}
                multiline
                rows={2}
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={
                  formik.touched.descripcion &&
                  Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
              />
              <TextField
                fullWidth
                id="textoBoton"
                name="textoBoton"
                label="Texto Botón"
                className="mb-2"
                disabled={true}
                value={formik.values.textoBoton}
                onChange={formik.handleChange}
                error={
                  formik.touched.textoBoton && Boolean(formik.errors.textoBoton)
                }
                helperText={
                  formik.touched.textoBoton && formik.errors.textoBoton
                }
              />
              <TextField
                fullWidth
                id="urlAccion"
                name="urlAccion"
                label="Url llamado a la acción"
                className="mb-2"
                disabled={true}
                value={formik.values.urlAccion}
                onChange={formik.handleChange}
                error={
                  formik.touched.urlAccion && Boolean(formik.errors.urlAccion)
                }
                helperText={formik.touched.urlAccion && formik.errors.urlAccion}
              />
              <div className="my-3">
                <label for="imagen" className="form-label">
                  Sube tu imagen de plano
                </label>
                <input
                  className="form-control"
                  disabled={true}
                  type="file"
                  id="imagen"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    formik.setFieldValue("imagen", e.target.files[0]);
                  }}
                />
              </div>

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Eliminar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
