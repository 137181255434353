/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import * as yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../../auth/authContext";
import { types } from "../../../../types/types";
import { API } from './../../../../constants/ApiConnection';


const validationSchema = yup.object({
    nombre: yup
    .string("Ingresa nombre")
    .max(100, "100 caracteres máximo")
    .required("El nombre es necesario"),
});

export const EditAdminTypesActivitiesScreen = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      nombre: props.crudType.data.nombre,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      saveData(values);
    },
  });

  const saveData = async (values) => {
    try {
      const response = await axios.put(
        API + "tipoActividad/updateTipoActividad/" + props.crudType.data.id,
        values,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success("Registro editado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCrudType({
        action: null,
        data: null,
      });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudType({
        action: null,
        data: null,
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <>
      <div
        className={`FormCrudScreenComponent col-md-3 col-sm-12 animate_animated animate_bounce`}
      >
        <div className="container-fluid p-5">
          <div className="row mt-3">
            <div className="col mt-1">
              <h5 className={null}>
                <strong>Editar tipo de actividad</strong>
              </h5>
            </div>
            <div className="col-1 text-end">
              <button
                type="button"
                onClick={() =>
                  props.setCrudType({
                    action: null,
                    data: null,
                  })
                }
                className="btn btn-sm btn-outline-danger"
              >
                X
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <form onSubmit={formik.handleSubmit} className="mt-5">
                <TextField
                  className="mb-3"
                  fullWidth
                  id="nombre"
                  name="nombre"
                  label="Nombre"
                  value={formik.values.nombre}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nombre &&
                    Boolean(formik.errors.nombre)
                  }
                  helperText={
                    formik.touched.nombre &&
                    formik.errors.nombre
                  }
                />
              

                <Button
                  className="mt-2"
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Editar
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
