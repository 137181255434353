import React from "react";
import styles from "./SubUserComponent.module.css";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import CakeIcon from "@material-ui/icons/Cake";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import EventSeatIcon from '@material-ui/icons/EventSeat';
export const SubUserComponent = (props) => {
  return (
    <>
      <div className="col mb-3">
        <div className={`card ${styles.cardSubUser}`}>
          <div className="card-body">
            {props.data.telCelular == null ||
            props.data.nombre == null ||
            props.data.fechaNacimiento == null ? (
              <>
                {" "}
                <span className="badge rounded-pill bg-danger my-2">
                 Por Completar Perfil
                </span>{" "}
              </>
            ) : (
              <>
                {" "}
                <span className="badge rounded-pill bg-success my-2">
                  Perfil Completo
                </span>{" "}
              </>
            )}

            <h5 className="card-title">{props.data.email.toLowerCase()}</h5>
            <p>
              <PersonIcon fontSize="small" />
              {props.data.nombre != null ? (
                props.data.nombre
              ) : (
                <> Sin nombre </>
              )}{" "}
              <br></br>
              <PhoneIcon fontSize="small" />
              {props.data.telCelular != null ? (
                props.data.telCelular
              ) : (
                <> Sin Teléfono </>
              )}{" "}
              <br></br>
              <CakeIcon fontSize="small" />
              {props.data.fechaNacimiento != null ? (
                props.data.fechaNacimiento
              ) : (
                <> Sin Fecha de Nacimiento </>
              )}{" "}
              <br></br>
            </p>
          </div>
          <div className="card-footer">
            {props.resultadoPermisos.map((permiso, index) => (
              <React.Fragment key={index}>
                {permiso.guidFuncion ==
                  "bde09d67-4f6b-47d2-a2e5-547b6794a5e4" &&
                permiso.allowed == 1 ? (
                  <>
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() =>
                        props.setCrudType({
                          action: "userPermissions",
                          data: props.data,
                        })
                      }
                    >
                      <VpnKeyIcon className="me-2" /> Asignar Permisos
                    </button>
                  </>
                ) : null}
              </React.Fragment>
            ))}
            {props.resultadoPermisos.map((permiso, index) => (
              <React.Fragment key={index}>
                {permiso.guidFuncion ==
                  "93957cf9-3680-41c3-9a67-f7eb1c6c2504" &&
                permiso.allowed == 1 ? (
                  <>
                    <button
                      className="btn btn-sm btn-info ms-2"
                      onClick={() =>
                        props.setCrudType({
                          action: "assignEvents",
                          data: props.data,
                        })
                      }
                    >
                      <EventSeatIcon className="me-2" /> Asignar Eventos
                    </button>
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
