import React from "react";
import dayjs from "dayjs";
import locale from "dayjs/locale/es-mx";
import HeightIcon from "@material-ui/icons/Height";
import localizedFormat from "dayjs/plugin/localizedFormat";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import GroupIcon from "@material-ui/icons/Group";
import RoomIcon from "@material-ui/icons/Room";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import { encode } from "base64-arraybuffer";
export const AccordionEspacioEnclosureComponent = (props) => {
  dayjs.extend(localizedFormat);

  console.log(props);

  return (
    <>
      <div key={props.index} className="accordion-item">
        <div className="accordion-header" id={`heading${props.index}`}>
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${props.index}`}
            aria-expanded="true"
            aria-controls={`#collapse${props.index}`}
          >
            <div className="d-flex justify-content-between w-100">
              <strong> {props.data.nombreEspacio} </strong>
            </div>
          </button>
        </div>
        <div
          id={`collapse${props.index}`}
          className="accordion-collapse collapse show w-100 p-4"
          aria-labelledby={`heading${props.index}`}
          data-bs-parent="#accordionPrograma"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7">
                <table classsName="table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          <RoomIcon fontSize="inherit" className="me-2" />
                          Nombre del salón:
                        </strong>
                      </td>
                      <td> {props.data.nombreEspacio}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <OpenWithIcon fontSize="inherit" className="me-2" />
                          Dimensiones:
                        </strong>
                      </td>
                      <td> {props.data.dimensiones}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <HeightIcon fontSize="inherit" className="me-2" />
                          Altura:
                        </strong>
                      </td>
                      <td> {props.data.altura}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <FitnessCenterIcon
                            fontSize="inherit"
                            className="me-2"
                          />
                          Capacidad de Carga: <br></br>
                          <small>Piso</small>
                        </strong>
                      </td>
                      <td> {props.data.capacidadCarga}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          <FitnessCenterIcon
                            fontSize="inherit"
                            className="me-2"
                          />
                          Capacidad de Carga: <br></br>
                          <small>Techo</small>
                        </strong>
                      </td>
                      <td> {props.data.capacidadCargaTecho}</td>
                    </tr>
                    {props.data.cupo.cupo.map((cupo, index) => (
                      <tr key={index}>
                        <td>
                          <strong>
                            <GroupIcon fontSize="inherit" className="me-2" />
                            Cupo: ({cupo.nombreCupo})
                          </strong>
                        </td>
                        <td> {cupo.cupo} personas</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-md-5">
                <h6>
                  <PhotoAlbumIcon className="pe-2" />
                  Fotos:{" "}
                </h6>
                {props.data.galeria.galeriaEspacio.map(
                  (imagenGaleria, index) => (
                    <>
                      <img
                        alt="imagenGaleria"
                        className="w-100"
                        key={index}
                        src={
                          "data:image/png;base64," +
                          encode(imagenGaleria.imagenEspacio.data)
                        }
                      />
                      imagen
                    </>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
