import React from "react";
import { AuthContext } from "../../auth/authContext";
import StreetviewIcon from "@material-ui/icons/Streetview";
import RoomServiceIcon from "@material-ui/icons/RoomService";
export const CardAreasServiciosEnclosureComponent = (props) => {
   
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-3">
          <button
            className="btn btn-sm btn-warning me-md-2"
            type="button"
            onClick={() =>
              props.setCrudAction({ action: "edit", data: props.data })
            }
          >
            Editar
          </button>
          <button
            className="btn btn-sm btn-danger"
            type="button"
            onClick={() =>
              props.setCrudAction({ action: "delete", data: props.data })
            }
          >
            Eliminar
          </button>
        </div>
        <h5>
          {props.data.nombreAreaServicio}
          <br></br>
          <small> {props.data.descripcion} </small>
          <br></br>
        </h5>
      </div>
    </div>
  );
};
