import "./css/custom.scss";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/js/bootstrap';
import "./css/custom.css";
ReactDOM.render(
  <React.StrictMode>
    {/* Ponemos el container para cambiar el header "helmet" */}
    <HelmetProvider>
      {/*Instanciamos el toast*/}
      <ToastContainer />
      {/*Comenzamos con el render de la APP*/}
      <App />
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
