import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "../Images/noImageAvailable";

export const getOrganizadoresEvento = async (values, user, idevento) => {
  let url = API + "organizador/getOrganizadorEvento/" + idevento;
   
   
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenOrganizador === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenOrganizador.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getPublicOrganizadoresEvento = async (values, idevento) => {
  let url = API + "organizador/getOrganizadorEvento/" + idevento;
 
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenOrganizador === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenOrganizador.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createOrganizador = async (values, user, formik) => {
  let url = API + "organizador/createOrganizador";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const updateOrganizador = async (values, user, formik, idorganizador) => {
  let url = API + "organizador/updateOrganizador/" + idorganizador;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const deleteOrganizador = async (values, user, formik, idorganizador) => {
  let url = API + "organizador/eliminarOrganizador/" + idorganizador;
  try {
    const response = await axios.delete(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
