import React from "react";
import styles from "./CardPonentComponent.module.css";
export const CardPonentComponent = (props) => {
  const [hiddenSecondDiv, setHiddenSecondDiv] = React.useState(true);

  const donwloadFile = (blob) => {
    const arr = new Uint8Array(blob);
    const file = new Blob([arr], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  return (
    <>
      <div className="col mb-2">
        <div
          onMouseEnter={() => {
            setHiddenSecondDiv(false);
          }}
          onMouseLeave={() => {
            setHiddenSecondDiv(true);
          }}
          className={`card ${styles.cardPonent}`}
          style={{
            backgroundSize: "cover",
            background:
              'linear-gradient(rgba(0,0,0,0.45),rgba(0,0,0,0.45)),url("' +
              props.data.imagenDecoded +
              '")',
          }}
        >
          <div className={`card-body delante text-white`}>
            <div
              className={`delante ${
                hiddenSecondDiv === false ? "esconderDiv" : ""
              }`}
            >
              <h5 className="m-0 p-0">{props.data.nombre}</h5>
              <h5 className="m-0 p-0 mt-1">
                <small className="fw-light">{props.data.apellido}</small>
              </h5>
            </div>
            <div
              className={`delante ${
                hiddenSecondDiv === true ? "esconderDiv" : ""
              }`}
            >
              <div className="d-flex justify-content-between">
                <span>Descripción</span>
                {props.editable === false ? (
                  " "
                ) : (
                  <>
                    <div class="d-flex justify-content-end">
                      <button
                        type="button"
                        className="btn btn-warning btn-sm me-2"
                        onClick={() => {
                          props.setCrudAction({
                            action: "edit",
                            data: props.data,
                          });
                        }}
                      >
                        Editar
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          props.setCrudAction({
                            action: "delete",
                            data: props.data,
                          });
                        }}
                      >
                        Eliminar
                      </button>
                    </div>
                  </>
                )}
              </div>
              <small className="">{props.data.cvCorto}</small>
              <div className="d-flex justify-content-between">
                {props.data.cvFile != "null" ? (
                  <>
                    {" "}
                    <button
                      type="button"
                      className="btn btn-success btn-sm mt-3"
                      onClick={() => {
                        var link = document.createElement("a");
                        link.innerHTML = "Download PDF file";
                        link.download = "file.pdf";
                        link.href = props.data.cvFile;
                        document.body.appendChild(link);

                        link.click();
                      }}
                    >
                      Descargar CV
                    </button>{" "}
                  </>
                ) : null}
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
