import React from "react";
import EventIcon from "@material-ui/icons/Event";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale from "dayjs/locale/es-mx";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

export const CardAdminSupplierComponent = (props) => {
  dayjs.extend(localizedFormat);
  let history = useHistory();
  return (
    <div className="col mb-2">
      <div className="card">
        <div className="card-body">
          <p className="mb-3">
            <EventIcon className="text-success" fontSize="inherit" /> Creado el:{" "}
            {dayjs(props.data.createdAt).locale("es-mx").format("lll")}{" "}
          </p>
          <div className="h5 fw-bold m-0">{props.data.nombreProveedor}</div>
        </div>
        <div className="card-footer">
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            {props.resultadoPermisos.map((permiso, index) => (
              <React.Fragment key={index}>
                {permiso.guidFuncion ==
                  "e99bbb4a-5da1-45bd-8212-659f97b7b43c" &&
                permiso.allowed == 1 ? (
                  <>
                    <button
                      className="btn btn-primary btn-sm text-white"
                      type="button"
                      onClick={() => {
                        history.push("supplieredit/" + props.data.id);
                      }}
                    >
                      <EditIcon fontSize="inherit" /> Modificar Proveedor
                    </button>
                  </>
                ) : null}
              </React.Fragment>
            ))}

            {props.resultadoPermisos.map((permiso, index) => (
              <React.Fragment key={index}>
                {permiso.guidFuncion ==
                  "8ff66675-cfd7-40a7-ae0e-77b69bf98790" &&
                permiso.allowed == 1 ? (
                  <>
                    <button
                      className="btn btn-danger btn-sm text-white"
                      type="button"
                      onClick={() => {
                        props.setCrudType({
                          type: "eliminar",
                          data: props.data,
                        });
                      }}
                    >
                      <DeleteIcon fontSize="inherit" /> Eliminar Proveedor
                    </button>
                  </>
                ) : null}
              </React.Fragment>
            ))}
            {props.resultadoPermisos.map((permiso, index) => (
              <React.Fragment key={index}>
                {permiso.guidFuncion ==
                  "1c4547ef-c518-4e71-b0a3-a103c47d57d1" &&
                permiso.allowed == 1 ? (
                  <>
                    <button
                      className="btn btn-success btn-sm text-white"
                      type="button"
                      onClick={() => {
                        history.push("supplier/" + props.data.id);
                      }}
                    >
                      <VisibilityIcon fontSize="inherit" /> Ver Proveedor
                    </button>
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
