import React from "react";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";

import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import logoImage from "../../../Images/logo.png";

import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./../../../auth/authContext";
import { API, HEADERS_API_JSON } from "./../../../constants/ApiConnection";
import axios from "axios";

import { ToastContainer, toast } from "react-toastify";
import { types } from "./../../../types/types";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
} from "react-router-dom";
import { validatePassRecovery } from "../../../API/APIRecovery";
import { setNewPasswordAdmin } from "../../../API/APIAdminUsers";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/1600x900/?conference)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  logoImage: {
    width: "30%",
  },
}));

const validationSchema = yup.object({
  password: yup
    .string()
    .min(8, "La contraseña debe tener como mínimo 8 caracteres")
    .required("Contraseña Necesaria"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Las Contraseñas deben coincidir"),
});

export const CreateNewPasswordScreen = (props) => {
  let { uuid } = useParams();
  const [isDisabled, setIsDisabled] = React.useState(false);
  const classes = useStyles();
  const [typeLogin, isTypeLogin] = React.useState(props.type);
  let history = useHistory();
  const { dispatch } = React.useContext(AuthContext);

  const [formularioMostrado, setFormularioMostrado] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsDisabled(true);
      ApiCall(values);

       
    },
  });

  React.useEffect(() => {
    let ready = false;

    validatePassRecovery(uuid).then((resultado) => {
      if (resultado == false) {
        toast.warning("La URL ingresada ya no es válida");
        setFormularioMostrado(false);
      } else {
        setFormularioMostrado(true);
      }
    });
    return () => {
      return (ready = true);
    };
  }, []);

  const ApiCall = async (values) => {
    try {
      //URL
      const APIURL = API + "user/resetPassword/" + uuid;
      const response = await axios.put(
        APIURL,
        { password: values.password },
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
          },
        }
      );
      if (response.status === 200) {
        toast.success("Contraseña Actualizada", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        formik.resetForm();
        setIsDisabled(false);

        dispatch({
          type: types.logout,
          payload: response.data,
        });

        history.replace("/login")

      }
    } catch (error) {
      toast.error("Ha habido un error.");
    }
  };

  return (
    <>
      <ToastContainer />
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>

      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image} />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img
              src={logoImage}
              className={classes.logoImage}
              alt="Tipo de usuario"
            />
            <br></br>
            <br></br>
            <br></br>
            <Typography component="h1" className="fw-bold" variant="h5">
              Cambiar Contraseña
            </Typography>
            <Typography
              className="text-center fw-bold my-3"
              component="p"
              variant="p"
            >
              Ingresa tu nueva contraseña:
            </Typography>
            {formularioMostrado === true ? (
              <>
                <form className={classes.form} onSubmit={formik.handleSubmit}>
                  <TextField
                    margin="normal"
                    type="password"
                    required
                    fullWidth
                    disabled={isDisabled}
                    id="password"
                    label="Contraseña Nueva"
                    name="password"
                    autoComplete="password"
                    autoFocus
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                  <TextField
                    margin="normal"
                    type="password"
                    required
                    fullWidth
                    disabled={isDisabled}
                    id="confirmPassword"
                    label="Confirma Contraseña"
                    name="confirmPassword"
                    autoComplete="confirmPassword"
                    
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />

                  <Button
                    type="submit"
                    fullWidth
                    disabled={isDisabled}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Cambiar Contraseña
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="/login" variant="body2">
                        <strong>¿Ya tienes cuenta?</strong> inicia sesión aquí
                      </Link>
                    </Grid>
                    <Grid item>
                      {typeLogin != "Administrador" ? (
                        <>
                          <Link href="/signup" variant="body2">
                            {"Crear Cuenta"}
                          </Link>
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </form>
              </>
            ) : (
              <>
                <div className="my-4 text-center">
                  <p>Enlace Expirado</p>
                  <a className="btn btn-info" href="/restorepassword">
                    Restablecer Contraseña
                  </a>
                </div>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};
