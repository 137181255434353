import React from "react";
import dayjs from "dayjs";
import locale from "dayjs/locale/es-mx";
import HeightIcon from "@material-ui/icons/Height";
import localizedFormat from "dayjs/plugin/localizedFormat";
import OpenWithIcon from "@material-ui/icons/OpenWith";
import FitnessCenterIcon from "@material-ui/icons/FitnessCenter";
import GroupIcon from "@material-ui/icons/Group";
import RoomIcon from "@material-ui/icons/Room";
import PhotoAlbumIcon from "@material-ui/icons/PhotoAlbum";
import { encode } from "base64-arraybuffer";
import { getGaleriaServicioProveedorPublico } from "../../API/APIProveedores";

export const AccordionServiciosProveedorComponent = (props) => {
  const [imagenesServicio, setImagenesServicio] = React.useState([]);
  dayjs.extend(localizedFormat);

  let ready = false;

  React.useEffect(() => {
    getGaleriaServicioProveedorPublico(null, props.data.id).then(
      (resultado) => {
      
        setImagenesServicio(resultado);
      }
    );
    return () => {
      ready = true;
    };
  }, []);

  return (
    <>
      <div key={props.index} className="accordion-item">
        <div className="accordion-header" id={`heading${props.index}`}>
          <button
            className="accordion-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target={`#collapse${props.index}`}
            aria-expanded="true"
            aria-controls={`#collapse${props.index}`}
          >
            <div className="d-flex justify-content-between w-100">
              <strong> {props.data.nombreServicio} </strong>
            </div>
          </button>
        </div>
        <div
          id={`collapse${props.index}`}
          className="accordion-collapse collapse show w-100"
          aria-labelledby={`heading${props.index}`}
          data-bs-parent="#accordionPrograma"
        >
          <div className="container-fluid p-3">
            <div className="row">
              <div className="col-md-7">
                <table classsName="table table-borderless">
                  <tbody>
                    <tr>
                      <td>
                        <strong>Nombre del Servicio: </strong>
                      </td>
                      <td>{props.data.nombreServicio}</td>
                    </tr>

                    <tr>
                      <td>
                        <strong>Descripción: </strong>
                      </td>
                      <td>{props.data.descripcion}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Capacidad: </strong>
                      </td>
                      <td>{props.data.capacidad}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Limitantes: </strong>
                      </td>
                      <td>{props.data.limitantes}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Alcances: </strong>
                      </td>
                      <td>{props.data.alcances}</td>
                    </tr>
                    <tr classsName="text-success font-weight-bolder">
                      <td>
                        <strong>Precio: </strong>
                      </td>
                      <td>
                        <strong>$ {props.data.precio}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-md-5">
                <h6>
                  {" "}
                  <PhotoAlbumIcon className="mw-2" />
                  Galería:
                </h6>

                {imagenesServicio.map((galeria, index) => (
                  <img
                    key={index}
                    alt="imagenGaleria"
                    className="w-100"
                    src={galeria.imagenConvertida}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
