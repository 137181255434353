import React from "react";
import EventIcon from "@material-ui/icons/Event";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale from "dayjs/locale/es-mx";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";

export const CardAdminEnclosureComponent = (props) => {
  dayjs.extend(localizedFormat);
  let history = useHistory();
  return (
    <div className="col mb-2">
      <div className="card">
        <div className="card-body">
          <p className="mb-3">
            <EventIcon className="text-success" fontSize="inherit" /> Creado el:{" "}
            {dayjs(props.data.createdAt).locale("es-mx").format("lll")}{" "}
          </p>
          <div className="h5 fw-bold m-0">{props.data.nombreRecinto}</div>
        </div>
        <div className="card-footer">
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            {props.resultadoPermisos.map((permiso, index) => (
              <React.Fragment key={index}>
                {permiso.guidFuncion ==
                  "5ede007b-c17e-48e8-8134-7f1fc3de36fe" &&
                permiso.allowed == 1 ? (
                  <>
                    <button
                      className="btn btn-primary btn-sm text-white"
                      type="button"
                      onClick={() => {
                        history.push("enclosureedit/" + props.data.id);
                      }}
                    >
                      <EditIcon fontSize="inherit" /> Modificar Recinto
                    </button>
                  </>
                ) : null}
              </React.Fragment>
            ))}

            {props.resultadoPermisos.map((permiso, index) => (
              <React.Fragment key={index}>
                {permiso.guidFuncion ==
                  "1513d67f-2a99-4902-8d60-623768be3c0d" &&
                permiso.allowed == 1 ? (
                  <>
                    <button
                      className="btn btn-danger btn-sm text-white"
                      type="button"
                      onClick={() => {
                        props.setCrudType({
                          type: "eliminar",
                          data: props.data,
                        });
                      }}
                    >
                      <DeleteIcon fontSize="inherit" /> Eliminar Recinto
                    </button>
                  </>
                ) : null}
              </React.Fragment>
            ))}

            {props.resultadoPermisos.map((permiso, index) => (
              <React.Fragment key={index}>
                {permiso.guidFuncion ==
                  "db2c9805-d30a-4fb8-926f-32d4294d2de2" &&
                permiso.allowed == 1 ? (
                  <>
                    <button
                      className="btn btn-success btn-sm text-white"
                      type="button"
                      onClick={() => {
                        history.push("enclosure/" + props.data.id);
                      }}
                    >
                      <VisibilityIcon fontSize="inherit" /> Ver Recinto
                    </button>
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
