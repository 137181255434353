import React from "react";
import { DateTime } from "luxon";

export const CardAdminUsersComponent = (props) => {
 

  const hora = DateTime.fromISO(props.data.createdAt).toLocaleString();

  return (
    <>
      <div className="col mt-2">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{props.data.nombreUsuario}</h5>
            <p className="card-text">
              {props.data.email}
              <br></br>
              <small>Fecha de Creación: {hora} </small>
            </p>
            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
              <button
                className="btn btn-sm btn-warning"
                onClick={() =>
                  props.setIsFormOpen({
                    id: props.data.id,
                    visible: true,
                    formFunction: "Editar",
                  })
                }
              >
                Editar Usuario
              </button>
              <button className="btn btn-sm btn-warning btn-primary">
                Restablecer Contraseña
              </button>
              <button
                className="btn btn-sm btn-error btn-danger"
                onClick={() =>
                  props.setIsFormOpen({
                    id: props.data.id,
                    visible: true,
                    formFunction: "Eliminar",
                  })
                }
              >
                Eliminar Usuario
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
