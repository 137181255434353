import React from "react";
import { ChangePasswordComponent } from "../ChangePasswordComponent/ChangePasswordComponent";

export const AdminUsersListComponent = (props) => {
  const [recoverPasswordOpen, setRecoverPasswordOpen] = React.useState(false);

  return (
    <div className="row row-cols-md-3">
      {props.crudType.data.map((usuarioAdmin, index) => (
        <div className="col mb-2" key={index}>
          <div className="card">
            <div className="card-body">
              <h6>{usuarioAdmin.id}</h6>
              <h5>{usuarioAdmin.nombre}</h5>
              <small>
                <strong>{usuarioAdmin.email}</strong>{" "}
              </small>
            </div>
            <div className="card-footer">
              <div className="w-100 p-2 mt-2">
                <ChangePasswordComponent id={usuarioAdmin.id} crudType={props}/>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
