/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  nombreAreaServicio: yup
    .string("Ingresa nombre de servicio")
    .max(50, "50 caracteres máximo")
    .required("Nombre es necesario"),
  descripcion: yup
    .string("Ingresa Distancia")
    .max(50, "50 caracteres máximo")
    .required("Descripción necesaria"),
  capacidad: yup.number("Ingresa número").required("Precio necesario"),
});

export const EditDataEditAreasServiciosDataEnclosureComponent = (props) => {
  const [isDisabled, setIsDisabled] = React.useState(false);

  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      idrecinto: props.idevento,
      nombreAreaServicio: props.crudAction.data.nombreAreaServicio,
      descripcion: props.crudAction.data.descripcion,
      capacidad: props.crudAction.data.capacidad,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addData(values);
    },
  });

  const addData = async (values) => {
    setIsDisabled(true);

    try {
      const response = await axios.put(
        API + "recintos/updateAreaServicio/" + props.crudAction.data.id,
        values,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success(
        "Area de servicio agregada correctamente, actualizando información.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        }
      );
      setIsDisabled(false);
      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Agregar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              {isDisabled === true ? (
                <>
                  <div className="alert alert-primary" role="alert">
                    procesando información
                  </div>
                </>
              ) : null}

              <TextField
                fullWidth
                id="nombreAreaServicio"
                name="nombreAreaServicio"
                disabled={isDisabled}
                label="Nombre de Servicio"
                className="mb-2"
                value={formik.values.nombreAreaServicio}
                onChange={formik.handleChange}
                error={
                  formik.touched.nombreAreaServicio &&
                  Boolean(formik.errors.nombreAreaServicio)
                }
                helperText={
                  formik.touched.nombreAreaServicio &&
                  formik.errors.nombreAreaServicio
                }
              />
              <TextField
                fullWidth
                id="descripcion"
                name="descripcion"
                disabled={isDisabled}
                label="Descripción"
                className="mb-2"
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={
                  formik.touched.descripcion &&
                  Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
              />
              <TextField
                fullWidth
                id="capacidad"
                name="capacidad"
                disabled={isDisabled}
                label="Capacidad"
                className="mb-2"
                value={formik.values.capacidad}
                onChange={formik.handleChange}
                error={
                  formik.touched.capacidad && Boolean(formik.errors.capacidad)
                }
                helperText={formik.touched.capacidad && formik.errors.capacidad}
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
                disabled={isDisabled}
              >
                Agregar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
