import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "../Images/noImageAvailable";

export const getPatrocinadorPorEvento = async (values, user, idevento) => {
  let url = API + "patrocinador/getPatrocinadorEvento/" + idevento;
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPatrocinador === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPatrocinador.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getPatrocinadorPorEventoPublic = async (
  values,
  user,
  idevento
) => {
  let url = API + "patrocinador/getPatrocinadorEvento/" + idevento;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPatrocinador === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPatrocinador.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createPatrocinador = async (values, user, formik) => {
  let url = API + "patrocinador/createPatrocinador";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const editPatrocinador = async (
  values,
  user,
  formik,
  idpatrocinador
) => {
   

  let url = API + "patrocinador/updatePatrocinador/" + idpatrocinador;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const deletePatrocinador = async (
  values,
  user,
  formik,
  idpatrocinador
) => {
  let url = API + "patrocinador/eliminarPatrocinador/" + idpatrocinador;
  try {
    const response = await axios.delete(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });

    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const asignarPatrocinadorActividad = async (values, user) => {
  let url = API + "patrocinador/setPatrocinadorActividad";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPatrocinadorActividad = async (values, user, idactividad) => {
  let url = API + "patrocinador/getPatrocinadorPorActividad/" + idactividad;
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPatrocinador === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPatrocinador.data);
        }
      }
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getPublicPatrocinadorActividad = async (
  values,
  user,
  idactividad
) => {
  let url = API + "patrocinador/getPatrocinadorPorActividad/" + idactividad;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPatrocinador === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPatrocinador.data);
        }
      }
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
