import React from "react";
import logoImage from "../../Images/logo.png";
import styles from "./FooterComponent.module.css";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { Link, NavLink, useHistory } from "react-router-dom";
export const FooterComponent = () => {
  const history = useHistory();
  return (
    <>
      <footer
        className="font-small"
        style={{ backgroundColor: "#d94D1A", color: "white" }}
      >
        <div className="container text-md-left mt-5 pt-5 pb-5">
          <div className="row mt-3">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <img
                onClick={() => history.push("/")}
                className={styles.logoImage}
                src={logoImage}
                style={{backgroundColor: 'white', padding: 10}}
                alt="nombre"
              />
              <br></br>
              <br></br>
              <small>
                <p>
                  <i className="fas fa-home mr-3"></i> Dirección (Google Maps)
                </p>
                <p>
                  <i className="fas fa-envelope mr-3"></i> fobregon@eventsuctrl.com
                </p>
                <p>
                  <i className="fas fa-phone mr-3"></i> + 52 477 774 5182{" "}
                </p>
                <p>
                  <FacebookIcon className="mx-1" />
                  <InstagramIcon className="mx-1" />
                  <YouTubeIcon className="mx-1" />
                  <WhatsAppIcon className="mx-1" />
                </p>
              </small>
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase font-weight-bold">Busca</h6>
              <hr
                className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: "60px" }}
              />
              <p>
              <span className="text-white cursor-pointer"  onClick={() => history.push("/events")}>Eventos</span>
              </p>
              <p>
              <span className="text-white cursor-pointer"  onClick={() => history.push("/compounds")}>Recintos</span>
              </p>
              <p>
              <span className="text-white cursor-pointer"  onClick={() => history.push("/suppliers")}>Proveedores</span>
              </p>
              <p className="font-weight-bold">
                <i className="far fa-presentation"></i>
                <br></br>
                <small>Conócenos!</small>
                <br></br>
                <span className="text-white cursor-pointer"  onClick={() => history.push("/suppliers")}>Events U Ctrl</span>
              </p>
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase font-weight-bold">Sesión</h6>
              <hr
                className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: "60px" }}
              />
              <p>
              <span className="text-white cursor-pointer"  onClick={() => history.push("/login")}>Iniciar Sesión</span>
              </p>
              <p>
              <span className="text-white cursor-pointer"  onClick={() => history.push("/signup")}>Crear Cuenta</span>
              </p>
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase font-weight-bold">Otros</h6>
              <hr
                className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto"
                style={{ width: "60px" }}
              />
              <p>
                <span className="text-white cursor-pointer"  onClick={() => history.push("/terms")}>Términos y Condiciones</span>
              </p>
              <p>
              <span className="text-white cursor-pointer"  onClick={() => history.push("/privacy")}>Aviso de Privacidad</span>
              </p>
             
            </div>
          </div>
        </div>
        <div className="footer-copyright text-center py-3">© 2020 Copyright</div>
      </footer>
    </>
  );
};
