/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";

import { AuthContext } from "../../../../auth/authContext";

import { useHistory } from "react-router-dom";
import { API } from "./../../../../constants/ApiConnection";
import { types } from "../../../../types/types";
import { createCarousel } from "../../../../API/APICarouselPrincipal";
import { createAd } from "./../../../../API/APIPublicidadEvento";

export const AddAdminEventAds = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      imagenPublicidad: "",
    },
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {
    if (
      values.imagenPublicidad == null ||
      values.imagenPublicidad == "" ||
      values.imagenPublicidad == false
    ) {
      toast.error("Debes agregar una imagen");
    } else {
      var bodyFormData = new FormData();
      bodyFormData.append("imagenPublicidad", values.imagenPublicidad);

      createAd(bodyFormData, user, formik).then((respuesta) => {
        if (respuesta.status === 200) {
          toast.success("Pubilicidad Agregada Correctamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
            progress: undefined,
          });
          props.setCrudAction({ action: null, data: null });
        } else {
          console.error(respuesta);
          if (respuesta.status === 401) {
            dispatch({
              type: types.logout,
            });
            history.replace("/");
          }
        }
      });
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Agregar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <div className="my-3">
                <label for="imagen" className="form-label">
                  Sube tu imagen de Publicidad
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="imagenPublicidad"
                  accept="image/png, image/jpeg"
                  onChange={(e) => {
                    formik.setFieldValue("imagenPublicidad", e.target.files[0]);
                  }}
                />
              </div>

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Agregar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
