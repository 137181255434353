import React from "react";
import AddIcon from "@material-ui/icons/Add";
import {
  eliminarSubCategoriaRecinto,
  getSubCategoriasRecinto,
} from "../../API/APICategoriasRecinto";
import { AuthContext } from "../../auth/authContext";
import { toast } from "react-toastify";
import { EditEnclosureSubcategorieScreen } from "../../screens/Private/EnclosureCategoriesSubcategoriesTypesScreen/EditEnclosureSubcategorieScreen/EditEnclosureSubcategorieScreen";
export const CardAdminEnclosureType = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);

  const [finalDataSubcategories, setFinalDataSubcategories] = React.useState(
    []
  );

  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  React.useEffect(() => {
    getSubCategoriasRecinto(props.data.id, user).then((resultado) => {
      setFinalDataSubcategories(resultado);
    });
  }, [crudAction]);

  React.useEffect(() => {
    let ready = false;

    return () => {
      ready = true;
    };
  }, [props]);

  return (
    <>
      <div className="card mb-2">
        <div className="card-header bg-white d-flex align-items-center d-flex justify-content-between">
          <h6 className="mt-2 text-muted">
            {props.index + 1}.-{props.data.nombreCategoria}
          </h6>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
              className="btn btn-sm text-white btn-warning"
              type="button"
              onClick={() =>
                props.setCrudAction({ action: "edit", data: props.data })
              }
            >
              Editar
            </button>
            <button
              className="btn btn-sm text-white btn-danger"
              type="button"
              onClick={() =>
                props.setCrudAction({ action: "delete", data: props.data })
              }
            >
              Eliminar
            </button>
            <button
              className="btn btn-sm btn-success"
              onClick={() => {
                props.setCrudAction({
                  action: "addSubCategorie",
                  data: props.data,
                });
              }}
            >
              <AddIcon />
              Subcategoría
            </button>
          </div>
        </div>
        <div className="card-body">
          <div classsName="d-grid gap-2 d-md-flex justify-content-md-end"></div>

          <ul classsName="list-group list-group-flush">
            {finalDataSubcategories.map((subcategoria, index) => (
              <React.Fragment key={index}>
                <li classsName="list-group-item d-flex justify-content-between align-items-start">
                  <span>{subcategoria.nombreSubcategoria}</span>

                  <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                    <button
                      className="btn btn-sm btn-outline-warning"
                      onClick={() => {
                        setCrudAction({ action: "edit", data: subcategoria });
                      }}
                    >
                      Editar
                    </button>

                    <button
                      className="btn btn-sm btn-outline-danger"
                      onClick={() => {
                        eliminarSubCategoriaRecinto(
                          null,
                          user,
                          subcategoria.id
                        ).then((resultado) => {
                          if (resultado.status === 200) {
                            toast.success("Subcategoría Eliminada");
                            getSubCategoriasRecinto(props.data.id, user).then(
                              (resultado) => {
                                setFinalDataSubcategories(resultado);
                              }
                            );
                            props.setCrudAction({ action: null, data: null });
                          } else {
                            toast.error(
                              "Error al eliminar subcategoría, consulta consola"
                            );
                          }
                        });
                      }}
                    >
                      Eliminar
                    </button>
                  </div>
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>
      </div>

      {crudAction.action === "edit" ? (
        <>
          <EditEnclosureSubcategorieScreen
            idcategoria={props.id}
            crudAction={crudAction}
            setCrudAction={setCrudAction}
            nombre="Subcategoría"
          />
        </>
      ) : null}
    </>
  );
};
