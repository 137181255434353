/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";
import { encode, decode } from "base64-arraybuffer";
const validationSchema = yup.object({
  pregunta: yup.string("Ingresa Pregunta").required("Pregunta es necesaria"),
  respuesta: yup.string("Ingresa Respuesta").required("Respuesta es necesaria"),
});

export const DeleteDataPlanoDataEventComponent = (props) => {

    
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      idevento: props.idevento,
      nombre: props.crudAction.data.nombre,
      descripcion: props.crudAction.data.descripcion,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {
    try {
      const response = await axios.delete(
        API + "plano/eliminarPlano/" + props.crudAction.data.id,
        values,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success("Registro Eliminado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Borrar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-3">
          <div className="col-12">
            Confirma la información antes de eliminar.
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit} className="mb-5">
              <TextField
                fullWidth
                id="nombre"
                name="nombre"
                label="Nombre de plano"
                className="mb-2"
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
              />
              <TextField
                fullWidth
                id="descripcion"
                name="descripcion"
                label="Describe el documento a subir"
                className="mb-2"
                multiline
                rows={5}
                value={formik.values.descripcion}
                onChange={formik.handleChange}
                error={
                  formik.touched.descripcion &&
                  Boolean(formik.errors.descripcion)
                }
                helperText={
                  formik.touched.descripcion && formik.errors.descripcion
                }
              />

              <img
                alt="ImagenPlano"
                className="img-fluid"
                style={{ width: "100%" }}
                src={`data:image/png;base64,${encode(
                  props.crudAction.data.imagenPlano.data
                )}`}
              />

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Borrar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
