import React from "react";
import { CardInformationPanelComponent } from "../../../Components/CardInformationPanelComponent/CardInformationPanelComponent";
import { DescriptionIcon } from "@material-ui/icons/Description";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import { checkViewPermission } from "../../../API/APIPermisos";
import { AuthContext } from "../../../auth/authContext";
import { SinPermisoParaAcceder } from "./../../Public/SinPermisoParaAcceder/SinPermisoParaAcceder";
import { LoaderEventsComponent } from "./../../../Components/LoaderEventsComponent/LoaderEventsComponent";
import "../../../css/panel.scss";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import AdjustIcon from "@material-ui/icons/Adjust";
import AllInclusiveIcon from "@material-ui/icons/AllInclusive";
import BeenhereIcon from "@material-ui/icons/Beenhere";
import CameraIcon from "@material-ui/icons/Camera";
import { CardButtonPanelComponent } from "../../../Components/CardButtonPanelComponent/CardButtonPanelComponent";
const data = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "#A11692",
        "#17183B",
        "#FF4F79",
        "#D8CC34",
        "#7B7554",
        "#1B998B",
      ],
      borderColor: [
        "#A11692",
        "#17183B",
        "#FF4F79",
        "#D8CC34",
        "#7B7554",
        "#1B998B",
      ],
      borderWidth: 1,
    },
  ],
};

const dataVertical = {
  labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      backgroundColor: [
        "#A11692",
        "#17183B",
        "#FF4F79",
        "#D8CC34",
        "#7B7554",
        "#1B998B",
      ],
      borderColor: [
        "#A11692",
        "#17183B",
        "#FF4F79",
        "#D8CC34",
        "#7B7554",
        "#1B998B",
      ],
      borderWidth: 1,
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const dataLine = {
  labels: ["1", "2", "3", "4", "5", "6"],
  datasets: [
    {
      label: "# of Votes",
      data: [12, 19, 3, 5, 2, 3],
      fill: false,
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgba(255, 99, 132, 0.2)",
    },
  ],
};

const optionsLine = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

export const MainDashboardScreen = (props) => {
  const uuidVista = "8593a05f-acae-435c-969e-999040ce1b6c";
  const { user, dispatch } = React.useContext(AuthContext);
  const [resultadoPermisos, setResultadoPermisos] = React.useState([]);
  const [statusPermiso, setStatusPermiso] = React.useState(0);
  React.useEffect(() => {
    checkViewPermission(uuidVista, user).then((resultado) => {
      setResultadoPermisos(resultado);
      setStatusPermiso(resultado.status);
    });
  }, []);

  return (
    <>
      {statusPermiso === 200 ? (
        <>
          <div className="container-fluid">
            <div className="row row-cols-md-3">
              <CardButtonPanelComponent
                backgroundColorCard={"#DEC1FF"}
                textColor={"white"}
                url={"/myevents"}
                titulo={`Mis Eventos`}
                descripcion={`Acceder a mis eventos`}
                icon={<AcUnitIcon style={{ fontSize: 30, color: "orange" }} />}
              />
              <CardButtonPanelComponent
                backgroundColorCard={"#F4EEA9"}
                textColor={"white"}
                url={"/myenclosures"}
                titulo={`Mis Recintos`}
                descripcion={`Acceder a mis Recintos`}
                icon={<AcUnitIcon style={{ fontSize: 30, color: "orange" }} />}
              />
              <CardButtonPanelComponent
                backgroundColorCard={"#D0FFCE"}
                textColor={"white"}
                url={"/mysuppliers"}
                titulo={`Mis Proveedores`}
                descripcion={`Acceder a mis Proveedores`}
                icon={<AcUnitIcon style={{ fontSize: 30, color: "orange" }} />}
              />
            </div>

            <hr />

            <div className="row row-cols-md-3">
              <CardInformationPanelComponent
                numero={"50 %"}
                titulo={`Numero de prueba analítica`}
                descripcion={`Este número muestra un ejemplo`}
                icon={<AcUnitIcon style={{ fontSize: 30, color: "orange" }} />}
              />
              <CardInformationPanelComponent
                numero={120}
                titulo={`Numero de prueba analítica`}
                descripcion={`Este número muestra un ejemplo`}
                icon={<AdjustIcon style={{ fontSize: 30, color: "blue" }} />}
              />
              <CardInformationPanelComponent
                numero={99}
                titulo={`Numero de prueba analítica`}
                descripcion={`Este número muestra un ejemplo`}
                icon={
                  <AllInclusiveIcon style={{ fontSize: 30, color: "red" }} />
                }
              />
              <CardInformationPanelComponent
                numero={552}
                titulo={`Numero de prueba analítica`}
                descripcion={`Este número muestra un ejemplo`}
                icon={
                  <BeenhereIcon style={{ fontSize: 30, color: "purple" }} />
                }
              />
              <CardInformationPanelComponent
                numero={0}
                titulo={`Numero de prueba analítica`}
                descripcion={`Este número muestra un ejemplo`}
                icon={<AcUnitIcon style={{ fontSize: 30, color: "pink" }} />}
              />
              <CardInformationPanelComponent
                numero={50}
                titulo={`Numero de prueba analítica`}
                descripcion={`Este número muestra un ejemplo`}
                icon={<CameraIcon style={{ fontSize: 30, color: "black" }} />}
              />
            </div>

            <hr />
            <div className="row row-cols-md-3">
              <div className="col">
                <div
                  className="card p-4"
                  style={{
                    borderRadius: "12px",
                    boxShadow: "0 6px 10px -4px rgb(0 0 0 / 15%)",
                  }}
                >
                  <div className="card-body">
                    <Doughnut data={data} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="card p-4"
                  style={{
                    borderRadius: "12px",
                    boxShadow: "0 6px 10px -4px rgb(0 0 0 / 15%)",
                  }}
                >
                  <div className="card-body">
                    <Bar data={dataVertical} options={options} />
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="card p-4"
                  style={{
                    borderRadius: "12px",
                    boxShadow: "0 6px 10px -4px rgb(0 0 0 / 15%)",
                  }}
                >
                  <div className="card-body">
                    <Line data={dataLine} options={optionsLine} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : statusPermiso === 0 ? (
        <>
          <LoaderEventsComponent />{" "}
        </>
      ) : (
        <>
          <SinPermisoParaAcceder />{" "}
        </>
      )}
    </>
  );
};
