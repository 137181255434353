/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import { API } from "../../constants/ApiConnection";
import { AuthContext } from "../../auth/authContext";
import { types } from "../../types/types";
import { useHistory } from "react-router-dom";

const validationSchema = yup.object({
  nombre: yup.string("Ingresa Nombre").required("Nombre necesario"),
  apellido: yup.string("Ingresa Apellidos").required("Nombre Apellidos"),
  cvCorto: yup.string("Ingresa Descripción").required("Descripción Necesaria"),
  facebookPonente: yup.string("Ingresa Url Facebook"),
  youtubePonente: yup.string("Ingresa Url Youtube"),
});

export const DeleteDataPonentesDataEventComponent = (props) => {

  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);

  const formik = useFormik({
    initialValues: {
      idevento: props.crudAction.data.idevento,
      nombre: props.crudAction.data.nombre,
      apellido: props.crudAction.data.apellido,
      cvCorto: props.crudAction.data.cvCorto,
      cvFile: props.crudAction.data.cvFile,
      imagenPonente: props.crudAction.data.imagenPonente,
      facebookPonente: props.crudAction.data.facebookPonente,
      youtubePonente: props.crudAction.data.youtubePonente,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {
     

    var bodyFormData = new FormData();
    bodyFormData.append("idevento", values.idevento);
    bodyFormData.append("nombre", values.nombre);
    bodyFormData.append("apellido", values.apellido);
    bodyFormData.append("cvCorto", values.cvCorto);
    bodyFormData.append("cvFile", values.cvFile);
    bodyFormData.append("imagenPonente", values.imagenPonente);
    bodyFormData.append("facebookPonente", values.facebookPonente);
    bodyFormData.append("youtubePonente", values.youtubePonente);

    try {
      const response = await axios.delete(
        API + "ponente/eliminarPonente/" + props.crudAction.data.id,
        null,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

      toast.success("Registro Eliminado", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });

      props.setCrudAction({ action: null, data: null });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>Eliminar {props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <TextField
                fullWidth
                id="nombre"
                disabled={true}
                name="nombre"
                label="Nombre(s)"
                className="mb-2"
                multiline
                rows={1}
                value={formik.values.nombre}
                onChange={formik.handleChange}
                error={formik.touched.nombre && Boolean(formik.errors.nombre)}
                helperText={formik.touched.nombre && formik.errors.nombre}
              />
              <TextField
                fullWidth
                disabled={true}
                id="apellido"
                name="apellido"
                label="Apellidos"
                className="mb-2"
                multiline
                rows={1}
                value={formik.values.apellido}
                onChange={formik.handleChange}
                error={
                  formik.touched.apellido && Boolean(formik.errors.apellido)
                }
                helperText={formik.touched.apellido && formik.errors.apellido}
              />
              <TextField
                fullWidth
                id="cvCorto"
                disabled={true}
                name="cvCorto"
                label="Cv Corto"
                className="mb-2"
                multiline
                rows={5}
                value={formik.values.cvCorto}
                onChange={formik.handleChange}
                error={formik.touched.cvCorto && Boolean(formik.errors.cvCorto)}
                helperText={formik.touched.cvCorto && formik.errors.cvCorto}
              />

              <TextField
                fullWidth
                id="facebookPonente"
                name="facebookPonente"
                label="Facebook Ponente"
                disabled={true}
                className="mb-2"
                multiline
                rows={1}
                value={formik.values.facebookPonente}
                onChange={formik.handleChange}
                error={
                  formik.touched.facebookPonente &&
                  Boolean(formik.errors.facebookPonente)
                }
                helperText={
                  formik.touched.facebookPonente &&
                  formik.errors.facebookPonente
                }
              />

              <TextField
                fullWidth
                id="youtubePonente"
                name="youtubePonente"
                label="Youtube Ponente"
                disabled={true}
                className="mb-2"
                multiline
                rows={1}
                value={formik.values.youtubePonente}
                onChange={formik.handleChange}
                error={
                  formik.touched.youtubePonente &&
                  Boolean(formik.errors.youtubePonente)
                }
                helperText={
                  formik.touched.youtubePonente && formik.errors.youtubePonente
                }
              />

              <div className="my-3 d-none">
                <label for="cvFile" className="form-label">
                  Ingresa archivo PDF de Curriculum.
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="cvFile"
                  accept="application/pdf"
                  onChange={(e) => {
                    formik.setFieldValue("cvFile", e.target.files[0]);
                  }}
                />
              </div>
              <div className="my-3 d-none">
                <label for="imagenPonente" className="form-label">
                  Ingresa imagen de ponente.
                </label>
                <input
                  className="form-control"
                  type="file"
                  id="imagenPonente"
                  accept="image/x-png,image/jpeg"
                  onChange={(e) => {
                    formik.setFieldValue("imagenPonente", e.target.files[0]);
                  }}
                />
              </div>

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                Eliminar {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
