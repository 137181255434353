import React from "react";
import styles from "./EventsPublic.module.css";
import { Helmet } from "react-helmet-async";
import * as business from "../../../constants/BusinessInfo";
import { HeaderAnnouncementComponent } from "../../../Components/HeaderAnnouncementComponent/HeaderAnnouncementComponent";
import { CardFeaturedProductComponent } from "../../../Components/CardFeaturedProductComponent/CardFeaturedProductComponent";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { CardEventComponent } from "../../../Components/CardEventComponent/CardEventComponent";
import SearchIcon from "@material-ui/icons/Search";
import { FeaturedEventsComponent } from "../../../Components/FeaturedEventsComponent/FeaturedEventsComponent";
import { UpcomingEventsComponent } from "../../../Components/UpcomingEventsComponent/UpcomingEventsComponent";
import { AllEventsComponent } from "../../../Components/AllEventsComponent/AllEventsComponent";
import { SearchEventsComponent } from "./../../../Components/SearchEventsComponent/SearchEventsComponent";
import { SearchEventsResultComponent } from "../../../Components/SearchEventsResultComponent/SearchEventsResultComponent";

export const EventsPublic = (props) => {
  const [crudAction, setCrudAction] = React.useState({
    action: null,
    data: null,
  });

  return (
    <>
      {/*Buscar Eventos*/}
      <div
        className={styles.searchButton}
        onClick={() => {
          setCrudAction({ action: "search", data: [] });
        }}
      >
        <SearchIcon /> Buscar {props.title}
      </div>
      <Helmet>
        <title>{`${business.information.businessName} - ${props.title}`}</title>
        <meta name="description" content={props.description} />
      </Helmet>
      <div className="" style={{ marginTop: "88px" }}></div>
      <HeaderAnnouncementComponent
        title="Eventos"
        subtitle="Nuestros eventos"
        background={false}
      />
      {crudAction.action === "showResults" ? (
        <>
          <section className={`pt-1 pb-5 text-center`}>
            <div className="container mt-5 pt-2 pb-3">
              <div className="row">
                <div className="col-md-12 mb-5 mt-3">
                  <h2>Eventos Encontrados</h2>
                </div>
              </div>
            </div>

            <SearchEventsResultComponent data={crudAction.data} />
          </section>
        </>
      ) : null}

      <section className={`pt-1 pb-5 text-center`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Eventos Destacados</h2>
            </div>
          </div>
        </div>
        <FeaturedEventsComponent />
      </section>

      <HeaderAnnouncementComponent
        title="Promociones especiales"
        subtitle="Publica tu evento ahora!"
        background={false}
        icon={<LocalOfferIcon fontSize="large" />}
      />

      <section className={`pt-1 pb-5 text-center`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Eventos Recientes</h2>
            </div>
          </div>
        </div>
        <UpcomingEventsComponent />
      </section>

      <HeaderAnnouncementComponent
        title="Todos los eventos"
        subtitle="No te pierdas la oportunidad de tener tu evento en nuestra plataforma"
        background={false}
        icon={<LocalOfferIcon fontSize="large" />}
      />

      <section className={`pt-1 pb-5 text-center`}>
        <div className="container mt-5 pt-2 pb-3">
          <div className="row">
            <div className="col-md-12 mb-5 mt-3">
              <h2>Todos los eventos</h2>
            </div>
          </div>
        </div>
        <AllEventsComponent />
      </section>

      {crudAction.action === null ||
      crudAction.action == "showResults" ? null : (
        <SearchEventsComponent
          crudAction={crudAction}
          setCrudAction={setCrudAction}
        />
      )}
    </>
  );
};
