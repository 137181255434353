import React from "react";
import styles from "./BusinessDataScreen.module.css";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import jwt_decode from "jwt-decode";
import { AuthContext } from "../../../auth/authContext";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { API } from "../../../constants/ApiConnection";
import { toast } from "react-toastify";
import axios from "axios";
import { types } from "../../../types/types";

import { useHistory } from "react-router-dom";
import { CountryStateCity, getCities } from "../../../helpers/CountryStateCity";


const validationSchema = yup.object({
  nombreEmpresa: yup
    .string("Ingresa Nombre de la empresa")
    .required("El nombre es requerido"),
  nombreComercialEmpresa: yup
    .string("Ingresa Nombre Comercial de la empresa")
    .required("El nombre es requerido"),
  rfc: yup
    .string()
    .required("Por Favor Ingresa el RFC")
    .matches(
      /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
      "Verifica el RFC de la empresa"
    ),
  domicilioFiscal: yup
    .string("Ingresa domicilio fiscal")
    .required("Ingresa domicilio fiscal"),
    codigoPostal: yup
    .string("Ingresa domicilio fiscal")
    .required("Ingresa domicilio fiscal")
    .matches(/^\d{5}(\-?\d{4})?$/gm, "Verifica el código postal de la empresa"),
  telefonoFijo: yup
    .string("Ingresa teléfono fijo")
    .required("Teléfono Requerido")
    .matches(
      /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/,
      "Verifica el número"
    ),
  whatsapp: yup
    .string("Ingresa Whatsapp")
    .required("Whatsapp Requerido")
    .matches(
      /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/,
      "Verifica el número"
    ),
  direccionContacto: yup
    .string("Ingresa dirección de contacto")
    .required("Dirección de contacto requerida"),
  telefonoContacto: yup
    .string("Ingresa teléfono fijo")
    .required("Teléfono Requerido")
    .matches(
      /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/,
      "Verifica el número"
    ),
  emailContacto: yup
    .string("Ingresa Correo Electrónico")
    .email("Ingresa un correo válido")
    .required("Correo Necesario"),
  emailDestinoContacto: yup
    .string("Ingresa Correo Electrónico")
    .email("Ingresa un correo válido")
    .required("Correo Necesario"),
  nombreDirector: yup
    .string("Ingresa dirección de contacto")
    .required("Dirección de contacto requerida"),
  emailDirector: yup
    .string("Ingresa Correo Electrónico")
    .email("Ingresa un correo válido")
    .required("Correo Necesario"),
  webUrl: yup
    .string("Ingresa Página Web")
    .required("Página necesaria")
    .matches(
      /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi,
      "La dirección web no es correcta"
    ),
  facebookUrl: yup
    .string("Ingresa URL de Facebook")
    .required("URL de Facebook Necesari<")
    .matches(
      /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/gi,
      "La dirección Facebook no es correcta"
    ),
  instagramUrl: yup
    .string("Ingresa URL de Instagram")
    .required("URL de Instagram Necesaria")
    .matches(
      /(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9_.]{1,30}\/?/g,
      "La dirección Instagram no es correcta"
    ),
  twitterUrl: yup
    .string("Ingresa URL de Twitter")
    .required("URL de Twitter Necesaria")
    .matches(
      /(https?:\/\/)?(www\.)?twitter\.com\/[A-Za-z0-9_]{5,15}(\?(\w+=\w+&?)*)?/g,
      "La dirección Twitter no es correcta"
    ),
  horarioAtencion: yup
    .string("Ingresa el horario")
    .required("Horario requerido"),
  nombreContactoEUC: yup
    .string("Ingresa el Nombre de Contacto EUC")
    .required("Nombre requerido"),
  emailContactoEUC: yup
    .string("Ingresa Correo Electrónico")
    .email("Ingresa un correo válido")
    .required("Correo Necesario"),
  telefonoOficinaContactoEUC: yup
    .string("Ingresa teléfono fijo")
    .required("Teléfono Requerido")
    .matches(
      /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/,
      "Verifica el número"
    ),
    telefonoCelularContactoEUC: yup
    .string("Ingresa teléfono Celular")
    .required("Teléfono Requerido")
    .matches(
      /^(\(\+?\d{2,3}\)[\*|\s|\-|\.]?(([\d][\*|\s|\-|\.]?){6})(([\d][\s|\-|\.]?){2})?|(\+?[\d][\s|\-|\.]?){8}(([\d][\s|\-|\.]?){2}(([\d][\s|\-|\.]?){2})?)?)$/,
      "Verifica el número"
    ),
    puestoContactoEUC: yup
    .string("Ingresa el puesto de Contacto EUC")
    .required("Nombre requerido"),
    
});

export const BusinessDataScreen = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [userData, setUserData] = React.useState(jwt_decode(user.accessToken));
  const[finalData, setFinalData] = React.useState({})
  const [estados, setEstados] = React.useState([]);
  const [municipios, setMunicipios] = React.useState([]);

  React.useEffect(() => {
    CountryStateCity().then((resultado) => {
      CountryStateCity(1).then((resultados) => {
        setEstados(resultados);
        getInfo();
        })
        getCities(1).then((resultado) => {
          setMunicipios(resultado);
        })
    });
  }, []);

  async function getInfo() {
    const url = API + "user/getCompData/"+ userData.id;

    try {
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });

      if (response.status === 200) {
        
        setFinalData(response.data);

        formik.setFieldValue('idusuario', response.data.idusuario);
        formik.setFieldValue('nombreEmpresa', response.data.nombreEmpresa );
        formik.setFieldValue('nombreComercialEmpresa', response.data.nombreComercialEmpresa );
        formik.setFieldValue('rfc',response.data.rfc );
        formik.setFieldValue('domicilioFiscal', response.data.domicilioFiscal );
        formik.setFieldValue('idpais',response.data.idpais );
        formik.setFieldValue('idestado', response.data.idestado);
        formik.setFieldValue('idciudad', response.data.idciudad);
        formik.setFieldValue('codigoPostal', response.data.codigoPostal);
        formik.setFieldValue('telefonoFijo',response.data.telefonoFijo );
        formik.setFieldValue('whatsapp', response.data.whatsapp);
        formik.setFieldValue('direccionContacto', response.data.direccionContacto);
        formik.setFieldValue('telefonoContacto', response.data.telefonoContacto );
        formik.setFieldValue('emailContacto',response.data.emailContacto );
        formik.setFieldValue('emailDestinoContacto', response.data.emailDestinoContacto );
        formik.setFieldValue('nombreDirector',response.data.nombreDirector );
        formik.setFieldValue('emailDirector',response.data.emailDirector );
        formik.setFieldValue('webUrl', response.data.webUrl);
        formik.setFieldValue('facebookUrl', response.data.facebookUrl );
        formik.setFieldValue('instagramUrl', response.data.instagramUrl );
        formik.setFieldValue('twitterUrl', response.data.twitterUrl );
        formik.setFieldValue('horarioAtencion', response.data.horarioAtencion );
        formik.setFieldValue('nombreContactoEUC',  response.data.nombreContactoEUC);
        formik.setFieldValue('emailContactoEUC',  response.data.emailContactoEUC);
        formik.setFieldValue('telefonoOficinaContactoEUC', response.data.telefonoOficinaContactoEUC );
        formik.setFieldValue('telefonoCelularContactoEUC',response.data.telefonoCelularContactoEUC  );
        formik.setFieldValue('puestoContactoEUC', response.data.puestoContactoEUC  );
      
        getCities(response.data.idestado).then((resultado) => {
          setMunicipios(resultado);
          formik.setFieldValue('idciudad', response.data.idciudad);
        })

      }
      
    
    } catch (error) {
      

     

      toast.info("Te recomendamos completar tu información de empresa", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (error.response.status === 401) {
        toast.error("Tu sesión ha caducado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      idusuario: userData.id,
      nombreEmpresa: "",
      nombreComercialEmpresa: "",
      rfc: "",
      domicilioFiscal: "",
      idpais: "1",
      idestado: "",
      idciudad: "",
      codigoPostal: "",
      telefonoFijo: "",
      whatsapp: "",
      direccionContacto: "",
      telefonoContacto: "",
      emailContacto: "",
      emailDestinoContacto: "",
      nombreDirector: "",
      emailDirector: "",
      webUrl: "",
      facebookUrl: "",
      instagramUrl: "",
      twitterUrl: "",
      horarioAtencion: "",
      nombreContactoEUC: "",
      emailContactoEUC: "",
      telefonoOficinaContactoEUC: "",
      telefonoCelularContactoEUC: "",
      puestoContactoEUC: ""
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      APIData(values);
    },
  });

  
  const APIData = async (values) => {
    try {
      const urls = API + "user/updateCompData";
      const response = await axios.post(API + "user/updateCompData", values, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });
      toast.success("Información Agregada Correctamente!  ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      console.warn(error.response);
      toast(error.response.data.message);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };


  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="container-fluid mt-3">
          <div className="row">
            <div className="col-12">
              <h4 className="text-success">{props.title}</h4>
              <p>{props.description}</p>
            </div>
          </div>
        </div>
        <div className="container-fluid my-3">
          <div className="row row-cols-md-1">
            <div className="col mb-2">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title mb-2">Datos principales de empresa</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Información General.
                  </h6>
                  <div className="container-fluid g-0">
                    <div className="row row-cols-md-3">
                      <div className="col">
                        <TextField
                          fullWidth
                          id="nombreEmpresa"
                          name="nombreEmpresa"
                          label="Nombre de empresa"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.nombreEmpresa}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.nombreEmpresa &&
                            Boolean(formik.errors.nombreEmpresa)
                          }
                          helperText={
                            formik.touched.nombreEmpresa &&
                            formik.errors.nombreEmpresa
                          }
                        />
                      </div>
                      <div className="col">
                        <TextField
                          fullWidth
                          id="nombreComercialEmpresa"
                          name="nombreComercialEmpresa"
                          label="Nombre Comercial Empresa"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.nombreComercialEmpresa}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.nombreComercialEmpresa &&
                            Boolean(formik.errors.nombreComercialEmpresa)
                          }
                          helperText={
                            formik.touched.nombreComercialEmpresa &&
                            formik.errors.nombreComercialEmpresa
                          }
                        />
                      </div>
                      <div className="col">
                        <TextField
                          fullWidth
                          id="rfc"
                          name="rfc"
                          label="RFC"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.rfc}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.rfc && Boolean(formik.errors.rfc)
                          }
                          helperText={formik.touched.rfc && formik.errors.rfc}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <TextField
                          fullWidth
                          id="domicilioFiscal"
                          name="domicilioFiscal"
                          label="Domicilio Fiscal Completo"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.domicilioFiscal}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.domicilioFiscal &&
                            Boolean(formik.errors.domicilioFiscal)
                          }
                          helperText={
                            formik.touched.domicilioFiscal &&
                            formik.errors.domicilioFiscal
                          }
                        />
                      </div>
                      <div className="col-md-2">
                        <FormControl shrink variant="filled" fullWidth>
                          <InputLabel htmlFor="Pais">Pais</InputLabel>
                          <Select
                            native
                            value={formik.values.idpais}
                            onChange={formik.handleChange}
                            inputProps={{
                              name: "idpais",
                              id: "idpais",
                            }}
                          >
                            <option value={"1"}>MÉXICO</option>
                           
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-2">
                        <FormControl variant="filled" fullWidth>
                          <InputLabel shrink htmlFor="idestado">Estado</InputLabel>
                          <Select
                            native
                            
                            value={formik.values.idestado}
                            onChange={(e) => {
                              formik.handleChange(e);
                              var idEstado = e.target.value;
                              getCities(idEstado).then((resultado) => {
                                setMunicipios(resultado);
                              })
                            }}
                            inputProps={{
                              name: "idestado",
                              id: "idestado",
                            }}
                          >
                           {estados.map((estado) => (
                          <>
                            <option value={estado.idestado}>{estado.nombreEstado}</option>
                          </>
                        ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-2">
                        <FormControl shrink variant="filled" fullWidth>
                          <InputLabel shrink htmlFor="idciudad">Ciudad</InputLabel>
                          <Select
                            native
                            value={formik.values.idciudad}
                            onChange={formik.handleChange}
                            inputProps={{
                              name: "idciudad",
                              id: "idciudad",
                            }}
                          >
                           {municipios.map((municipio) => (
                          <>
                            <option value={municipio.idciudad}>{municipio.nombreCiudad}</option>
                          </>
                        ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="col-md-2">
                        <TextField
                          fullWidth
                          id="codigoPostal"
                          name="codigoPostal"
                          label="Código Postal"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.codigoPostal}
                          onChange={formik.handleChange}
                          error={formik.touched.codigoPostal && Boolean(formik.errors.codigoPostal)}
                          helperText={formik.touched.codigoPostal && formik.errors.codigoPostal}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="telefonoFijo"
                          name="telefonoFijo"
                          label="Teléfono Fijo (10 dígitos)"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.telefonoFijo}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.telefonoFijo &&
                            Boolean(formik.errors.telefonoFijo)
                          }
                          helperText={
                            formik.touched.telefonoFijo &&
                            formik.errors.telefonoFijo
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="whatsapp"
                          name="whatsapp"
                          label="Whatsapp (10 dígitos)"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.whatsapp}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.whatsapp &&
                            Boolean(formik.errors.whatsapp)
                          }
                          helperText={
                            formik.touched.whatsapp && formik.errors.whatsapp
                          }
                        />
                      </div>
                      <div className="col-md-12">
                        <TextField
                          fullWidth
                          id="horarioAtencion"
                          name="horarioAtencion"
                          label="Horario de atención"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.horarioAtencion}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.horarioAtencion &&
                            Boolean(formik.errors.horarioAtencion)
                          }
                          helperText={
                            formik.touched.horarioAtencion &&
                            formik.errors.horarioAtencion
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card mb-2">
                <div className="card-body">
                  <h5 className="card-title mb-2">
                    Datos de Contacto para formularios
                  </h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    Esta información aparecerá en el formulario de contacto.
                  </h6>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="direccionContacto"
                          name="direccionContacto"
                          label="Dirección de Contacto"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.direccionContacto}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.direccionContacto &&
                            Boolean(formik.errors.direccionContacto)
                          }
                          helperText={
                            formik.touched.direccionContacto &&
                            formik.errors.direccionContacto
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="telefonoContacto"
                          name="telefonoContacto"
                          label="Teléfono de Contacto"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.telefonoContacto}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.telefonoContacto &&
                            Boolean(formik.errors.telefonoContacto)
                          }
                          helperText={
                            formik.touched.telefonoContacto &&
                            formik.errors.telefonoContacto
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="emailContacto"
                          name="emailContacto"
                          label="Email Contacto"
                          variant="filled"
                          size="small"
                          type="email"
                          className="my-2"
                          value={formik.values.emailContacto}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.emailContacto &&
                            Boolean(formik.errors.emailContacto)
                          }
                          helperText={
                            formik.touched.emailContacto &&
                            formik.errors.emailContacto
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="emailDestinoContacto"
                          name="emailDestinoContacto"
                          label="Correo destinatario de mensajes de contacto"
                          variant="filled"
                          size="small"
                          className="my-2"
                          type="email"
                          value={formik.values.emailDestinoContacto}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.emailDestinoContacto &&
                            Boolean(formik.errors.emailDestinoContacto)
                          }
                          helperText={
                            formik.touched.emailDestinoContacto &&
                            formik.errors.emailDestinoContacto
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card border-success mb-2 ">
                <div className="card-body">
                  <h5 className="card-title mb-2">Datos de Gerente General</h5>
                  <h6 className="card-subtitle mb-2">
                    Ingresa los datos del gerente de tu empresa!
                  </h6>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="nombreDirector"
                          name="nombreDirector"
                          label="Nombre de director"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.nombreDirector}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.nombreDirector &&
                            Boolean(formik.errors.nombreDirector)
                          }
                          helperText={
                            formik.touched.nombreDirector &&
                            formik.errors.nombreDirector
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="emailDirector"
                          name="emailDirector"
                          label="Correo Director"
                          variant="filled"
                          size="small"
                          className="my-2"
                          type="email"
                          value={formik.values.emailDirector}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.emailDirector &&
                            Boolean(formik.errors.emailDirector)
                          }
                          helperText={
                            formik.touched.emailDirector &&
                            formik.errors.emailDirector
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="card border-info mb-2 ">
                <div className="card-body">
                  <h5 className="card-title mb-2">Redes Sociales</h5>
                  <h6 className="card-subtitle mb-2">
                    Ingresa los datos de Redes Sociales
                  </h6>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="webUrl"
                          name="webUrl"
                          label="Url página web"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.webUrl}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.webUrl &&
                            Boolean(formik.errors.webUrl)
                          }
                          helperText={
                            formik.touched.webUrl && formik.errors.webUrl
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="facebookUrl"
                          name="facebookUrl"
                          label="Facebook Url"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.facebookUrl}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.facebookUrl &&
                            Boolean(formik.errors.facebookUrl)
                          }
                          helperText={
                            formik.touched.facebookUrl &&
                            formik.errors.facebookUrl
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="instagramUrl"
                          name="instagramUrl"
                          label="Instagram Url"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.instagramUrl}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.instagramUrl &&
                            Boolean(formik.errors.instagramUrl)
                          }
                          helperText={
                            formik.touched.instagramUrl &&
                            formik.errors.instagramUrl
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="twitterUrl"
                          name="twitterUrl"
                          label="Twitter Url"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.twitterUrl}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.twitterUrl &&
                            Boolean(formik.errors.twitterUrl)
                          }
                          helperText={
                            formik.touched.twitterUrl &&
                            formik.errors.twitterUrl
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div
                className="card border-success mb-2 "
                style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
              >
                <div className="card-body">
                  <h5 className="card-title mb-2">
                    Datos de Contacto exclusivo EUC
                  </h5>
                  <h6 className="card-subtitle mb-2">
                    Estos datos serviran para ponernos en contacto contigo lo
                    más rápido posible
                  </h6>
                  <div className="container-fluid g-0">
                    <div className="row">
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="nombreContactoEUC"
                          name="nombreContactoEUC"
                          label="Nombre de Contacto EUC"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.nombreContactoEUC}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.nombreContactoEUC &&
                            Boolean(formik.errors.nombreContactoEUC)
                          }
                          helperText={
                            formik.touched.nombreContactoEUC &&
                            formik.errors.nombreContactoEUC
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="emailContactoEUC"
                          name="emailContactoEUC"
                          label="Correo para contacto EUC"
                          variant="filled"
                          size="small"
                          className="my-2"
                          type="email"
                          value={formik.values.emailContactoEUC}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.emailContactoEUC &&
                            Boolean(formik.errors.emailContactoEUC)
                          }
                          helperText={
                            formik.touched.emailContactoEUC &&
                            formik.errors.emailContactoEUC
                          }
                        />
                      </div>
                     
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="telefonoOficinaContactoEUC"
                          name="telefonoOficinaContactoEUC"
                          label="Teléfono Oficina Contacto"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.telefonoOficinaContactoEUC}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.telefonoOficinaContactoEUC &&
                            Boolean(formik.errors.telefonoOficinaContactoEUC)
                          }
                          helperText={
                            formik.touched.telefonoOficinaContactoEUC &&
                            formik.errors.telefonoOficinaContactoEUC
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="telefonoCelularContactoEUC"
                          name="telefonoCelularContactoEUC"
                          label="Teléfono Celular Contacto"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.telefonoCelularContactoEUC}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.telefonoCelularContactoEUC &&
                            Boolean(formik.errors.telefonoCelularContactoEUC)
                          }
                          helperText={
                            formik.touched.telefonoCelularContactoEUC &&
                            formik.errors.telefonoCelularContactoEUC
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <TextField
                          fullWidth
                          id="puestoContactoEUC"
                          name="puestoContactoEUC"
                          label="Puesto Contacto"
                          variant="filled"
                          size="small"
                          className="my-2"
                          value={formik.values.puestoContactoEUC}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.puestoContactoEUC &&
                            Boolean(formik.errors.puestoContactoEUC)
                          }
                          helperText={
                            formik.touched.puestoContactoEUC &&
                            formik.errors.puestoContactoEUC
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                <button
                  className="btn btn-lg text-white btn-primary me-md-2"
                  type="submit"
                >
                  Guardar Cambios
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};
