import React from "react";
import InfoIcon from "@material-ui/icons/Info";
export const NoDataComponent = (props) => {
  return (
    <div className="container-fluid text-center">
      <div className="row">
        <div className="col-12 py-5 h4">
          <InfoIcon className="text-info" fontSize="inherit" />
          <br></br>
          <br></br>
          <strong className="text-info"> No existen {props.nombre} </strong>
          <br></br>
          <br></br>
          <span className="fw-light h5">
            Pulsa el botón de agregar para comenzar.
          </span>
        </div>
      </div>
    </div>
  );
};
