/* eslint-disable no-unused-vars */
import React from "react";
import axios from "axios";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import { UserDecode } from "../../../helpers/UserDecode";
import { toast } from "react-toastify";
import { API } from "../../../constants/ApiConnection";
import { AuthContext } from "../../../auth/authContext";
import { types } from "../../../types/types";
import { CardEventAdministrationComponent } from "../../../Components/CardEventAdministrationComponent/CardEventAdministrationComponent";
import { DeleteEventComponent } from "../../../Components/DeleteEventComponent/DeleteEventComponent";
import { checkViewPermission } from "../../../API/APIPermisos";
import { SinPermisoParaAcceder } from "../../Public/SinPermisoParaAcceder/SinPermisoParaAcceder";
import { LoaderEventsComponent } from "./../../../Components/LoaderEventsComponent/LoaderEventsComponent";
import Skeleton from "@material-ui/lab/Skeleton";
const uuidVista = "03d1a359-7bbe-407e-a37e-5a00f2c5b600";

export const EventListScreen = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  let history = useHistory();
  const [finalData, setFinalData] = React.useState([]);
  const idusuarioPadre = UserDecode().idusuarioPadre;
  const idusuarioPadreTodo = UserDecode();


   

  const [CrudType, setCrudType] = React.useState({
    type: null,
    data: null,
  });

  const [resultadoPermisos, setResultadoPermisos] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const [statusPermiso, setStatusPermiso] = React.useState(0);
  React.useEffect(() => {
    checkViewPermission(uuidVista, user).then((resultado) => {
      setResultadoPermisos(resultado.data);
      setStatusPermiso(resultado.status);
      if (resultado.status === 200) {
        addPrincipalData(user, idusuarioPadre).then((result) => {
          setFinalData(result);
          setCargando(false);
        });
      }
    });
  }, []);

  React.useEffect(() => {
    addPrincipalData(user, idusuarioPadre).then((result) => {
       

      setFinalData(result);
    });
  }, [CrudType]);

  //Agregamos datos principales
  const addPrincipalData = async (user, idusuarioPadre) => {
    try {
      const response = await axios.get(
        API + "eventos/getEventosByMaster/" + idusuarioPadre,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Access-Control-Allow-Headers": "*",
            "x-access-token": user.accessToken,
          },
        }
      );

       
      return response.data;
    } catch (error) {
      console.warn(error.response);
      if (error.response.status === 401) {
        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  return (
    <>
      {statusPermiso === 200 ? (
        <>
          {CrudType.type === "eliminar" ? (
            <DeleteEventComponent
              data={CrudType}
              setCrudType={setCrudType}
              nombre="evento"
            />
          ) : null}
          <div className="container-fluid mt-4">
            <div className="row">
              <div className="col-12">
                <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                  {resultadoPermisos.map((permiso, index) => (
                    <React.Fragment key={index}>
                      {permiso.guidFuncion ==
                        "896d0353-fcc2-427d-b859-ad14e9423a30" &&
                      permiso.allowed == 1 ? (
                        <>
                          <button
                            className="btn btn-primary text-white"
                            type="button"
                            onClick={() => history.push("/addevent")}
                          >
                            <AddCircleIcon fontSize="inherit" /> Agregar Evento
                          </button>
                        </>
                      ) : null}
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid mt-4">
            <div className="row row-cols-md-1">
              {finalData.length === 0 ? (
                <>
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-12 text-center py-3">
                        <h4>No tienes eventos asignados</h4> <br></br>
                        <h4>Consulta al administrador</h4>
                      </div>
                    </div>
                  </div>

                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={250}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={250}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={250}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={250}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={250}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={250}
                    className="mb-2"
                  />
                  <Skeleton
                    variant="rect"
                    width={"100%"}
                    height={250}
                    className="mb-2"
                  />
                </>
              ) : null}
              {finalData.map((evento, index) => (
                <CardEventAdministrationComponent
                  key={index}
                  data={evento}
                  setCrudType={setCrudType}
                  resultadoPermisos={resultadoPermisos}
                />
              ))}
            </div>
          </div>
        </>
      ) : statusPermiso === 0 ? (
        <LoaderEventsComponent />
      ) : (
        <SinPermisoParaAcceder />
      )}
    </>
  );
};
