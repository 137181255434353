/* eslint-disable no-unused-vars */
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import { EditDirectoryDataEventComponent } from "../../../Components/EditDirectoryDataEventComponent/EditDirectoryDataEventComponent";
import { EditEventImageDataEventComponent } from "../../../Components/EditEventImageDataEventComponent/EditEventImageDataEventComponent";
import { EditFrecuentQuestionsDataEventComponent } from "../../../Components/EditFrecuentQuestionsDataEventComponent/EditFrecuentQuestionsDataEventComponent";
import { EditGaleryDataEventComponent } from "../../../Components/EditGaleryDataEventComponent/EditGaleryDataEventComponent";
import { EditGeneralDataEventComponent } from "../../../Components/EditGeneralDataEventComponent/EditGeneralDataEventComponent";
import { EditPlanoDataEventComponent } from "../../../Components/EditPlanoDataEventComponent/EditPlanoDataEventComponent";
import { EditPoliticasDataEventComponent } from "../../../Components/EditPoliticasDataEventComponent/EditPoliticasDataEventComponent";
import { EditPricingDataEventComponent } from "../../../Components/EditPricingDataEventComponent/EditPricingDataEventComponent";
import InfoIcon from "@material-ui/icons/Info";
import ListIcon from "@material-ui/icons/List";
import ImageIcon from "@material-ui/icons/Image";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import DescriptionIcon from "@material-ui/icons/Description";
import MapIcon from "@material-ui/icons/Map";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import { EditProgramDataEventComponent } from "../../../Components/EditProgramDataEventComponent/EditProgramDataEventComponent";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { EditPonentesDataEventComponent } from "../../../Components/EditPonentesDataEventComponent/EditPonentesDataEventComponent";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import { EditSponsorsDataEventComponent } from "../../../Components/EditSponsorsDataEventComponent/EditSponsorsDataEventComponent";
import { EditEventPortadaImageDataEventComponent } from "../../../Components/EditEventPortadaImageDataEventComponent/EditEventPortadaImageDataEventComponent";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import { EditOrganizadoresDataEventComponent } from "../../../Components/EditOrganizadoresDataEventComponent/EditOrganizadoresDataEventComponent";
import { checkViewPermission } from "../../../API/APIPermisos";
import { AuthContext } from "../../../auth/authContext";
import { LoaderEventsComponent } from "../../../Components/LoaderEventsComponent/LoaderEventsComponent";
import { SinPermisoParaAcceder } from "../../Public/SinPermisoParaAcceder/SinPermisoParaAcceder";
import PanToolIcon from "@material-ui/icons/PanTool";
import { getDetalleEventoPrivado } from "../../../API/APIEventos";
import { LoremComponent } from "../../../Components/LoremComponent/LoremComponent";

const uuidVista = "03d1a359-7bbe-407e-a37e-5a00f2c5b600";

export const EventEditScreen = () => {
  const [idEvento, setIdEvento] = React.useState(useParams().id);
  const { user, dispatch } = React.useContext(AuthContext);

  const [resultadoPermisos, setResultadoPermisos] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const [statusPermiso, setStatusPermiso] = React.useState(0);

  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [permisoDatosGenerales, setPermisoDatosGenerales] =
    React.useState(false);
  const [permisoOrganizadores, setPermisoOrganizadores] = React.useState(false);
  const [permisoPatrocinadores, setPermisoPatrocinadores] =
    React.useState(false);
  const [permisoPonentes, setPermisoPonentes] = React.useState(false);
  const [permisoPrograma, setPermisoPrograma] = React.useState(false);
  const [permisoImagen, setPermisoImagen] = React.useState(false);
  const [permisoPrecios, setPermisoPrecios] = React.useState(false);
  const [permisoDirectorio, setPermisoDirectorio] = React.useState(false);
  const [permisosPreguntasFrecuentes, setPermisosPreguntasFrecuentes] =
    React.useState(false);
  const [permisosPoliticas, setPermisosPoliticas] = React.useState(false);
  const [permisoPlano, setPermisoPlano] = React.useState(false);
  const [permisoGaleria, setPermisoGaleria] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    setCargando(true);
    getDetalleEventoPrivado(user, idEvento).then((resultado) => {
       
      if (resultado.length === 0) {
        history.push("/403");
      }
      else{
        setCargando(false);
      }
    });
  }, []);

  React.useEffect(() => {
    setCargando(true);
    checkViewPermission(uuidVista, user).then((resultado) => {
      getDetalleEventoPrivado(user, idEvento).then((resultado) => {
         
        if (resultado.length === 0) {
          history.push("/403");
        }
        else{
          setCargando(false);
        }
      });

       
      setResultadoPermisos(resultado.data);
      setStatusPermiso(resultado.status);
      for (const permiso of resultado.data) {
        if (
          permiso.guidFuncion == "e447ab1e-bd2d-48b0-8d35-b15564b055fd" &&
          permiso.allowed == 1
        ) {
          setPermisoParaEditar(true);
        }
        if (
          permiso.guidFuncion == "e11ccb9f-3583-4fdc-8531-3fb9600ce071" &&
          permiso.allowed == 1
        ) {
          setPermisoDatosGenerales(true);
        }
        if (
          permiso.guidFuncion == "c506cb5a-e66b-4400-9665-8f150878f1c0" &&
          permiso.allowed == 1
        ) {
          setPermisoOrganizadores(true);
        }
        if (
          permiso.guidFuncion == "8b13ae9f-81ba-4a6e-ad77-cb50088d1489" &&
          permiso.allowed == 1
        ) {
          setPermisoPatrocinadores(true);
        }
        if (
          permiso.guidFuncion == "f6f496e6-c46f-4f04-999c-a6f08b8bef29" &&
          permiso.allowed == 1
        ) {
          setPermisoPonentes(true);
        }
        if (
          permiso.guidFuncion == "165f6994-d956-4b44-9f77-9bed3bf4fa9e" &&
          permiso.allowed == 1
        ) {
          setPermisoPrograma(true);
        }
        if (
          permiso.guidFuncion == "bdfc53bf-39a0-4fc3-b562-3939693c76bb" &&
          permiso.allowed == 1
        ) {
          setPermisoImagen(true);
        }
        if (
          permiso.guidFuncion == "6f1fb6e6-dee3-4848-93d1-63ba58988bbc" &&
          permiso.allowed == 1
        ) {
          setPermisoPrecios(true);
        }
        if (
          permiso.guidFuncion == "dbdd0797-0e83-4270-8aaf-0c251d60b699" &&
          permiso.allowed == 1
        ) {
          setPermisoDirectorio(true);
        }
        if (
          permiso.guidFuncion == "879a3a01-f969-49c7-9170-ebdfc310c597" &&
          permiso.allowed == 1
        ) {
          setPermisosPreguntasFrecuentes(true);
        }
        if (
          permiso.guidFuncion == "637f1aa9-c9e5-4176-99dc-776ef0c13d20" &&
          permiso.allowed == 1
        ) {
          setPermisosPoliticas(true);
        }
        if (
          permiso.guidFuncion == "d2ec3188-4f31-4018-bda9-756366ae06f0" &&
          permiso.allowed == 1
        ) {
          setPermisoPlano(true);
        }
        if (
          permiso.guidFuncion == "6bf4e133-921f-4314-9219-b999da4446aa" &&
          permiso.allowed == 1
        ) {
          setPermisoGaleria(true);
        }
      }
    });
  }, []);

  return (
    <>
      {" "}
      {cargando === false ? (
        <>
          {permisoParaEditar === false ? (
            <SinPermisoParaAcceder />
          ) : (
            <>
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-bienvenida-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-bienvenida"
                    type="button"
                    role="tab"
                    aria-controls="nav-bienvenida"
                    aria-selected="true"
                  >
                    <PanToolIcon fontSize="inherit" className="me-1" />{" "}
                    Bienvenido
                  </button>

                  {permisoDatosGenerales === true ? (
                    <button
                      className="nav-link"
                      id="nav-home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-home"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      <InfoIcon fontSize="inherit" className="me-1" /> Datos
                      Generales
                    </button>
                  ) : null}

                  {permisoOrganizadores === true ? (
                    <button
                      className="nav-link"
                      id="nav-organizadores-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-organizadores"
                      type="button"
                      role="tab"
                      aria-controls="nav-organizadores"
                      aria-selected="true"
                    >
                      <EmojiPeopleIcon fontSize="inherit" className="me-1" />{" "}
                      Organizadores
                    </button>
                  ) : null}

                  {permisoPatrocinadores === true ? (
                    <button
                      className="nav-link"
                      id="nav-patrocinadores-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-patrocinadores"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      <PeopleAltIcon fontSize="inherit" className="me-1" />{" "}
                      Patrocinadores
                    </button>
                  ) : null}

                  {permisoPonentes === true ? (
                    <button
                      className="nav-link"
                      id="nav-ponentes-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-ponentes"
                      type="button"
                      role="tab"
                      aria-controls="nav-home"
                      aria-selected="true"
                    >
                      <SupervisorAccountIcon
                        fontSize="inherit"
                        className="me-1"
                      />{" "}
                      Ponentes
                    </button>
                  ) : null}

                  {permisoPrograma === true ? (
                    <button
                      className="nav-link"
                      id="nav-programa-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-programa"
                      type="button"
                      role="tab"
                      aria-controls="nav-programa"
                      aria-selected="true"
                    >
                      <ListIcon fontSize="inherit" className="me-1" /> Programa
                    </button>
                  ) : null}

                  {permisoImagen === true ? (
                    <button
                      className="nav-link"
                      id="nav-imagenevento-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-imagenevento"
                      type="button"
                      role="tab"
                      aria-controls="nav-imagenevento"
                      aria-selected="false"
                    >
                      <ImageIcon fontSize="inherit" className="me-1" /> Imagen
                      Evento
                    </button>
                  ) : null}

                  {permisoPrecios === true ? (
                    <button
                      className="nav-link"
                      id="nav-precio-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-precio"
                      type="button"
                      role="tab"
                      aria-controls="nav-profile"
                      aria-selected="false"
                    >
                      <MonetizationOnIcon fontSize="inherit" className="me-1" />{" "}
                      Precios
                    </button>
                  ) : null}

                  {permisoDirectorio === true ? (
                    <button
                      className="nav-link"
                      id="nav-directorio-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-directorio"
                      type="button"
                      role="tab"
                      aria-controls="nav-directorio"
                      aria-selected="false"
                    >
                      <ImportContactsIcon fontSize="inherit" className="me-1" />{" "}
                      Directorio
                    </button>
                  ) : null}

                  {permisosPreguntasFrecuentes === true ? (
                    <button
                      className="nav-link"
                      id="nav-preguntas-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-preguntas"
                      type="button"
                      role="tab"
                      aria-controls="nav-preguntas"
                      aria-selected="false"
                    >
                      <LiveHelpIcon fontSize="inherit" className="me-1" />{" "}
                      Preguntas Frecuentes
                    </button>
                  ) : null}

                  {permisosPoliticas === true ? (
                    <button
                      className="nav-link"
                      id="nav-politicas-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-politicas"
                      type="button"
                      role="tab"
                      aria-controls="nav-politicas"
                      aria-selected="false"
                    >
                      <DescriptionIcon fontSize="inherit" className="me-1" />{" "}
                      Políticas
                    </button>
                  ) : null}

                  {permisoPlano === true ? (
                    <button
                      className="nav-link"
                      id="nav-plano-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-plano"
                      type="button"
                      role="tab"
                      aria-controls="nav-plano"
                      aria-selected="false"
                    >
                      <MapIcon fontSize="inherit" className="me-1" /> Plano
                    </button>
                  ) : null}
                  {permisoGaleria === true ? (
                    <button
                      className="nav-link"
                      id="nav-galeria-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-galeria"
                      type="button"
                      role="tab"
                      aria-controls="nav-galeria"
                      aria-selected="false"
                    >
                      <PhotoCameraIcon fontSize="inherit" className="me-1" />{" "}
                      Galería
                    </button>
                  ) : null}
                </div>
              </nav>
              <div
                className="tab-content bg-white pt-3 p-2"
                id="nav-tabContent"
              >
                {permisoDatosGenerales === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-home"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <EditGeneralDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisoPrecios === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-precio"
                    role="tabpanel"
                    aria-labelledby="nav-precio-tab"
                  >
                    <EditPricingDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisoImagen === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-imagenevento"
                    role="tabpanel"
                    aria-labelledby="nav-imagenevento-tab"
                  >
                    <EditEventImageDataEventComponent id={idEvento} />
                    <EditEventPortadaImageDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisoDirectorio === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-directorio"
                    role="tabpanel"
                    aria-labelledby="nav-directorio-tab"
                  >
                    <EditDirectoryDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisosPreguntasFrecuentes === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-preguntas"
                    role="tabpanel"
                    aria-labelledby="nav-preguntas-tab"
                  >
                    <EditFrecuentQuestionsDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisosPoliticas === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-politicas"
                    role="tabpanel"
                    aria-labelledby="nav-politicas-tab"
                  >
                    <EditPoliticasDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisoPlano === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-plano"
                    role="tabpanel"
                    aria-labelledby="nav-plano-tab"
                  >
                    <EditPlanoDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisoGaleria === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-galeria"
                    role="tabpanel"
                    aria-labelledby="nav-galeria-tab"
                  >
                    <EditGaleryDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisoPrograma === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-programa"
                    role="tabpanel"
                    aria-labelledby="nav-programa-tab"
                  >
                    <EditProgramDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisoPonentes === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-ponentes"
                    role="tabpanel"
                    aria-labelledby="nav-ponentes-tab"
                  >
                    <EditPonentesDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisoPatrocinadores === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-patrocinadores"
                    role="tabpanel"
                    aria-labelledby="nav-patrocinadores-tab"
                  >
                    <EditSponsorsDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                {permisoOrganizadores === true ? (
                  <div
                    className="tab-pane fade"
                    id="nav-organizadores"
                    role="tabpanel"
                    aria-labelledby="nav-organizadores-tab"
                  >
                    <EditOrganizadoresDataEventComponent id={idEvento} />
                  </div>
                ) : null}

                <div
                  className="tab-pane fade show active p-3"
                  id="nav-bienvenida"
                  role="tabpanel"
                  aria-labelledby="nav-bienvenida-tab"
                >
                  <h4>Bienvenido a la edición del evento.</h4>
                  <p>
                    <LoremComponent />
                  </p>
                  <p>
                    <LoremComponent />
                  </p>
                </div>
              </div>
            </>
          )}
        </>
      ) : null}
    </>
  );
};
