import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode, decode } from "base64-arraybuffer";
export const getPonentesPorEvento = async (values, user, idEvento) => {
  let url = API + "ponente/ponentesPorEvento/" + idEvento;

  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const ponente of response.data) {
        ponente.imagenDecoded =
          "data:image/png;base64," + encode(ponente.imagenPonente.data);
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const asignarPonenteAActividad = async (values, user) => {
  let url = API + "ponente/ponenteActividad/";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
    
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const GetPonentesPorActividad = async (values, user, idactividad) => {
   
  let url = API + "ponente/ponentesPorActividad/" + idactividad;
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const ponente of response.data) {
        ponente.imagenDecoded =
          "data:image/png;base64," + encode(ponente.imagenPonente.data);
      }
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const publicGetPonentesPorActividad = async (values, idactividad) => {
   
  let url = API + "ponente/ponentesPorActividad/" + idactividad;
  try {
    const response = await axios.get(url,  {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
       
      },
    });
    if (response.status === 200) {
      for (const ponente of response.data) {
        ponente.imagenDecoded =
          "data:image/png;base64," + encode(ponente.imagenPonente.data);
      }
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
