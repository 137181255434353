import React from "react";

export const ServiciosOfrecidosRecintoPublico = (props) => {
  return (
    <>
      <h6>Servicios ofrecidos:</h6>
      <hr></hr>
      <table className="table table-borderless">
        <thead>
          <tr>
            <th scope="col">Servicio</th>
            <th scope="col">Precio</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((servicio, index) => (
            <tr key={index}>
              <td>
                {servicio.nombreServicio} <br></br>{" "}
                <small>{servicio.descripcion}</small>
              </td>
              <td>$ {servicio.precio}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};
