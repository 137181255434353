/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";

import { AuthContext } from "../../../../auth/authContext";

import { useHistory } from "react-router-dom";
import { API } from "./../../../../constants/ApiConnection";
import { types } from "../../../../types/types";
import { createCarousel } from "../../../../API/APICarouselPrincipal";
import { createAd } from "./../../../../API/APIPublicidadEvento";
import { getAllEvents } from "../../../../API/APIEventos";

import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import { asignarPublicidadAEvento } from './../../../../API/APIPublicidadEvento';

export const AsignarAEventoAd = (props) => {
  let history = useHistory();
  const { user, dispatch } = React.useContext(AuthContext);
  const [finalData, setFinalData] = React.useState([]);
  const [disabled, setDisabled] = React.useState(false);

  React.useEffect(() => {
    getAllEvents(null, user).then((resultado) => {
      setFinalData(resultado);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
        
    },
    onSubmit: (values) => {
      //alert(JSON.stringify(values, null, 2));
      addData(values);
    },
  });

  const addData = async (values) => {

     


    asignarPublicidadAEvento(values, user, props.crudAction.data.id).then((respuesta) => {
      if (respuesta.status === 200) {
        toast.success("Pubilicidad linkeada Correctamente", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        });
        props.setCrudAction({ action: null, data: null });
      } else {
        console.error(respuesta);
        if (respuesta.status === 401) {
          dispatch({
            type: types.logout,
          });
          history.replace("/");
        }
      }
    });
  };

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      props.setCrudAction({ action: null, data: null });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  return (
    <div className="crudDivComponent col-md-3">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12 my-4">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h5>{props.nombre}</h5>
              </div>
              <div>
                <button
                  className="btn btn-sm btn-danger"
                  onClick={() =>
                    props.setCrudAction({ action: null, data: null })
                  }
                >
                  {" "}
                  <CloseIcon fontSize="inherit" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <form onSubmit={formik.handleSubmit}>
              <FormControl fullWidth>
                <InputLabel htmlFor="idevento">Evento</InputLabel>
                <Select
                  disabled={disabled}
                  autoWidth
                  native
                  className="mb-2"
                  value={formik.values.idevento}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  inputProps={{ name: "idevento", id: "idevento" }}
                >
                  <option aria-label="Seleccionar Evento">
                    Seleccionar Evento
                  </option>
                  <option value={0}>
                     Desligar
                    </option>
                  {finalData.map((evento, index) => (
                    <option key={index} value={evento.id}>
                      {evento.tituloEvento}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Button
                color="primary"
                variant="contained"
                fullWidth
                type="submit"
                className="mt-3"
              >
                {props.nombre}
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
