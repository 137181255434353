import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
export const getCarousel = async (values, user) => {
  let url = API + "sitio/getCarrousell";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        imagen.imagenConvertida =
          "data:image/png;base64," + encode(imagen.imagen.data);
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPublicCarousel = async (values) => {
  let url = API + "public/getCarrousell";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {

 
      for (const imagen of response.data) {
        imagen.imagenDecoded =
          "data:image/png;base64," + encode(imagen.imagen.data);
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const createCarousel = async (values, user, formik) => {
  let url = API + "sitio/createCarrousell";
  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data boundary=${formik._boundary}`,
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const alternarImagenCarousel = async (values, user, idimagen) => {
  let url = API + "sitio/unsetCarrousell/" + idimagen;

   
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDetalleCarousel = async (values, user, idimagen) => {
  let url = API + "sitio/detalleCarrousell/" + idimagen;
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const setOrdenCarousel = async (values, user, idimagen) => {
  let url = API + "sitio/setOrden";
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
