import React from "react";
import styles from "./CardFeaturedProductComponent.module.css";
import EventIcon from "@material-ui/icons/Event";
import { DateTime } from "luxon";
import { noImageAvailable } from "./../../Images/noImageAvailable";
import {
  setUnsetEspecialDestacado,
  setUnsetEspecialProximo,
  setUnsetEventoProximo,
} from "../../API/APIEventos";
import { AuthContext } from "../../auth/authContext";
import stylesDos from "../CardSponsorComponent/CardSponsorComponent.module.css";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import locale from "dayjs/locale/es-mx";

export const CardFeaturedProductComponent = (props) => {

  dayjs.extend(localizedFormat);

  const { user, dispatch } = React.useContext(AuthContext);
  var fotoFinal = noImageAvailable();

  return (
    <div className="col-sm-4 mb-3">
      <div className="card">
        <div
          className={`${stylesDos.aspectratio__container} ${stylesDos.aspect_ratio__container_1x1}`}
        >
          <picture className={`${stylesDos.aspect_ratio__overlay}`}>
            <img
              className={`${stylesDos.aspect_ratio__image}`}
              src={props.data.imagenDecoded}
              alt=""
            />
          </picture>
          <div className="w-100 text-end">
            <span className="badge bg-primary">Evento Destacado</span>
          </div>
        </div>
        <div className="card-body text-start">
          {props.admin === true && props.destacado === true ? (
            <>
              <div className="w-100 text-end">
                <div
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    setUnsetEspecialDestacado(
                      { idevento: props.data.id },
                      user,
                      props.data.id
                    ).then((resultado) => {
                      props.setCrudAction({
                        action: "" + Math.ceil(Math.random() * 100),
                        data: props.data,
                      });
                    });
                  }}
                >
                  Borrar
                </div>
                <div
                  className="btn btn-sm btn-warning ms-2"
                  onClick={() => {
                    props.setCrudAction({
                      action: "changeOrder",
                      data: props.data,
                    });
                  }}
                >
                  Cambiar Orden
                </div>
              </div>
            </>
          ) : null}
          {props.admin === true && props.proximos === true ? (
            <>
              <div className="w-100 text-end">
                <div
                  className="btn btn-sm btn-danger"
                  onClick={() => {
                    setUnsetEspecialProximo(
                      { idevento: props.data.id },
                      user,
                      props.data.id
                    ).then((resultado) => {
                      props.setCrudAction({
                        action: "" + Math.ceil(Math.random() * 100),
                        data: props.data,
                      });
                    });
                  }}
                >
                  Borrar
                </div>
              </div>
            </>
          ) : null}
          <h5 className="card-title mb-1">{props.data.tituloEvento.substring(0,35) + '...'}</h5>
          <p className="card-text mt-0">
            <small className="text-muted">
              <span className="text-success">
                <EventIcon fontSize="small" />{" "}
                {dayjs(props.data.fechaInicio).locale("es-mx").format("lll")}
              </span>
              <br />
              Categoría de Evento
              <br /> <br />
              <strong>
                {props.data.descripcionCortaEvento.substring(0, 50) + '...'}
              </strong>
            </small>
          </p>

          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <a
              href={`event/${props.data.id}`}
              className="btn btn-sm btn-dark me-md-2 text-white"
            >
              Ver Evento
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
