import { API } from "./../constants/ApiConnection";
import axios from "axios";
import { encode } from "base64-arraybuffer";
import { noImageAvailable } from "../Images/noImageAvailable";
import { ToastContainer, toast } from "react-toastify";

export const getAllEvents = async (values, user) => {
  let url = API + "eventos/getEventos";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getAllEventsPublic = async (values) => {
  let url = API + "eventos/getEventos";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenDecoded = noImageAvailable();
        } else {
          imagen.imagenDecoded =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const setUnsetEspecialDestacado = async (values, user, idevento) => {
  let url = API + "sitio/setUnsetEventoDestacado/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      toast.success("Ejecución exitosa");
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const setUnsetEspecialProximo = async (values, user, idevento) => {
  let url = API + "sitio/setUnsetEventoProximo/";

  try {
    const response = await axios.post(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      toast.success("Ejecución exitosa");
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const eventosSeleccionarDestacados = async (values, user) => {
  let url = API + "sitio/eventosSeleccionarDestacados";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const eventosSeleccionarProximos = async (values, user) => {
  let url = API + "sitio/getProximos";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenConvertida = noImageAvailable();
        } else {
          imagen.imagenConvertida =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getPublicFeaturedEvents = async (values) => {
  let url = API + "public/getDestacados";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenDecoded = noImageAvailable();
        } else {
          imagen.imagenDecoded =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getEventosSeleccionarProximos = async (values, user) => {
  let url = API + "sitio/eventosSeleccionarProximos/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenDecoded = noImageAvailable();
        } else {
          imagen.imagenDecoded =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const setUnsetEventoProximo = async (values, user) => {
  let url = API + "sitio/eventosSeleccionarProximos/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenDecoded = noImageAvailable();
        } else {
          imagen.imagenDecoded =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
export const getPublicProximos = async (values) => {
  let url = API + "public/getProximos/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenDecoded = noImageAvailable();
        } else {
          imagen.imagenDecoded =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const setOrdenDestacados = async (values, user, idevento) => {
  let url = API + "sitio/setOrdenDestacados/" + idevento;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      for (const imagen of response.data) {
        if (imagen.imagenPortada === null) {
          imagen.imagenDecoded = noImageAvailable();
        } else {
          imagen.imagenDecoded =
            "data:image/png;base64," + encode(imagen.imagenPortada.data);
        }
      }

      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const getDetalleEventoPrivado = async (user, idEvento) => {


  try {
    const response = await axios.get(
      API + "eventos/detalleEvento/" + idEvento,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      }
    );

  
    return response.data;
  } catch (error) {
    console.warn(error.response);
    toast(error.response.data.message);
    return [];
  }
};
