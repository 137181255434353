import React from "react";
import { getPublicAdsEnclosure } from "../../API/APIPublicidadEnclosure";
import { getPublicAds } from "./../../API/APIPublicidadEvento";

export const PublicAdsEclosureComponent = (props) => {
  const [finalData, setFinalData] = React.useState([]);

  React.useEffect(() => {
    getPublicAdsEnclosure(null).then((resultado) => {
      const resultadoFinal = resultado.data.filter(
        (ad) => ad.idrecinto == props.idrecinto
      );

      if (resultadoFinal.length > 0) {
        setFinalData(resultadoFinal);
      } else {
        setFinalData(resultado.data);
      }
    });
  }, []);

  return (
    <>
      <h6>
        <i className="fas fa-users"></i>&nbsp;&nbsp;Publicidad Events U-Ctrl
      </h6>
      <hr></hr>
      <div className="container-fluid g-0">
        <div className="row g-0">
          {finalData.map((imagen, index) => (
            <img
              key={index}
              src={imagen.imagenConvertida}
              alt="Publicidad Events U-Ctrl"
              className="w-100 mb-2"
            />
          ))}
        </div>
      </div>
    </>
  );
};
