import React from "react";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import GetAppIcon from "@material-ui/icons/GetApp";
import { encode, decode } from "base64-arraybuffer";
export const CardPlanoEventComponent = (props) => {
 
    

  return (
    <div className="col">
      <div className="card mb-2">
        <div className="card-header bg-white d-flex align-items-center d-flex justify-content-between">
          <h6 className="mt-2 text-muted">Plano</h6>
          <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button
              className="btn btn-sm text-white btn-danger"
              type="button"
              onClick={() =>
                props.setCrudAction({ action: "delete", data: props.data })
              }
            >
              Eliminar
            </button>
          </div>
        </div>

        <div className="card-body d-flex justify-content-between">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6">
                <strong>{props.index + 1}.- {props.data.descripcion} </strong>
                <br></br>
                <small>Este plano</small>
              </div>
              <div className="col-md-6">
                <img
                  alt="ImagenPlano"
                  className="img-fluid"
                  style={{ width: "100%" }}
                  src={`data:image/png;base64,${encode(props.data.imagenPlano.data)}`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
