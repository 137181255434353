import { API } from "./../constants/ApiConnection";
import axios from "axios";

export const getAdminUsers = async (user) => {
  let url = API + "user/getisAdmin/";
  try {
    const response = await axios.get(url, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const setNewPasswordAdmin = async (values, user) => {

     
  let url = API + "user/resetPassword/" + values.id;
  try {
    const response = await axios.put(url, values, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Headers": "*",
        "x-access-token": user.accessToken,
      },
    });
    if (response.status === 200) {
      return response.data;
    } else if (response.status === 401) {
      return response;
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};
