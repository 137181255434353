import React from "react";
import { AuthContext } from "../../../auth/authContext";
import { API } from "../../../constants/ApiConnection";
import styles from "./BusinessUsersScreen.module.css";
import { CreateUserScreen } from "./CreateUserScreen/CreateUserScreen";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { types } from "../../../types/types";
import { SubUserComponent } from "../../../Components/SubUserComponent/SubUserComponent";
import { RepeatOneSharp } from "@material-ui/icons";
import { checkViewPermission } from "../../../API/APIPermisos";
import { LoaderEventsComponent } from "../../../Components/LoaderEventsComponent/LoaderEventsComponent";
import { SinPermisoParaAcceder } from "../../Public/SinPermisoParaAcceder/SinPermisoParaAcceder";
import { UserPermissionsComponent } from "../../../Components/UserPermissionsComponent/UserPermissionsComponent";
import { UserAssignEventsComponent } from "../../../Components/UserAssignEventsComponent/UserAssignEventsComponent";
const uuidVista = "a10831d5-7c6b-450e-8b5e-c7f222e6b528";

export const BusinessUsersScreen = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [resultData, setResultData] = React.useState([]);

  const [crudType, setCrudType] = React.useState({
    action: null,
    data: null,
  });

  let history = useHistory();

  const [resultadoPermisos, setResultadoPermisos] = React.useState([]);
  const [cargando, setCargando] = React.useState(true);
  const [statusPermiso, setStatusPermiso] = React.useState(0);
  const [counterUpdate, setCounterUpdate] = React.useState(0);
  React.useEffect(() => {
    checkViewPermission(uuidVista, user).then((resultado) => {
      setResultadoPermisos(resultado.data);
      setStatusPermiso(resultado.status);
      if (resultado.status === 200) {
        getData().then((resultado) => {
          setCargando(false);
        });
      }
    });
  }, []);
  React.useEffect(() => {
    checkViewPermission(uuidVista, user).then((resultado) => {
      setResultadoPermisos(resultado.data);
      setStatusPermiso(resultado.status);
      if (resultado.status === 200) {
        getData().then((resultado) => {
          setCargando(false);
        });
      }
    });
  }, [counterUpdate]);

  React.useEffect(() => {}, []);

  const getData = async () => {
    const { idusuarioPadre } = await jwt_decode(user.accessToken);
    const url = API + "user/getSubuser/" + idusuarioPadre;
    try {
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Headers": "*",
          "x-access-token": user.accessToken,
        },
      });
      if (response.status === 200) {
        response.data.length == 1
          ? setResultData([])
          : setResultData(response.data);
      }
    } catch (error) {
      toast.error("Error en llamada: " + JSON.stringify(error), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (error.response.status === 401) {
        toast.error("Tu sesión ha caducado", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        dispatch({
          type: types.logout,
        });
        history.replace("/");
      }
    }
  };

  return (
    <>
      {statusPermiso === 200 ? (
        <>
          <div className="container-fluid mt-3">
            <div className="row">
              <div className="col-12">
                <h4 className="text-success">{props.title}</h4>
                <p>{props.description}</p>
              </div>
            </div>

            {resultadoPermisos.map((permiso, index) => (
              <React.Fragment key={index}>
                {permiso.guidFuncion ==
                  "a9bc7b07-6f19-4e34-94c1-400368c42e6a" &&
                permiso.allowed == 1 ? (
                  <>
                    <CreateUserScreen counterUpdate={counterUpdate} setCounterUpdate={setCounterUpdate} />
                  </>
                ) : null}
              </React.Fragment>
            ))}
          </div>

          <div className="container-fluid my-4">
            <div className="row row-cols-md-3">
              {resultData.map((result) => (
                <SubUserComponent
                  key={result.id}
                  data={result}
                  resultadoPermisos={resultadoPermisos}
                  crudType={crudType}
                  setCrudType={setCrudType}
                />
              ))}
            </div>
          </div>

          {crudType.action === "userPermissions" ? (
            <>
              <UserPermissionsComponent
                crudType={crudType}
                setCrudType={setCrudType}
                nombre="Categoria"
              />
            </>
          ) : null}
          {crudType.action === "assignEvents" ? (
            <>
              <UserAssignEventsComponent
                crudType={crudType}
                setCrudType={setCrudType}
                nombre="Categoria"
              />
            </>
          ) : null}
        </>
      ) : statusPermiso === 0 ? (
        <LoaderEventsComponent />
      ) : (
        <SinPermisoParaAcceder />
      )}
    </>
  );
};
